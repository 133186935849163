import React, { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { Icon, OutsideClickHandler } from 'components';

import styles from './Modal.module.sass';

const Modal = ({
  visible,
  onClose,
  title,
  children,
  blurBackground,
  closeOnOutsideClick,
  className,
  hideHeader,
  outerClasses
}) => {
  const closeModalHandler = useCallback(() => {
    document.body.style.overflow = 'auto';
    onClose();
  }, [onClose]);

  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) closeModalHandler();
    },
    [closeModalHandler]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const handleClose = () => {
    if (closeOnOutsideClick) {
      closeModalHandler();
    }
  };

  return createPortal(
    visible && (
      <div
        id='modal'
        className={classNames(styles.modal, outerClasses, {
          [styles.blur]: blurBackground,
          [styles.hideHeader]: hideHeader
        })}
      >
        <OutsideClickHandler onOutsideClick={handleClose}>
          <div
            className={classNames(styles.outer, className, {
              [styles.hideHeader]: hideHeader
            })}
          >
            {!hideHeader && (
              <div className={styles.header}>
                <h3 title={title}>{title}</h3>
                <button
                  className={styles.close}
                  onClick={closeModalHandler}
                >
                  <Icon
                    name='close'
                    size='20'
                  />
                </button>
              </div>
            )}
            {children}
          </div>
        </OutsideClickHandler>
      </div>
    ),
    document.body
  );
};

export default Modal;
