import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { PageHeader, PaginationNav, Searchbar, SwitchButton } from 'components';
import { headerTextPlaceholder } from 'components/layout/PageHeader/placeholders';
import SeoHelmet from 'components/layout/SeoHelmet';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import pages from 'src/dictionaries/pages.json';
import { getCorrectFormOfResultsLabel } from 'src/utils/helpers';
import query from 'src/utils/query';

import List from './components/List';

import style from './CompaniesList.module.scss';

const CompaniesList = (props) => {
  const params = query(props);
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAsTree, setShowAsTree] = useState(false);
  const [currentPage, setCurrentPage] = useState(params.get('page'));
  const [querySearch, setQuerySearch] = useState(params.get('search'));
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [itemsQty, setItemsQty] = useState();

  const version = useSelector((state) => state.companiesList.version);

  useEffect(() => {
    const getDataWithLoading = async () => {
      if (!isLoading) {
        setIsLoading(true);
        await getData();
        setIsLoading(false);
      }
    };

    getDataWithLoading();
  }, [currentPage, querySearch, perPage, showAsTree]);

  useEffect(() => {
    getData();
  }, [version]);

  const getCompanies = useMemo(() => (showAsTree ? AdminApi.getCompaniesAsTree : AdminApi.getCompanies), [showAsTree]);

  const getData = async () => {
    const queryProps = {
      page: currentPage || 1,
      perPage: perPage,
      search: querySearch
    };

    try {
      const { data } = await getCompanies(queryProps);
      setPageData(data.data);
      setItemsQty(data.total);
      setPageQty(data?.last_page);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleChangeViewType = (value) => {
    params.set('page', 1);
    setCurrentPage(1);
    setShowAsTree(value);
  };

  useEffect(() => {
    setCurrentPage(params.get('page'));
    setQuerySearch(params.get('search'));
  }, [params]);

  return (
    <>
      <SeoHelmet title={pages.companies.title} />
      <PageHeader
        name={pages.companies.title}
        wrapOnMobile
        text={isLoading ? headerTextPlaceholder() : getCorrectFormOfResultsLabel(itemsQty)}
      >
        <div className={style.headerWrapper}>
          <SwitchButton
            label={'Widok w formie drzewa'}
            value={showAsTree}
            setValue={handleChangeViewType}
          />
          <Searchbar params={params} />
        </div>
      </PageHeader>
      <List
        companies={pageData}
        isLoading={isLoading}
      />
      <PaginationNav
        onClick={(number) => setCurrentPage(number)}
        params={params}
        pagesQty={pageQty}
        setPerPage={setPerPage}
        defaultQty={perPage}
      />
    </>
  );
};

export default CompaniesList;
