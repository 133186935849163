import React from 'react';
import { RadioGroup } from '@mui/material';

import { Card, Tag, useIsMedicover } from 'components';
import Radio from 'components/layout/Radio';

import style from 'src/styles/custom/Shared.module.scss';

const OrderOptions = ({ groupName, sectionTitle, options = [], setCurrentOption, currentOption, showError, errorMessage }) => {
  const isMedicover = useIsMedicover();

  if (isMedicover) {
    return null;
  }

  return (
    <Card className={style.wrapper}>
      <div className={style.defaultWrapper}>
        <h2 className={style.cardTitle}>{sectionTitle}</h2>
        <RadioGroup name={groupName}>
          {options.length > 0 ? (
            options.map(({ name, id }) => (
              <Radio
                onChange={() => setCurrentOption(id)}
                value={currentOption === id}
                content={name}
                key={id}
              />
            ))
          ) : (
            <Tag value={'Brak sposobów płatności'} />
          )}
        </RadioGroup>
        {showError && <p>{errorMessage}</p>}
      </div>
    </Card>
  );
};

export default OrderOptions;
