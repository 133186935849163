import React from 'react';
import classNames from 'classnames';

import { AdditionalShipping, Tag } from 'components';

import Accordion from 'src/components/layout/Accordion';
import shared from 'src/styles/custom/Shared.module.scss';
import { getFormattedAmount } from 'src/utils/helpers';

import style from './Summary.module.scss';

const Summary = (props) => {
  const { totalSum, productsSum, payMethod, shipMethod, additionalShippingPrice, supplierComment, hideShipment } = props;
  const title = <h3 className={classNames(shared.tableCategoryTitle, shared.large, shared.noPadding)}>Podsumowanie</h3>;

  return (
    <Accordion
      section
      title={title}
      className={classNames(shared.tableCategory)}
      extraDeps={[props]}
    >
      {!hideShipment && (
        <>
          <div className={style.container}>
            <p className={style.sumText}>Sposób płatności:</p>
            <p className={style.sumAmount}>{payMethod ? payMethod : <Tag value={'Brak'} />}</p>
          </div>
          <div className={style.container}>
            <p className={style.sumText}>Sposób wysyłki:</p>
            <p className={style.sumAmount}>{shipMethod ? shipMethod : <Tag value={'Brak'} />}</p>
          </div>
        </>
      )}
      <div className={style.container}>
        <p className={style.sumText}>Wartość produktów:</p>
        <p className={style.sumAmount}>{getFormattedAmount(productsSum)}</p>
      </div>
      <div className={style.container}>
        <p className={style.sumText}>Koszty wysyłki:</p>
        <p className={style.sumAmount}>{getFormattedAmount(totalSum - productsSum - (additionalShippingPrice || 0))}</p>
      </div>
      {!!additionalShippingPrice && (
        <div className={style.container}>
          <p className={style.sumText}>Dodatkowe koszty wysyłki:</p>
          <p className={classNames(style.sumAmount, style.gapRemove)}>
            <AdditionalShipping
              price={additionalShippingPrice}
              comment={supplierComment}
              tooltipFirst
              noPlus
            />
          </p>
        </div>
      )}
      <div className={style.container}>
        <p className={classNames(style.sumText, style.final)}>Suma zamówienia:</p>
        <p className={classNames(style.sumAmount, style.final)}>{getFormattedAmount(totalSum)}</p>
      </div>
    </Accordion>
  );
};

export default Summary;
