import React from 'react';

const useIsMedicover = () => {
  const value = process.env.REACT_APP_IS_MEDICOVER;
  if (value) {
    return JSON.parse(value);
  } else {
    return false;
  }
};

export default useIsMedicover;
