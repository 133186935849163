import React from 'react';
import classNames from 'classnames';

import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import shared from 'src/styles/custom/Shared.module.scss';

import SupplierRow from '../SupplierRow';

import style from './SuppliersTable.module.scss';

const SuppliersTable = ({ suppliers = [], isLoading }) => {
  if (isLoading) {
    return (
      <section className={classNames(shared.tableWrapper, style.table)}>
        <div className={classNames(shared.tableHeader, style.tableHeader)}>
          <p>Dostawca</p>
          <p>Link do produktu</p>
          <p>Cena</p>
        </div>
        <ProductPlaceholder />
      </section>
    );
  }

  return (
    <section className={classNames(shared.tableWrapper, style.table)}>
      <div className={classNames(shared.tableHeader, style.tableHeader)}>
        <p>Dostawca</p>
        <p>Link do produktu</p>
        <p>Cena</p>
        <p>Wariant ID</p>
        <p>Blok. Stan</p>
        <p>Blok. Cena</p>
      </div>
      <div className={classNames(shared.tableCategory, style.tableCategory)}>
        {suppliers.map((supplier) => (
          <SupplierRow
            supplier={supplier}
            key={supplier.id}
          />
        ))}
      </div>
    </section>
  );
};

export default SuppliersTable;
