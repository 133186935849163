import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import cn from 'classnames';

import { Button, LoaderGlobal, PublicFooter, PublicHeader } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AccountsApi } from 'src/api';

import MemberRegisterForm from './components/MemberRegisterForm';

import style from '../../onboarding.module.scss';

const NewMember = () => {
  const { token } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [userDetails, setUserDetails] = useState();

  const getMemberData = async () => {
    const queryParams = { invitation_token: token };

    try {
      setIsLoading(true);
      const { data } = await AccountsApi.validateInvitation(queryParams);
      setUserDetails(data);
    } catch (err) {
      if (err.response.status === 404) {
        history.push({
          pathname: '/404',
          state: { message: err.response.data.message }
        });
      } else {
        notifyApiError(err);
        history.push('/');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleJoinCompany = async () => {
    const queryParams = { invitation_token: token };

    try {
      setIsButtonLoading(true);
      await AccountsApi.joinCompany(queryParams);
      history.push('/dashboard');
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsButtonLoading(false);
    }
  };

  useEffect(() => {
    getMemberData();
  }, []);

  if (userDetails?.user_exists) {
    history.push(`/join-company/${token}`);
  }

  if (isLoading) {
    return <LoaderGlobal />;
  }

  return (
    <>
      <PublicHeader />
      <div className={style.newMemberContainer}>
        <div className={cn(style.column, style.center)}>
          <div className={style.welcomeWrapper}>
            <h2>
              <span className={style.blue}>{userDetails?.company_name}</span> wita w aplikacji Dentametr
            </h2>
            <p>
              Cieszymy się, że dołączasz do grona naszych użytkowników.
              {userDetails?.user_exists ? ' Potwierdź zaproszenie' : ' Zarejestruj się '}
              aby uzyskać dostęp.
            </p>
          </div>
          {userDetails?.user_exists ? (
            <Button
              label={'Dołącz do ' + userDetails?.company_name}
              onClick={handleJoinCompany}
              isLoading={isButtonLoading}
            />
          ) : (
            <MemberRegisterForm
              email={userDetails?.email}
              token={token}
            />
          )}
        </div>
      </div>
      <PublicFooter />
    </>
  );
};

export default NewMember;
