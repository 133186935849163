import { PageHeader, SeoHelmet } from 'components';

import { informationPagesTypes } from 'src/constants/enums';
import pages from 'src/dictionaries/pages.json';

import InformationSection from '../../components/InformationSection';
import News from '../../components/News';
import QuickAccess from '../../components/QuickAccess';

import style from '../../Dashboard.module.scss';

const SupplierDashboard = () => {
  return (
    <>
      <SeoHelmet title={pages.dashboard.title} />
      <PageHeader
        name={pages.dashboard.title}
        className={style.header}
      />
      <div className={style.container}>
        <InformationSection type={informationPagesTypes.supplier} />
        <QuickAccess />
        {/*<div className={style.column}>*/}
        {/*  <News />*/}
        {/*</div>*/}
      </div>
    </>
  );
};

export default SupplierDashboard;
