import React, { useState } from 'react';

import placeholder from 'assets/img/small.png';
import { Button, ProductSearch, QtySelector } from 'components';
import SearchThumbnail from 'components/layout/SearchThumbnail';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';

import style from './WarehouseAddProduct.module.scss';

const WarehouseAddProduct = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState();
  const [price, setPrice] = useState();

  const addToWarehouse = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const params = {
        product_id: selectedProduct.id,
        consumption: 0,
        quantity,
        state_id: 1,
        price: price
      };
      await WarehouseApi.addProduct(params);
      notifyCommon([`Produkt ${selectedProduct.name} został dodany do magazynu.`]);
      setSelectedProduct(null);
      setQuantity(null);
      setPrice(null);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = (item) => {
    setSelectedProduct(item);
  };

  return (
    <form
      className={style.container}
      onSubmit={(e) => addToWarehouse(e)}
    >
      {selectedProduct ? (
        <SearchThumbnail
          onClear={() => setSelectedProduct(null)}
          title={selectedProduct.name}
          id={selectedProduct.id}
          subtitle={selectedProduct.producer}
          thumbnail={selectedProduct?.thumbnail || placeholder}
          hideQty
        />
      ) : (
        <ProductSearch
          onProductClick={handleClick}
          hideQty
        />
      )}
      <div className={style.wrapper}>
        <div className={style.row}>
          <QtySelector
            label={'Aktualna liczba w magazynie'}
            state={quantity}
            id={'quantity'}
            setState={setQuantity}
            wrapperStyle={style.qtySelector}
          />
          <QtySelector
            label={'Cena za sztukę'}
            state={price}
            id={'price'}
            name={'price'}
            setState={setPrice}
            wrapperStyle={style.qtySelector}
            suffix={'zł'}
            hideArrows
            priceInput
          />
        </div>
        <Button
          className={style.button}
          label={'Zapisz'}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default WarehouseAddProduct;
