import React, { useEffect, useState } from 'react';

import { AsyncSelect, Card, Chart } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { OrdersApi, StatisticsApi } from 'src/api';
import { orderStatuses } from 'src/constants/enums';
import { getShortTime } from 'src/utils/dateTime';

import style from '../Charts.module.scss';

const defaultData = [
  {
    name: 'Czas do otrzymania zamówienia',
    data: []
  },
  {
    name: 'Czas obsługi zamówienia',
    data: []
  }
];

const TimeOfResponseOfSingleOrder = ({ selectedCompanyId }) => {
  const [chartData, setChartData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const getData = async () => {
    if (!selectedOrder?.value) return;

    const queryData = {
      public_id: selectedOrder?.value,
      ...(selectedCompanyId && { company_id: selectedCompanyId })
    };

    try {
      setIsLoading(true);
      const { data } = await StatisticsApi.getActionsTimesForOrder(queryData);
      setChartData(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getInitialOrder = async () => {
    const params = { page: 1, perPage: 1, hide: true, status: orderStatuses.completed };
    try {
      const { data } = await OrdersApi.getOrders(params);
      if (data.data.length > 0) {
        const { public_id, name } = data.data[0];
        setSelectedOrder({ label: name, value: public_id });
      }
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    getInitialOrder();
  }, []);

  useEffect(() => {
    getData();
  }, [selectedOrder]);

  const chartHeight = Math.max((chartData?.supplier_names?.length || 0) * 50, 300);
  const colors = ['#F9D370', '#6AF1C0'];

  return (
    <Card className={style.container}>
      <header className={style.header}>
        <h2 className={style.title}>Czas reakcji dostawców - szczegóły zamówienia</h2>
        <div className={style.options}>
          <AsyncSelect
            value={selectedOrder}
            onChange={setSelectedOrder}
            apiCallback={OrdersApi.getOrders}
            valueKey={'public_id'}
            labelKey={'name'}
            placeholder={'#F5F32X'}
            label={'Zrealizowane zamówienia'}
            queryParams={{ ...(selectedCompanyId && { company_id: selectedCompanyId }), status: orderStatuses.completed }}
            className={style.select}
            wrapperStyle={style.select}
          />
        </div>
      </header>
      <Chart
        customSeries={chartData?.series || defaultData}
        categories={chartData?.categories}
        emptyStateType={'avgSupplierTimeChart'}
        xAxisFormatter={getShortTime}
        dataLabelsFormatter={getShortTime}
        tooltipFormatter={getShortTime}
        haveData={chartData?.have_data}
        isLoading={isLoading}
        chartTypes={['bar']}
        height={chartHeight}
        colors={colors}
        tooltipShared
        horizontal
        fullWidth
        stacked
      />
    </Card>
  );
};

export default TimeOfResponseOfSingleOrder;
