import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SwitchButton } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';

import { changeCompaniesVersion } from '../../actions';

import style from './OfferCatalog.module.scss';

const CompanySettings = ({ company }) => {
  const dispatch = useDispatch();
  const firstRender = useRef(true);

  const [companyConfig, setCompanyConfig] = useState(company);
  const [isLoading, setIsLoading] = useState(false);

  const toggleSuppliersManagementState = async () => {
    const queryData = {
      suppliers_management_enabled: companyConfig.suppliers_management_enabled,
      is_order_approval_enabled: companyConfig.is_order_approval_enabled,
      offer_catalog_for_corp: companyConfig.offer_catalog_for_corp,
      offer_catalog_enabled: companyConfig.offer_catalog_enabled
    };

    try {
      setIsLoading(true);
      await AdminApi.setCompanyDetails(company.id, queryData);
      dispatch(changeCompaniesVersion());
      notifyCommon(['Zapisano ustawienia firmy.']);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (value, name) => {
    setCompanyConfig((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      toggleSuppliersManagementState();
    }
  }, [companyConfig]);

  return (
    <div className={style.container}>
      <div className={style.box}>
        <h3>Zarządzanie dostawcami</h3>
        <SwitchButton
          value={companyConfig.suppliers_management_enabled}
          setValue={(val) => onChange(val, 'suppliers_management_enabled')}
          label={'Dostęp do Zarządzania dostawcami'}
          isLoading={isLoading}
          reverse
        />
      </div>
      <div className={style.box}>
        <h3>Katalog ofert</h3>
        <SwitchButton
          value={companyConfig.offer_catalog_enabled}
          setValue={(val) => onChange(val, 'offer_catalog_enabled')}
          label={'Dostęp do Katalogu ofert'}
          isLoading={isLoading}
          reverse
        />
        <SwitchButton
          value={companyConfig.offer_catalog_for_corp}
          setValue={(val) => onChange(val, 'offer_catalog_for_corp')}
          label={'Katalog w wersji zdefiniowanej'}
          isLoading={isLoading}
          reverse
        />
      </div>
      <div className={style.box}>
        <h3>Akceptacja zamówień</h3>
        <SwitchButton
          value={companyConfig.is_order_approval_enabled}
          setValue={(val) => onChange(val, 'is_order_approval_enabled')}
          label={'Proces akceptacji zamówień'}
          isLoading={isLoading}
          reverse
        />
      </div>
    </div>
  );
};

export default CompanySettings;
