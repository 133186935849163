import React from 'react';
import classNames from 'classnames';

import { ColumnSortButton, EmptyState } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import UserThumbnail from 'src/features/Users/components/UserThumbnail';
import shared from 'src/styles/custom/Shared.module.scss';

import style from './UsersTable.module.scss';

const UsersTable = ({ list = [], isLoading, params }) => {
  if (isLoading) {
    return (
      <section className={shared.tableWrapper}>
        <div className={classNames(shared.tableHeader, style.header)}>
          <p>E-mail</p>
          <p>Nazwa</p>
          <p>Rola</p>
          <p>Telefon</p>
          <p>Data logowania</p>
        </div>
        <div className={shared.tableCategory}>
          <ProductPlaceholder />
        </div>
      </section>
    );
  }

  return (
    <section className={shared.tableWrapper}>
      <div className={classNames(shared.tableHeader, style.header)}>
        <ColumnSortButton
          parameterName={'role'}
          params={params}
          content={'Rola'}
        />
        <ColumnSortButton
          parameterName={'email'}
          params={params}
          content={'E-mail'}
        />
        <ColumnSortButton
          parameterName={'name'}
          params={params}
          content={'Nazwa'}
        />
        <ColumnSortButton
          parameterName={'phone'}
          params={params}
          content={'Telefon'}
        />
        <ColumnSortButton
          parameterName={'last_login_at'}
          params={params}
          content={'Data logowania'}
        />
        <ColumnSortButton
          parameterName={'created_at'}
          params={params}
          content={'Rejestracja'}
        />
      </div>
      <div className={shared.tableCategory}>
        {list.length ? (
          list.map((user) => (
            <UserThumbnail
              key={user.id}
              user={user}
            />
          ))
        ) : (
          <EmptyState type={'users'} />
        )}
      </div>
    </section>
  );
};

export default UsersTable;
