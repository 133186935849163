import React, { useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { OutsideClickHandler } from 'components';

import styles from './Popover.module.sass';

const Popover = (props) => {
  const { className, classActionsBody, visible, setVisible, up, disabled = false, displayOver, content, children } = props;
  const [innerVisible, setInnerVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({});
  const elementTarget = useRef(null);

  const setPosition = () => {
    if (elementTarget.current && displayOver) {
      const data = elementTarget.current?.getBoundingClientRect();
      setMenuPosition({ top: data.bottom + 12, left: data.right });
    }
  };

  useLayoutEffect(() => {
    if (innerVisible && displayOver) window.addEventListener('scroll', setPosition);

    return () => {
      window.removeEventListener('scroll', setPosition);
    };
  }, [innerVisible, displayOver, elementTarget.current]);

  const toggle = () => {
    if (!disabled) {
      setPosition();
      setVisible ? setVisible(!visible) : setInnerVisible(!innerVisible);
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => (setVisible ? setVisible(false) : setInnerVisible(false))}>
      <div
        className={cn(styles.actions, className, {
          [styles.active]: visible ? visible : innerVisible,
          [styles.up]: up
        })}
      >
        <button
          onClick={toggle}
          id={'menu-button'}
          ref={elementTarget}
        >
          {children}
        </button>
        <div
          className={cn(styles.body, { [styles.displayOver]: displayOver }, classActionsBody)}
          style={menuPosition}
        >
          {content}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Popover;
