import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import { Checkbox, QtySelector, SaveButton, useModalConfirm, useValidator } from 'components';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OrdersApi } from 'src/api';
import { getProductStatusTag } from 'src/utils/helpers';

import { refreshOrder } from '../../../../actions';

import style from './ProductSelector.module.scss';

const ProductSelector = ({ product, isSelected, onSelectChange, disabled, collectPartOfAmount }) => {
  const { thumbnail, image, name, qty, unit_name, product_id, received_quantity } = product;
  const validator = useValidator();
  const dispatch = useDispatch();
  const { uid } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [quantity, setQuantity] = useState(qty - received_quantity);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [wasCollected, setWasCollected] = useState(false);

  const collectHandler = () => {
    if (wasCollected) {
      handleOpenModalConfirm({
        message: 'Na pewno chcesz ponownie odebrać produkt?',
        handleConfirm: collectPartOfProductAmountHandler,
        cancelButtonName: 'Nie',
        confirmButtonName: 'Tak'
      });
    } else {
      collectPartOfProductAmountHandler();
    }
  };

  const collectPartOfProductAmountHandler = async () => {
    const queryData = {
      uid,
      product_id,
      received_quantity: quantity
    };

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsLoading(true);
      await OrdersApi.partialReceiveProducts(queryData);
      notifyCommon([`Odebrano ${quantity} ${unit_name} produktu ${name}`]);
      setWasCollected(true);
      dispatch(refreshOrder());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setQuantity(qty - received_quantity);
  }, [received_quantity, qty]);

  return (
    <div className={cn(style.container, { [style.collectPart]: collectPartOfAmount })}>
      <div className={cn(style.wrapper, style.first)}>
        <ProductThumbnailImage
          thumbnail={thumbnail}
          image={image}
          title={name}
          id={product_id}
        />
        <p className={style.text}>{name}</p>
      </div>
      {!collectPartOfAmount && (
        <div className={style.wrapper}>
          <p>
            {qty - received_quantity}&nbsp;{unit_name}
          </p>
        </div>
      )}
      <div className={style.wrapper}>{getProductStatusTag(product.status)}</div>
      <div className={style.wrapper}>
        {collectPartOfAmount ? (
          <>
            <QtySelector
              wrapperStyle={style.qtySelector}
              setState={setQuantity}
              suffix={unit_name}
              state={quantity}
              max={qty}
              width={120}
              rule={`required|only_positive|max:${qty - received_quantity},num`}
              validator={validator}
              disabled={isLoading}
            />
            <p>
              / {qty - received_quantity}&nbsp;{unit_name}
            </p>
          </>
        ) : (
          <Checkbox
            value={isSelected}
            onChange={onSelectChange}
            disabled={disabled}
          />
        )}
      </div>
      {collectPartOfAmount && (
        <div className={style.wrapper}>
          <SaveButton
            onClick={collectHandler}
            isLoading={isLoading}
          />
        </div>
      )}
      {renderedModalConfirm}
    </div>
  );
};

export default ProductSelector;
