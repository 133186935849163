import React, { useState } from 'react';
import { useParams } from 'react-router';

import { Button, Modal, useModalConfirm } from 'components';
import { notifyApiError, notifyDanger } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { orderStatuses } from 'src/constants/enums';
import { handleNumeralWords } from 'src/utils/helpers';

import Attachments from '../../../Attachments';
import Communication from '../../../Communication';
import Invoices from '../../../Invoices';
import Summary from '../../../Summary';
import UploadAttachment from '../../../UploadAttachment';
import Table from '../../components/ProductsTable';
import TableHeader from '../../components/TableHeader';

import style from '../../OrderCart.module.scss';

const SupplierCart = (props) => {
  const { pageData, refreshDataCallback, uid } = props;
  const {
    messages,
    attachments,
    products,
    status,
    supplier_id: supplierId,
    created_at: createdAt,
    supplier_pricing_comment,
    user_comment,
    invoices,
    suborder_uid
  } = pageData || {};

  const [selectedProducts, setSelectedProducts] = useState(products || []);
  const [isLoading, setIsLoading] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [isModalOpen, setIsModalOpen] = useState();

  const params = useParams();

  const confirmOrder = async () => {
    if (!selectedProducts?.length) {
      notifyDanger(['Aby zatwierdzić zamówienie, zaznacz produktu które udało się skompletować.']);
      return null;
    }

    const queryParams = {
      selected: selectedProducts.map((product) => product.id),
      uid: params.uid
    };

    try {
      setIsLoading(true);
      await SupplierApi.saveOrderLink(queryParams);
      await refreshDataCallback();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const cancelOrder = async () => {
    try {
      setIsCancelLoading(true);
      await SupplierApi.cancelOrder({ uid: params.uid });
      await refreshDataCallback();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsCancelLoading(false);
    }
  };

  const handleCancelOrder = () => {
    handleOpenModalConfirm({
      message: 'Czy na pewno chcesz anulować zamówienie?',
      handleConfirm: cancelOrder,
      cancelButtonName: 'Nie',
      confirmButtonName: 'Tak'
    });
  };
  const handleConfirmOrder = () => {
    handleOpenModalConfirm({
      message: <h2>Potwierdzasz realizację zamówienia</h2>,
      content: (
        <h3 className={style.confirmTitle}>
          {selectedProducts?.length}/{products?.length}{' '}
          {handleNumeralWords(['produkt', 'produkty', 'produktów'], products?.length)}
        </h3>
      ),
      handleConfirm: confirmOrder,
      cancelButtonName: 'Cofnij',
      confirmButtonName: 'Zatwierdź'
    });
  };

  const closeModal = () => setIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);

  return (
    <div className={style.wrapper}>
      <TableHeader openModal={openModal} />
      <Invoices
        supplierId={supplierId}
        invoices={invoices}
        suborderUid={suborder_uid}
      />
      <Table
        uid={uid}
        refreshDataCallback={refreshDataCallback}
        setSelectedProducts={setSelectedProducts}
        selectedProducts={selectedProducts}
        products={products}
        status={status}
      />
      <Communication
        orderCreatedAt={createdAt}
        supplierView
        messages={messages}
        refreshHandler={refreshDataCallback}
        pricingComment={supplier_pricing_comment}
        userComment={user_comment}
      />
      {attachments?.length > 0 && (
        <Attachments
          uid={params.uid}
          attachments={attachments}
          supplierId={supplierId}
          refreshDataCallback={refreshDataCallback}
        />
      )}
      <Summary
        totalSum={pageData?.total_sum}
        productsSum={pageData?.products_sum}
        additionalShippingPrice={pageData?.order?.shipping_details?.additional_shipping_price}
        supplierComment={pageData?.supplier_pricing_comment}
        hideShipment
      />
      {status === orderStatuses.pending && (
        <div className={style.buttonWrapper}>
          <Button
            label={'Anuluj zamówienie'}
            onClick={handleCancelOrder}
            isLoading={isCancelLoading}
            gray
          />
          <Button
            label={`Zatwierdź zamówienie (${selectedProducts?.length}/${products?.length} produktów)`}
            onClick={handleConfirmOrder}
            isLoading={isLoading}
          />
        </div>
      )}
      {renderedModalConfirm}
      <Modal
        visible={isModalOpen}
        onClose={closeModal}
        title='Dodaj załączniki'
      >
        <UploadAttachment
          uid={params.uid}
          supplierId={supplierId}
          refreshDataCallback={refreshDataCallback}
          closeModal={closeModal}
        />
      </Modal>
    </div>
  );
};

export default SupplierCart;
