import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Button, EmptyState, useIsAdmin } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';

import { refreshValuations } from '../../actions';
import ModalProduct from './components/ModalProduct';

import style from './SupplierReplacementSelect.module.scss';
import shared from 'styles/custom/Shared.module.scss';

const SupplierReplacementSelect = ({ item, closeModal, selectedCompany }) => {
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();

  const [valuation, setValuation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async () => {
    const queryParams = {
      id: valuation?.id,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      setIsLoading(true);
      await OfferCatalogApi.selectPrice(queryParams);
      closeModal();
      dispatch(refreshValuations());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValuation(item.replacements.find((offer) => offer.id === item.id));
  }, []);

  return (
    <div className={style.container}>
      <section className={shared.tableWrapper}>
        <div className={classNames(style.header)}>
          <p></p>
          <p>Nazwa</p>
          <p>Producent</p>
          <p>Ilość</p>
          <p>Cena (za szt.)</p>
          <p>Wartość</p>
        </div>
        <div className={shared.tableCategory}>
          {item.replacements?.length ? (
            item.replacements?.map((sup) => (
              <ModalProduct
                key={sup.id}
                product={sup}
                setValuation={setValuation}
                valuation={valuation}
              />
            ))
          ) : (
            <div className={shared.tableCategory}>
              <h3 className={shared.tableCategoryTitle}>
                <EmptyState type={'suppliers'} />
              </h3>
            </div>
          )}
          <div className={style.buttonWrapper}>
            <Button
              onClick={handleChange}
              label={'Wybierz zamiennik'}
              disabled={valuation?.length === 0}
              isLoading={isLoading}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default SupplierReplacementSelect;
