import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import addIcon from 'assets/icons/add.svg';
import minusIcon from 'assets/icons/minus.svg';
import { Button, LoaderGlobal, PageHeader } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import pages from 'src/dictionaries/pages.json';

import { WarehouseApi } from '../../api';
import SeoHelmet from '../../components/layout/SeoHelmet';
import { modalTypes } from '../Warehouse';
import ProductModal from '../Warehouse/components/ProductModal';
import FilteringAndSorting, { contentRangeTypes } from './components/FilteringAndSorting';
import Table from './components/Table';

import style from './WarehouseHistory.module.scss';

const WarehouseHistory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedType, setSelectedType] = useState(contentRangeTypes[1]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [areDataSelected, setAreDataSelected] = useState(false);

  const filteringAndSortingProps = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedType,
    setSelectedType
  };

  const getHistory = async () => {
    if (!startDate || !endDate || !selectedType) {
      setAreDataSelected(false);
      return;
    } else {
      setAreDataSelected(true);
    }

    const params = {
      date_from: moment(startDate).toISOString(),
      date_to: moment(endDate).toISOString(),
      range: selectedType?.value
    };

    try {
      setIsLoading(true);
      const { data } = await WarehouseApi.getWarehouseHistory(params);
      setProducts(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getHistory();
  }, [startDate, endDate, selectedType]);

  const handleOpenModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  return (
    <>
      <SeoHelmet title={pages.warehouseHistory.title} />
      <PageHeader
        name={'Historia magazynu'}
        className={style.header}
      >
        <Link to={'/warehouse'}>Magazyn</Link>
        <Button
          label='Dodaj produkt'
          onClick={() => handleOpenModal(modalTypes.addNew)}
          gray
        />
        <Button
          label='Zużycie'
          icon={minusIcon}
          onClick={() => handleOpenModal(modalTypes.decrease)}
        />
        <Button
          label='Dostawa'
          iconName={'add'}
          onClick={() => handleOpenModal(modalTypes.increase)}
        />
      </PageHeader>
      <div className={style.container}>
        <FilteringAndSorting {...filteringAndSortingProps} />
        <ProductModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          modalType={modalType}
          reloadData={getHistory}
        />
        {isLoading ? (
          <LoaderGlobal />
        ) : (
          <Table
            products={products}
            selectedType={selectedType}
            areDataSelected={areDataSelected}
          />
        )}
      </div>
    </>
  );
};

export default WarehouseHistory;
