import React, { useEffect, useRef, useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import { Tag } from 'components';
import ProductOrdersChart from 'components/layout/ProductThumbnailImage/components/ProductOrdersChart';
import TableWithPagination from 'components/layout/ProductThumbnailImage/components/TableWithPagination';
import { notifyApiError } from 'components/layout/Toasts';

import { ProductsApi } from 'src/api';
import { ReactComponent as ZoomImage } from 'src/assets/icons/zoom.svg';
import { getFormattedAmount, imageErrorHandler } from 'src/utils/helpers';

import Skeleton from '../Skeleton';

import 'react-medium-image-zoom/dist/styles.css';
import style from './ProductDetails.module.scss';

const ProductDetails = ({ id, image }) => {
  const [product, setProduct] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const getProductData = async () => {
      try {
        setIsLoading(true);
        const { data } = await ProductsApi.productDetails(id);
        setProduct(data);
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    };

    getProductData();
  }, []);

  useEffect(() => {
    disableBodyScroll(containerRef.current);

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const { category_name, last_price, last_supplier, producer_name, product_name, unit_name } = product || {};

  return (
    <div
      className={style.container}
      ref={containerRef}
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <div className={style.imageWrapper}>
            <Zoom>
              <img
                loading={'lazy'}
                src={image}
                alt={product_name}
                className={style.image}
                onError={imageErrorHandler}
              />
            </Zoom>
            <span className={style.zoomIcon}>
              <ZoomImage />
            </span>
          </div>
          <div className={style.details}>
            <div className={style.titleWrapper}>
              <div className={style.producer}>
                <p className={style}>Producent: </p>
                <p>{producer_name}</p>
              </div>
              <h2>{product_name}</h2>
            </div>
            <div className={style.row}>
              <div className={style.box}>
                <p className={style.label}>Kategoria:</p>
                <p className={style.value}>{category_name}</p>
              </div>
              <div className={style.box}>
                <p className={style.label}>Jednostka:</p>
                <p className={style.value}>{unit_name}</p>
              </div>
            </div>
            <div className={style.row}>
              <div className={style.box}>
                <p className={style.label}>Ostatnia cena: </p>
                {last_price ? <p className={style.value}>{getFormattedAmount(last_price)}</p> : <Tag value={'Brak'} />}
              </div>
              <div className={style.box}>
                <p className={style.label}>Ostatni dostawca: </p>
                {last_supplier ? <p className={style.value}>{last_supplier}</p> : <Tag value={'Brak'} />}
              </div>
            </div>
          </div>
        </>
      )}
      <div className={style.wrapper}>
        <ProductOrdersChart id={id} />
        <TableWithPagination
          id={id}
          apiCallback={ProductsApi.productOrders}
          title={'Historia produktu w zamówieniach'}
        />
        <TableWithPagination
          id={id}
          apiCallback={ProductsApi.productPricings}
          title={'Historia produktu w wycenach'}
        />
      </div>
    </div>
  );
};

export default ProductDetails;
