import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as MessageIcon } from 'assets/icons/message.svg';
import { ReactComponent as ReplacementIcon } from 'assets/icons/replacement.svg';
import { Button, CustomTooltip, Modal, Textarea, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';

import { refreshValuations } from '../../../ValuationView/actions';
import SupplierReplacementSelect from '../SupplierReplacementSelect';

import style from './OfferSettings.module.scss';

const tooltipConfig = {
  placement: 'top',
  enterDelay: 500,
  leaveDelay: 200
};

const OfferSettings = ({ item, selectedCompany, priceId }) => {
  const validator = useValidator();
  const dispatch = useDispatch();
  const { replacements, comment } = item;

  const [isCommentEditingEnabled, setIsCommentEditingEnabled] = useState(false);
  const [isReplacementModalOpen, setIsReplacementModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isCommentLogin, setIsCommentLogin] = useState(false);
  const [isCommentDeletingLogin, setIsCommentDeletingLogin] = useState(false);
  const [newComment, setNewComment] = useState(item.comment);

  const closeModal = () => {
    setIsCommentModalOpen(false);
    setIsCommentEditingEnabled(false);
  };

  const cancelEditing = () => {
    setIsCommentEditingEnabled(false);
    setNewComment(item.comment);
    validator.purgeFields();
  };

  const saveComment = async () => {
    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const queryData = {
      price_id: priceId,
      comment: newComment
    };

    try {
      setIsCommentLogin(true);
      await OfferCatalogApi.editPriceComment(queryData);
      notifyCommon(['Komentarz został zapisany.']);
      dispatch(refreshValuations());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsCommentLogin(false);
    }
  };

  const deleteComment = async () => {
    try {
      setIsCommentDeletingLogin(true);
      await OfferCatalogApi.deletePriceComment(priceId);
      notifyCommon(['Komentarz został usunięty.']);
      dispatch(refreshValuations());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsCommentDeletingLogin(false);
    }
  };

  return (
    <>
      <div className={style.wrapper}>
        {comment && (
          <CustomTooltip
            {...tooltipConfig}
            title={'Komentarz do oferty'}
          >
            <button
              className={style.button}
              onClick={() => setIsCommentModalOpen(true)}
            >
              <MessageIcon />
            </button>
          </CustomTooltip>
        )}
        {replacements?.length > 1 && (
          <CustomTooltip
            {...tooltipConfig}
            title={'Dostępne zamienniki'}
          >
            <button
              className={style.button}
              onClick={() => setIsReplacementModalOpen(true)}
            >
              <ReplacementIcon />
            </button>
          </CustomTooltip>
        )}
      </div>
      <Modal
        visible={isReplacementModalOpen}
        onClose={() => setIsReplacementModalOpen(false)}
        title={'Dostępne zamienniki produktu'}
      >
        <SupplierReplacementSelect
          item={item}
          selectedCompany={selectedCompany}
          closeModal={() => setIsReplacementModalOpen(false)}
        />
      </Modal>
      <Modal
        visible={isCommentModalOpen}
        onClose={closeModal}
        title={'Komentarz do oferty'}
      >
        {isCommentEditingEnabled ? (
          <Textarea
            label={'Komentarz'}
            setValue={setNewComment}
            value={newComment}
            rule={'required'}
            validator={validator}
          />
        ) : (
          <div className={style.comment}>{item.comment}</div>
        )}
        <div className={style.buttonWrapper}>
          {isCommentEditingEnabled ? (
            <>
              <Button
                label={'Anuluj'}
                onClick={cancelEditing}
                gray
              />
              <Button
                label={'Zapisz'}
                onClick={saveComment}
                isLoading={isCommentLogin}
              />
            </>
          ) : (
            <>
              <Button
                label={'Edytuj'}
                onClick={() => setIsCommentEditingEnabled(true)}
                gray
              />
              <Button
                label={'Usuń'}
                isLoading={isCommentDeletingLogin}
                onClick={deleteComment}
              />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default OfferSettings;
