import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { PublicFooter, PublicHeader } from 'components';

import pages from 'src/dictionaries/pages.json';

import SeoHelmet from '../../components/layout/SeoHelmet';

import style from './NotFoundRoute.module.scss';

const NotFoundRoute = () => {
  const location = useLocation();

  return (
    <>
      <SeoHelmet title={pages.notFoundRoute.title} />
      <PublicHeader />
      <div className={style.container}>
        <h1 className={style.main}>404</h1>
        <h2 className={style.second}>{(location.state?.message || 'Coś poszło nie tak') + '. '}</h2>
        <h3>
          Wróć na <Link to={'/'}>stronę główną.</Link>
        </h3>
      </div>
      <PublicFooter />
    </>
  );
};

export default NotFoundRoute;
