import React, { useEffect, useState } from 'react';

import { FilterByCategory, Searchbar } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ProductsApi } from 'src/api';

import style from './Filtering.module.scss';

const Filtering = ({ params }) => {
  const [category, setCategory] = useState(null);

  const getSelectedCategory = async () => {
    const queryParams = {
      category_id: params.get('category'),
      perPage: 1,
      page: 1
    };

    try {
      const { data } = await ProductsApi.getCategories(queryParams);
      return data.data[0];
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    if (params.get('category')) {
      getSelectedCategory().then(setCategory);
    }
  }, []);

  useEffect(() => {
    params.setFew([
      { key: 'category', value: category ? category.value : undefined },
      { key: 'page', value: '1' }
    ]);
  }, [category]);

  return (
    <div className={style.container}>
      <FilterByCategory
        params={params}
        queryParams={{ filter_by: 'WAREHOUSE' }}
        showAsTree
        fullWidth
      />
      <Searchbar
        params={params}
        fullWidth
        alwaysExpand
        className={style.search}
        wrapperStyle={style.search}
      />
    </div>
  );
};

export default Filtering;
