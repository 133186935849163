import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Card, useIsAdmin } from 'components';
import BlurDataInfo from 'components/layout/BlurDataInfo';

import shared from 'src/styles/custom/Shared.module.scss';

import CompanyDetails from '../CompanyDetails';

import style from './ClientDetails.module.scss';
const ClientDetails = () => {
  const isAdmin = useIsAdmin();

  const comment = useSelector((state) => state.supplierForm.list_comment);
  const company = useSelector((state) => state.supplierForm.company);
  const areDataHidden = useSelector((state) => state.supplierForm.client_data_hidden);

  return (
    <div className={style.container}>
      {(company || areDataHidden) && (
        <Card className={classNames(shared.defaultWrapper, style.card)}>
          <h2 className={style.title}>
            Dane klienta
            {!!areDataHidden && <BlurDataInfo />}
          </h2>
          <CompanyDetails
            company={company}
            areDataHidden={areDataHidden && !isAdmin}
          />
        </Card>
      )}
      {comment && (
        <Card className={classNames(shared.defaultWrapper, style.card)}>
          <h2 className={style.title}>Komentarz klienta do wyceny</h2>
          <p className={style.comment}>{comment}</p>
        </Card>
      )}
    </div>
  );
};

export default ClientDetails;
