import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';
import { useOutsideClick } from 'components';

import style from './changeMenu.module.scss';

const ChangeMenu = ({
  wasDataChange,
  isActive,
  setIsActive,
  displayOver,
  value,
  width,
  children,
  tagClassName,
  displayValueFormatter = (val) => val
}) => {
  const handleClose = () => setIsActive(false);
  const [menuPosition, setMenuPosition] = useState({});
  const elementTargetRef = useOutsideClick(handleClose);

  const setPosition = () => {
    if (elementTargetRef.current && displayOver) {
      const data = elementTargetRef.current?.getBoundingClientRect();
      setMenuPosition({ top: data.bottom + 12, left: data.right });
    }
  };

  useEffect(() => {
    if (isActive && displayOver) {
      setPosition();
      window.addEventListener('scroll', setPosition);
    }

    return () => {
      window.removeEventListener('scroll', setPosition);
    };
  }, [isActive, displayOver, elementTargetRef.current]);

  const handleShow = () => {
    if (!isActive) {
      setPosition();
      setIsActive(true);
    }
  };

  const wrapperClasses = classNames(style.wrapper, {
    [style.active]: isActive,
    [style.yellow]: wasDataChange
  });

  return (
    <div
      className={wrapperClasses}
      ref={elementTargetRef}
      onClick={handleShow}
    >
      <p className={tagClassName}>{displayValueFormatter(value)}</p>
      <div
        className={classNames(style.menu, { [style.active]: isActive, [style.displayOver]: displayOver })}
        style={{ ...menuPosition, ...(width && { width }) }}
      >
        <button
          className={style.close}
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
        {children}
      </div>
    </div>
  );
};

export default ChangeMenu;
