import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';

import {
  Button,
  EmptyState,
  FilterByCompany,
  Modal,
  PaginationNav,
  Searchbar,
  SeoHelmet,
  StickyPageHeader,
  useIsMobile,
  useRequestAbortController
} from 'components';
import ColumnList from 'components/layout/ColumnList';
import TimelineHistory from 'components/layout/TimelineHistory';
import { notifyApiError } from 'components/layout/Toasts';

import { InvoicesApi } from 'src/api';
import pages from 'src/dictionaries/pages.json';
import query from 'src/utils/query';

import Filtering from './components/Filtering';
import InvoiceForm from './components/InvoiceForm';
import InvoiceThumbnail from './components/InvoiceThumbnail';
import { refreshInvoices } from './actions';

import style from './Invoices.modules.scss';
const Invoices = (props) => {
  const params = query(props);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const [pageData, setPageData] = useState();
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [historyStartDate, setHistoryStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortBy, setSortBy] = useState(params.get('sort_by'));
  const [sortOrder, setSortOrder] = useState(params.get('sort_order'));
  const [historyEndDate, setHistoryEndDate] = useState(null);
  const [search, setSearch] = useState();
  const [queryUser, setQueryUser] = useState();
  const [queryCompanyId, setQueryCompanyId] = useState();
  const [wasDataLoaded, setWasDataLoaded] = useState(false);
  const [history, setHistory] = useState([]);
  const [historyTotal, setHistoryTotal] = useState(0);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [abortController, setNewController] = useRequestAbortController();

  const version = useSelector((state) => state.invoices.version);
  const openNewListModal = useMemo(() => params.get('new_element'), [params]);

  const getData = async () => {
    let error;
    const params = {
      page: currentPage || 1,
      perPage,
      search,
      user: queryUser,
      company_id: queryCompanyId,
      ...(selectedStatuses && {
        statuses: Array.isArray(selectedStatuses) ? selectedStatuses : [selectedStatuses]
      }),
      ...(selectedSuppliers && {
        supplier_ids: Array.isArray(selectedSuppliers) ? selectedSuppliers : [selectedSuppliers]
      }),
      ...(startDate &&
        endDate && {
          date_from: moment(startDate).format('Y-MM-DD'),
          date_to: moment(endDate).format('Y-MM-DD')
        }),
      ...(sortBy &&
        sortOrder && {
          sort_by: sortBy,
          sort_order: sortOrder
        })
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      const { data } = await InvoicesApi.getInvoices(params, signal);
      setPageData(data);
      setPageQty(data?.last_page);
    } catch (err) {
      notifyApiError(err);
      error = err;
    } finally {
      setIsLoading(error?.message === 'canceled');
      if (!wasDataLoaded) setWasDataLoaded(true);
    }
  };

  const getActions = async () => {
    const params = {
      page: 1,
      perPage: 100,
      ...(historyStartDate &&
        historyEndDate && {
          date_from: moment(historyStartDate).format('Y-MM-DD'),
          date_to: moment(historyEndDate).format('Y-MM-DD')
        })
    };
    try {
      setIsHistoryLoading(true);
      const { data } = await InvoicesApi.getActions(params);
      setHistory(data.data);
      setHistoryTotal(data.total);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsHistoryLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [
    version,
    search,
    currentPage,
    perPage,
    queryUser,
    queryCompanyId,
    selectedStatuses,
    selectedSuppliers,
    startDate,
    endDate,
    sortBy,
    sortOrder
  ]);

  useEffect(() => {
    if (openNewListModal) setVisible(true);
  }, [openNewListModal]);

  useEffect(() => {
    if (openNewListModal) params.remove('new_element');
  }, [visible]);

  useEffect(() => {
    getActions();
  }, [historyStartDate, historyEndDate, version]);

  useEffect(() => {
    setCurrentPage(params.get('page'));
    setSearch(params.get('search'));
    setQueryUser(params.get('user'));
    setQueryCompanyId(params.get('company'));
    setSelectedStatuses(params.get('status'));
    setSelectedSuppliers(params.get('supplier'));
    setSortOrder(params.get('sort_order'));
    setSortBy(params.get('sort_by'));
    setSelectedSuppliers(params.get('supplier'));
    setStartDate(params.get('startDate'));
    setEndDate(params.get('endDate'));
  }, [params]);

  const headerContent = (
    <>
      {!isMobile && (
        <>
          <Searchbar params={params} />
          <FilterByCompany params={params} />
        </>
      )}
      <Button
        label={'Dodaj fakturę'}
        onClick={() => setVisible(true)}
        iconName={'add'}
      />
      <TimelineHistory
        history={history}
        buttonLabel={'Historia faktur'}
        title={'Historia faktur'}
        bottomMessage={history?.length ? `+ ${historyTotal - history?.length} innych.` : ''}
        startDate={historyStartDate}
        endDate={historyEndDate}
        setStartDate={setHistoryStartDate}
        setEndDate={setHistoryEndDate}
        isLoading={isHistoryLoading}
      />
    </>
  );

  const saveHandler = () => {
    setVisible(false);
    dispatch(refreshInvoices());
  };

  return (
    <div className={style.container}>
      <SeoHelmet title={pages.invoices.title} />
      <StickyPageHeader
        name={pages.invoices.title}
        filtering={<Filtering params={params} />}
      >
        {headerContent}
      </StickyPageHeader>
      <ColumnList
        isLoading={isLoading}
        list={pageData?.data}
        component={InvoiceThumbnail}
        extraProps={{ refreshCallback: () => dispatch(refreshInvoices()) }}
        emptyState={<EmptyState type={params.get('search') ? 'search' : 'invoices'} />}
      />
      <PaginationNav
        onClick={(number) => setCurrentPage(number)}
        params={params}
        pagesQty={pageQty}
        setPerPage={setPerPage}
        defaultQty={perPage}
      />
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        title={'Dodaj fakturę'}
      >
        <InvoiceForm closeModal={saveHandler} />
      </Modal>
    </div>
  );
};

export default Invoices;
