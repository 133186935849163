import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import {
  PageHeader,
  PaginationNav,
  Searchbar,
  SeoHelmet,
  SwitchButton,
  Tag,
  useIsAdmin,
  useRequestAbortController
} from 'components';
import { headerTextPlaceholder } from 'components/layout/PageHeader/placeholders';
import { notifyApiError } from 'components/layout/Toasts';

import { StatisticsApi } from 'src/api';
import pages from 'src/dictionaries/pages.json';
import { getCorrectFormOfResultsLabel } from 'src/utils/helpers';
import query from 'src/utils/query';

import Filtering from './components/Filtering';
import ProductsList from './components/ProductsList';

import style from './MarketPrices.module.scss';

const MarketPrices = (props) => {
  const { type } = props || {};
  const params = query(props);
  const { list_uid } = useParams();
  const location = useLocation();
  const isAdmin = useIsAdmin();

  const [isLoading, setIsLoading] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(params.get('page'));
  const [querySearch, setQuerySearch] = useState(params.get('search'));
  const [queryCategory, setQueryCategory] = useState(params.get('category'));
  const [queryProducer, setQueryProducer] = useState(params.get('producer'));
  const [queryCompany, setQueryCompany] = useState(params.get('company'));
  const [queryOnlyCompany, setQueryOnlyCompany] = useState(params.get('onlyCompany'));
  const [sortBy, setSortBy] = useState(params.get('sort_by'));
  const [sortOrder, setSortOrder] = useState(params.get('sort_order'));
  const [switchValue, setSwitchValue] = useState(false);
  const [showSuppliers, setShowSuppliers] = useState(false);

  const [pageQty, setPageQty] = useState(1);
  const [itemsQty, setItemsQty] = useState();
  const [perPage, setPerPage] = useState(50);

  const [abortController, setNewController] = useRequestAbortController();
  const listVersion = useSelector((state) => state.productsBase.version);

  useEffect(() => {
    params.set('page', 1);
  }, [queryOnlyCompany]);

  useEffect(() => {
    params.set('onlyCompany', +switchValue);
  }, [switchValue]);

  useEffect(() => {
    setSwitchValue(!!queryOnlyCompany);
  }, []);

  const getData = async () => {
    let error;
    const queryProps = {
      page: currentPage || 1,
      perPage: perPage,
      search: querySearch,
      categoryId: queryCategory,
      producerId: queryProducer,
      company_ids: params.get('company'),
      onlyCompany: queryOnlyCompany,
      ...(sortBy && { sort_by: sortBy }),
      ...(list_uid && { list_uid: list_uid }),
      ...(sortOrder && { sort_order: sortOrder })
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      const { data } = await StatisticsApi.getProductsData(queryProps, signal);
      setPageData(data.data);
      setItemsQty(data.total);
      setPageQty(data?.last_page);
      setIsLoading(false);
    } catch (err) {
      notifyApiError(err);
      error = err;
    } finally {
      setIsLoading(error?.message === 'canceled');
    }
  };

  useEffect(() => {
    getData();
  }, [
    listVersion,
    currentPage,
    perPage,
    querySearch,
    queryCategory,
    queryProducer,
    queryCompany,
    queryOnlyCompany,
    sortBy,
    sortOrder
  ]);

  useEffect(() => {
    setQueryCompany(params.get('company'));
    setCurrentPage(params.get('page'));
    setQuerySearch(params.get('search'));
    setQueryCategory(params.get('category'));
    setQueryProducer(params.get('producer'));
    setQueryOnlyCompany(params.get('onlyCompany'));
    setSortBy(params.get('sort_by'));
    setSortOrder(params.get('sort_order'));
  }, [location.search]);

  return (
    <>
      <SeoHelmet title={pages.marketPrices.title} />
      <PageHeader
        name={pages.marketPrices.title}
        wrapOnMobile
        text={
          <div className={style.row}>
            {!!list_uid && (
              <Tag
                value={'Na podstawie listy'}
                title={list_uid}
              />
            )}
            {isLoading ? headerTextPlaceholder() : getCorrectFormOfResultsLabel(itemsQty)}
          </div>
        }
      >
        <SwitchButton
          value={showSuppliers}
          setValue={setShowSuppliers}
          label={'Pokaż unikalnych dostawców'}
        />
      </PageHeader>
      <div className={style.container}>
        <div className={style.header}>
          <Searchbar
            params={params}
            alwaysExpand
            fullWidth
            searchSize={'large'}
            className={style.search}
          />
          <Filtering
            params={params}
            isAdmin={isAdmin}
          />
        </div>
        <ProductsList
          type={type}
          params={params}
          list={pageData}
          isLoading={isLoading}
          showSuppliers={showSuppliers}
        />
        <PaginationNav
          onClick={(number) => setCurrentPage(number)}
          params={params}
          pagesQty={pageQty}
          setPerPage={setPerPage}
          defaultQty={perPage}
        />
      </div>
    </>
  );
};

export default MarketPrices;
