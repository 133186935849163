export const AccountsRoutes = {
  LOGIN: '/login',
  REGISTER: '/register',
  REGISTER_MEMBER: '/registerMember',
  VALIDATE_INVITATION: '/validateInvitation',
  JOIN_COMPANY: '/joinCompany',
  GET_USER_DATA: '/user/me',
  FORGOT_PASSWORD: '/account/forgotPassword',
  RESET_PASSWORD: '/account/resetPassword',
  ACCOUNT_CHANGE_PASSWORD: '/account/changepassword',
  GET_ATTACHMENT: '/getAttachment',
  GET_SHIP_AND_PAY_METHODS: '/getShipAndPayMethods'
};

export const CompanyRoutes = {
  UPDATE_COMPANY: '/company/update',
  CREATE_NEW_COMPANY: '/company/create',
  GET_ALL_COMPANIES: '/company/getAll',
  ADD_SHIPPING_ADDRESS: '/company/addShippingAddress',
  GET_USERS: '/company/users/get',
  INVITE_USER: '/company/users/invite',
  REMOVE_USER: '/company/users/remove',
  GET_INVITES_LIST: '/company/users/invitesList',
  DELETE_SHIPPING_DETAILS: (id) => `/company/deleteShippingAddress/${id}`,
  DELETE_COMPANY_MEMBER: '/company/users/remove',
  CANCEL_INVITATION: '/company/users/cancelInvitation',
  SAVE_LOGO: '/company/saveLogo',
  COLLECTION_GET: '/company/collection/get',
  ADD_SUPPLIER_TO_PRODUCT: '/company/collection/addProducts',
  REMOVE_SUPPLIER_FROM_PRODUCT: '/company/collection/removeProducts',
  GET_THRESHOLDS: '/company/getThresholds',
  ADD_THRESHOLDS: '/company/addThreshold',
  DELETE_THRESHOLDS: '/company/deleteThreshold',
  EDIT_THRESHOLD: '/company/editThreshold',
  GET_EMPLOYEES: '/company/getUsersList',
  CREATE_EMPLOYEE: '/company/createUser',
  EDIT_COMPANY_USER: '/company/editCompanyUser',
  ADD_EMPLOYEE: '/company/addUserToCompany',
  GET_USERS_VALUATIONS_CREATE: '/company/getUsersValuationsCreate',
  SEND_WELCOME_MESSAGE: (id) => `/account/sendWelcomeEmail/${id}`,
  ADD_USER_TO_COMPANY: '/company/addUserToCompany'
};

export const UserRoutes = {
  ME: '/user/me',
  GET_DISTRIBUTORS: '/user/getDistributors',
  ADD_SUPPLIER: '/suppliers/add',
  EDIT_SUPPLIER: '/suppliers/edit',
  DELETE_SUPPLIER: (id) => `suppliers/delete/${id}`,
  GET_USER_PRODUCTS: '/user/products',
  GET_USER_PROD_CREATION_DATA: '/user/getProductCreationData',
  ADD_PRODUCT: '/user/addProduct',
  SAVE_PROFILE_IMAGE: '/user/saveProfileImage',
  DELETE_PRODUCT: (product_id) => `/user/deleteProduct/${product_id}`,
  UPDATE_SHIPPING_DETAILS: '/user/addShippingAddress',
  DELETE_SHIPPING_DETAILS: (id) => `/user/deleteShippingAddress/${id}`,
  UPDATE_INVOICE_DETAILS: '/user/updateBillingDetails',
  ADD_PRODUCER: '/user/addProducer',
  GET_NOTIFICATIONS: '/user/notifications',
  GET_UNREAD_NOTIFICATIONS: '/user/unreadNotifications',
  SET_NOTIFICATION_AS_READ: '/user/readNotification',
  SET_ALL_NOTIFICATIONS_AS_READ: '/user/readAllNotifications',
  UPDATE_USER_DATA: '/user/updateAccount',
  SAVE_DASHBOARD_CONFIG: '/user/saveDashboardConfig'
};

export const ListsRoutes = {
  GET_LISTS: '/lists/get',
  CREATE_LIST: '/lists/create',
  MERGE_LISTS: '/lists/mergeLists',
  GET_LIST_PRODUCTS: '/lists/getProducts',
  STORE_PRODUCT_TO_LIST: '/lists/storeToList',
  DELETE_PRODUCT: (uid, id) => `/lists/deleteProduct/${uid}/${id}`,
  DELETE_LIST: (uid) => `/lists/delete/${uid}`,
  CREATE_PRICING: '/valuations/createPricing',
  CREATE_HORIZONTAL_PRICING: '/valuations/createHorizontalPricing',
  EDIT_LIST_NAME: '/lists/editName',
  EXPORT_CSV: '/lists/generatecsv',
  LIST_DUPLICATE: '/lists/duplicate',
  SAVE_LIST_COMMENT: '/lists/setComment',
  SET_PRODUCTS_COMMENT: '/lists/setProductsComment',
  GET_CART_DATA: '/lists/getCart',
  GET_CART_SUMMARY: '/lists/getCartSummary',
  REMOVE_ALL_SELECTS: '/lists/removeAllSelects',
  OPTIMIZE_OFFER_CATALOG: '/lists/optimizeOfferCatalog',
  SELECT_PREFERRED: '/lists/selectPreferred',
  SET_PREFERRED_SUPPLIERS: '/lists/setPreferredSuppliers',
  SELECT_PRICE: '/lists/selectPrice',
  RESOLVE_ORDER_DATA: '/lists/resolveOrderData',
  CREATE_ORDER: '/lists/createOrder',
  GET_LIST_ACTIONS: '/lists/getListActions',
  GET_RECENTLY_ORDERED_PRODUCTS: '/lists/getRecentlyOrderedProducts'
};

export const ProductsRoutes = {
  GET_PRODUCTS: '/products',
  GET_PRODUCTS_PROPERTIES: '/getProductsProperties',
  GET_PROD_CREATION_DATA: '/getProductCreationData',
  ADD_PRODUCT: '/admin/addProduct',
  DELETE_PRODUCT: (product_id) => `/products/delete/${product_id}`,
  GET_PRODUCERS: '/producers',
  GET_CATEGORIES: '/categories',
  PRODUCT_DETAILS: (id) => `/products/productDetails/${id}`,
  PRODUCT_ORDERS: (id) => `/products/productOrders/${id}`,
  PRODUCT_PRICINGS: (id) => `/products/productPricings/${id}`,
  EXPORT_ALL_PRODUCTS: '/products/exportAllProducts',
  IMPORT_EDITED_PRODUCTS: '/products/importEditedProducts',
  REPLACE_PRODUCT: '/products/replaceProduct'
};

export const InvoicesRoutes = {
  PARTNER_DOCUMENTS: '/partner/documents',
  GET_INVOICES: '/invoices/get',
  ADD_USER_INVOICE: '/invoices/addUserInvoice',
  ADD_USER_INVOICE_OTHER: '/invoices/addUserInvoiceOther',
  EDIT_USER_INVOICE: '/invoices/editUserInvoice',
  EDIT_USER_INVOICE_OTHER: '/invoices/editUserInvoiceOther',
  DOWNLOAD_INVOICE_OTHER: '/invoices/downloadInvoiceOther',
  DOWNLOAD_INVOICE: '/invoices/downloadInvoice',
  DELETE_USER_INVOICE: '/invoices/deleteUserInvoice',
  DELETE_USER_INVOICE_OTHER: '/invoices/deleteUserInvoiceOther',
  ADD_SUPPLIERS_INVOICE: '/invoices/addSuppliersInvoice',
  EDIT_SUPPLIERS_INVOICE: '/invoices/editSuppliersInvoice',
  DELETE_SUPPLIER_INVOICE: '/invoices/deleteSupplierInvoice',
  GET_ACTIONS: '/invoices/getActions'
};

export const OfferCatalogRoutes = {
  GET_DATA: '/offerCatalog/get',
  SELECT_PRICE: '/offerCatalog/selectPrice',
  RESOLVE_ORDER_DATA: '/offerCatalog/resolveOrderData',
  CHANGE_QUANTITY: '/offerCatalog/changeQuantity',
  ADD_PRICE: '/offerCatalog/addPrice',
  CREATE_ORDER: '/offerCatalog/createOrder',
  REMOVE_ALL_SELECTS: '/offerCatalog/removeAllSelects',
  GET_SUMMARY: '/offerCatalog/summary',
  OPTIMIZE_OFFER_CATALOG: '/offerCatalog/optimizeOfferCatalog',
  SET_PREFERRED_SUPPLIER: '/offerCatalog/setPreferredSupplier',
  DELETE_PRODUCT: '/offerCatalog/deleteProduct',
  DELETE_PRICE: '/offerCatalog/deletePrice',
  SELECT_PREFERRED: '/offerCatalog/selectPreferred',
  EXPORT_CATALOG: '/offerCatalog/exportCatalog',
  EXPORT_ALL_PRODUCTS_FOR_CATALOG: '/offerCatalog/exportAllProductsForCatalog',
  IMPORT_CATALOG_PRICES: '/offerCatalog/importCatalogPrices',
  GET_PRODUCTS: '/offerCatalog/getProducts',
  SET_PREFERRED_SUPPLIERS: '/offerCatalog/setPreferredSuppliers',
  GET_OFFER_CATALOG_PRODUCTS_WITHOUT_PRICE: '/offerCatalog/getOfferCatalogProductsWithoutPrice',
  EDIT_PRICE_COMMENT: '/offerCatalog/editPriceComment',
  DELETE_PRICE_COMMENT: (id) => `/offerCatalog/deletePriceComment/${id}`
};

export const ValuationsRoutes = {
  GET_VALUATIONS: '/valuations/get',
  GET_VALUATION_PRODUCTS: '/valuations/getproducts',
  GET_VALUATION_PRODUCTS_SIMPLE: '/valuations/getSimpleProducts',
  CREATE_ORDER: '/valuations/createOrder',
  SAVE_ORDER_DIST: '/valuations/saveOrderDataDistributor',
  SAVE_ORDER_PRODUCTS: '/valuations/saveOrderDataProducts',
  RESOLVE_ORDER: '/valuations/resolveOrderData',
  CANCEL_VALUATION: (uid) => `/valuations/delete/${uid}`,
  EXPORT_CSV: '/valuations/export',
  GET_PRODUCT_DETAILS: '/valuations/getProductDetails',
  CHANGE_DENTAMETR_PRICE: '/valuations/changeDentametrPrice',
  CHANGE_SELECTED_PRODUCT_DIST: '/valuations/changeSelectedProductDistributor',
  CHANGE_SUPPLIER_OFFER: '/valuations/changeSupplierOffer',
  DELETE_OFFER_SELECTION: '/valuations/removeProductSelection',
  SELECT_ALL_IN_COLUMN: '/valuations/selectDistributorColumn',
  UNSELECT_ALL_IN_COLUMN: '/valuations/unselectDistributorColumn',
  SELECT_SUBSTITUTE: '/valuations/selectSubstitute',
  CREATE_SUPPLIER_VALUATION: '/valuations/createSupplierValuation',
  OPTIMIZE_SELECT: '/valuations/selectOptimized',
  SHOW_COLUMNS: '/valuations/showColumns',
  HIDE_COLUMNS: '/valuations/hideColumns',
  CHANGE_ORDER_QUANTITY: '/valuations/changePreOrderQuantity',
  GET_PENDING_STATUS: '/valuations/getPendingStatus'
};

export const OrdersRoutes = {
  GET_ORDERS: '/orders/get',
  GET_PRODUCTS: '/orders/getProducts',
  CANCEL_ORDER: '/orders/cancelOrder',
  ORDER_PRODUCT_RECEIVED: '/orders/productListReceived',
  CONFIRM_PRODUCTS: '/orders/productListReceived',
  ADD_ORDER_TO_WAREHOUSE: '/orders/addOrderToWarehouse',
  CHANGE_PRODUCTS_STATUS: '/orders/changeProductsStatus',
  ADD_PRODUCT_COMMENT: '/orders/addProductComment',
  UPLOAD_USER_ATTACHMENT: '/orders/uploadUserAttachment',
  DELETE_USER_ATTACHMENT: '/orders/deleteUserAttachment',
  ADD_MESSAGE: '/orders/addMessage',
  CANCEL_ORDER_LINK: '/orders/cancelOrderLink',
  SEND_ORDER_APPROVAL: '/orders/sendOrderApproval',
  PARTIAL_RECEIVE_PRODUCTS: '/orders/partialReceiveProducts'
};

export const SupplierRoutes = {
  GET_FORM: '/links/getForm',
  SAVE_PRICE: '/links/savePrice',
  REMOVE_OFFER_OR_REPLACEMENT: '/links/deleteOffersReplacements',
  SAVE_FORM_DATA: '/links/saveFormData',
  GET_ORDER: '/links/getOrder',
  SAVE_ORDER_LINK: '/links/saveOrder',
  UPDATE_ORDER: '/links/updateOrderData',
  FINALIZE_ORDER: '/links/finalizeOrder',
  SEND_VALUATION: '/links/sendValuation',
  GET_VALUATIONS: '/supplier/valuations',
  GET_ORDERS: '/supplier/orders',
  CANCEL_ORDER: '/links/cancelOrder',
  GENERATE_PDF: '/links/generatePDF',
  ADD_SHIPPING_METHOD: '/links/addShippingMethod',
  DELETE_SHIPPING_METHOD: '/links/removeShippingMethod',
  REGISTER_SUPPLIER_ACCOUNT: '/supplier/registerSupplierAccount',
  VALIDATE_REGISTER_TOKEN: '/supplier/validateRegisterToken',
  CONNECT_SUPPLIER_VALUATIONS: '/supplier/connectSupplierValuations',
  ACCEPT_NEW_QUANTITY: '/links/acceptNewQuantity',
  ACCEPT_NEW_PRICE: '/links/acceptNewPrice',
  GET_PRODUCTS: '/supplier/prices/get',
  ADD_TO_COLLECTION: '/supplier/prices/add',
  REMOVE_FROM_COLLECTION: '/supplier/prices/remove',
  GET_COLLECTIONS: '/supplier/prices/getAll',
  GET_CLIENTS: '/supplier/clients/get',
  CHANGE_PRICE: '/supplier/prices/change',
  UPLOAD_SUPPLIER_ATTACHMENT: '/links/uploadSupplierAttachment',
  DELETE_SUPPLIER_ATTACHMENT: '/links/deleteSupplierAttachment',
  ADD_MESSAGE: '/links/addMessage',
  ORDER_APPROVAL_DATA: '/links/orderApprovalData',
  ORDER_APPROVAL: '/links/orderApproval',
  DOWNLOAD_INVOICE: '/links/downloadInvoice'
};

export const DistributorRoutes = {
  GET_ORDER: '/distributor/orders'
};

export const AdminRoutes = {
  SAVE_SUPPLIER: '/admin/distributors/save',
  DELETE_DISTRIBUTOR: (id) => `/admin/distributors/delete/${id}`,
  GET_USER_PRODUCTS: '/admin/getUserProducts',
  USER_TO_DB_PRODUCT: '/admin/UserProductToDb',
  GET_CATEGORIES: '/admin/categories',
  SAVE_CATEGORIES: '/admin/saveCategories',
  GET_USERS: '/admin/user',
  USER_DATA: (id) => `/admin/user/${id}`,
  GET_USER_ROLES: '/admin/user/roles',
  ADD_USER: '/admin/user/create',
  ADD_CATEGORY: '/admin/addCategory',
  SAVE_CATEGORY: '/admin/saveCategory',
  USER_PRODUCT_TO_DB: '/admin/userProductToDb',
  VALUATION_EXTEND: '/admin/valuations/extend',
  ADD_PRODUCER: '/admin/addProducer',
  MOVE_PRODUCER_PRODUCTS: '/admin/moveProducerProducts',
  DELETE_PRODUCER: (id) => `/admin/deleteProducer/${id}`,
  DELETE_SUPPLIER_USER_CONNECTION: '/admin/deleteSupplierUserConnection',
  USER_VALUATION_EXTEND: '/admin/valuations/extendUserValuation',
  MOVE_LIST: '/admin/lists/move',
  MOVE_VALUATION: '/admin/valuations/move',
  REMIND_DISTRIBUTOR_VALUATION: '/admin/valuations/remindDistributorValuation',
  GET_COMPANIES: '/admin/company/get',
  GET_COMPANIES_AS_TREE: '/admin/company/getTree',
  COMPANY_DETAILS: (id) => `/admin/company/${id}`,
  GET_COMPANY_MEMBERS: '/admin/company/membersAndInvitations',
  ADD_SHIPPING_ADDRESS: '/admin/company/addShippingAddress',
  DELETE_SHIPPING_ADDRESS: (id) => `/admin/company/deleteShippingAddress/${id}`,
  DELETE_COMPANY_MEMBER: '/admin/company/removeUserFromCompany',
  INVITE_USER_TO_COMPANY: '/admin/company/invite',
  REMOVE_USER_INVITE_TO_COMPANY: '/admin/company/removeInvitation',
  GET_USER_TOKEN: '/admin/user/token',
  MOVE_COMPANY: '/admin/company/move',
  DELETE_CATEGORY: '/admin/deleteCategory',
  MOVE_CATEGORY_PRODUCTS: '/admin/moveCategoryProducts',
  HIDE_CATEGORY: '/admin/hideCategory',
  SHOW_CATEGORY: '/admin/showCategory',
  DEACTIVATE_USER: '/admin/user/deactivate',
  ACTIVATE_USER: '/admin/user/activate',
  EDIT_PRODUCT_PRICE: '/admin/lists/editProductPrice',
  GET_ORDER_APPROVALS: '/admin/getOrderApprovals',
  CHANGE_SUPPLIER_EMAIL: '/admin/user/changeSupplierEmail'
};

export const WarehouseRoutes = {
  GET_WAREHOUSE_DATA: '/warehouse/get',
  ADD_PRODUCT: '/warehouse/save',
  DELETE_PRODUCT: (id) => `/warehouse/delete/${id}`,
  CHANGE_PRODUCT_STATE: '/warehouse/changeQuantity',
  GET_WAREHOUSE_HISTORY: '/warehouse/getHistory'
};

export const StatisticsRoutes = {
  GET_STATISTICS: '/statistics/get',
  PRODUCTS_DATA: '/statistics/productsData',
  GET_EXPENSES_MONTHLY: '/statistics/getExpensesMonthly',
  GET_SAVINGS_MONTHLY: '/statistics/getSavingsMonthly',
  GET_PRODUCTS_REALISATION_BY_SUPPLIER: '/statistics/getProductsRealisationBySupplier',
  GET_PRODUCT_ORDERS: (id) => `/statistics/getProductOrders/${id}`,
  GET_EXPENSES_BY_ORDER: '/statistics/getExpensesByOrder',
  GET_AVG_ACTIONS_TIMES_BY_ORDER: '/statistics/getAvgActionsTimesByOrder',
  GET_EXPENSES_BY_CATEGORY: '/statistics/getExpensesByCategory',
  GET_ACTIONS_TIMES_FOR_ORDER: '/statistics/getActionsTimesForOrder',
  EXPORT_EXPENSES_MONTHLY: '/statistics/exportExpensesMonthly',
  EXPORT_EXPENSES_BY_CATEGORY: '/statistics/exportExpensesByCategory',
  EXPORT_PRODUCTS_REALISATION_BY_SUPPLIER: '/statistics/exportProductsRealisationBySupplier',
  EXPORT_AVG_ACTIONS_TIMES_BY_ORDER: '/statistics/exportAvgActionsTimesByOrder',
  EXPORT_ACTIONS_TIMES_BY_ORDER: '/statistics/exportActionsTimesByOrder',
  EXPORT_ORDERS_DATA: '/statistics/exportOrdersData',
  GET_PRODUCTS_REALISATION_BY_SUPPLIER_MONTHLY: '/statistics/getProductsRealisationBySupplierMonthly',
  GET_PRODUCTS_REALISATION_BY_SUPPLIER_FOR_ORDER_UID: '/statistics/getProductsRealisationBySupplierForOrderUid',
  EXPORT_SAVINGS_DATA: '/statistics/exportSavingsData',
  GET_TOP_PRODUCTS: '/statistics/getTop20Products',
  EXPORT_TOP_PRODUCTS: '/statistics/exportTop20Products'
};

export const PermissionsRoutes = {
  GET_AVAILABLE: '/permissions/getAvailable',
  GRANT_PERMISSION: '/permissions/grant',
  REVOKE_PERMISSION: '/permissions/revoke'
};

export const LandingRoutes = {
  SEND_EMAIL: 'contactform/registerToNewsletter',
  CONTACT_FORM: 'contactform/handleContactForm'
};

export const ArticlesRoutes = {
  GET_ARTICLES: '/posts?_embed',
  GET_CATEGORIES: '/categories',
  GET_PRIVATE_ARTICLES: '/pages',
  GET_PRIVATE_ARTICLE_PAGE: (id) => `/pages/${id}`
};
