import React, { useEffect, useRef, useState } from 'react';
import Barcode from 'react-barcode';

import { AsyncSelect, Button, Input, QtySelector, Select, useAuthUser, useValidator } from 'components';
import PhotoUploader from 'components/layout/PhotoUploader';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi, ProductsApi, UserApi } from 'src/api';
import { userRoles } from 'src/constants/enums';
import { getAllCategories, getFormattedUnits, getSelectedUnits } from 'src/utils/helpers';

import style from './ProductDetailsForm.module.scss';

export const defaultProduct = {
  croppedPhotoData: null,
  productName: '',
  category: null,
  unit: null,
  producer: null,
  collectionQty: 10,
  newProducer: ''
};

const ProductDetailsForm = ({ product, onSave, isParentLoading, children, clearProductData }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [wasDataLoaded, setWasDataLoaded] = useState(false);
  const [productDetails, setProductDetails] = useState(defaultProduct);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const producerInputRef = useRef(null);
  const user = useAuthUser();
  const validator = useValidator();

  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);

  const handleProductChange = (value, key) => {
    setProductDetails((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const handleSave = async () => {
    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    if (productDetails.producer?.value === 'ADD_NEW_PRODUCER') {
      try {
        const { data } =
          user.role === userRoles.admin
            ? await AdminApi.addProducer(productDetails.newProducer)
            : await UserApi.addProducer(productDetails.newProducer);
        await onSave({
          ...productDetails,
          producer: {
            label: productDetails.newProducer,
            value: data
          }
        });
        if (clearProductData) {
          setProductDetails(defaultProduct);
        }
      } catch (err) {
        notifyApiError(err);
      }
    } else {
      await onSave(productDetails);
      if (clearProductData) {
        setProductDetails(defaultProduct);
      }
    }
  };

  const getProductOptionsData = async () => {
    try {
      setIsLoading(true);
      const { data } = await ProductsApi.getProductsProperties();
      setCategories(getAllCategories(data.categories));
      setUnits(getFormattedUnits(data.units));
      setWasDataLoaded(true);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const newProducerInputFocus = (value) => {
    handleProductChange({ label: 'Dodaj nowego', value: 'ADD_NEW_PRODUCER' }, 'producer');
    handleProductChange(value, 'newProducer');
    setIsInputFocused(true);
  };

  const producersEmptyState = (value) => <a onClick={() => newProducerInputFocus(value)}>Dodaj nowego producenta</a>;

  useEffect(() => {
    getProductOptionsData();
  }, []);

  useEffect(() => {
    if (wasDataLoaded) {
      if (product) {
        const { category_id, unit_id, name, collection_amount, unit_name } = product;
        setProductDetails({
          croppedPhotoData: null,
          productName: name,
          category: categories.find(({ value }) => value === category_id) || null,
          unit: getSelectedUnits(units, unit_id, unit_name),
          producer: {
            label: product.producer,
            value: product.producer_id
          },
          collectionQty: +collection_amount,
          newProducer: ''
        });
      } else {
        setProductDetails(defaultProduct);
      }
    }
  }, [wasDataLoaded, product]);

  useEffect(() => {
    if (productDetails.producer?.value === 'ADD_NEW_PRODUCER' && isInputFocused) {
      producerInputRef.current?.focus();
      setIsInputFocused(false);
    }
  }, [isInputFocused, productDetails]);

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <PhotoUploader
          onSubmit={(e) => handleProductChange(e, 'croppedPhotoData')}
          sourceImage={product?.image}
          productThumbnail
        />
        <div className={style.column}>
          <Input
            value={productDetails.productName}
            onChange={(e) => handleProductChange(e.target.value, 'productName')}
            label={'Nazwa produktu'}
            placeholder={'Kompresy gazowe 5x5 KompriLux jał.'}
            disabled={isLoading}
            validator={validator}
            rule={'required|product_name'}
          />
          <Select
            options={categories}
            value={productDetails.category}
            onChange={(e) => handleProductChange(e, 'category')}
            label={'Kategoria'}
            placeholder={'Akcesoria protetyczne'}
            isLoading={isLoading}
            isClearable
            validator={validator}
            rule={'required'}
          />
          {product?.id && user?.role === userRoles.admin && <p className={style.productId}>ID produktu: {product.id}</p>}
        </div>
      </div>
      <div className={style.column}>
        <div className={style.rowWrapper}>
          <Select
            options={units}
            value={productDetails.unit}
            onChange={(e) => handleProductChange(e, 'unit')}
            label={'Jednostka'}
            placeholder={'szt.'}
            isLoading={isLoading}
            validator={validator}
            rule={'required'}
          />
          {productDetails.unit?.is_collection && (
            <QtySelector
              state={productDetails.collectionQty}
              setState={(e) => handleProductChange(e, 'collectionQty')}
              suffix={'szt.'}
              wrapperStyle={style.qty}
              label={'Ilość sztuk'}
              hideArrows
              validator={validator}
              rule={'required|only_positive'}
            />
          )}
        </div>
        <div className={style.rowWrapper}>
          <AsyncSelect
            value={productDetails.producer}
            onChange={(e) => handleProductChange(e, 'producer')}
            apiCallback={ProductsApi.getProducers}
            valueKey={'id'}
            labelKey={'producer_name'}
            label={'Producent'}
            placeholder={'3M'}
            noOptionCallback={producersEmptyState}
            isClearable
            validator={validator}
            rule={'required'}
          />
          {productDetails.producer?.value === 'ADD_NEW_PRODUCER' && (
            <Input
              value={productDetails.newProducer}
              onChange={(e) => handleProductChange(e.target.value, 'newProducer')}
              label={'Nazwa producenta'}
              placeholder={'Twój producent'}
              ref={producerInputRef}
              validator={validator}
              rule={'required'}
            />
          )}
        </div>
      </div>
      {!!product?.ean && (
        <div className={style.columnCenter}>
          <Barcode
            value={product.ean}
            height={40}
          />
        </div>
      )}
      {children}
      <Button
        className={style.button}
        label={'Zapisz'}
        onClick={handleSave}
        isLoading={isParentLoading || isLoading}
      />
    </div>
  );
};

export default ProductDetailsForm;
