import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Button, EmptyState } from 'components';
import { notifyApiError } from 'components/layout/Toasts';
import ValuationModalProduct from 'components/valuations/ValuationModalProduct';

import { ValuationsApi } from 'src/api';
import { refreshValuations } from 'src/features/ValuationView/actions';
import shared from 'src/styles/custom/Shared.module.scss';

import style from './ChangeDistributor.module.scss';

const ChangeDistributor = ({ item, closeModal }) => {
  const [valuation, setValuation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const changeDentametrPrice = async () => {
    try {
      setIsLoading(true);
      await ValuationsApi.changeDentametrPrice({
        pricing_prod_id: item.pricing_prod_id,
        price_id: valuation.id
      });
      await ValuationsApi.changeSelectedProductDistributor({
        pricing_prod_id: item.pricing_prod_id,
        dist_id: valuation.dist_id
      });
      closeModal();
      dispatch(refreshValuations());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    item.other_prices.forEach((val) => {
      if (val.dist_id === item.supplier_id) {
        setValuation(val);
      }
    });
  }, []);

  return (
    <div className={style.container}>
      <section className={shared.tableWrapper}>
        <div className={classNames(style.header)}>
          <p></p>
          <p>Nazwa</p>
          <p>Dostepność</p>
          <p>Ilość</p>
          <p>Cena (za szt.)</p>
          <p>Wartość</p>
        </div>
        <div className={classNames(shared.tableCategory, style.list)}>
          {item.other_prices?.length ? (
            item.other_prices?.map((sup) => (
              <ValuationModalProduct
                key={sup.id}
                product={sup}
                isSelected={sup.name === item.supplier}
                setValuation={setValuation}
                valuation={valuation}
              />
            ))
          ) : (
            <div className={shared.tableCategory}>
              <h3 className={shared.tableCategoryTitle}>
                <EmptyState type={'suppliers'} />
              </h3>
            </div>
          )}
        </div>
        <div className={style.buttonWrapper}>
          <Button
            onClick={changeDentametrPrice}
            label={'Zmień dostawcę'}
            disabled={valuation.length === 0}
            isLoading={isLoading}
          />
        </div>
      </section>
    </div>
  );
};

export default ChangeDistributor;
