import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { ReactComponent as PlusIcon } from 'assets/icons/plus-blue.svg';
import { Accordion, AddressDetails, Button, CompanyDetails, QtySelector, Textarea, useValidator } from 'components';
import { AdditionalShipping } from 'components';
import Modal from 'components/layout/Modal';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { getFormattedAmount } from 'src/utils/helpers';

import style from './OrderDetails.module.scss';

const OrderDetails = ({ data, refreshDataCallback, hideExtraCost, defaultClose }) => {
  const { invoice_details, shipping_details } = data.order || {};

  const params = useParams();
  const validator = useValidator();
  const [shippingValue, setShippingValue] = useState();
  const [shippingComment, setShippingComment] = useState();
  const [isModalShippingOpen, setIsModalShippingOpen] = useState();

  useEffect(() => {
    setShippingValue(shipping_details?.additional_shipping_price);
    setShippingComment(data?.supplier_comment);
  }, [data]);

  const clearShippingFormHandler = () => {
    setShippingValue(null);
    setShippingComment(null);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const queryParams = {
      uid: params.uid,
      additional_shipping_price: shippingValue,
      supplier_comment: shippingComment
    };

    try {
      await SupplierApi.updateOrder(queryParams);
      notifyCommon(['Dodano pomyślnie!']);
      refreshDataCallback();
      setIsModalShippingOpen(false);
      clearShippingFormHandler();
    } catch (error) {
      notifyApiError(error);
    }

    validator.hideMessages();
  };

  const invoiceDataTitle = <h2 className={style.title}>Dane do faktury</h2>;
  const shippingDataTitle = <h2 className={style.title}>Dane do wysyłki</h2>;

  return (
    <div className={style.container}>
      <Accordion
        title={invoiceDataTitle}
        defaultWrapped={defaultClose}
      >
        <CompanyDetails
          details={invoice_details}
          readOnly
        />
      </Accordion>
      <Accordion
        title={shippingDataTitle}
        defaultWrapped={defaultClose}
      >
        <div className={style.contentWrapper}>
          <AddressDetails
            details={shipping_details}
            readOnly
          />
          <div>
            <div className={style.row}>
              <p className={style.label}>Metoda płatności:</p>
              <p className={style.value}>{shipping_details?.pay_method}</p>
            </div>
            <div className={style.row}>
              <p className={style.label}>Metoda wysyłki:</p>
              <p className={style.value}>{shipping_details?.ship_method}</p>
            </div>
            <div className={style.row}>
              <p className={style.label}>Koszty wysyłki:</p>
              <p className={style.value}>
                {getFormattedAmount(shipping_details?.shipping_price)}
                <AdditionalShipping
                  price={shipping_details?.additional_shipping_price}
                  comment={data?.supplier_comment}
                />
              </p>
              {!hideExtraCost && (
                <a
                  onClick={() => setIsModalShippingOpen(true)}
                  className={style.extraCost}
                >
                  <PlusIcon />
                </a>
              )}
            </div>
          </div>
        </div>
      </Accordion>
      <Modal
        visible={isModalShippingOpen}
        onClose={() => setIsModalShippingOpen(false)}
        title={
          shipping_details?.additional_shipping_price > 0 ? 'Edytuj dodatkowy koszt wysyłki' : 'Dodaj dodatkowy koszt wysyłki'
        }
        closeOnOutsideClick
      >
        <form
          className={style.modalFormWrapper}
          onSubmit={submitHandler}
        >
          <QtySelector
            id={'shippingValue'}
            name={'shippingValue'}
            label={'Dodatkowy koszt wysyłki'}
            suffix={'zł'}
            state={shippingValue}
            setState={setShippingValue}
            validator={validator}
            rule={'required|only_positive'}
            hideArrows
            priceInput
          />
          <Textarea
            id={'shippingComment'}
            name={'shippingComment'}
            label={'Komentarz'}
            value={shippingComment}
            setValue={setShippingComment}
            validator={validator}
            rule={'required'}
          />
          <Button label={'Dodaj'} />
        </form>
      </Modal>
    </div>
  );
};

export default OrderDetails;
