import React from 'react';
import cn from 'classnames';

import { toDateNumber, toTime } from 'src/utils/dateTime';

import style from '../HistorySidebar/HistorySidebar.module.scss';

const Tile = ({ index, item, itemContent, getProductName, historyLength }) => {
  const { color, title, content, products, product, triggerer, list, children, approver } = itemContent;
  const { content: listContent, title: listTitle } = list || {};

  const displaySupplier = (!!triggerer && triggerer === 'supplier') || (!triggerer && !!item.supplier_name);
  const displayUser = (!!triggerer && triggerer === 'user') || (!triggerer && !!item.user_name);

  return (
    <div
      className={cn(style.item, {
        [style.last]: ++index === historyLength,
        [style.first]: index === 1,
        [style[color]]: !!color
      })}
    >
      {!!item.created_at && (
        <span className={style.date}>
          {toDateNumber(item.created_at)}, {toTime(item.created_at)}
        </span>
      )}
      <h3 className={style.title}>{title}</h3>
      {!!content && (
        <p
          className={style.content}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
      {!!children && children}
      {products?.length > 0 &&
        (products.length === 1 ? (
          <p className={style.product}>{getProductName(products[0])}</p>
        ) : (
          <ol className={style.list}>
            {products?.map((prod) => (
              <li
                key={prod}
                className={style.product}
              >
                {getProductName(prod)}
              </li>
            ))}
          </ol>
        ))}
      {listContent?.length > 0 && (
        <ol className={style.list}>
          {!!listTitle && <h5 style={{ marginLeft: '-20px', marginTop: '6px' }}>{listTitle}</h5>}
          {listContent?.map((listContentItem) => (
            <li
              key={listContentItem}
              className={style.product}
            >
              {listContentItem}
            </li>
          ))}
        </ol>
      )}
      {product && (
        <p className={style.content}>
          <strong>Produkt: </strong>
          {getProductName(product)}
        </p>
      )}
      {displayUser && (
        <p className={style.text}>
          {item.user_name} {!!item.user_email && `(${item.user_email})`}
        </p>
      )}
      {displaySupplier && (
        <p className={style.text}>
          {item.supplier_name} {!!item.supplier_email && `(${item.supplier_email})`}
        </p>
      )}
      {!!approver && <p className={style.text}>{approver}</p>}
    </div>
  );
};

export default Tile;
