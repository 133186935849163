import React from 'react';
import classNames from 'classnames';

import { EmptyState, useIsAdmin } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import SuppliersItem from 'src/features/Suppliers/components/SuppliersItem';
import shared from 'src/styles/custom/Shared.module.scss';

import style from './SuppliersTable.module.scss';

const SuppliersTable = ({ list = [], isLoading }) => {
  const isAdmin = useIsAdmin();

  if (isLoading) {
    return (
      <section className={shared.tableWrapper}>
        <div className={classNames(shared.tableHeader, style.header, { [style.admin]: isAdmin })}>
          <p>Nazwa</p>
          <p>E-mail</p>
          <p>Telefon</p>
          {isAdmin && (
            <>
              <p>Właściciel</p>
              <p>Ukryj dane</p>
            </>
          )}
        </div>
        <div className={shared.tableCategory}>
          <ProductPlaceholder quantity={6} />
        </div>
      </section>
    );
  }

  if (!list.length) {
    return (
      <section className={shared.tableWrapper}>
        <div className={shared.tableCategory}>
          <EmptyState type={'suppliers'} />
        </div>
      </section>
    );
  }

  return (
    <section className={shared.tableWrapper}>
      <div className={classNames(shared.tableHeader, style.header, { [style.admin]: isAdmin })}>
        <p>Nazwa</p>
        <p>E-mail</p>
        <p>Telefon</p>
        {isAdmin && (
          <>
            <p>Właściciel</p>
            <p>Ukryj dane</p>
          </>
        )}
      </div>
      <div className={shared.tableCategory}>
        {list.map((sup) => (
          <SuppliersItem
            key={sup.id}
            supplier={sup}
          />
        ))}
      </div>
    </section>
  );
};

export default SuppliersTable;
