import React from 'react';

import { Button } from 'components';

import style from '../../OrderCart.module.scss';

const TableHeader = ({ openModal }) => {
  return (
    <div className={style.sectionTop}>
      <header className={style.headerTop}>
        <h2 className={style.title}>Szczegóły zamówienia</h2>
      </header>
      {!!openModal && (
        <Button
          onClick={openModal}
          label='Dodaj załącznik'
        />
      )}
    </div>
  );
};

export default TableHeader;
