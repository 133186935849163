import { useEffect, useRef } from 'react';

const useOutsideClick = (callback) => {
  const ref = useRef(null);

  const isExcludedElement = (classes = []) => {
    let isExcluded = false;
    classes.forEach((className) => {
      if (typeof className === 'string' && className.substring(0, 4) === 'css-') isExcluded = true;
    });
    return isExcluded;
  };

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !(ref.current.contains(event.target) || isExcludedElement(event.target.classList))) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref]);

  return ref;
};

export default useOutsideClick;
