import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Card, LoadingIndicator, Textarea, useAutoSave, useRequestAbortController } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';
import { ReactComponent as PlusBlue } from 'src/assets/icons/plus-blue.svg';

import { refreshListView } from '../../actions';

import style from './ListComment.module.scss';
import shared from 'styles/custom/Shared.module.scss';

const ListComment = ({ listComment, uid, parentIsLoading }) => {
  const dispatch = useDispatch();

  const [comment, setComment] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveError, setIsSaveError] = useState(false);
  const [abortController, setNewController] = useRequestAbortController();

  const saveComment = async () => {
    const queryParams = {
      list_comment: comment || '',
      uid
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsSaveError(false);
      setIsLoading(true);
      await ListsApi.saveListComment(queryParams, signal);
      dispatch(refreshListView());
    } catch (err) {
      notifyApiError(err);
      setIsSaveError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    //  we don't want falsy value here;
    if (listComment) setIsOpen(true);
    setComment(listComment);
  }, [listComment]);

  useAutoSave({
    first: listComment,
    second: comment,
    saveHandler: saveComment,
    dependency: [comment],
    isLoading: parentIsLoading,
    timeoutValue: 1800
  });

  if (parentIsLoading) {
    return (
      <Card className={classNames(shared.defaultWrapper, style.card)}>
        <Skeleton height={20} />
      </Card>
    );
  }

  return (
    <Card className={classNames(shared.defaultWrapper, style.card, { [style.isClose]: !isOpen })}>
      {isOpen ? (
        <h2 className={classNames(shared.cardTitle)}>Dodaj komentarz do listy</h2>
      ) : (
        <a
          onClick={() => setIsOpen(true)}
          className={classNames(shared.cardTitle, style.title)}
        >
          Dodaj komentarz do listy <PlusBlue />
        </a>
      )}

      {isOpen && (
        <>
          <p>Wiadomość będzie widoczna dla każdego z dostawców, który otrzyma listę do wyceny.</p>
          <div className={style.box}>
            <Textarea
              value={comment}
              setValue={setComment}
              placeholder={'Np. Interesują mnie tańsze zamienniki'}
            />
            <LoadingIndicator
              isLoading={isLoading}
              hasError={isSaveError}
              aboveInput
            />
          </div>
        </>
      )}
    </Card>
  );
};

export default ListComment;
