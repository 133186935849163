import React, { useEffect } from 'react';

const useAutoSave = ({ first, second, saveHandler, isLoading, timeoutValue = 800, dependency = [] }) => {
  useEffect(() => {
    let timeout;
    if (!isLoading && first !== second) {
      timeout = setTimeout(saveHandler, timeoutValue);
    }

    return () => clearTimeout(timeout);
  }, [...dependency, isLoading]);

  return null;
};

export default useAutoSave;
