import React, { useState } from 'react';
import classNames from 'classnames';

import { Button, Input, useIsMedicover, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AccountsApi } from 'src/api';

import style from '../../../../onboarding.module.scss';

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isMedicover = useIsMedicover();

  const validator = useValidator();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    setIsLoading(true);
    try {
      const { data } = await AccountsApi.forgotPassword({ email });
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.card}
      onSubmit={handleSubmit}
    >
      <h2 className={style.formLabel}>Resetowanie hasła</h2>
      <Input
        id='email'
        name='email'
        placeholder='Adres e-mail'
        type='email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        validator={validator}
        rule={'required|email'}
      />
      <div className={classNames(style.buttons, style.buttonsWithMargin)}>
        <Button
          label={'Zaloguj się'}
          to={isMedicover ? '/' : '/login'}
          className={style.button}
          gray
        />
        <Button
          type='submit'
          label={'Resetuj hasło'}
          isLoading={isLoading}
          className={style.button}
        />
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
