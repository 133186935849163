import React from 'react';
import classNames from 'classnames';

import SinglePrice from './components/SinglePrice';

import style from './PriceList.module.scss';
import shared from 'styles/custom/Shared.module.scss';

const PriceList = ({ prices = [] }) => {
  return (
    <div className={style.container}>
      <section className={classNames(shared.tableWrapper, style.table)}>
        <div className={classNames(shared.tableHeader, style.header)}>
          <p>Dostawca</p>
          <p>cena</p>
          <p>data</p>
        </div>
        {prices.map((price) => (
          <SinglePrice
            key={price.id}
            data={price}
          />
        ))}
      </section>
    </div>
  );
};

export default PriceList;
