import * as types from './types';

export const addProduct = (product) => ({
  type: types.ADD_PRODUCT,
  payload: product
});

export const addOffer = (valuation) => ({
  type: types.ADD_OFFER,
  payload: valuation
});

export const clearProducts = (list_id) => ({
  type: types.CLEAR_PRODUCTS,
  payload: list_id
});

export const addOrderDetails = (data) => ({
  type: types.ADD_ORDER_DETAILS,
  payload: data
});

export const changeProductQuantity = (id, new_quantity) => ({
  type: types.CHANGE_PRODUCT_QUANTITY,
  payload: { id, new_quantity }
});

export const clearOrderDetails = () => ({
  type: types.CLEAR_ORDER_DETAILS
});

export const refreshValuations = () => ({
  type: types.CHANGE_VALUATIONS_VERSION
});

export const refreshSummary = () => ({
  type: types.REFRESH_SUMMARY
});

export const openSummary = () => ({
  type: types.OPEN_CART_DETAILS
});

export const closeSummary = () => ({
  type: types.CLOSE_CART_DETAILS
});

export const toggleSummary = () => ({
  type: types.TOGGLE_CART_DETAILS
});

export const updateSavings = (data) => ({
  type: types.UPDATE_SAVINGS,
  payload: data
});
export const updateSingleSaving = (data) => ({
  type: types.UPDATE_SINGLE_SAVING,
  payload: data
});
