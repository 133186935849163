import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useIsAdmin } from 'components';
import ChangePrice from 'components/layout/changeMenu/ChangePrice';
import { notifyApiError } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';
import { getFormattedAmount } from 'src/utils/helpers';

import { refreshValuations } from '../../actions';

import style from './RefPrice.module.scss';

const RefPrice = ({ price, quantity = 1, className, showRefPrice, selectedCompany, productId }) => {
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);

  if (!showRefPrice) {
    return null;
  }

  const addPrice = async (newPrice) => {
    const queryData = {
      ...(isAdmin && { company_id: selectedCompany }),
      product_id: productId,
      type: 'ref_price',
      price: +newPrice
    };

    try {
      await OfferCatalogApi.addPrice(queryData);
      dispatch(refreshValuations());
    } catch (err) {
      notifyApiError(err);
    }
  };

  return (
    <div className={classNames(style.container, { [style.active]: isActive }, className)}>
      <ChangePrice
        tagClassName={style.tag}
        price={price}
        setPrice={addPrice}
        isActive={isActive}
        setIsActive={setIsActive}
        displayValueFormatter={() => (!price || +price === 0 ? 'Brak' : getFormattedAmount(price * quantity))}
        label={'Cena za szt.'}
      />
    </div>
  );
};

export default RefPrice;
