import React, { useState } from 'react';
import { useParams } from 'react-router';
import cn from 'classnames';

import { Button, FilterByCategory, FilterBySupplier, Modal, Searchbar, useIsAdmin, usePermissions } from 'components';

import { userPermissions } from 'src/constants/enums';

import AddNewPrice from '../AddNewPrice';
import FlatFileHandling from '../FlatFileHandling';

import style from './Filtering.module.scss';

const Filtering = ({ params, selectedCompany }) => {
  const { listUID } = useParams();
  const isAdmin = useIsAdmin();
  const [canManageCatalog] = usePermissions([userPermissions.offer_catalog.menage]);
  const [isFlatFileModalOpen, setIsFlatFileModalOpen] = useState(false);

  const closeModal = () => setIsFlatFileModalOpen(false);
  const openModal = () => setIsFlatFileModalOpen(true);

  return (
    <div className={cn(style.container, { [style.fromList]: listUID })}>
      <Searchbar
        params={params}
        fullWidth
      />
      <FilterByCategory
        params={params}
        queryParams={listUID ? { filter_by: 'LIST', list_uid: listUID } : undefined}
        fullWidth
        isMulti
      />
      <FilterBySupplier
        params={params}
        queryParams={{ pagination: 1, ...(isAdmin && { company_id: selectedCompany }) }}
        asUser
        fullWidth
        isMulti
      />
      {canManageCatalog && (
        <Button
          label={'Import & Export'}
          onClick={openModal}
          gray
        />
      )}
      {canManageCatalog && !listUID && (
        <div className={style.wrapper}>
          <AddNewPrice
            selectedCompany={selectedCompany}
            asButton
          />
        </div>
      )}
      <Modal
        visible={isFlatFileModalOpen}
        onClose={closeModal}
        title={'Import & Export katalogu ofert'}
      >
        <FlatFileHandling
          closeModal={closeModal}
          selectedCompany={selectedCompany}
        />
      </Modal>
    </div>
  );
};

export default Filtering;
