import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import { Icon, OutsideClickHandler, useCompany } from 'components';
import UserData from 'components/layout/UserData';

import logoPlaceholder from 'src/assets/img/profile_placeholder.png';
import shared from 'src/styles/custom/Shared.module.scss';
import { imageErrorHandler } from 'src/utils/helpers';

import styles from './User.module.scss';

const items = [
  {
    title: 'Profil',
    url: '/profile',
    icon: 'profile'
  },
  {
    title: 'Wyloguj się',
    color: true,
    url: '/logout',
    icon: 'logout'
  }
];

const User = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const company = useCompany();

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={classnames(styles.user, { [styles.active]: visible }, className)}>
        <button
          className={styles.head}
          onClick={() => setVisible((prev) => !prev)}
        >
          <img
            src={company?.logo_small || logoPlaceholder}
            className={shared.companyLogo}
            alt='Company logo'
            onError={imageErrorHandler}
          />
        </button>
        <div className={styles.body}>
          <UserData />
          <div className={styles.menu}>
            {items.map((item, index) => (
              <NavLink
                className={classnames(styles.item, {
                  [styles.color]: item.color
                })}
                activeClassName={styles.active}
                to={item.url}
                onClick={() => setVisible(false)}
                key={index}
              >
                {item.icon && (
                  <Icon
                    name={item.icon}
                    size='24'
                  />
                )}
                {item.title}
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
