import React, { useEffect, useMemo, useState } from 'react';

import { Card, Chart, Select } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { StatisticsApi } from 'src/api';
import { toDateNumber } from 'src/utils/dateTime';
import { getFormattedAmount, translateMonthsWithInfo } from 'src/utils/helpers';

import ExportData from '../ExportData';

import style from '../Charts.module.scss';

const options = [
  { label: 'Wartość oszczędności narastająco', value: 'saved_money_agregation' },
  { label: 'Wartość oszczędności miesięcznie', value: 'saved_money' },
  { label: 'Procent oszczędności w stosunku do łącznych wydatków', value: 'perc_saved_to_total_sum' },
  { label: 'Procent oszczędności w stosunku do kosztów produktów', value: 'perc_saved_to_product_sum' }
];

const SavingsMonthly = ({ selectedCompanyId, filters }) => {
  const [chartData, setChartData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState(options[0]);

  const getData = async () => {
    const queryData = {
      ...(!!filters.selectedCompanies && { company_ids: filters.selectedCompanies.map((company) => company.value) }),
      ...(selectedCompanyId && { company_id: selectedCompanyId }),
      ...(filters.startDate && filters.endDate && { date_from: filters.startDate, date_to: filters.endDate })
    };
    try {
      setIsLoading(true);
      const { data } = await StatisticsApi.getSavingsMonthly(queryData);
      setChartData(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [filters, selectedCompanyId]);

  const selectedChartData = useMemo(() => {
    if (chartData) return chartData[selectedDataType.value];
    else return [];
  }, [chartData, selectedDataType, selectedCompanyId]);

  const chartFormatter = useMemo(() => {
    switch (selectedDataType?.value) {
      case 'saved_money':
        return {
          yAxis: (val) => getFormattedAmount(val),
          formatter: (val, opts) =>
            `${getFormattedAmount(val)} (${chartData?.perc_saved_to_total_sum[opts?.dataPointIndex]?.toFixed(2)}%)`
        };
      case 'saved_money_agregation':
        return {
          yAxis: (val) => getFormattedAmount(val),
          formatter: (val) => getFormattedAmount(val)
        };
      case 'perc_saved_to_total_sum':
      case 'perc_saved_to_product_sum':
        return {
          yAxis: (val) => +val.toFixed(2) + '%',
          formatter: (val) => +val.toFixed(2) + '%'
        };
      default:
        return {
          yAxis: (val) => val,
          formatter: (val) => val
        };
    }
  }, [chartData, selectedCompanyId, selectedDataType]);

  return (
    <Card className={style.container}>
      <header className={style.header}>
        <h2 className={style.title}>Oszczędności</h2>
        <div className={style.options}>
          <ExportData
            company_id={selectedCompanyId}
            filters={filters}
            apiCallback={StatisticsApi.exportSavingsData}
          />
        </div>
      </header>
      <Chart
        title={'Oszczędności w ujęciu miesięcznym'}
        data={selectedChartData}
        categories={chartData?.months}
        categoriesLabel={'Oszczędności'}
        chartTypes={['area', 'bar']}
        dataLabelsFormatter={chartFormatter.formatter}
        yAxisFormatter={chartFormatter.yAxis}
        categoriesFormatter={(value) => translateMonthsWithInfo(value, filters)}
        tooltipFormatter={chartFormatter.formatter}
        configRefreshVariable={selectedDataType}
        haveData={chartData?.have_data}
        isLoading={isLoading}
        colors={['#04e396']}
        height={350}
        fullWidth
      >
        <Select
          options={options}
          value={selectedDataType}
          onChange={setSelectedDataType}
          isSearchable={false}
          width={200}
          className={style.select}
          wrapperStyle={style.select}
        />
      </Chart>
      <p>* - Dane na dzień {toDateNumber(filters.endDate)}r. Dane agregują się po odebraniu zamówienia.</p>
    </Card>
  );
};

export default SavingsMonthly;
