import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';

import { changeUsersVersion } from '../../../features/Users/actions';

import style from './SupplierClientThumbnail.module.scss';

const SupplierClientThumbnail = ({ clinic }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const deleteConnection = async () => {
    const data = {
      supplier_id: clinic.supplier_id,
      user_id: clinic.supplier_user_id
    };

    try {
      setIsLoading(true);
      await AdminApi.deleteSupplierUserConnection(data);
      dispatch(changeUsersVersion());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <p>{clinic.email}</p>
      </div>
      <div className={style.wrapper}>
        <p>{clinic.clinics_name}</p>
      </div>
      <div className={style.wrapper}>
        <p>{clinic.name}</p>
      </div>
      <div className={style.wrapper}>
        <Button
          label={'Usuń połączenie'}
          type={'submit'}
          onClick={deleteConnection}
          classNames={style.button}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default SupplierClientThumbnail;
