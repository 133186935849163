import React from 'react';
import { useSelector } from 'react-redux';

import { Card } from 'components';

import { getSelectedId, useSupplierFormValidationForceUpdate } from '../../../../helpers';
import PaymentSelection from '../../../PaymentSelection';

import style from '../../OrderDetails.module.scss';
import shared from 'styles/custom/Shared.module.scss';

const Payments = ({ disabled }) => {
  useSupplierFormValidationForceUpdate();
  const payments = useSelector((store) => store.supplierForm.form.payments);
  const validator = useSelector((state) => state.supplierForm.validator);

  const selectedPaymentsId = getSelectedId(payments);

  return (
    <Card
      className={shared.defaultWrapper}
      id={'payments'}
    >
      <h2 className={style.title}>Sposób płatności</h2>
      {payments.map((method) => (
        <PaymentSelection
          key={method.id}
          paymentMethod={method}
          disabled={disabled}
          showError={!!validator?.message('payments', selectedPaymentsId, 'required')}
        />
      ))}
      <span className={style.errorMessage}>{validator?.message('payments', selectedPaymentsId, 'required')}</span>
    </Card>
  );
};

export default Payments;
