import React from 'react';
import classNames from 'classnames';

import { ColumnSortButton, EmptyState } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import { companyPlaceholder } from 'src/constants/misc';
import shared from 'src/styles/custom/Shared.module.scss';

import ClientThumbnail from '../ClientThumbnail';

import style from './ClientsList.module.scss';

const ClientsList = ({ list = [], isLoading, params }) => {
  if (isLoading) {
    return (
      <section className={shared.tableWrapper}>
        <div className={shared.tableCategory}>
          <ProductPlaceholder />
        </div>
      </section>
    );
  }

  return (
    <section className={shared.tableWrapper}>
      <div className={classNames(shared.tableHeader, style.header)}>
        <ColumnSortButton
          parameterName={'name'}
          params={params}
          content={'Nazwa firmy'}
        />
        <ColumnSortButton
          parameterName={'nip'}
          params={params}
          content={'NIP'}
        />
        <ColumnSortButton
          parameterName={'phone'}
          params={params}
          content={'Telefon'}
        />
        <ColumnSortButton
          parameterName={'orders_total'}
          params={params}
          content={'Suma zamówień'}
        />
        <ColumnSortButton
          parameterName={'valuations_total'}
          params={params}
          content={'Suma wycen'}
        />
      </div>
      <div className={shared.tableCategory}>
        {list.length ? (
          list.map((company) => (
            <ClientThumbnail
              key={company.id}
              company={company?.client_data_hidden ? { ...company, ...companyPlaceholder } : company}
            />
          ))
        ) : (
          <EmptyState type={'clients'} />
        )}
      </div>
    </section>
  );
};

export default ClientsList;
