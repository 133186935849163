import React, { useEffect, useState } from 'react';
import Barcode from 'react-barcode';
import { useDispatch } from 'react-redux';

import { AsyncSelect, Button, Input, OptionSwitch, SaveButton, useValidator } from 'components';
import { notifyApiError, notifyCommon, notifyDanger } from 'components/layout/Toasts';

import { ProductsApi, UserApi } from 'src/api';

// TODO move this component to /components/
import ProductDetails from '../../../features/OfferCatalog/components/SuppliersList/components/ProductDetails';
import { refreshList } from '../../../features/ProductsBase/actions';
import Scanner from './components/Scanner';

import style from './BarcodeScanner.module.scss';

const BarcodeScanner = ({ product, onClose }) => {
  const dispatch = useDispatch();
  const validator = useValidator();

  const [code, setCode] = useState(product?.ean);
  const [productDetails, setProductDetails] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isScannerVisible, setIsScannerVisible] = useState(true);

  const saveProduct = async () => {
    const productData = product || selectedProduct;

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    if (!code) {
      notifyDanger(['Zeskanuj lub wpisz kod EAN, aby zapisać.']);
      return;
    }

    const queryParams = {
      product_id: productData?.product_id || productData?.id,
      ...productData,
      ...productDetails,
      ean: code
    };

    try {
      setIsLoading(true);
      await ProductsApi.addProduct(queryParams);
      dispatch(refreshList());
      notifyCommon(['Zmiany zostały zapisane.']);
      if (onClose) onClose();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getData = async () => {
    const productData = product || selectedProduct;

    try {
      const { data } = await UserApi.getProductCreationData({
        product_id: productData?.product_id || productData?.id
      });
      setProductDetails(data.product);
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={style.container}>
      <OptionSwitch
        defaultOption={isScannerVisible}
        onChange={setIsScannerVisible}
        falseLabel={'Wpisz numer'}
        trueLabel={'Skanuj obraz'}
      />
      {product ? (
        <ProductDetails product={product} />
      ) : (
        <AsyncSelect
          id={'select-product'}
          value={selectedProduct}
          onChange={setSelectedProduct}
          apiCallback={ProductsApi.getProducts}
          valueKey={'id'}
          labelKey={'name'}
          label={'Produkt'}
          placeholder={'Produkt'}
          productSearch
          isClearable
          validator={validator}
          rule={'required'}
        />
      )}
      {isScannerVisible ? (
        code ? (
          <div className={style.code}>
            <Barcode value={code} />
            <SaveButton
              type={'delete'}
              onClick={() => setCode(null)}
              className={style.clear}
            />
          </div>
        ) : (
          <Scanner onScan={setCode} />
        )
      ) : (
        <Input
          onChange={(e) => setCode(e.target.value)}
          placeholder={'5900049823002'}
          validator={validator}
          rule={'required'}
          label={'Kod EAN'}
          id={'ean-input'}
          value={code}
        />
      )}
      <Button
        label={'Zapisz kod EAN'}
        isLoading={isLoading}
        onClick={saveProduct}
      />
    </div>
  );
};

export default BarcodeScanner;
