import React from 'react';

import { Card } from 'components';

import shared from 'src/styles/custom/Shared.module.scss';

const SupplierComment = ({ comment }) => {
  if (!comment) {
    return null;
  }

  return (
    <Card className={shared.defaultWrapper}>
      <h2 className={shared.cardTitle}>Komentarz dostawcy</h2>
      <p>{comment}</p>
    </Card>
  );
};

export default SupplierComment;
