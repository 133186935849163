import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { InfoWidget, Menu, useAuthUser, useIsMobile } from 'components';
import ChangePrice from 'components/layout/changeMenu/ChangePrice';
import ChangeQuantity from 'components/layout/changeMenu/ChangeQuantity';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';
import replacementThumbnail from 'src/assets/img/replacement-thumbnail.png';
import { orderProductTypes, orderStatuses, userRoles } from 'src/constants/enums';
import { getFormattedAmount } from 'src/utils/helpers';

import style from './OrderListProduct.module.scss';

const OrderListProduct = (props) => {
  const {
    product,
    type,
    hideStatusColumn,
    hideCommentColumn,
    children,
    refreshDataCallback,
    hideProductActions,
    index,
    displayOver
  } = props;
  const {
    name,
    producer,
    unit_name,
    qty: quantityShort,
    quantity,
    price,
    price_for_one,
    thumbnail,
    image,
    status,
    type: productType,
    id
  } = product || {};

  const params = useParams();
  const user = useAuthUser();
  const isLgScreen = useIsMobile(1500);
  const [isQtyActive, setIsQtyActive] = useState(false);
  const [isPriceActive, setIsPriceActive] = useState(false);

  const qty = useMemo(() => quantityShort || quantity, [quantityShort, quantity]);

  const newQuantityProducts = useSelector((state) => state.valuationView.newQuantityProducts);
  const newQuantity = useMemo(
    () => newQuantityProducts.find((item) => item.id === product.id)?.new_quantity,
    [newQuantityProducts]
  );

  const newPriceProducts = useSelector((state) => state.valuationView.newPriceProducts);
  const newPrice = useMemo(() => newPriceProducts?.find((item) => item.id === product.id)?.new_price, [newPriceProducts]);

  const wasQtyChange = useMemo(() => newQuantity && +newQuantity !== qty, [newQuantity, qty]);
  const wasPriceChange = useMemo(() => newQuantity && +newQuantity !== qty, [newQuantity, qty]);

  const containerClasses = classNames(style.wrapper, style.productName, {
    [style.hideStatusColumn]: hideStatusColumn
  });

  const productName = (
    <p className={classNames(style.text, style.trim)}>
      {type === 'replacement' && (
        <span className={classNames(style.info, style.replacement)}>
          <WarningIcon />
          Zamiennik
        </span>
      )}
      {type === 'offer' && <span className={classNames(style.info, style.offer)}>Oferta dostawcy</span>}
      {name}
    </p>
  );

  const handleChangeQty = async (quantity) => {
    const query = {
      uid: params.uid,
      products: [{ id: product.id, quantity }]
    };

    try {
      await SupplierApi.acceptProductQuantity(query);
      notifyCommon(['Liczba produktu została zmieniona.']);
      refreshDataCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleChangePrice = async (price) => {
    const query = {
      uid: params.uid,
      products: [{ id: product.id, price }]
    };

    try {
      await SupplierApi.acceptNewPrice(query);
      notifyCommon(['Cena produktu została zmieniona.']);
      refreshDataCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const actions = hideProductActions
    ? []
    : [
        {
          title: 'Zmień ilość',
          icon: 'design',
          action: () => setIsQtyActive(true),
          roles: ['admin']
        },
        {
          title: 'Zmień cenę',
          color: 'red',
          icon: 'edit',
          action: () => setTimeout(() => setIsPriceActive(true), 50)
        }
      ];

  return (
    <div
      className={classNames(style.container, {
        [style.hideStatusColumn]: hideStatusColumn,
        [style.hideCommentColumn]: hideCommentColumn,
        [style.cancelled]: status === 'CANCELLED',
        [style.replacement]: productType === 'replacement',
        [style.offer]: productType === 'offer',
        [style.offer]: productType === 'offer',
        [style.withMenu]: !!actions.length
      })}
    >
      <div className={classNames(style.wrapper, style.index)}>
        <p>{index}.</p>
      </div>
      <div className={containerClasses}>
        <ProductThumbnailImage
          thumbnail={type === 'replacement' ? replacementThumbnail : thumbnail}
          image={type === 'replacement' ? null : image}
          title={name}
          id={product.product_id}
        />
        {productName}
      </div>
      <div className={classNames(style.wrapper, style.producer)}>
        {isLgScreen && <p>Producent: </p>}
        <p className={style.text}>{producer}</p>
      </div>
      <div className={classNames(style.wrapper, style.qty)}>
        {isLgScreen && <p>Ilość: </p>}
        {user?.role === userRoles.admin ? (
          <ChangeQuantity
            wasQtyChange={wasQtyChange}
            quantity={newQuantity || qty}
            unit={unit_name}
            setQuantity={handleChangeQty}
            isActive={isQtyActive}
            setIsActive={setIsQtyActive}
            displayOver={displayOver}
          />
        ) : (
          <p className={style.text}>{`${newQuantity || qty} ${unit_name ? unit_name : ''}`}</p>
        )}
      </div>
      <div className={classNames(style.wrapper, style.forOne)}>
        {isLgScreen && <p>Cena za szt.: </p>}
        <ChangePrice
          wasPriceChange={wasPriceChange}
          price={newPrice || price_for_one}
          setPrice={handleChangePrice}
          isActive={isPriceActive}
          setIsActive={setIsPriceActive}
          displayOver={displayOver}
        />
      </div>
      <div className={classNames(style.wrapper, style.sum)}>
        {isLgScreen && <p>Wartość: </p>}
        <p className={style.text}>{getFormattedAmount(price)}</p>
      </div>
      {(!!children || product.comment) && (
        <div className={classNames(style.wrapper, style.withChildren)}>
          {!!product.comment && (
            <InfoWidget>
              <p>{product.comment}</p>
            </InfoWidget>
          )}
          {children}
        </div>
      )}
      {!!actions?.length && type === orderProductTypes.supplier && status === orderStatuses.pending && (
        <Menu
          actions={actions}
          className={style.menu}
          displayOver={displayOver}
        />
      )}
    </div>
  );
};

export default OrderListProduct;
