import React, { useState } from 'react';
import classNames from 'classnames';

import thumbnail from 'assets/img/replacement-thumbnail.png';
import { Input, useStateTimeout } from 'components';

import { imageErrorHandler } from 'src/utils/helpers';

import style from '../ProductComponents.module.scss';
import shared from 'styles/custom/Shared.module.scss';

const ReplacementDetails = (props) => {
  const { name, producer, setName, setProducer, isMobile, validator, disabled } = props;

  const [localName, setLocalName] = useState('');
  const [localProducer, setLocalProducer] = useState('');

  useStateTimeout({
    localState: localName,
    setLocalState: setLocalName,
    state: name,
    setState: setName
  });

  useStateTimeout({
    localState: localProducer,
    setLocalState: setLocalProducer,
    state: producer,
    setState: setProducer
  });

  return (
    <div className={classNames(style.wrapper, style.productDetails)}>
      <div className={classNames(style.wrapper, style.replacementDetails)}>
        <div className={style.wrapper}>
          <img
            src={thumbnail}
            alt='Miniatura przedmiotu'
            className={classNames(shared.defaultThumbnail, style.replacementThumbnail)}
            onError={imageErrorHandler}
          />
          <p className={style.replacement}>Zamiennik produktu:</p>
        </div>
        <div className={classNames(style.wrapper, style.replacementInputs)}>
          <Input
            name={'productName'}
            id={`productName-${parent.id}`}
            value={localName}
            onChange={(e) => setLocalName(e.target.value)}
            placeholder={'Nazwa zamiennika'}
            label={isMobile ? 'Nazwa zamiennika' : null}
            validator={validator}
            rule={'required'}
            disabled={disabled}
            wrapperStyle={style.inputName}
          />
          <Input
            name={'producer'}
            id={`producer-${parent.id}`}
            value={localProducer}
            onChange={(e) => setLocalProducer(e.target.value)}
            placeholder={'Producent'}
            label={isMobile ? 'Producent' : null}
            validator={validator}
            rule={'required'}
            disabled={disabled}
            wrapperStyle={style.inputProducer}
          />
        </div>
      </div>
    </div>
  );
};

export default ReplacementDetails;
