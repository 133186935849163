import { formatPhoneNumberIntl } from 'react-phone-number-input';

import { ADD_TO_WAREHOUSE } from '../features/OrdersView/components/SingleCart/components/ProductsSelect';
import { getRoleColorCode, getUserRoleLabel } from '../utils/helpers';
import { availabilities, productStatuses, userRoles } from './enums';

export const CONTACT_PHONE_NUMBER = '+48732191011';
export const CONTACT_PHONE_NUMBER_FORMATTED = formatPhoneNumberIntl(CONTACT_PHONE_NUMBER);

export const KATE_PHONE_NUMBER = '+48732131585';
export const KATE_PHONE_NUMBER_FORMATTED = formatPhoneNumberIntl(KATE_PHONE_NUMBER);

export const CONTACT_EMAIL = 'kontakt@dentametr.pl';

export const APP_DENTAMETR_PUBLIC_URL = 'https://app.dentametr.pl';

export const legalForms = [
  {
    id: 1,
    name: 'JDG',
    title: 'Jednoosobowa działalność gospodarcza'
  },
  {
    id: 2,
    name: 'Sp. c.',
    title: 'Spółka cywilna'
  },
  {
    id: 3,
    name: 'Sp. z o.o.',
    title: 'Spółka z ograniczoną odpowiedzialnością'
  },
  {
    id: 4,
    name: 'S.A.',
    title: 'Spółka akcyjna'
  },
  {
    id: 7,
    name: 'S.K.A',
    title: 'Spółka komandytowo-akcyjna'
  },
  {
    id: 5,
    name: 'Sp. j.',
    title: 'Spółka jawna'
  },
  {
    id: 6,
    name: 'Sp. k.',
    title: 'Spółka komandytowa'
  },
  {
    id: 7,
    name: 'Sp. p.',
    title: 'Spółka partnerska'
  }
];

const getRoleOption = (role) => ({
  value: role,
  label: getUserRoleLabel(role),
  color: getRoleColorCode(role)
});

export const userRolesOptions = [
  getRoleOption(userRoles.admin),
  getRoleOption(userRoles.user),
  getRoleOption(userRoles.supplier),
  getRoleOption(userRoles.distributor)
];

const avaialbilityDictionary = {
  AVAILABLE: 'Dostępny',
  AVAILABLE_IN: 'Dostępny w',
  AVAILABLE_AFTER: 'Dostępny od',
  UNAVAILABLE: 'Brak'
};

const avaialbilityColors = {
  AVAILABLE: '#6AF1C0',
  AVAILABLE_IN: '#F9D370',
  AVAILABLE_AFTER: '#F9D370',
  UNAVAILABLE: '#F56969'
};

export const availabilityOptions = [
  {
    label: avaialbilityDictionary[availabilities.available],
    color: avaialbilityColors[availabilities.available],
    value: availabilities.available
  },
  {
    label: avaialbilityDictionary[availabilities.availableSince],
    color: avaialbilityColors[availabilities.availableSince],
    value: availabilities.availableSince
  },
  {
    label: avaialbilityDictionary[availabilities.notAvailable],
    color: avaialbilityColors[availabilities.notAvailable],
    value: availabilities.notAvailable
  }
];

export const getOrderProductModalContent = (status) => {
  switch (status) {
    case productStatuses.completed:
      return {
        title: 'Odbieranie produktów',
        description: 'Zaznacz te produkty, które do Ciebie dotarły:',
        button: 'Odbierz produkty',
        allowedStatuses: [productStatuses.pending, productStatuses.processing, productStatuses.sent, productStatuses.complaint],
        emptyState: 'Wszystkie produkty zostały odebrane.'
      };
    case productStatuses.complaint:
      return {
        title: 'Reklamacja produktów',
        description: 'Zaznacz produkty, które zostały poddane reklamacji:',
        button: 'Oznacz produkty jako reklamacja',
        allowedStatuses: [productStatuses.pending, productStatuses.processing, productStatuses.completed],
        emptyState: 'Reklamacji można poddać tylko odebrane produkty.'
      };
    case productStatuses.refunded:
      return {
        title: 'Zwrot produktów',
        description: 'Zaznaczy produkty, które zostały zwrócone:',
        button: 'Oznacz produkty jako',
        allowedStatuses: [productStatuses.completed, productStatuses.complaint],
        emptyState: 'Zwrócić można tylko odebrane lub reklamowane produkty.'
      };
    case productStatuses.cancelled:
      return {
        title: 'Anuluj produkty',
        description: 'Zaznacz produkty, które zostały anulowane:',
        button: 'Anuluj produkty',
        allowedStatuses: [productStatuses.processing, productStatuses.sent],
        emptyState: 'Anulować można tylko te produkty, które nie zostały odebrane, zwrócone lub reklamowane.'
      };
    case ADD_TO_WAREHOUSE:
      return {
        title: 'Dodaj produkty do magazynu',
        description: 'Zaznacz te produkty, które chcesz dodać do magazynu:',
        button: 'Dodaj do magazynu',
        allowedStatuses: [productStatuses.completed],
        emptyState: 'Dodać do magazynu można tylko odebrane produkty.'
      };
    default:
      return {
        title: '',
        description: '',
        button: '',
        allowedStatuses: [],
        emptyState: ''
      };
  }
};

export const companyPlaceholder = {
  name: 'Dane klienta ukryte',
  nip: 'Dane ukryte',
  phone: '+48 123 123 123',
  address: {
    apartment_number: null,
    building_number: '0',
    zip_code: 'Ukryto',
    street: 'Ukryto',
    city: 'Dane ukryte'
  }
};

export const months = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień'
];

export const EXCEL_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
