import React from 'react';
import cn from 'classnames';

import Actions from 'components/layout/Actions';

import style from './Menu.module.scss';

const Menu = ({ actions = [], className, disabled, displayOver }) => {
  return (
    <div className={cn(style.container, className)}>
      <Actions
        items={actions}
        small
        disabled={disabled}
        displayOver={displayOver}
      />
    </div>
  );
};

export default Menu;
