import React from 'react';
import classNames from 'classnames';

import style from './Tag.module.scss';

const Tag = ({ value, green = false, yellow = false, red = false, blue = false, className, color, title, ...rest }) => {
  return (
    <div
      className={classNames(
        style.container,
        {
          [style[color]]: !!color
        },
        className
      )}
      {...rest}
      title={title ? title : value}
    >
      {value}
    </div>
  );
};

export default Tag;
