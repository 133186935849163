import React from 'react';

import { PublicFooter, PublicHeader } from 'components';

import shared from 'src/styles/custom/Shared.module.scss';

import OrderApproval from '../orderConfirmations/containers/OrderApproval';

const OrderApprovalPage = () => {
  return (
    <>
      <PublicHeader supplierForm />
      <div className={shared.publicContainer}>
        <OrderApproval />
      </div>
      <PublicFooter />
    </>
  );
};

export default OrderApprovalPage;
