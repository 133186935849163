import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import { Button, Checkbox, OptionSwitch, Textarea } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OrdersApi } from 'src/api';
import { productStatuses } from 'src/constants/enums';
import { getOrderProductModalContent } from 'src/constants/misc';

import { refreshOrder } from '../../../../actions';
import ProductSelector from '../ProductSelector';

import style from './ProductsSelect.module.scss';

export const ADD_TO_WAREHOUSE = 'ADD_TO_WAREHOUSE';

const ProductsSelect = ({ products = [], newStatus, closeModal, supplierId, suborderUid }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState('');
  const params = useParams();
  const dispatch = useDispatch();

  const formContent = useMemo(() => getOrderProductModalContent(newStatus), [newStatus]);
  const [collectingPartDisabled, setCollectingPartDisabled] = useState(true);

  const allowedProducts = useMemo(
    () => products.filter((product) => formContent.allowedStatuses.includes(product.status)),
    [products, formContent]
  );

  const selectAll = () => {
    if (selectedProducts.length === allowedProducts.length) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(allowedProducts);
    }
  };
  const getIsSelected = (item) => products?.length && selectedProducts?.filter((product) => product.id === item.id).length > 0;
  const changeItemSelection = (item) => {
    setSelectedProducts((prev) => (getIsSelected(item) ? prev?.filter((product) => product.id !== item.id) : [...prev, item]));
  };

  const addComment = async (data) => {
    try {
      await OrdersApi.addProductComment({ ...data, comment });
    } catch (err) {
      notifyApiError(err);
    }
  };

  const changeProductStatusHandler = async () => {
    const data = {
      product_ids: selectedProducts.map((prod) => prod.id),
      status: newStatus,
      uid: params.uid
    };

    try {
      setIsLoading(true);
      if (newStatus === productStatuses.complaint && !!comment) {
        await addComment(data);
      }
      await OrdersApi.changeProductsStatus(data);
      closeModal();
      dispatch(refreshOrder());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const addSelectedProductsToWarehouse = async () => {
    const data = {
      uid: params.uid,
      added_to_warehouse: true,
      supplier_id: supplierId,
      product_ids: selectedProducts.map((prod) => prod.id)
    };

    try {
      setIsLoading(true);
      await OrdersApi.addOrderToWarehouse(data);
      notifyCommon(['Wybrane produkty zostały dodane do magazynu.']);
      closeModal();
      dispatch(refreshOrder());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickHandler = newStatus === ADD_TO_WAREHOUSE ? addSelectedProductsToWarehouse : changeProductStatusHandler;

  if (allowedProducts.length === 0) {
    return <p className={style.emptyState}>{formContent.emptyState}</p>;
  }

  return (
    <div className={style.container}>
      <OptionSwitch
        trueLabel={'Odbierz produkty'}
        falseLabel={'Odbierz część produktów'}
        onChange={setCollectingPartDisabled}
        defaultOption={collectingPartDisabled}
      />
      {newStatus === productStatuses.complaint && (
        <Textarea
          value={comment}
          setValue={setComment}
          label={'Komentarz'}
        />
      )}
      <div className={cn(style.header, style.wrapperHeader)}>
        <p className={style.text}>{formContent.description}</p>
        {collectingPartDisabled && (
          <Checkbox
            onChange={selectAll}
            value={selectedProducts.length === allowedProducts.length}
            wrapperStyle={style.checkbox}
            content={'zaznacz wszystko'}
            reverse
          />
        )}
      </div>
      <div className={style.wrapper}>
        {allowedProducts.map((product) => (
          <ProductSelector
            product={product}
            key={product.id}
            isSelected={getIsSelected(product)}
            onSelectChange={() => changeItemSelection(product)}
            disabled={product.added_to_warehouse && newStatus === ADD_TO_WAREHOUSE}
            collectPartOfAmount={!collectingPartDisabled}
            suborderUid={suborderUid}
          />
        ))}
      </div>
      {collectingPartDisabled && (
        <Button
          label={formContent.button}
          onClick={onClickHandler}
          isLoading={isLoading}
          disabled={selectedProducts.length === 0}
        />
      )}
    </div>
  );
};

export default ProductsSelect;
