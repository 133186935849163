import classNames from 'classnames';

import AttachmentThumbnail from 'components/layout/AttachmentThumbnail';

import Accordion from 'src/components/layout/Accordion';
import shared from 'src/styles/custom/Shared.module.scss';

const Attachments = ({ attachments = [], uid, supplierId, refreshDataCallback, userAttachment }) => {
  if (attachments.length === 0) {
    return null;
  }

  const title = <h3 className={classNames(shared.tableCategoryTitle, shared.large, shared.noPadding)}>Załączniki</h3>;

  return (
    <Accordion
      title={title}
      section
      extraDeps={[attachments]}
    >
      {attachments.map((attachment) => (
        <AttachmentThumbnail
          attachmentName={attachment.file_name}
          key={attachment.file_name}
          uid={uid}
          supplierId={supplierId}
          refreshDataCallback={refreshDataCallback}
          userAttachment={userAttachment}
        />
      ))}
    </Accordion>
  );
};

export default Attachments;
