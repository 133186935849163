import { toDateNumber, toTime } from 'src/utils/dateTime';

const getHqIndicator = (isSelected) => ({
  '&::after': {
    content: '"(HQ)"',
    color: isSelected ? '#fff' : '#515C6AFF',
    fontSize: 11,
    paddingLeft: '.5ch',
    position: 'static'
  }
});

const getDepthIndicator = (depth) => ({
  ':before': {
    borderRadius: '0 0 0 4px',
    border: '2px solid #c0c0c0',
    borderTop: 'none',
    borderRight: 'none',
    visibility: 'hidden',
    content: '" "',
    display: depth ? 'block' : 'none',
    height: 12,
    width: `${13 * depth}px`,
    position: 'absolute',
    left: 12,
    top: '50%',
    transform: 'translateY(-50%)'
  }
});

const dot = (color = 'transparent', singleValue, isSearchable) => ({
  paddingLeft: '22px !important',

  ...(singleValue && {
    paddingLeft: '12px !important'
  }),

  '&::before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 6,
    position: 'absolute',
    left: '6px',
    top: '50%',
    height: 10,
    width: 10,
    minWidth: 10,
    minHeight: 10,
    transform: 'translateY(-50%)'
    // ...(singleValue && {
    //   top: isSearchable ? '13px' : '7px'
    // })
  }
});

const extendedEmail = (email, color) => ({
  display: 'flex',
  flexDirection: 'column',
  '&::before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 6,
    position: 'absolute',
    left: '6px',
    top: '15px',
    height: 10,
    width: 10,
    minWidth: 10,
    minHeight: 10
  },
  '&::after': {
    content: `"${email}"`,
    fontSize: 13,
    opacity: 0.8
  }
});
const productSearchStyles = ({ producer, category, thumbnail }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '60px',
  '&::after': {
    content: `"${producer} | ${category}"`,
    fontSize: 13,
    opacity: 0.8
  },
  '&::before': {
    content: '""',
    width: '40px',
    position: 'absolute',
    left: '12px',
    top: '50%',
    transform: 'translateY(-50%)',
    height: '40px',
    background: '#000',
    borderRadius: '6px',
    backgroundImage: `url(${thumbnail})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    fontSize: 13
  }
});

const extendedCreatedAt = (date) => ({
  display: 'flex',
  flexDirection: 'column',
  '&::after': {
    content: `"${toDateNumber(date)}, ${toTime(date)}"`,
    fontSize: 13,
    opacity: 0.8
  }
});

export const selectStyles = ({ hasError, hideArrow, hiddenSelect, isMulti, isSearchable, productSearch, isDisabled }) => ({
  container: (base) => ({
    ...base,
    width: '100%',
    height: isMulti ? 'auto' : '40px',
    opacity: isDisabled ? 0.5 : 1,
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  placeholder: (base) => ({
    ...base,
    color: '#9A9FA5FF',
    fontSize: 15,
    fontWeight: 700,
    marginLeft: 0,
    marginRight: 0
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    display: hideArrow ? (state.options.length > 1 ? 'flex' : 'none') : 'flex'
  }),
  control: (base, state) => ({
    ...base,
    height: isMulti ? 'auto' : '40px',
    minHeight: '40px',
    fontWeight: 700,
    outline: 'none',
    fontSize: 15,
    borderRadius: 6,
    width: '100%',
    borderColor: 'transparent',
    textAlign: 'left',
    cursor: 'pointer',
    transition: 'all 0.2s',
    background: state.isFocused ? '#fff' : hiddenSelect ? 'transparent' : '#fff',
    boxShadow: state.isFocused ? (hasError ? '0 0 0 1px #F56969' : '0 0 0 1px #4849FF') : 0,
    border: hasError ? '1px solid #F56969' : state.isFocused ? '1px solid #4849FF' : '1px solid rgba(0, 0, 0, 0.23)',
    '&:hover': {
      borderColor: state.isFocused ? 'none' : 'rgba(75, 75, 75, 0.6)',
      background: '#fff'
    }
  }),
  valueContainer: (base) => ({
    ...base,
    maxHeight: isMulti ? '68px' : 'unset',
    overflow: isMulti ? 'auto' : base.overflow
  }),
  singleValue: (base, state) => ({
    ...base,
    marginLeft: 0,
    marginRight: 0,
    fontSize: 15,
    fontWeight: 700,
    color: state.isDisabled && hiddenSelect ? 'hsl(0, 0%, 20%)' : base.color,
    ...(state.data.isHeadQuoter && getHqIndicator()),
    ...(!!state.data?.color && dot(state.data.color, true, isSearchable))
  }),
  multiValue: (base) => ({
    ...base,
    maxWidth: '180px'
  }),
  option: (base, { data, isSelected }) => ({
    ...base,
    paddingLeft: data?.depth ? `${15 * (data?.depth + 1)}px` : '12px',
    position: 'relative',
    ...(!!data.depth?.toString() && getDepthIndicator(data.depth)),
    ...(data.isHeadQuoter && getHqIndicator(isSelected)),
    ...(!!data?.color && dot(data.color, false, isSearchable)),
    ...(!!data?.created_at && extendedCreatedAt(data.created_at)),
    ...(!!data?.email && extendedEmail(data.email, data.color)),
    ...(productSearch && productSearchStyles(data))
  })
});
