import React, { useEffect, useState } from 'react';

import {
  AsyncSelect,
  Button,
  FileDropzone,
  Input,
  Modal,
  OptionSwitch,
  QtySelector,
  Select,
  Textarea,
  useIsAdmin,
  useIsMobile,
  useValidator
} from 'components';
import File from 'components/layout/FileUploader/components/File';
import InputWrapper from 'components/layout/InputWrapper';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi, InvoicesApi } from 'src/api';
import { invoiceStatuses, invoiceTypes } from 'src/constants/enums';
import { getInvoiceStatus, getInvoiceType } from 'src/utils/helpers';

import MakeImage from '../MakeImage';
import SelectOrder from '../SelectOrder';

import style from './InvoiceForm.module.scss';

export const getDefaultInvoice = (sum = 0, suborder_uid = '', supplier_id = '') => ({
  invoice: null,
  number: '',
  payment_date: '',
  status: 'DRAFT',
  suborder_uid,
  supplier_id,
  sum,
  company_id: 0,
  type: invoiceTypes.invoice,
  comment: ''
});

export const statusToOption = (status) => {
  const { title, color } = getInvoiceStatus({ status, returnData: true });
  const colors = { blue: '#9c9cff', yellow: '#f9d370', green: '#6af1c0' };
  return { label: title, value: status, color: colors[color] };
};

export const typeToOption = (type) => {
  const { title } = getInvoiceType({ type, returnData: true });
  return { label: title, value: type };
};

export const statuses = [statusToOption(invoiceStatuses.TO_PAY), statusToOption(invoiceStatuses.COMPLETED)];
const types = [
  typeToOption(invoiceTypes.invoice),
  typeToOption(invoiceTypes.wz),
  typeToOption(invoiceTypes.other),
  typeToOption(invoiceTypes.correction)
];

const InvoiceForm = ({ closeModal, invoiceData, orderValue, apiHandler, companyId, suborderUid, supplierId }) => {
  const [invoice, setInvoice] = useState(invoiceData || getDefaultInvoice(orderValue));
  const [invoiceStatus, setInvoiceStatus] = useState(invoiceData ? statusToOption(invoiceData.status) : statuses[0]);
  const [invoiceType, setInvoiceType] = useState(typeToOption(invoiceData ? invoiceData.type : invoiceTypes.invoice));
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceFromOrder, setInvoiceFromOrder] = useState(true);
  const [isCameraVisible, setIsCameraVisible] = useState(false);
  const [fileReplaceEnabled, setFileReplaceEnabled] = useState(false);

  const validator = useValidator();
  const isAdmin = useIsAdmin();
  const isMobile = useIsMobile();

  const onChange = (e) =>
    setInvoice((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));

  const onChangeHandler = (name, data) => {
    setInvoice((prev) => ({
      ...prev,
      [name]: data
    }));
  };

  const saveFormHandler = async (callback, data) => {
    try {
      setIsLoading(true);
      await callback(data);
      if (closeModal) closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const addInvoiceHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const formData = new FormData();
    for (const [key, value] of Object.entries(invoice)) {
      if (value) formData.append(key, value);
    }

    if (isAdmin && !invoiceFromOrder) {
      formData.append('company_id', companyId || selectedCompany.value);
    }

    formData.append('status', invoiceStatus.value);
    formData.append('type', invoiceType.value);
    const apiCallback = invoiceFromOrder ? InvoicesApi.addUserInvoice : InvoicesApi.addUserInvoiceOther;

    if (apiHandler) {
      formData.append('suborder_uid', suborderUid);
      formData.append('supplier_id', supplierId);
      await saveFormHandler(apiHandler, formData);
      return;
    }

    await saveFormHandler(apiCallback, formData);
  };

  const editInvoiceHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const editedInvoice = {
      id: invoice.id,
      sum: invoice.sum,
      number: invoice.number,
      type: invoiceType.value,
      status: invoiceStatus.value,
      payment_date: invoice.payment_date,
      company_id: invoice?.company_id,
      suborder_uid: suborderUid || invoice?.suborder_uid,
      supplier_id: supplierId || invoice?.supplier_id,
      comment: invoice?.comment
    };

    const formData = new FormData();
    for (const [key, value] of Object.entries(editedInvoice)) {
      if (value) formData.append(key, value);
    }

    if (invoice.invoice) formData.append('invoice', invoice.invoice);

    const apiCallback = invoiceData.suborder_uid ? InvoicesApi.editUserInvoice : InvoicesApi.editUserInvoiceOther;

    await saveFormHandler(apiCallback, formData);
  };

  const makePhotoHandler = (e) => {
    e.preventDefault();
    setIsCameraVisible(true);
  };

  const onPhotoTake = (uri, file) => {
    onChangeHandler('invoice', file);
    setIsCameraVisible(false);
  };

  const dropzone = (
    <FileDropzone
      label={'Plik z fakturą'}
      onChange={(file) => onChangeHandler('invoice', file)}
      value={invoice.invoice}
      validator={validator}
      rule={'required'}
      id={'file'}
      name={'file'}
    />
  );

  useEffect(() => {
    validator?.purgeFields();
  }, [invoiceFromOrder]);

  return (
    <>
      <form
        className={style.container}
        onSubmit={invoiceData ? editInvoiceHandler : addInvoiceHandler}
      >
        {invoiceData &&
          (fileReplaceEnabled ? (
            dropzone
          ) : (
            <InputWrapper
              label={'Plik z fakturą'}
              rule={'required'}
            >
              <div className={style.fileCard}>
                <File
                  value={{ name: invoiceData.file_name }}
                  handleClearFile={() => setFileReplaceEnabled(true)}
                >
                  <a
                    onClick={() => setFileReplaceEnabled(true)}
                    className={style.a}
                  >
                    Zmień plik z fakturą{' '}
                  </a>
                </File>
              </div>
            </InputWrapper>
          ))}
        {!invoiceData && (
          <>
            {!(suborderUid || supplierId) && (
              <OptionSwitch
                falseLabel={'Zamówienie spoza aplikacji'}
                trueLabel={'Zamówienie z aplikacji'}
                defaultOption={invoiceFromOrder}
                onChange={(value) => {
                  setInvoiceFromOrder(value);
                  validator.hideMessages();
                  validator.purgeFields();
                }}
              />
            )}
            {dropzone}
            {isMobile && (
              <Button
                label={'Zrób zdjęcie'}
                onClick={makePhotoHandler}
              />
            )}
            {isAdmin && !invoiceFromOrder && (
              <AsyncSelect
                value={selectedCompany}
                onChange={setSelectedCompany}
                apiCallback={AdminApi.getCompanies}
                valueKey={'id'}
                labelKey={'name'}
                placeholder={'Firma'}
                label={'Nazwa firmy'}
                isClearable
                validator={validator}
                rule={'required'}
                id={'file'}
                name={'file'}
              />
            )}
            {!(suborderUid || supplierId) && (
              <SelectOrder
                validator={validator}
                onSuborderSelect={(uid) => onChangeHandler('suborder_uid', uid)}
                onSupplierSelect={(id) => onChangeHandler('supplier_id', id)}
                invoiceFromOrder={invoiceFromOrder}
                selectedCompanyId={selectedCompany?.value}
                id={'order'}
                name={'order'}
              />
            )}
          </>
        )}
        <div className={style.row}>
          <Input
            id='number'
            name='number'
            label='Numer faktury'
            value={invoice.number}
            onChange={onChange}
            validator={validator}
            rule={'required'}
          />
          <QtySelector
            id='sum'
            name='sum'
            label='Wartość faktury'
            state={invoice.sum}
            setState={(sum) => onChangeHandler('sum', sum)}
            wrapperStyle={style.sum}
            width={'100%'}
            suffix={'zł'}
            priceInput
            hideArrows
            validator={validator}
            rule={'required|only_positive'}
          />
        </div>
        <div className={style.tripleRow}>
          <Select
            options={types}
            value={invoiceType}
            onChange={setInvoiceType}
            label={'Rodzaj'}
            isSearchable={false}
            validator={validator}
            rule={'required'}
            id={'type'}
            name={'type'}
          />
          <Input
            type='date'
            id='payment_date'
            name='payment_date'
            label='Termin płatności'
            value={invoice.payment_date}
            onChange={onChange}
            validator={validator}
            rule={'required'}
          />
          <Select
            options={statuses}
            value={invoiceStatus}
            onChange={setInvoiceStatus}
            placeholder={'Status'}
            label={'Status'}
            isSearchable={false}
            validator={validator}
            rule={'required'}
            id={'status'}
            name={'status'}
          />
        </div>
        <Textarea
          id={'invoice-comment'}
          name={'invoice-comment'}
          label={'Komentarz'}
          value={invoice.comment}
          setValue={(comment) => onChangeHandler('comment', comment)}
        />
        <Button
          label={'Zapisz'}
          isLoading={isLoading}
          className={style.button}
        />
      </form>
      <Modal
        visible={isCameraVisible}
        onClose={() => setIsCameraVisible(false)}
      >
        <MakeImage onPhotoTake={onPhotoTake} />
      </Modal>
    </>
  );
};

export default InvoiceForm;
