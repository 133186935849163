import React from 'react';
import { useSelector } from 'react-redux';

import style from '../../SupplierForm.module.scss';

const FooterInfo = () => {
  const name = useSelector((state) => state.supplierForm.distributor_name);

  return (
    <>
      <div className={style.supplierInfo}>
        Dentametr to cyfrowe narzędzie do komunikacji pomiędzy klinikami stomatologicznymi a dostawcami. Wszystkie informacje
        zawarte w powyższej wycenie są poufne.
        <br />
        Dentametr nie udostępnia podmiotom trzecim ani nie wykorzystuje w żaden sposób informacji zawartych w wycenach.
        <br />
      </div>
      <div className={style.supplierInfo}>Dostawca: {name}</div>
    </>
  );
};

export default FooterInfo;
