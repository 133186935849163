import React from 'react';

import { Modal } from 'components';

import { modalTypes } from '../../index';
import ChangeProductQuantity from '../ChangeProductQuantity';
import WarehouseAddProduct from '../WarehouseAddProduct';

const ProductModal = ({ isOpen, setIsOpen, modalType, reloadData }) => {
  const getModalContent = () => {
    switch (modalType) {
      case 'add_new':
        return <WarehouseAddProduct />;
      case 'increase':
        return <ChangeProductQuantity type={'increase'} />;
      case 'decrease':
        return <ChangeProductQuantity type={'decrease'} />;
      default:
        return null;
    }
  };

  const getModalTitle = () => {
    switch (modalType) {
      case modalTypes.addNew:
        return 'Dodaj produkt';
      case modalTypes.increase:
        return 'Dostawa do magazynu';
      case modalTypes.decrease:
        return 'Zużycie produktów';
      default:
        return '';
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    reloadData();
  };

  return (
    <Modal
      visible={isOpen}
      onClose={handleCloseModal}
      title={getModalTitle()}
    >
      {getModalContent()}
    </Modal>
  );
};

export default ProductModal;
