import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import logoPlaceholder from 'assets/img/no-image.png';
import { Card, CompanyDetails, Menu, Modal, Tag } from 'components';

import { imageErrorHandler } from 'src/utils/helpers';

import * as types from '../../actions/types';
import Addresses from '../Addresses';
import CompanySettings from '../CompanySettings';
import MoveCompany from '../MoveCompany';
import Personnel from '../Personnel';

import style from './CompanyThumbnail.module.scss';
import shared from 'styles/custom/Shared.module.scss';

const CompanyThumbnail = ({ company, index }) => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [contentType, setContentType] = useState('');

  const handleRefresh = () => {
    dispatch({ type: types.CHANGE_COMPANIES_VERSION });
  };

  const openModalHandler = (content = '') => {
    setContentType(content);
    setIsVisible(true);
  };

  const modalContent = useMemo(() => {
    switch (contentType) {
      case 'ADDRESSES':
        return {
          content: <Addresses companyId={company.id} />,
          title: `Adresy dostaw oddziału: ${company.name}`
        };
      case 'PERSONNEL':
        return {
          content: <Personnel companyId={company.id} />,
          title: `Personel oddziału: ${company.name}`
        };
      case 'SETTINGS':
        return {
          content: <CompanySettings company={company} />,
          title: 'Ustawienia organizacji'
        };
      case 'MOVE':
        return {
          content: (
            <MoveCompany
              companyId={company.id}
              companyName={company.name}
              closeModal={() => setIsVisible(false)}
            />
          ),
          title: `Przenieś oddział ${company.name}`
        };
      default:
        return {
          content: null,
          title: ''
        };
    }
  }, [contentType, company]);

  const actions = [
    {
      title: 'Personel',
      icon: 'list',
      action: () => openModalHandler('PERSONNEL')
    },
    {
      title: 'Adresy dostaw',
      icon: 'home',
      action: () => openModalHandler('ADDRESSES')
    },
    {
      title: 'Przenieś oddział',
      icon: 'move',
      action: () => openModalHandler('MOVE')
    },
    {
      title: 'Ustawienia organizacji',
      icon: 'setting',
      color: 'blue',
      action: () => openModalHandler('SETTINGS')
    }
  ];

  return (
    <Card
      key={company.id}
      withShadow
    >
      <Menu actions={actions} />
      <div className={style.titleWrapper}>
        <img
          src={company?.logo_small || logoPlaceholder}
          className={classNames(shared.companyLogo, shared.large)}
          alt='Company logo'
          onError={imageErrorHandler}
        />
        <h2 className={style.companyTitle}>{company.name}</h2>
        {!company.company_id && (
          <Tag
            value={'HQ'}
            title={'Główny oddział organizacji'}
            color={'blue'}
          />
        )}
        {company.suppliers_management_enabled && (
          <Tag
            value={'Zarządzanie Dostawcami'}
            title={'Główny oddział organizacji'}
            color={'green'}
          />
        )}
        {!!company.user_name && <Tag value={'Właściciel: ' + company.user_name} />}
      </div>
      <CompanyDetails
        companyManagement
        details={company}
        renderChildren
        index={index}
        refreshCb={handleRefresh}
      />
      <Modal
        visible={isVisible}
        onClose={() => setIsVisible(false)}
        title={modalContent.title}
      >
        {modalContent.content}
      </Modal>
    </Card>
  );
};

export default CompanyThumbnail;
