import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  EmptyState,
  FilterByCompany,
  LoaderGlobal,
  PageHeader,
  PaginationNav,
  SeoHelmet,
  useCompany,
  useIsAdmin,
  useRequestAbortController
} from 'components';
import { headerTextPlaceholder } from 'components/layout/PageHeader/placeholders';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi, OfferCatalogApi } from 'src/api';
import pages from 'src/dictionaries/pages.json';
import query from 'src/utils/query';

import { OfferCatalogTypes } from '../../constants/enums';
import { getCorrectFormOfResultsLabel } from '../../utils/helpers';
import Filtering from './components/Filtering';
import HeaderContent from './components/HeaderContent';
import PendingValuations from './components/PendingValuations';
import ProductsList from './components/ProductsList';
import ProductsWithoutPrice from './components/ProductsWithoutPrice';
import SelectionSummary from './components/SelectionSummary';
import { clearProducts } from './actions';
import { getCategoriesAfterSelectionChange, getProducerColumns } from './helpers';

import 'react-alice-carousel/lib/scss/alice-carousel.scss';
import style from './OfferCatalog.module.scss';

const OfferCatalog = (props) => {
  const { type = OfferCatalogTypes.catalog } = props;
  const { listUID } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const isAdmin = useIsAdmin();
  const company = useCompany();
  const history = useHistory();
  const params = query(props);
  const clearSelection = useRef(!!location?.state?.clearSelection);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [pageQty, setPageQty] = useState(0);
  const [itemsQty, setItemsQty] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [isQuietLoading, setIsQuietLoading] = useState(false);

  const [pageData, setPageData] = useState();
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showRefPrice, setShowRefPrice] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState();
  const [selectedSuppliers, setSelectedSuppliers] = useState();
  const [querySearch, setQuerySearch] = useState();
  const [isOfferCatalogForCorp, setIsOfferCatalogForCorp] = useState(
    company?.offer_catalog_for_corp || pageData?.offer_catalog_for_corp
  );

  useEffect(() => {
    setIsOfferCatalogForCorp(company?.offer_catalog_for_corp || pageData?.offer_catalog_for_corp);
  }, [company?.offer_catalog_for_corp, pageData?.offer_catalog_for_corp]);

  const [abortController, setNewController] = useRequestAbortController();
  const valuationsVersion = useSelector((state) => state.offerCatalog.valuationsVersion);

  const refreshData = async () => {
    if (isAdmin && !selectedCompany) {
      return null;
    }

    if (abortController) abortController.abort();
    const signal = setNewController();

    const queryParams = {
      page: currentPage || 1,
      perPage: perPage,
      search: querySearch,
      ...(selectedCategories && {
        category_ids: typeof selectedCategories === 'object' ? selectedCategories : [selectedCategories]
      }),
      ...(selectedSuppliers && { supplier_ids: typeof selectedSuppliers === 'object' ? selectedSuppliers : [selectedSuppliers] }),
      ...(isAdmin && { company_id: selectedCompany }),
      ...(listUID && { list_uid: listUID })
    };

    const Api = type === OfferCatalogTypes.catalog ? OfferCatalogApi : ListsApi;

    try {
      setIsQuietLoading(true);
      if (clearSelection.current) {
        const queryData = {
          list_uid: listUID,
          ...(isAdmin && { company_id: selectedCompany })
        };
        const callback = isOfferCatalogForCorp ? Api.selectPreferred : Api.removeAllSelects;
        await callback(queryData, signal);
        clearSelection.current = false;
        history.replace({ ...location, state: {} });
      }
      const { data } = await Api.getData(queryParams, signal);
      setPageData({ ...data.data, total: data.total });
      setItemsQty(data.total);
      setPageQty(data?.last_page);
    } catch (err) {
      notifyApiError(err);
      if (err?.message !== 'canceled') history.push('/dashboard');
    } finally {
      setIsQuietLoading(false);
    }
  };
  const changeValuationSelections = (data) => {
    setPageData((prev) => ({
      ...prev,
      categories: getCategoriesAfterSelectionChange(data, prev?.categories)
    }));
  };

  const proceedOrder = () => {
    const link = type === OfferCatalogTypes.cart ? `/lists/${listUID}/cart/proceed-order` : '/offer-catalog/proceed-order';
    history.push(link);
  };

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      await refreshData();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (pageData) setColumns(getProducerColumns(pageData));
  }, [pageData]);

  useEffect(() => {
    getData();
    dispatch(clearProducts());
  }, [location.pathname, type]);

  useEffect(() => {
    if (valuationsVersion > 0) refreshData();
  }, [valuationsVersion]);

  useEffect(() => {
    refreshData();
  }, [currentPage, perPage, selectedCompany, selectedSuppliers, selectedCategories, querySearch, location.pathname]);

  useEffect(() => {
    setCurrentPage(params.get('page'));
    setSelectedCompany(params.get('company'));
    setSelectedCategories(params.get('category'));
    setSelectedSuppliers(params.get('supplier'));
    setQuerySearch(params.get('search'));
  }, [location.search]);

  const pagination = (
    <PaginationNav
      className={style.pagination}
      params={params}
      pagesQty={pageQty}
      setPerPage={setPerPage}
      defaultQty={perPage}
      hidePadding
    />
  );

  const pageText = useMemo(
    () =>
      isLoading
        ? headerTextPlaceholder()
        : listUID
        ? pageData?.list_title
          ? pageData?.list_title
          : 'Na podstawie listy'
        : getCorrectFormOfResultsLabel(itemsQty, ['produkt', 'produkty', 'produktów']),
    [isLoading, listUID, itemsQty]
  );

  if (isLoading) {
    return <LoaderGlobal />;
  }

  if (isAdmin && !selectedCompany) {
    return (
      <>
        <PageHeader
          name={pages.offerCatalog[type].title}
          text={'Wybierz firmę'}
        />
        <div className={style.centerContent}>
          <EmptyState type={'selectCompany'} />
          <FilterByCompany params={params} />
        </div>
      </>
    );
  }

  if (!pageData) {
    return null;
  }

  return (
    <div className={style.container}>
      <SeoHelmet title={pages.offerCatalog[type].title} />
      <PageHeader
        name={pages.offerCatalog[type].title}
        text={pageText}
        textMaxWidth
      >
        <HeaderContent
          type={type}
          params={params}
          isLoading={isLoading}
          showRefPrice={showRefPrice}
          handleProceedOrder={proceedOrder}
          setShowRefPrice={setShowRefPrice}
          selectedCompany={selectedCompany}
          isOfferCatalogForCorp={isOfferCatalogForCorp}
          setIsOfferCatalogForCorp={setIsOfferCatalogForCorp}
        />
      </PageHeader>
      <div className={style.contentWrapper}>
        <SelectionSummary
          pageData={pageData}
          showRefPrice={showRefPrice}
          columns={columns}
          selectedCompany={selectedCompany}
          type={type}
        />
        {type === OfferCatalogTypes.catalog && <PendingValuations selectedCompany={selectedCompany} />}
        {type === OfferCatalogTypes.cart && (
          <ProductsWithoutPrice
            selectedCompany={selectedCompany}
            listUID={listUID}
            isOfferCatalogForCorp={isOfferCatalogForCorp}
          />
        )}
        {pagination}
        <Filtering
          params={params}
          selectedCompany={selectedCompany}
        />
        <ProductsList
          listUID={listUID}
          isCatalogForCorporations={isOfferCatalogForCorp}
          pageData={pageData}
          productClasses={style.product}
          changeValuationSelections={changeValuationSelections}
          status={pageData?.status}
          showRefPrice={showRefPrice}
          isQuietLoading={isQuietLoading}
          selectedCompany={selectedCompany}
          type={type}
        />
        {pagination}
      </div>
    </div>
  );
};

export default OfferCatalog;
