import React from 'react';
import { DatePicker } from 'antd';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right-small.svg';
import InputWrapper, { getWrapperProps } from 'components/layout/InputWrapper';

import 'dayjs/locale/pl';

import localePL from './locale.json';

import style from './DateRangePicker.module.scss';

const { RangePicker } = DatePicker;

const DateRangePicker = (props) => {
  const wrapperProps = getWrapperProps(props);
  const { startDate, endDate, setStartDate, setEndDate, ...rest } = props;

  const onChangeHandler = (dates) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  return (
    <InputWrapper
      {...wrapperProps}
      wrapperStyle={cn(wrapperProps.wrapperStyle, style.wrapper)}
    >
      <RangePicker
        className={style.DateRangePicker}
        onCalendarChange={onChangeHandler}
        value={[startDate, endDate]}
        separator={<ArrowIcon />}
        format={'DD/MM/YYYY'}
        locale={localePL}
        {...rest}
      />
    </InputWrapper>
  );
};

DateRangePicker.propType = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func
};

export default DateRangePicker;
