import Button from 'components/layout/Button';

import buttons from 'src/dictionaries/buttons.json';

export default function ActionButtons({ isAuth, className }) {
  return (
    <>
      {isAuth ? (
        <>
          <Button
            className={className}
            label={buttons.public.dashboard}
            to={'/dashboard'}
            gray
          />
          <Button
            className={className}
            label={buttons.public.logout}
            to={'/logout'}
          />
        </>
      ) : (
        <>
          <Button
            className={className}
            label={buttons.public.login}
            to={'/login'}
            gray
          />
          <Button
            className={className}
            label={buttons.public.registerAlt}
            to={'/contact'}
          />
        </>
      )}
    </>
  );
}
