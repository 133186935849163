import React, { useLayoutEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Badge } from 'antd';
import cn from 'classnames';

import { Icon, useAuthUser, useIsAdmin, useIsMedicover } from 'components';

import { userRoles } from 'src/constants/enums';

import styles from './Dropdown.module.sass';

const Dropdown = ({ className, item, visibleSidebar, onClose, isClose, horizontalOnMobile }) => {
  const [items, setItems] = useState([]);
  const user = useAuthUser();
  const isAdmin = useIsAdmin();
  const isMedicover = useIsMedicover();

  const { pathname } = useLocation();

  useLayoutEffect(() => {
    const itemsArr = [];

    item.dropdown.forEach((link, index) => {
      const linkForDifferentRole = !link.roles?.includes(user?.role);
      const canCompanyEntries = link?.companyAccessFn ? isAdmin || link?.companyAccessFn(user?.company) : true;
      const correctEnv = link?.env
        ? (isMedicover && link.env === 'medicover') || (!isMedicover && link.env === 'dentametr')
        : true;

      if (!correctEnv || linkForDifferentRole || !canCompanyEntries) return null;

      if (
        user &&
        user.role !== userRoles.admin &&
        link?.requiredPermission &&
        user?.company?.permissions &&
        !user.company.permissions.includes(link.requiredPermission)
      ) {
        return null;
      }

      itemsArr.push(
        <NavLink
          className={cn(styles.link, {
            [styles.isClose]: isClose,
            [styles.wide]: horizontalOnMobile
          })}
          activeClassName={styles.active}
          to={link.url}
          key={index}
          onClick={onClose}
          exact={link.exact}
          id={link.url.slice(1, link.url.length)}
        >
          {link.icon && link.icon}
          {!isClose && link.title}
          <Badge
            count={link.count}
            className={styles.badge}
          />
        </NavLink>
      );
    });

    setItems(itemsArr);
  }, [item, user, visibleSidebar, isClose]);

  if (items.length === 0) {
    return null;
  }

  return (
    <div
      className={cn(styles.dropdown, className, styles.active, {
        [styles.active]: pathname.includes(item.slug),
        [styles.wide]: visibleSidebar
      })}
    >
      {item.add && (
        <div
          className={cn(styles.top, {
            [styles.active]: pathname.startsWith('/products/add')
          })}
        >
          <Link
            className={cn(styles.add, {
              [styles.active]: pathname.startsWith('/products/add')
            })}
            to='/products/add'
            onClick={onClose}
          >
            <Icon
              name='plus'
              size='10'
            />
          </Link>
        </div>
      )}
      <div
        className={cn(styles.body, {
          [styles.horizontalOnMobile]: horizontalOnMobile
        })}
      >
        {items}
      </div>
    </div>
  );
};

export default Dropdown;
