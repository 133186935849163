import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Textarea } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';

import { refreshListView } from '../../actions';

import style from './ProductCommentForm.module.scss';

const ProductCommentForm = ({ product, uid, closeModal }) => {
  const { id } = product;
  const dispatch = useDispatch();

  const [comment, setComment] = useState(product.comment);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAll, setIsLoadingAll] = useState(false);

  const products = useSelector((state) => state.listsView.products);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { product_id: id, comment, uid };

    try {
      setIsLoading(true);
      await ListsApi.storeToList(data);
      dispatch(refreshListView());
      if (closeModal) closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const saveForAll = async (e) => {
    e.preventDefault();
    const data = { product_ids: products, comment, uid };

    try {
      setIsLoadingAll(true);
      await ListsApi.setProductsComment(data);
      dispatch(refreshListView());
      if (closeModal) closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoadingAll(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={handleSubmit}
    >
      <Textarea
        id={`product-comment-${id}`}
        name={`product-comment-${id}`}
        label={'Komentarz do produktu'}
        value={comment}
        setValue={setComment}
      />
      <div className={style.buttonsWrapper}>
        <Button
          label={`Zapisz do wybranych (${products?.length})`}
          isLoading={isLoadingAll}
          onClick={saveForAll}
          gray
        />
        <Button
          label={'Zapisz'}
          type={'submit'}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default ProductCommentForm;
