import { notifyApiError, notifyDanger } from 'components/layout/Toasts';

export const getProducerColumns = (data) =>
  data.suppliers.map((supplier) => {
    const categories = data.categories.map((cat) => {
      const products = cat.products.map((product) => {
        const pricesArr = product.prices.find((price) => price.supplier_id === supplier.id || price.type === supplier.id);
        return { product, valuation: pricesArr };
      });
      return { category: cat, products: products };
    });
    return { supplier, items: categories };
  });

export const getCategoriesAfterSelectionChange = (data, categories = []) =>
  categories.map((cat) => ({
    ...cat,
    products: cat.products.map((product) =>
      product.id !== data.productId
        ? product
        : {
            ...product,
            prices: product.prices.map((price) => ({ ...price, isSelected: price.supplier_id === data.supplierId }))
          }
    )
  }));

export const getSumOfProducts = (columns = [[]]) => {
  let sum = 0;
  columns[0].items.forEach(({ products }) => {
    sum += products?.length;
  });
  return sum;
};

export const getNumberOfSelectedProductInColumn = (column) => {
  let count = 0;

  column.items.forEach((cat) => {
    cat.products.forEach((product) => {
      if (product?.valuation?.selected) {
        count++;
      }
    });
  });

  return count;
};

export const getColumnPricesSum = (column) => {
  let sum = 0;

  column.items.forEach((cat) => {
    cat.products.forEach((product) => {
      if (product?.valuation?.selected) {
        sum += (product.valuation?.price * (100 - product.valuation?.discount)) / 100;
      }
    });
  });

  return sum;
};

export const dataLoadingErrorHandler = (err, onError) => {
  if (err.response && err.response.status === 404) {
    notifyDanger(['Wybrana wycena nie istnieje, lub nie masz do niej dostępu.']);
    history.push('/valuations');
  } else {
    notifyApiError(err);
    onError();
  }
};
