import React, { useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { Icon, OutsideClickHandler } from 'components';
import Button from 'components/layout/Actions/components/Button';

import styles from './Actions.module.sass';

const Actions = (props) => {
  const {
    className,
    classActionsHead,
    classActionsBody,
    classActionsOption,
    items,
    visible,
    setVisible,
    small,
    up,
    disabled = false,
    displayOver
  } = props;
  const [innerVisible, setInnerVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({});
  const elementTarget = useRef(null);

  const setPosition = () => {
    if (elementTarget.current && displayOver) {
      const data = elementTarget.current?.getBoundingClientRect();
      setMenuPosition({ top: data.bottom + 12, left: data.right });
    }
  };

  useLayoutEffect(() => {
    if (innerVisible && displayOver) window.addEventListener('scroll', setPosition);

    return () => {
      window.removeEventListener('scroll', setPosition);
    };
  }, [innerVisible, displayOver, elementTarget.current]);

  const toggle = () => {
    if (!disabled) {
      setPosition();
      setVisible ? setVisible(!visible) : setInnerVisible(!innerVisible);
    }
  };

  const handleClickOption = async (fn) => {
    await fn();
    toggle();
  };

  return (
    <OutsideClickHandler onOutsideClick={() => (setVisible ? setVisible(false) : setInnerVisible(false))}>
      <div
        className={cn(styles.actions, className, {
          [styles.active]: visible ? visible : innerVisible,
          [styles.small]: small,
          [styles.up]: up
        })}
      >
        <button
          onClick={toggle}
          id={'menu-button'}
          ref={elementTarget}
          className={cn(styles.head, classActionsHead, {
            [styles.disabled]: disabled
          })}
        >
          <Icon
            name='more-horizontal'
            size='24'
          />
        </button>
        <div
          className={cn(styles.body, { [styles.displayOver]: displayOver }, classActionsBody)}
          style={menuPosition}
        >
          {items.map((item, index) => (
            <Button
              classActionsOption={classActionsOption}
              handleClickOption={handleClickOption}
              key={item.title + index.toString()}
              toggle={toggle}
              index={index}
              item={item}
            />
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Actions;
