import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { Card, PageHeader, Select, StickyPageHeader, useAuthUser, useIsMobile, usePermissions } from 'components';
import Dropdown from 'components/layout/Sidebar/Dropdown';

import { userPermissions } from 'src/constants/enums';

import { desktopLinks, mobileLinks } from './links';

import style from './Navigation.module.scss';

const { company } = userPermissions;

const Navigation = () => {
  const history = useHistory();
  const isMobile = useIsMobile(1240);
  const user = useAuthUser();

  const [isCompanyAdmin] = usePermissions([company.admin]);
  const [selectedOption, setSelectedOption] = useState(null);

  const options = useMemo(
    () =>
      mobileLinks.filter(
        (link) =>
          link.roles?.includes(user?.role) &&
          (link?.forCompanyAdmin ? isCompanyAdmin : true) &&
          (link?.companyAccessFn ? link.companyAccessFn(company) : true)
      ),
    [isCompanyAdmin, user]
  );

  const onChangeHandler = (option) => {
    setSelectedOption(null);
    history.push(option.value);
  };

  useEffect(() => {
    if (!selectedOption) {
      setSelectedOption(options.find((option) => option.value === history.location.pathname));
    }
  }, [history.location]);

  if (isMobile) {
    return (
      <StickyPageHeader
        name={'Profil'}
        noWrap
      >
        <Select
          menuPlacement={'bottom'}
          options={options}
          value={selectedOption}
          onChange={onChangeHandler}
          wrapperStyle={style.select}
        />
      </StickyPageHeader>
    );
  }

  return (
    <div className={style.container}>
      <Card className={style.wrapper}>
        <PageHeader
          name={'Profil'}
          className={style.header}
          titleClasses={style.title}
        />
        {desktopLinks.map((x, index) => (
          <Dropdown
            horizontalOnMobile
            key={index}
            item={x}
            disabled
          />
        ))}
      </Card>
    </div>
  );
};

export default Navigation;
