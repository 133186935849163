import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { Card } from 'components';
import OrderHeader from 'components/layout/OrderHeader';

import shared from 'src/styles/custom/Shared.module.scss';
import { getFormattedAmount } from 'src/utils/helpers';

import { addOrderDetails } from '../../../OfferCatalog/actions';
import OrderOptions from '../OrderOptions';
import ProductsList from '../ProductsList';
import OrderComment from '../SupplierComment';
import UserCommentAndGifts from '../UserCommentAndGifts';

import style from './OrderDetails.module.scss';

const OrderDetails = ({ order }) => {
  const dispatch = useDispatch();

  const { name, payment, shipping, amount, shipping_price } = order;
  const [paymentId, setPaymentId] = useState(payment[0]?.id);
  const [shippingId, setShippingId] = useState(shipping[0]?.id);
  const [userComment, setUserComment] = useState('');

  const handleDispatchOrderDetails = () =>
    dispatch(
      addOrderDetails({
        supplier_id: order.id,
        shipping_id: shippingId,
        payment_id: paymentId,
        user_comment: userComment
      })
    );

  useEffect(() => {
    if (paymentId && shippingId) {
      handleDispatchOrderDetails();
    }
  }, [paymentId, shippingId, order]);

  useEffect(() => {
    const timeoutId = setTimeout(handleDispatchOrderDetails, 500);
    return () => clearTimeout(timeoutId);
  }, [userComment]);

  return (
    <div
      className={style.supplier}
      id={name}
    >
      <OrderHeader supplier={order} />
      <ProductsList products={order.products} />
      <OrderComment comment={order.comment} />
      <div className={style.container}>
        <OrderOptions
          groupName={`${order.name}-payment`}
          options={payment}
          setCurrentOption={setPaymentId}
          currentOption={paymentId}
          errorMessage={'Musisz wybrać sposób płatności'}
          sectionTitle={'Sposób płatności'}
        />
        <OrderOptions
          groupName={`${order.name}-shipping`}
          options={shipping}
          setCurrentOption={setShippingId}
          currentOption={shippingId}
          errorMessage={'Musisz wybrać sposób dostawy'}
          sectionTitle={'Sposób wysyłki'}
        />
      </div>
      <UserCommentAndGifts
        setComment={setUserComment}
        comment={userComment}
        gifts={order.gifts}
      />
      <Card className={cn(shared.defaultWrapper, style.card)}>
        <h2 className={style.partOfAmount}>Wartość produktów: {getFormattedAmount(amount)}</h2>
        <h2 className={style.partOfAmount}>Koszty wysyłki: {getFormattedAmount(shipping_price)}</h2>
        <h2 className={style.amount}>Suma zamówienia: {getFormattedAmount(amount + shipping_price)}</h2>
      </Card>
    </div>
  );
};

export default OrderDetails;
