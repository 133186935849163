import React from 'react';

import { DateRangePicker, Select } from 'components';

import style from './DatePickerWrapper.module.scss';

export const dateOptions = [
  { label: 'ostatnie 30 dni', value: 'LAST_30_DAYS' },
  { label: 'ostatnie 3 miesiące', value: 'LAST_3_MONTHS' },
  { label: 'ostatnie 6 miesięcy', value: 'LAST_6_MONTHS' },
  { label: 'ostatni rok', value: 'LAST_YEAR' },
  { label: 'wybierz własny', value: 'CUSTOM_RANGE' }
];

const DatePickerWrapper = ({ startDate, setStartDate, endDate, setEndDate, selectedValue, setSelectedValue }) => {
  return (
    <>
      <Select
        options={dateOptions}
        onChange={setSelectedValue}
        value={selectedValue}
        wrapperStyle={style.select}
        label={'Okres badania'}
      />
      {selectedValue.value === 'CUSTOM_RANGE' && (
        <DateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          label={'Wybierz zakres'}
        />
      )}
    </>
  );
};

export default DatePickerWrapper;
