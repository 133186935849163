import React from 'react';
import classNames from 'classnames';

import style from '../OrderCart/OrderCart.module.scss';
import shared from 'styles/custom/Shared.module.scss';

const UserComment = ({ comment }) => {
  if (!comment) {
    return null;
  }

  return (
    <div className={style.wrapper}>
      <header className={style.headerTop}>
        <h2 className={style.title}>Komentarz klienta do zamówienia</h2>
      </header>
      <section className={classNames(shared.tableWrapper, style.container)}>
        <p className={style.comment}>{comment}</p>
      </section>
    </div>
  );
};

export default UserComment;
