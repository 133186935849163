import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { PageHeader, PaginationNav, Searchbar, SeoHelmet, SwitchButton, useRequestAbortController } from 'components';
import { headerTextPlaceholder } from 'components/layout/PageHeader/placeholders';
import { notifyApiError } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { getCorrectFormOfResultsLabel } from 'src/utils/helpers';
import query from 'src/utils/query';

import Filtering from './components/Filtering';
import ProductsList from './components/ProductsList';
import { finishDataLoading, startDataLoading } from './actions';

import style from './CollectionView.module.scss';

const CollectionView = (props) => {
  const params = query(props);
  const dispatch = useDispatch();
  const { uid } = useParams();

  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showOnlyAdded, setShowOnlyAdded] = useState(false);
  const [sortBy, setSortBy] = useState(params.get('sort_by'));
  const [sortOrder, setSortOrder] = useState(params.get('sort_order'));
  const [currentPage, setCurrentPage] = useState(params.get('page'));
  const [querySearch, setQuerySearch] = useState(params.get('search'));
  const [queryCategory, setQueryCategory] = useState(params.get('category'));
  const [queryProducer, setQueryProducer] = useState(params.get('producer'));

  const [pageQty, setPageQty] = useState(1);
  const [itemsQty, setItemsQty] = useState();
  const [perPage, setPerPage] = useState(50);

  const [abortController, setNewController] = useRequestAbortController();
  const version = useSelector((state) => state.collectionView.version);

  const handleGetProducts = async () => {
    const queryProps = {
      uid: uid,
      only_collection: 1,
      page: currentPage || 1,
      perPage: perPage,
      search: querySearch,
      categoryId: queryCategory,
      producerId: queryProducer,
      only_added: +showOnlyAdded,
      ...(sortBy && { sort_by: sortBy }),
      ...(sortOrder && { sort_order: sortOrder })
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      dispatch(startDataLoading());
      const { data } = await SupplierApi.getProducts(queryProps, signal);
      setPageData(data.data);
      setItemsQty(data.total);
      setPageQty(data?.last_page);
    } catch (err) {
      notifyApiError(err);
    } finally {
      dispatch(finishDataLoading());
    }
  };

  const getData = async () => {
    let error;

    try {
      setIsLoading(true);
      await handleGetProducts();
    } catch (err) {
      notifyApiError(err);
      error = err;
    } finally {
      setIsLoading(error?.message === 'canceled');
    }
  };

  useEffect(() => {
    getData();
  }, [currentPage, perPage, querySearch, queryCategory, queryProducer, showOnlyAdded, sortOrder, sortBy]);

  useEffect(() => {
    if (version > 0) {
      handleGetProducts();
    }
  }, [version]);

  useEffect(() => {
    setCurrentPage(params.get('page'));
    setQuerySearch(params.get('search'));
    setQueryCategory(params.get('category'));
    setQueryProducer(params.get('producer'));
    setSortBy(params.get('sort_by'));
    setSortOrder(params.get('sort_order'));
  }, [params]);

  const changeOnlyAdded = (bool) => {
    setShowOnlyAdded(bool);
    params.setFew([
      { key: 'onlyAdded', value: +bool },
      { key: 'page', value: '1' }
    ]);
  };

  const name = useMemo(
    () =>
      pageData?.collection_type === 'individual'
        ? pageData.client_data_hidden
          ? 'Dane klienta ukryte'
          : `Oferta dla: ${pageData?.company?.name}`
        : 'Twoja oferta dla wszystkich klientów',
    [pageData]
  );

  return (
    <>
      <SeoHelmet title={name} />
      <PageHeader
        name={isLoading ? headerTextPlaceholder('250px') : name}
        text={isLoading ? headerTextPlaceholder() : getCorrectFormOfResultsLabel(itemsQty)}
        blurTitle={pageData?.client_data_hidden && pageData?.collection_type === 'individual'}
        blurredTitlePrefix={'Oferta dla:'}
        wrapOnMobile
      >
        <div className={style.switchCenter}>
          <SwitchButton
            label={'Pokaż tylko dodane ceny'}
            value={showOnlyAdded}
            setValue={changeOnlyAdded}
          />
        </div>
      </PageHeader>
      <div className={style.container}>
        <div className={style.header}>
          <Searchbar
            params={params}
            alwaysExpand
            fullWidth
            searchSize={'large'}
            className={style.search}
          />
          <Filtering params={params} />
        </div>
        <ProductsList
          list={pageData?.products}
          isLoading={isLoading}
          params={params}
        />
        <PaginationNav
          onClick={(number) => setCurrentPage(number)}
          params={params}
          pagesQty={pageQty}
          setPerPage={setPerPage}
          defaultQty={perPage}
        />
      </div>
    </>
  );
};

export default CollectionView;
