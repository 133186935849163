import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Input, useModalConfirm, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AccountsApi, AdminApi } from 'src/api';

import { changeUsersVersion } from '../../actions';

import style from './ChangePassword.module.scss';

const ChangePassword = ({ user, closeModal }) => {
  const { id } = user;
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const dispatch = useDispatch();
  const validator = useValidator();

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return null;
    }

    handleOpenModalConfirm({
      message: `Potwierdź zmianę hasła dla konta ${user.email}`,
      handleConfirm: changePassword
    });
  };

  const changePassword = async () => {
    const data = {
      new_password: password
    };

    setIsLoading(true);
    try {
      await AdminApi.putUserData(id, data);
      dispatch(changeUsersVersion());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const handlePasswordReset = async () => {
    setIsLoading(true);
    try {
      await AccountsApi.forgotPassword({ email: user.email });
      notifyCommon(['Na podany adres e-mail został wysłany link do zresetowania hasła.']);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.container}>
      <Input
        label={'Nowe hasło'}
        id={'new-password'}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        validator={validator}
        rule={'required|password'}
      />
      <Button
        label={'Zmień hasło'}
        onClick={handleChangePassword}
        isLoading={isLoading}
      />
      <h2>Lub</h2>
      <Button
        onClick={handlePasswordReset}
        label={'Wyślij email z linkiem do zresetowanie hasła'}
        isLoading={isLoading}
      />
      {renderedModalConfirm}
    </div>
  );
};

export default ChangePassword;
