import React from 'react';
import { useSelector } from 'react-redux';

const useValuationResponsive = () => {
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);

  if (isSidebarOpen) {
    return {
      0: {
        items: 1
      },
      880: {
        items: 2
      },
      1024: {
        items: 1
      },
      1150: {
        items: 2
      },
      1220: {
        items: 2
      },
      1350: {
        items: 3
      },
      1600: {
        items: 4
      }
    };
  } else {
    return {
      0: {
        items: 1
      },
      880: {
        items: 2
      },
      1150: {
        items: 3
      },
      1550: {
        items: 4
      }
    };
  }
};

export default useValuationResponsive;
