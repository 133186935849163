import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { ConfigProvider } from 'antd';
import pl_PL from 'antd/lib/locale/pl_PL';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

import AppRouter from './app/AppRouter/index';
import MedicoverAppRouter from './app/AppRouter/medicover';
import store from './store/configureStore';
import { SeoHelmet } from './components';
import * as serviceWorker from './serviceWorker';

import 'react-notifications-component/dist/theme.css';

const SentryDNS =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? 'https://5fbe2a9ceab9a5aa5aaf19afcf4f58ce@sentry.beeweb.pl/2'
    : 'https://2372085b184d5df441c85bd6e6fde8e8@sentry.beeweb.pl/4';

if (process.env.REACT_APP_NODE_ENV !== 'development') {
  Sentry.init({
    dsn: SentryDNS,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay(),
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'https://https://app.dentametr.pl/',
          'https://api-prod.dentametr.pl/api/',
          'https://api-staging.dentametr.pl/api/',
          'https://bazawiedzy.dentametr.pl/wp-json/wp/v2/',
          'https://staging.dentametr-frontend.pages.dev/'
        ]
      })
    ]
  });
}

dayjs.extend(updateLocale);
dayjs.updateLocale('pl', {
  weekStart: 1
});

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ConfigProvider locale={pl_PL}>
        <SeoHelmet />
        <Provider store={store}>
          {process.env.REACT_APP_IS_MEDICOVER === 'true' ? <MedicoverAppRouter /> : <AppRouter />}
        </Provider>
      </ConfigProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
