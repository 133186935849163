import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { CustomTooltip, OptionCheckbox, ReplacementDetails, Tag, useIsAdmin, usePermissions } from 'components';
import InfoWidget from 'components/layout/InfoWidget';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi, OfferCatalogApi } from 'src/api';
import { ReactComponent as ArrowIcon } from 'src/assets/icons/up-arrow.svg';
import { OfferCatalogTypes, userPermissions } from 'src/constants/enums';
import { getTimeAgo, toDateNumber, toTime } from 'src/utils/dateTime';
import { getFormattedAmount, getPriceTypeContent, getSupplierBackgroundColor } from 'src/utils/helpers';

import { refreshSummary } from '../../actions';
import OfferSettings from '../OfferSettings';

import style from './OfferWrapper.module.scss';

const WEEK_INTERVAL = 60_480_000;

const OfferWrapper = ({ item, product, changeValuationSelections, selectedCompany, type }) => {
  const { price, discount, previous_price_created_at, previous_price, created_at } = item || {};
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();

  const [isSelected, setIsSelected] = useState(false);
  const [isLoadingSelection, setIsLoadingSelection] = useState(false);
  const [canMenageCatalog] = usePermissions([userPermissions.offer_catalog.menage]);

  const Api = type === OfferCatalogTypes.catalog ? OfferCatalogApi : ListsApi;

  const handleChange = async () => {
    const queryParams = { id: item.id, ...(isAdmin && { company_id: selectedCompany }) };
    const changeData = { productId: product.id, supplierId: isSelected ? false : item.supplier_id };

    try {
      setIsLoadingSelection(true);
      await Api.selectPrice(queryParams);
      if (changeValuationSelections) changeValuationSelections(changeData);
      dispatch(refreshSummary());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoadingSelection(false);
    }
  };

  const { label: priceAddTypeLabel } = useMemo(() => getPriceTypeContent(item?.add_type), [item]);

  const priceTooltipContent = canMenageCatalog ? (
    <div className={style.tooltip}>
      <p>{priceAddTypeLabel}</p>
      <br />
      <p>Poprzednia cena: {getFormattedAmount(previous_price)}</p>
      {!!created_at && (
        <p>
          Ostatnia zmiana: {getTimeAgo(created_at).date}
          <br />({toDateNumber(created_at)}, {toTime(created_at)})
        </p>
      )}
      {!!previous_price_created_at && (
        <p>
          Poprzednią cenę dodano:
          <br />
          {toDateNumber(previous_price_created_at)}
        </p>
      )}
    </div>
  ) : null;

  const priceChangeIndicator =
    +price !== +previous_price && dayjs().diff(dayjs(created_at)) < WEEK_INTERVAL ? (
      +price > +previous_price ? (
        <ArrowIcon className={style.up} />
      ) : (
        <ArrowIcon className={style.down} />
      )
    ) : null;

  const header = useMemo(
    () => (
      <div className={style.priceWrapper}>
        {item.price ? (
          <p className={style.price}>
            <CustomTooltip title={priceTooltipContent}>
              {getFormattedAmount(item.price)} {!!+previous_price && priceChangeIndicator}
            </CustomTooltip>
          </p>
        ) : price === null ? (
          <span className={classNames(style.price)} />
        ) : (
          <p className={style.price}>
            <CustomTooltip title={priceTooltipContent}>
              {getFormattedAmount(item.price)} {!!+previous_price && priceChangeIndicator}
            </CustomTooltip>
          </p>
        )}
        {item?.type === 'replacement' && (
          <ReplacementDetails replacement={item}>
            <InfoWidget place={'top'}>
              <p>
                Cena dotyczy zamiennika, który może się różnić się od oryginalnego produktu.{' '}
                <span className={style.underline}>Kliknij aby sprawdzić jego parametry</span>
              </p>
            </InfoWidget>
          </ReplacementDetails>
        )}
      </div>
    ),
    [item, price, discount]
  );

  useEffect(() => {
    setIsSelected(!!item.isSelected);
  }, [item]);

  return (
    <div className={style.offerBox}>
      <OptionCheckbox
        name={product.name}
        value={name + item.id}
        onChange={handleChange}
        handleDelete={handleChange}
        isChecked={isSelected}
        header={header}
        className={classNames(style.optionCheckbox, { [style.replacement]: item?.type === 'replacement' })}
        borderOnSelect
        tickOnSelect
        isLoading={isLoadingSelection}
        hideRadio={price === null}
      >
        <div className={style.contentWrapper}>
          <div className={style.settings}>
            <Tag
              value={item?.supplier_name || '-'}
              style={getSupplierBackgroundColor(item)}
            />
            <OfferSettings
              priceId={item.id}
              item={item}
              selectedCompany={selectedCompany}
            />
          </div>
        </div>
      </OptionCheckbox>
    </div>
  );
};

export default OfferWrapper;
