import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import {
  AllOrdersSumBar,
  Button,
  LoaderGlobal,
  OrderSuppliersTable,
  PageHeader,
  SeoHelmet,
  Tag,
  useCompany,
  useIsAdmin,
  usePermissions
} from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ListsApi, OfferCatalogApi } from 'src/api';
import { OfferCatalogTypes, userPermissions } from 'src/constants/enums';
import { scrollToElementAndReturnPromise } from 'src/utils/helpers';

import { clearOrderDetails } from '../OfferCatalog/actions';
import Address from './components/Address';
import OrdersList from './components/OrdersList';

import style from './OfferCatalogOrder.module.scss';

const OfferCatalogOrder = ({ type = OfferCatalogTypes.catalog }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { listUID } = useParams();
  const addressesWrapperRef = useRef();
  const company = useCompany();
  const isAdmin = useIsAdmin();

  const Api = type === OfferCatalogTypes.catalog ? OfferCatalogApi : ListsApi;

  const [isLoading, setIsLoading] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [shippingAddressId, setShippingAddressId] = useState();
  const [canCreateOrders] = usePermissions([userPermissions.orders.create]);

  const orderDetails = useSelector((state) => state.offerCatalog.orderDetails);
  const valuationsVersion = useSelector((state) => state.offerCatalog.valuationsVersion);
  const addressesVersion = useSelector((state) => state.companiesList.addressesVersion);

  const getData = async () => {
    const queryParams = { ...(listUID && { list_uid: listUID }) };

    try {
      const { data } = await Api.resolveOrderData(queryParams);
      setPageData(data);
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    const getInitialData = async () => {
      setIsLoading(true);
      await getData();
      setIsLoading(false);
    };

    dispatch(clearOrderDetails());
    getInitialData();

    return () => dispatch(clearOrderDetails());
  }, []);

  useEffect(() => {
    if (valuationsVersion > 0 || addressesVersion > 0) getData();
  }, [valuationsVersion, addressesVersion]);

  const createOrder = async (needApproval) => {
    if (!shippingAddressId) {
      notifyCommon(['Wybierz adres wysyłki zamówienia.']);
      if (addressesWrapperRef?.current) {
        scrollToElementAndReturnPromise(addressesWrapperRef.current).then(() => addressesWrapperRef.current?.focus());
      }
      return;
    }

    const data = {
      need_approval: company?.is_order_approval_enabled || needApproval,
      shipping_address_id: shippingAddressId,
      orderData: orderDetails,
      list_uid: listUID
    };

    setIsLoading(true);
    try {
      const response = await Api.createOrder(data);
      history.push({
        pathname: `/lists/${listUID}/cart/proceed-order/thanks`,
        state: response.data
      });
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoaderGlobal />;
  }

  return (
    <>
      <SeoHelmet title={'Realizacja zamówienia'} />
      <PageHeader
        name={'Realizacja zamówienia'}
        textCenter={!!listUID}
        text={
          !!listUID && (
            <Tag
              value={'Na podstawie listy'}
              onClick={() => history.push(`/lists/${listUID}`)}
              color={'blue'}
            />
          )
        }
      />
      <div className={style.container}>
        <OrderSuppliersTable order={pageData} />
        <Address
          ref={addressesWrapperRef}
          setShippingAddressId={setShippingAddressId}
          company={pageData?.company}
        />

        <form
          className={style.form}
          onSubmit={(e) => e.preventDefault()}
        >
          <OrdersList suppliers={pageData?.suppliers} />
          <AllOrdersSumBar
            suppliersNumber={pageData?.suppliers?.length}
            shipPrice={pageData?.shipping_sum}
            totalSum={pageData?.total_sum}
            status={pageData?.status}
          >
            {canCreateOrders && (
              <>
                {isAdmin && (
                  <Button
                    label={'Akceptacja zamówienia'}
                    onClick={() => createOrder(true)}
                    disabled={pageData?.suppliers?.length === 0}
                    gray
                  />
                )}
                <Button
                  label={'Potwierdź zamówienie'}
                  onClick={() => createOrder()}
                  disabled={pageData?.suppliers?.length === 0}
                />
              </>
            )}
          </AllOrdersSumBar>
        </form>
      </div>
    </>
  );
};

export default OfferCatalogOrder;
