import React, { useMemo, useState } from 'react';

import { Button, QtySelector } from 'components';

import ChangeMenu from '../index';

import style from '../changeMenu.module.scss';

const ChangeQuantity = (props) => {
  const { quantity, setQuantity, unit = '', productId, wasQtyChange, isActive, setIsActive, min, max, displayOver } = props;
  const [qtyValue, setQtyValue] = useState(+quantity);
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    if (setQuantity) await setQuantity(qtyValue);
    setIsActive(false);
    setIsLoading(false);
  };

  const isNotDisabled = useMemo(() => {
    if (min?.toString() && max?.toString()) {
      return +qtyValue >= +min && +qtyValue <= +max;
    } else if (min?.toString()) {
      return +qtyValue >= +min;
    } else if (max?.toString()) {
      return +qtyValue <= +max;
    } else {
      return true;
    }
  }, [qtyValue, min, max]);

  return (
    <ChangeMenu
      value={`${quantity} ${unit || ''}`}
      displayOver={displayOver}
      isActive={isActive}
      setIsActive={setIsActive}
      wasDataChange={wasQtyChange}
    >
      <QtySelector
        label={'Zmień ilość'}
        id={productId + '-new-qty'}
        name={productId + '-new-qty'}
        state={qtyValue}
        setState={setQtyValue}
        suffix={unit}
        width={130}
      />
      <Button
        label={'Zapisz'}
        onClick={handleSave}
        className={style.save}
        disabled={!isNotDisabled}
        isLoading={isLoading}
      />
    </ChangeMenu>
  );
};

export default ChangeQuantity;
