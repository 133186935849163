import React, { useState } from 'react';
import classNames from 'classnames';

import { ReactComponent as HandShakeEmptyIcon } from 'assets/icons/hand-shake-empty.svg';
import { ReactComponent as MessageIcon } from 'assets/icons/message.svg';
import { ReactComponent as MoneyIcon } from 'assets/icons/monney.svg';
import { ReactComponent as ReplacementIcon } from 'assets/icons/replacement.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { CustomTooltip, Modal } from 'components';

import ChangeDistributor from 'src/features/ValuationView/components/Column/components/ChangeDistributor';
import OfferSelect from 'src/features/ValuationView/components/Column/components/OfferSelect';
import SubstituteSelect from 'src/features/ValuationView/components/Column/components/SubstituteSelect';
import SupplierReplacementSelect from 'src/features/ValuationView/components/Column/components/SupplierReplacementSelect';

import style from './ValuationSettings.module.scss';

const ValuationSettings = ({ className, item, product, hideOffers = false, hideReplacements = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentType, setContentType] = useState('');

  const handleOpenModal = (type) => {
    setIsModalOpen(true);
    setContentType(type);
  };

  const sortPrices = (a, b) => a.price - b.price;
  const getSortedPrices = () => item.other_prices.sort(sortPrices);
  const getSelectedDistributor = () => product?.prices[0]?.supplier_id;

  const tooltipConfig = {
    placement: 'left',
    enterDelay: 500,
    leaveDelay: 200
  };

  const renderContent = () => {
    switch (contentType) {
      case 'CHANGE_DISTRIBUTOR':
        return (
          <ChangeDistributor
            item={item}
            otherPrices={getSortedPrices()}
            selectedDistributor={getSelectedDistributor()}
            closeModal={() => setIsModalOpen(false)}
          />
        );
      case 'OFFER_SELECT':
        return (
          <OfferSelect
            item={item}
            closeModal={() => setIsModalOpen(false)}
          />
        );
      case 'DISTRIBUTOR_REPLACEMENT_SELECT':
        return (
          <SubstituteSelect
            item={item}
            substitutes={product.substitutes}
            closeModal={() => setIsModalOpen(false)}
          />
        );
      case 'MESSAGE':
        return <div className={style.comment}>{item.comment}</div>;
      case 'SUPPLIER_REPLACEMENT_SELECT':
        return (
          <SupplierReplacementSelect
            item={item}
            closeModal={() => setIsModalOpen(false)}
          />
        );
      default:
        return null;
    }
  };

  const renderTitle = () => {
    switch (contentType) {
      case 'OFFER_SELECT':
        return 'Wybierz jedną z ofert dostawcy';
      case 'CHANGE_DISTRIBUTOR':
        return 'Zmień dostawcę';
      case 'SUPPLIER_REPLACEMENT_SELECT':
      case 'DISTRIBUTOR_REPLACEMENT_SELECT':
        return 'Dostępne zamienniki produktu';
      case 'MESSAGE':
        return `Wiadomość od ${item.supplier} dotycząca produktu`;
      default:
        return null;
    }
  };

  const { other_prices, offers, comment, substitutes_available, replacements } = item;

  return (
    <>
      <div className={classNames(style.container, className)}>
        {comment && (
          <CustomTooltip
            {...tooltipConfig}
            title={
              <>
                Komentarz dostawcy
                <br />
                odnośnie produktu
              </>
            }
          >
            <button
              className={style.button}
              onClick={() => handleOpenModal('MESSAGE')}
            >
              <MessageIcon />
            </button>
          </CustomTooltip>
        )}
        {other_prices?.length > 1 && (
          <CustomTooltip
            {...tooltipConfig}
            title={'Wybierz dostawcę produktu'}
          >
            <button
              className={style.button}
              onClick={() => handleOpenModal('CHANGE_DISTRIBUTOR')}
            >
              <MoneyIcon />
            </button>
          </CustomTooltip>
        )}
        {((!hideReplacements && replacements?.length > 1) || (replacements?.length === 1 && item.id === null)) && (
          <CustomTooltip
            {...tooltipConfig}
            title={'Dostępne zamienniki'}
          >
            <button
              className={style.button}
              onClick={() => handleOpenModal('SUPPLIER_REPLACEMENT_SELECT')}
            >
              <ReplacementIcon />
            </button>
          </CustomTooltip>
        )}
        {!hideOffers && offers?.length > 1 && (
          <CustomTooltip
            {...tooltipConfig}
            title={'Zobacz ofertę dostawcy'}
          >
            <button
              className={style.button}
              onClick={() => handleOpenModal('OFFER_SELECT')}
            >
              <HandShakeEmptyIcon />
            </button>
          </CustomTooltip>
        )}
        {substitutes_available && (
          <CustomTooltip
            {...tooltipConfig}
            title={'Dostępne zamienniki'}
          >
            <button
              className={style.button}
              onClick={() => handleOpenModal('DISTRIBUTOR_REPLACEMENT_SELECT')}
            >
              <SettingsIcon />
            </button>
          </CustomTooltip>
        )}
      </div>
      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={renderTitle()}
      >
        {renderContent()}
      </Modal>
    </>
  );
};

export default ValuationSettings;
