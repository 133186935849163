import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import { ReactComponent as LogoDentametr } from 'assets/img/Logo-Dentametr.svg';
import logo from 'assets/img/Logo-DentametrXXX.svg';
import { ReactComponent as LogoMedicover } from 'assets/img/Logo-medicover.svg';
import { useIsMedicover, useIsUserAuth } from 'components';

import { imageErrorHandler } from 'src/utils/helpers';

import BurgerButton from './components/BurgerButton';
import DesktopMenu from './components/DesktopMenu';
import MobileMenu from './components/MobileMenu';

import style from './PublicHeader.module.scss';

const items = [
  // {
  //   label: 'Cennik',
  //   pathname: '/pricing'
  // },
  // {
  //   label: 'Wiedza',
  //   pathname: '/knowledge-base'
  // },
  {
    label: 'Kontakt',
    pathname: '/contact'
  }
];

const PublicHeader = ({ supplierForm }) => {
  const isAuth = useIsUserAuth();
  const isMedicover = useIsMedicover();
  const [isBurgerClicked, setIsBurgerClicked] = useState(false);

  if (isMedicover) {
    return (
      <header className={cn(style.container, style.medicover)}>
        <LogoDentametr className={style.medicoverLogo} />
        <LogoMedicover className={style.medicoverLogo} />
      </header>
    );
  }

  return (
    <>
      <div className={style.container}>
        <NavLink to={'/'}>
          <img
            src={logo}
            alt='Dentametr Logo'
            className={style.logo}
            onError={imageErrorHandler}
          />
        </NavLink>
        <DesktopMenu
          supplierForm={supplierForm}
          items={items}
          isAuth={isAuth}
        />
        <BurgerButton
          value={isBurgerClicked}
          setValue={setIsBurgerClicked}
        />
      </div>
      <MobileMenu
        items={items}
        isActive={isBurgerClicked}
        isAuth={isAuth}
        supplierForm={supplierForm}
      />
    </>
  );
};

export default PublicHeader;
