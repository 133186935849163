import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Icon, Popover, useIsMobile } from 'components';

import { ReactComponent as Menu } from 'src/assets/icons/sidebar/menu.svg';

import style from './StickyPageHeader.module.scss';

const StickyPageHeader = ({ name, children, filtering, noWrap = false, className, containerClasses }) => {
  const isMobile = useIsMobile();
  const { isAdminView } = useSelector((state) => state.auth);

  const childrenWrapper = (children) => <div className={style.content}>{children}</div>;

  const mobileChildren = children ? (
    <Popover
      content={childrenWrapper(children)}
      placement={'bottomRight'}
    >
      <Menu />
    </Popover>
  ) : null;

  const mobileFiltering = filtering ? (
    <Popover
      content={childrenWrapper(filtering)}
      placement={'bottom'}
    >
      <button>
        <Icon
          name={'filter'}
          size={24}
          fill={'#6A798B'}
        />
      </button>
    </Popover>
  ) : null;

  return (
    <section className={cn(style.container, containerClasses, isAdminView && style.container_adminView)}>
      <header className={cn(style.header, className)}>
        <h2>{name}</h2>
        <div className={style.buttonWrapper}>
          {isMobile && !noWrap && mobileFiltering}
          {isMobile && !noWrap ? mobileChildren : children}
        </div>
      </header>
      {!isMobile && filtering}
    </section>
  );
};

export default StickyPageHeader;
