import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Accordion, useIsAdmin } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ValuationsApi } from 'src/api';
import { handleNumeralWords } from 'src/utils/helpers';

import PendingProductsTable from '../PendingProductsTable';

import style from './PendingValuations.module.scss';

const PendingValuations = ({ selectedCompany }) => {
  const [valuations, setValuations] = useState([]);
  const isAdmin = useIsAdmin();
  const valuationsVersion = useSelector((state) => state.offerCatalog.valuationsVersion);

  useEffect(() => {
    const getData = async () => {
      const queryData = { ...(isAdmin && { company_id: selectedCompany }) };

      try {
        const { data } = await ValuationsApi.getPendingStatus(queryData);
        setValuations(data);
      } catch (err) {
        notifyApiError(err);
      }
    };

    getData();
  }, [selectedCompany, valuationsVersion]);

  if (valuations?.length === 0) {
    return null;
  }

  const title = <h3 className={style.title}>Uwaga!</h3>;
  const description = (
    <p className={style.description}>
      Oferty nie zawiera wszystkich cen. Aktualnie trwa oczekiwanie na&nbsp;
      <strong>
        {valuations?.length} {handleNumeralWords(['wycenę', 'wyceny', 'wycen'], valuations?.length)}.
      </strong>
    </p>
  );

  return (
    <Accordion
      title={title}
      description={description}
      extraDeps={valuations}
      defaultWrapped
      section
      yellow
    >
      <PendingProductsTable products={valuations} />
    </Accordion>
  );
};

export default PendingValuations;
