import React, { useRef } from 'react';
import cn from 'classnames';

import { InfoWidget, VisibilityCallback } from 'components';
import useReadNotification from 'components/hooks/useReadNotification';

import { toDate, toTime } from 'src/utils/dateTime';

import style from './Message.module.scss';

const Message = ({ displayRight, message, text, label, notification }) => {
  const messageRef = useRef(null);
  const [readNotificationHandler] = useReadNotification(notification);

  const { created_at, message: content, name, email } = message || {};
  const messageHeader = label || (name || '') + (email ? ` (${email})` : '');

  return (
    <div className={cn(style.container, { [style.right]: displayRight })}>
      <div className={style.details}>
        <span>{created_at ? `${toDate(created_at)}, ${toTime(created_at)}` : ''}</span>
        <InfoWidget>{messageHeader}</InfoWidget>
      </div>
      <div
        className={cn(style.messageBody, { [style.right]: displayRight })}
        id={notification?.data?.message_id ? `message-${notification?.data?.message_id}` : undefined}
        ref={messageRef}
      >
        {content || text}
        <VisibilityCallback
          notification={notification}
          elementRef={messageRef}
          callback={readNotificationHandler}
        />
      </div>
    </div>
  );
};

export default Message;
