import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import { Modal } from 'components';
import ProductDetails from 'components/layout/ProductThumbnailImage/components/ProductDetails';

import placeholder from 'src/assets/img/product-thumbnail.png';
import { imageErrorHandler } from 'src/utils/helpers';

import shared from 'styles/custom/Shared.module.scss';

const ProductThumbnailImage = ({ thumbnail, image, title, size = 'small', id }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isProduction = useMemo(() => process.env.REACT_APP_NODE_ENV === 'production', []);

  const handleClick = () => {
    if (image) {
      setIsOpen(true);
    }
  };

  return (
    <>
      <img
        src={thumbnail || placeholder}
        alt={title}
        className={classNames(shared.defaultThumbnail, {
          [shared.withPointer]: !!image,
          [shared.medium]: size === 'medium',
          [shared.tiny]: size === 'tiny'
        })}
        onClick={handleClick}
        loading={'lazy'}
        onError={imageErrorHandler}
      />
      {!isProduction && <span className={shared.prodId}>id: {id}</span>}
      <Modal
        visible={isOpen}
        onClose={() => setIsOpen(false)}
        title={'Szczegóły produktu'}
      >
        <ProductDetails
          image={image}
          title={title}
          id={id}
        />
      </Modal>
    </>
  );
};

export default ProductThumbnailImage;
