import React, { useEffect, useState } from 'react';

import { Select } from 'components';

const SortByOptions = ({ params, options = [], ...rest }) => {
  const [selectedSorting, setSelectedSorting] = useState(null);

  const sortByValue = params.get('sort_by');
  const sortOrderValue = params.get('sort_order');

  useEffect(() => {
    if ((selectedSorting?.sort_by !== sortByValue || selectedSorting?.sort_by !== sortOrderValue) && params.get('page')) {
      params.setFew([
        { key: 'sort_by', value: selectedSorting?.sort_by },
        { key: 'sort_order', value: selectedSorting?.sort_order },
        { key: 'page', value: '1' }
      ]);
    } else if (selectedSorting?.sort_by !== sortByValue || selectedSorting?.sort_by !== sortOrderValue) {
      params.setFew([
        { key: 'sort_by', value: selectedSorting?.sort_by },
        { key: 'sort_order', value: selectedSorting?.sort_order }
      ]);
    }
  }, [selectedSorting]);

  useEffect(() => {
    if (sortByValue && sortOrderValue) {
      setSelectedSorting(options.find((opt) => opt?.sort_by === sortByValue && opt?.sort_order === sortOrderValue));
    }
  }, []);

  return (
    <Select
      options={options}
      value={selectedSorting}
      onChange={setSelectedSorting}
      isSearchable={false}
      isClearable
      {...rest}
    />
  );
};

export default SortByOptions;
