import React, { forwardRef, useState } from 'react';
import cn from 'classnames';

import { ReactComponent as CloseIcon } from 'src/assets/icons/closeIcon.svg';

import styles from './Card.module.scss';

const Card = forwardRef((props, ref) => {
  const { className, title, classTitle, classCardHead, head, children, showClose, yellow, red, style } = props;
  const [isHidden, setIsHidden] = useState(false);

  if (showClose && isHidden) {
    return null;
  }

  return (
    <div
      className={cn(styles.card, { [styles.yellow]: yellow, [styles.red]: red }, className)}
      style={style}
      ref={ref}
    >
      {showClose && (
        <button
          className={styles.closeBtn}
          onClick={() => setIsHidden(true)}
        >
          <CloseIcon />
        </button>
      )}
      {title && (
        <div className={cn(styles.head, classCardHead)}>
          <div className={cn(classTitle, styles.title)}>{title}</div>
          {head && head}
        </div>
      )}
      {children}
    </div>
  );
});

export default Card;
