import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { CustomTooltip, Tag, useIsAdmin } from 'components';
import SupplierContactData from 'components/layout/SupplierContactData';

import shared from 'styles/custom/Shared.module.scss';

const SupplierTag = ({ supplier, title, color, type = 'valuations' }) => {
  const [isVisible, setIsVisible] = useState(false);
  const isAdmin = useIsAdmin();

  return (
    <>
      <CustomTooltip title={title}>
        <Tag
          value={supplier.supplier_name}
          className={shared.thumbnailTag}
          color={color}
          onClick={() => setIsVisible(true)}
        />
      </CustomTooltip>
      <SupplierContactData
        isOpen={isVisible}
        setIsOpen={setIsVisible}
        supplier={supplier}
      >
        {isAdmin && <Link to={`/supplier/${type}/${supplier.uid}`}>Link do wyceny dostawcy</Link>}
      </SupplierContactData>
    </>
  );
};

export default SupplierTag;
