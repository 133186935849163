import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import classNames from 'classnames';
import moment from 'moment';

import { EmptyState, Input, SearchInput, useValidator } from 'components';
import InfoWidget from 'components/layout/InfoWidget';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';
import { notifyApiError } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';

import ProductThumbnail from './components/ProductThumbnail';

import style from './ChangeProductQuantity.module.scss';
import shared from 'styles/custom/Shared.module.scss';

const PER_PAGE = 10;

const ChangeProductQuantity = ({ type }) => {
  const [products, setProducts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState();
  const [version, setVersion] = useState(false);
  const [searchWasChange, setSearchWasChange] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [date, setDate] = useState(moment());
  const validator = useValidator();

  const getData = async (callbackVersion, clearData) => {
    const queryParams = {
      page: callbackVersion || 1,
      search: search,
      perPage: PER_PAGE
    };

    try {
      setIsLoading(true);
      const { data } = await WarehouseApi.getWarehouseData(queryParams);

      if (clearData || data.data.products.length === 0) {
        setProducts(data.data.products);
        setHasMore(data.data.products.length < data.total);
      } else {
        setProducts((prev) => [...prev, ...data.data.products]);
        setHasMore(data.data.products.length + products.length < data.total);
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
      setSearchWasChange(false);
    }
  };

  const loadMoreResults = () => {
    setVersion((prev) => prev + 1);
    getData(version + 1);
  };

  useEffect(() => {
    const searchDelay = setTimeout(() => {
      setVersion(1);
      setSearchWasChange(true);
      getData(1, true);
    }, 400);

    return () => {
      clearTimeout(searchDelay);
    };
  }, [search]);

  return (
    <div className={style.container}>
      <div className={style.headerWrapper}>
        <Input
          type='date'
          id='date'
          name='date'
          value={date}
          onChange={(e) => setDate(e.target.value)}
          validator={validator}
          rule={'required'}
          label={type === 'increase' ? 'Data dostawy' : 'Data zużycia'}
        />
        <SearchInput
          state={search}
          alwaysExpand
          fullWidth
          setState={(e) => {
            setSearch(e);
            setSearchWasChange(true);
          }}
        />
      </div>
      <section className={shared.tableWrapper}>
        <div className={classNames(shared.tableHeader, style.header)}>
          <span className={style.columnTitle}>Produkt</span>
          <span className={style.columnTitle}>Stan magazynowy</span>
          <span className={style.columnTitle}>
            {type === 'increase' ? <>Dostawa</> : <>Zużycie</>}
            <InfoWidget>
              <p>Podaj liczbę przedmiotów, którą</p>
              {type === 'increase' ? <p>chcesz dodać do magazynu</p> : <p>chcesz wyjąć z magazynu</p>}
            </InfoWidget>
          </span>
        </div>
        <InfiniteScroll
          dataLength={products.length}
          hasMore={hasMore}
          height={480}
          hasChildren={products.length}
          next={loadMoreResults}
          loader={
            <section className={shared.tableWrapper}>
              <ProductPlaceholder quantity={8} />
            </section>
          }
        >
          {searchWasChange ? (
            <section className={shared.tableWrapper}>
              <ProductPlaceholder quantity={8} />
            </section>
          ) : products.length ? (
            products.map((product) => (
              <ProductThumbnail
                product={product}
                setProducts={setProducts}
                products={products}
                key={product.id}
                type={type}
                date={date}
                validator={validator}
              />
            ))
          ) : (
            !isLoading && (
              <h2 className={style.title}>
                <EmptyState type={'search'} />
              </h2>
            )
          )}
        </InfiniteScroll>
      </section>
    </div>
  );
};

export default ChangeProductQuantity;
