import React, { useMemo } from 'react';
import classnames from 'classnames';

import { RecentlyOrdered, Tag } from 'components';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import { getFormattedAmount } from 'src/utils/helpers';

import style from '../../Product.module.scss';

const Details = ({ className, product, showRefPrice, hideQty, autoSize, tiny, orderedDate }) => {
  const { name, producer, unit_name, quantity, thumbnail, image, ref_price, product_id } = product || {};

  const refPriceSaving = useMemo(() => {
    const x = product?.prices?.find((x) => x.isSelected);
    return +ref_price * x?.quantity - +x?.price;
  }, [ref_price, product]);

  return (
    <div className={classnames(style.container, { [style.autoSize]: autoSize }, className)}>
      <div className={style.wrapper}>
        <ProductThumbnailImage
          thumbnail={thumbnail}
          image={image}
          title={name}
          id={product_id}
          size={tiny ? 'tiny' : undefined}
        />
        <p
          className={style.text}
          title={name}
        >
          {name}
        </p>
      </div>
      {!hideQty && (
        <div className={style.row}>
          <span
            className={style.details}
            title={`Ilość: ${quantity} ${unit_name || ''}`}
          >
            <p>Ilość:</p>
            <span className={style.detailsValue}>
              {quantity}&nbsp;{unit_name}
            </span>
          </span>
          <span
            className={style.details}
            title={`Producent: ${producer}`}
          >
            <p>Producent:</p>
            <span className={style.detailsValue}>{producer || 'Brak'}</span>
          </span>
          <RecentlyOrdered data={orderedDate} />
          {showRefPrice && !!ref_price && +refPriceSaving > 0 && (
            <div className={style.savings}>
              <Tag
                value={`Oszczędność względem listy: ${getFormattedAmount(refPriceSaving)}`}
                color={'yellow'}
                className={style.tag}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Details;
