import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Modal, useIsMobile } from 'components';
import AddToListForm from 'components/layout/AddProductToList/components/AddToListForm';

import style from './AddProductToList.module.scss';

const AddProductToList = (props) => {
  const { title = 'Dodaj produkt do listy', visible, onClose, products, selectedProducts, productId } = props;
  const isMobile = useIsMobile();

  return (
    <Modal
      title={title}
      visible={visible}
      onClose={onClose}
      className={classnames(style.modal, { [style.mobileModal]: isMobile })}
    >
      <AddToListForm
        products={products}
        selectedProducts={selectedProducts}
        productId={productId}
        onClose={onClose}
      />
    </Modal>
  );
};

AddProductToList.propTypes = {
  title: PropTypes.node,
  visible: PropTypes.bool,
  onclose: PropTypes.func,
  productId: PropTypes.number
};

export default AddProductToList;
