import React from 'react';
import classNames from 'classnames';

import { Accordion, Tag } from 'components';

import style from './OrderTracing.module.scss';
import shared from 'styles/custom/Shared.module.scss';

const OrderTracing = ({ order, children }) => {
  const { trackingnumber, trackingurl } = order;

  const title = <h3 className={classNames(shared.tableCategoryTitle, shared.large, shared.noPadding)}>Śledzenie przesyłki</h3>;

  return (
    <Accordion
      section
      title={title}
    >
      <div className={style.box}>
        <div className={style.column}>
          <p className={style.label}>Link do śledzenia przesyłki:</p>
          {trackingurl ? (
            <a
              className={classNames(style.value, style.link)}
              href={trackingurl}
              target={'_blank'}
              rel='noreferrer'
            >
              {trackingurl}
            </a>
          ) : (
            <Tag value={'Nie załączono'} />
          )}
        </div>
        <div className={style.column}>
          <p className={style.label}>Numer listu przewozowego:</p>
          {trackingnumber ? <p className={style.value}>{trackingnumber}</p> : <Tag value={'Nie załączono'} />}
        </div>
      </div>
      {children}
    </Accordion>
  );
};

export default OrderTracing;
