import { Link } from 'react-router-dom';

import { Tag, useIsAdmin } from 'components';

import style from './InfoCard.module.scss';

const tagLabels = {
  8: 'Dla Dostawcy',
  9: 'Dla Klienta',
  10: 'Tylko Medicover'
};

const tagColors = {
  8: 'yellow',
  9: 'green',
  10: 'blue'
};

const InfoCard = ({ title, id, tags }) => {
  const isAdmin = useIsAdmin();

  return (
    <div className={style.container}>
      <div className={style.tags}>
        {isAdmin &&
          tags.map((tag) => (
            <Tag
              value={tagLabels[tag]}
              color={tagColors[tag]}
              key={tag}
            />
          ))}
      </div>
      <h2>{title}</h2>
      <Link to={`/dashboard/informations/${id}`}>Czytaj więcej</Link>
    </div>
  );
};

export default InfoCard;
