import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { Input, Menu, QtySelector, SaveButton, useIsMobile, useModalConfirm } from 'components';
import AddProductToList from 'components/layout/AddProductToList';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';
import { notifyApiError } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { getExpireDateStatus } from 'src/utils/dateTime';
import { getFormattedAmount } from 'src/utils/helpers';

import style from './ProductRow.module.scss';

const getDateString = (date) => (date ? dayjs(date).format('YYYY-MM-DD') : null);

const Product = ({ item, states, reloadData }) => {
  const {
    id,
    name,
    quantity,
    thumbnail,
    image,
    price,
    state_id,
    product_id,
    current_collection_quantity,
    collection_amount,
    expire_date
  } = item || {};

  const isMobile = useIsMobile(1500);
  const [localPrice, setLocalPrice] = useState(price);
  const [itemState, setItemState] = useState(states[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [wasDataEdited, setWasDataEdited] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [expireDate, setExpireDate] = useState(getDateString(expire_date));
  const [isHiddenAfterDeleted, setIsHiddenAfterDeleted] = useState(false);
  const [isModalAddToListOpen, setIsModalAddToListOpen] = useState(false);

  const [collectionQty, setCollectionQty] = useState(current_collection_quantity ?? 0);

  useEffect(() => {
    setWasDataEdited(
      +price !== +localPrice ||
        state_id !== itemState.value ||
        +collectionQty !== +current_collection_quantity ||
        getDateString(expire_date) !== getDateString(expireDate)
    );
  }, [itemState, state_id, localPrice, price, collectionQty, current_collection_quantity, expireDate, expire_date]);

  useEffect(() => {
    setExpireDate(getDateString(expireDate));
    setCollectionQty(current_collection_quantity ?? 0);
    setLocalPrice(price);
  }, [expire_date, current_collection_quantity, price]);

  useEffect(() => {
    setItemState(states.find((state) => state.value === state_id));
  }, [state_id]);

  useEffect(() => {
    let timeout;
    if (isHiddenAfterDeleted) timeout = setTimeout(reloadData, 450);

    return () => clearTimeout(timeout);
  }, [isHiddenAfterDeleted]);

  const saveProduct = async () => {
    const params = {
      id,
      product_id,
      price: localPrice,
      state_id: itemState.value,
      current_collection_quantity: collectionQty,
      expire_date: getDateString(expireDate) || ''
    };

    try {
      setIsLoading(true);
      await WarehouseApi.addProduct(params);
      setWasDataEdited(false);
      item.current_collection_quantity = collectionQty;
      item.expire_date = getDateString(expireDate) || expire_date;
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
      reloadData();
    }
  };

  const deleteProduct = async () => {
    try {
      await WarehouseApi.deleteProduct(id);
      setIsHiddenAfterDeleted(true);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleDeleteProduct = () => {
    handleOpenModalConfirm({
      message: 'Czy na pewno chcesz usunąć produkt z magazynu?',
      handleConfirm: deleteProduct
    });
  };

  const menu = [
    {
      title: 'Usuń produkt',
      icon: 'trash',
      action: handleDeleteProduct
    },
    {
      title: 'Dodaj do listy',
      icon: 'add',
      action: () => setIsModalAddToListOpen(true)
    }
  ];

  const pickerClasses = useMemo(
    () =>
      classNames(style.picker, {
        [style.warning]: getExpireDateStatus(expireDate) === 'warning',
        [style.error]: getExpireDateStatus(expireDate) === 'error'
      }),
    [expire_date, expireDate]
  );

  return (
    <div
      className={classNames(style.container, {
        [style.willUnmount]: isHiddenAfterDeleted
      })}
    >
      <div className={classNames(style.wrapper, style.produceDetails)}>
        <ProductThumbnailImage
          thumbnail={thumbnail}
          id={product_id}
          image={image}
          title={name}
        />
        <p className={style.text}>{name}</p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.mobileLabel}>Data ważności: </p>}
        <Input
          type='date'
          id='expired_date'
          name='expired_date'
          value={expireDate}
          onChange={(e) => setExpireDate(e.target.value)}
          wrapperStyle={pickerClasses}
        />
      </div>
      <div className={style.wrapper}>
        {!!collection_amount && (
          <QtySelector
            state={collectionQty}
            setState={setCollectionQty}
            label={isMobile ? 'Aktualny stan opakowania' : null}
            suffix={'szt.'}
            hideArrows
          />
        )}
      </div>
      <div className={style.wrapper}>
        {!!collection_amount && (
          <>
            {isMobile && <p className={style.mobileLabel}>Sztuk w opakowaniu: </p>}
            <p>{collection_amount}</p>
          </>
        )}
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.mobileLabel}>Ilość: </p>}
        <p>{quantity}</p>
      </div>
      <div className={style.wrapper}>
        <div className={style.column}>
          <QtySelector
            state={localPrice}
            setState={setLocalPrice}
            label={isMobile ? 'Cena' : null}
            suffix={'zł'}
            hideArrows
            priceInput
            decimals={2}
          />
        </div>
      </div>
      <div className={classNames(style.wrapper, style.price)}>
        {isMobile && <p className={style.mobileLabel}>Wartość: </p>}
        <p>{getFormattedAmount(+quantity * +localPrice)}</p>
      </div>
      <div className={classNames(style.wrapper, style.menu)}>
        {wasDataEdited && (
          <SaveButton
            className={style.save}
            onClick={saveProduct}
            isLoading={isLoading}
          />
        )}
        <Menu
          actions={menu}
          className={style.menu}
        />
      </div>
      <AddProductToList
        title={`Dodaj "${name}" do listy`}
        visible={isModalAddToListOpen}
        onClose={() => setIsModalAddToListOpen(false)}
        productId={product_id}
      />
      {renderedModalConfirm}
    </div>
  );
};

export default Product;
