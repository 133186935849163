export const getProducerColumns = (data) =>
  data.suppliers.map((supplier) => {
    const categories = data.categories.map((cat) => {
      const products = cat.products.map((product) => {
        const pricesArr = product.prices.find((price) => price.supplier_id === supplier.id || price.type === supplier.id);
        return { product, valuation: pricesArr };
      });
      return { category: cat, products: products };
    });
    return { supplier, items: categories };
  });

export const getCategoriesAfterSelectionChange = (data, categories = []) => {
  const { products_pricings_id, distributor_id } = data;

  return categories.map((cat) => ({
    ...cat,
    products: cat.products.map((product) => {
      if (product.pricing_prod_id === products_pricings_id) {
        return {
          ...product,
          prices: product.prices.map((price) => {
            if (price.supplier_id === distributor_id) {
              return { ...price, selected: true };
            }
            return { ...price, selected: false };
          })
        };
      }
      return product;
    })
  }));
};

export const getSumOfProducts = (columns = [[]]) => {
  let sum = 0;
  columns[0].items.forEach(({ products }) => {
    sum += products?.length;
  });
  return sum;
};

export const getNumberOfSelectedProductInColumn = (column) => {
  let count = 0;

  column.items.forEach((cat) => {
    cat.products.forEach((product) => {
      if (product?.valuation?.selected) {
        count++;
      }
    });
  });

  return count;
};

export const getColumnPricesSum = (column) => {
  let sum = 0;

  column.items.forEach((cat) => {
    cat.products.forEach((product) => {
      if (product?.valuation?.selected) {
        sum += (product.valuation?.price * (100 - product.valuation?.discount)) / 100;
      }
    });
  });

  return sum;
};
