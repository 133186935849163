import React from 'react';
import Loader from 'react-loading-skeleton';

import style from '../ProductDetails/ProductDetails.module.scss';

const Skeleton = () => {
  return (
    <>
      <div className={style.imageWrapper}>
        <Loader
          width={300}
          height={295}
        />
      </div>
      <div className={style.details}>
        <div className={style.titleWrapper}>
          <div className={style.producer}>
            <p className={style}>Producent: </p>
            <Loader
              width={100}
              height={15}
              containerClassName={style.loader}
            />
          </div>
          <Loader
            containerClassName={style.titleLoader}
            height={27}
          />
        </div>
        <div className={style.row}>
          <div className={style.skeletonBox}>
            <p className={style.label}>Kategoria:</p>
            <Loader
              count={1}
              height={15}
              containerClassName={style.loader}
            />
          </div>
          <div className={style.skeletonBox}>
            <p className={style.label}>Jednostka:</p>
            <Loader
              count={1}
              height={15}
              containerClassName={style.loader}
            />
          </div>
        </div>
        <div className={style.row}>
          <div className={style.skeletonBox}>
            <p className={style.label}>Ostatnia cena: </p>
            <Loader
              count={1}
              height={15}
              containerClassName={style.loader}
            />
          </div>
          <div className={style.skeletonBox}>
            <p className={style.label}>Ostatni dostawca: </p>
            <Loader
              count={1}
              height={15}
              containerClassName={style.loader}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Skeleton;
