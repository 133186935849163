import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, FilterByRole, Modal, PageHeader, PaginationNav, Searchbar, SeoHelmet, useIsMobile } from 'components';
import { headerTextPlaceholder } from 'components/layout/PageHeader/placeholders';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import addIcon from 'src/assets/icons/add.svg';
import pages from 'src/dictionaries/pages.json';
import UsersTable from 'src/features/Users/components/UsersTable';
import { getCorrectFormOfResultsLabel } from 'src/utils/helpers';
import query from 'src/utils/query';

import AddUser from './components/AddUser';

import style from './Users.module.scss';

const Users = (props) => {
  const params = query(props);
  const isMobile = useIsMobile();
  const usersVersion = useSelector((state) => state.users.version);

  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(params.get('page'));
  const [querySearch, setQuerySearch] = useState(params.get('search'));
  const [queryRole, setQueryRole] = useState(params.get('role'));
  const [sortBy, setSortBy] = useState(params.get('sort_by'));
  const [sortOrder, setSortOrder] = useState(params.get('sort_order'));
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [itemsQty, setItemsQty] = useState();
  const [addUserModalVisible, setAddUserModalVisible] = useState();

  useEffect(() => {
    if (!isLoading) {
      getData();
    }
  }, [currentPage, querySearch, perPage, usersVersion, queryRole, sortBy, sortOrder]);

  const getData = async () => {
    setIsLoading(true);
    const queryProps = {
      page: currentPage || 1,
      perPage: perPage,
      search: querySearch,
      role: queryRole,
      ...(sortBy && { sort_by: sortBy }),
      ...(sortOrder && { sort_order: sortOrder })
    };

    try {
      const { data } = await AdminApi.getUsers(queryProps);
      setPageData(data.data);
      setItemsQty(data.total);
      setPageQty(data?.last_page);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(params.get('page'));
    setQuerySearch(params.get('search'));
    setQueryRole(params.get('role'));
    setSortBy(params.get('sort_by'));
    setSortOrder(params.get('sort_order'));
  }, [params]);

  return (
    <>
      <SeoHelmet title={pages.users.title} />
      <PageHeader
        name={pages.users.title}
        wrapOnMobile
        text={isLoading ? headerTextPlaceholder() : getCorrectFormOfResultsLabel(itemsQty)}
      >
        <div className={style.searchWrapper}>
          <Searchbar
            params={params}
            fullWidth={isMobile}
          />
          <FilterByRole params={params} />
          <Button
            label={'Dodaj użytkownika'}
            icon={addIcon}
            onClick={() => setAddUserModalVisible(true)}
            className={style.button}
          />
        </div>
      </PageHeader>
      <div className={style.container}>
        <UsersTable
          list={pageData}
          isLoading={isLoading}
          params={params}
        />
        <PaginationNav
          onClick={(number) => setCurrentPage(number)}
          params={params}
          pagesQty={pageQty}
          setPerPage={setPerPage}
          defaultQty={perPage}
        />
      </div>
      <Modal
        title='Dodaj nowego użytkownika'
        visible={addUserModalVisible}
        onClose={() => setAddUserModalVisible(false)}
      >
        <AddUser onClose={() => setAddUserModalVisible(false)} />
      </Modal>
    </>
  );
};

export default Users;
