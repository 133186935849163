import cn from 'classnames';

import style from './BurgerButton.module.scss';

export default function BurgerButton({ value, setValue }) {
  const toggleMobileMenu = () => {
    setValue(!value);
  };

  return (
    <div
      className={style.burgerMenu}
      onClick={toggleMobileMenu}
    >
      <div
        className={cn(style.burgerBar, {
          [style.burgerBarClicked]: value
        })}
      ></div>
      <div
        className={cn(style.burgerBar, {
          [style.burgerBarClicked]: value
        })}
      ></div>
      <div
        className={cn(style.burgerBar, {
          [style.burgerBarClicked]: value
        })}
      ></div>
    </div>
  );
}
