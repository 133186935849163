import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Button, Card, Modal } from 'components';

import SupplierAccountForm from './components/SupplierAccountForm';

import style from './SupplierRegister.module.scss';

const SupplierRegister = ({ supplierForm, token }) => {
  const [accountModalOpen, setAccountModalOpen] = useState(false);

  const clientName = useSelector((state) => state.supplierForm.clinics_name);

  if (!token) {
    return null;
  }

  return (
    <Card
      className={classNames(style.container, {
        [style.supplierForm]: supplierForm
      })}
    >
      <div className={style.wrapper}>
        {clientName ? <h2>{clientName} wita w aplikacji Dentametr</h2> : <h2>Witamy w aplikacji Dentametr</h2>}
        <p>Zarejestruj darmowe konto dostawcy aby mieć dostęp do nowych klientów.</p>
      </div>
      <Button
        id={'save-form'}
        className={style.button}
        onClick={() => setAccountModalOpen(true)}
        label='Zarejestruj konto dostawcy'
      />
      <Modal
        visible={accountModalOpen}
        onClose={() => setAccountModalOpen(false)}
        title={'Zarejestruj konto dostawcy'}
      >
        <SupplierAccountForm
          closeModal={() => setAccountModalOpen(false)}
          token={token}
        />
      </Modal>
    </Card>
  );
};

export default SupplierRegister;
