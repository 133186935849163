import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { Menu, Modal, Tag, useIsMobile, useModalConfirm } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';

import { changeProducersVersion } from '../../actions';
import MoveProductsProducer from '../MoveProductsProducer';

import style from './ProducersItem.module.scss';

const ProducersItem = ({ producer }) => {
  const { id, producer_name, name, products_count, user_name } = producer || {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const deleteProducer = async () => {
    try {
      setIsLoading(true);
      await AdminApi.deleteProducer(id);
      dispatch(changeProducersVersion());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteProducer = () => {
    handleOpenModalConfirm({
      message: `Czy na pewno chcesz usunąć producenta? (${name})`,
      handleConfirm: deleteProducer
    });
  };

  const menu = [
    {
      title: 'Przenieś produkty',
      icon: 'edit',
      action: () => setIsModalOpen(true)
    },
    {
      title: 'Usuń',
      icon: 'trash',
      action: () => handleDeleteProducer()
    }
  ];

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        {isMobile && <p>Nazwa: </p>}
        <p className={classnames(style.text, style.name)}>{producer_name}</p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p>Liczba produktów: </p>}
        <p className={classnames(style.text, style.name)}>{products_count}</p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Dodany przez:</p>}
        <span className={style.text}>
          {user_name ? (
            <Tag
              value={user_name}
              color={'yellow'}
            />
          ) : (
            <Tag value={'Dentametr'} />
          )}
        </span>
      </div>
      <div className={classnames(style.wrapper, style.menuWrapper)}>
        <Menu
          actions={menu}
          className={style.menu}
          disabled={isLoading}
        />
      </div>
      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`Przenieś produkty (${products_count})`}
      >
        <MoveProductsProducer
          producer={producer}
          closeModal={() => setIsModalOpen(false)}
        />
      </Modal>
      {renderedModalConfirm}
    </div>
  );
};

export default ProducersItem;
