import React, { useState } from 'react';
import classNames from 'classnames';

import { Modal } from 'components';

import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';
import { getFormattedAmount } from 'src/utils/helpers';

import style from './ReplacementDetails.module.scss';

const ReplacementDetails = ({ replacement, children }) => {
  const { replacement_producer, replacement_name, quantity, price } = replacement || {};
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!replacement) {
    return null;
  }

  return (
    <>
      <a
        className={style.link}
        onClick={() => setIsModalOpen(true)}
      >
        {children || (
          <>
            <WarningIcon classNames={style.icon} />
            Wybrano zamiennik
          </>
        )}
      </a>
      <Modal
        onClose={() => setIsModalOpen(false)}
        visible={isModalOpen}
        title={'Parametry zamiennika'}
      >
        <div className={style.container}>
          <div className={classNames(style.element, style.name)}>
            <p className={style.label}>Nazwa:</p>
            <p className={style.value}>{replacement_name}</p>
          </div>
          <div className={classNames(style.element, style.name)}>
            <p className={style.label}>Producent:</p>
            <p className={style.value}>{replacement_producer}</p>
          </div>
          <div className={style.element}>
            <p className={style.label}>Centa za sztukę:</p>
            <p className={style.value}>{getFormattedAmount(+price / +quantity)}</p>
          </div>
          <div className={style.element}>
            <p className={style.label}>Ilość:</p>
            <p className={style.value}>{quantity}</p>
          </div>
          <div className={style.element}>
            <p className={style.label}>Wartość:</p>
            <p className={style.value}>{getFormattedAmount(price)}</p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReplacementDetails;
