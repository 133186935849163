import { combineReducers } from 'redux';

import sidebarReducer from '../components/layout/Sidebar/reducers';
import categoriesReducer from '../features/CategoriesManager/reducers';
import collectionsReducer from '../features/Collections/reducers';
import collectionViewReducer from '../features/CollectionView/reducers';
import companiesListReducer from '../features/CompaniesList/reducers';
import invoicesReducer from '../features/Invoices/reducers';
import listsReducer from '../features/Lists/reducers';
import listsViewReducer from '../features/ListView/reducers';
import notificationsReducer from '../features/NotificationsStore/reducer';
import OfferCatalogReducer from '../features/OfferCatalog/reducers';
import authReducer from '../features/onboarding/reducers';
import ordersReducer from '../features/Orders/reducers';
import orderViewReducer from '../features/OrdersView/reducers';
import producersReducer from '../features/Producers/reducers';
import productsBaseReducer from '../features/ProductsBase/reducers';
import supplierFormReducer from '../features/SupplierForm/reducers';
import suppliersReducer from '../features/Suppliers/reducers';
import suppliersManagementReducer from '../features/SuppliersManagement/reducers';
import usersReducer from '../features/Users/reducers';
import valuationViewReducer from '../features/ValuationView/reducers';

const rootReducer = combineReducers({
  auth: authReducer,
  notifications: notificationsReducer,
  lists: listsReducer,
  listsView: listsViewReducer,
  valuationView: valuationViewReducer,
  suppliers: suppliersReducer,
  sidebar: sidebarReducer,
  supplierForm: supplierFormReducer,
  productsBase: productsBaseReducer,
  orders: ordersReducer,
  orderView: orderViewReducer,
  users: usersReducer,
  producers: producersReducer,
  companiesList: companiesListReducer,
  categories: categoriesReducer,
  collections: collectionsReducer,
  collectionView: collectionViewReducer,
  suppliersManagement: suppliersManagementReducer,
  invoices: invoicesReducer,
  offerCatalog: OfferCatalogReducer
});

export default rootReducer;
