import React, { useEffect, useMemo, useState } from 'react';

import { Card, Chart, Select } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { StatisticsApi } from 'src/api';
import { toDateNumber } from 'src/utils/dateTime';
import { getFormattedAmount, translateMonthsWithInfo } from 'src/utils/helpers';

import ExportData from '../ExportData';

import style from '../Charts.module.scss';

const topChartOptions = [
  { label: 'Wartość zamówień', value: 'total_sum' },
  { label: 'Liczba zamówień', value: 'orders_count' },
  { label: 'Liczba koszyków', value: 'buckets_count' },
  { label: 'Liczba zamówionych produktów', value: 'quantity_sum' },
  { label: 'Liczba zamówionych SKU', value: 'unique_products_count' }
];

const bottomChartOptions = [
  { label: 'Średnia wartość zamówienia', value: 'avg_ord_total_per_orders' },
  { label: 'Średnia wartość koszyka', value: 'avg_ord_total_per_baskets' },
  { label: 'Średnia liczba produktów w zamówieniu', value: 'prod_count_per_orders' },
  { label: 'Średnia liczba produktów w koszyku', value: 'prod_count_per_baskets' },
  { label: 'Średnia liczba SKU w zamówieniu', value: 'uniq_prod_count_per_orders' },
  { label: 'Średnia liczba SKU w koszyku', value: 'uniq_prod_count_per_baskets' },
  { label: 'Średnia liczba koszyków w zamówieniu', value: 'avg_baskets_per_order' },
  { label: 'Udział kosztów wysyłki w zamówieniach', value: 'perc_shipings_per_ord_total' }
];

const ExpensesMonthly = ({ selectedCompanyId, filters }) => {
  const [chartData, setChartData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [topChartSelectedDataType, setTopChartSelectedDataType] = useState(topChartOptions[0]);
  const [bottomChartSelectedDataType, setBottomChartSelectedDataType] = useState(bottomChartOptions[0]);

  const getData = async () => {
    const queryData = {
      ...(!!filters.selectedCompanies && { company_ids: filters.selectedCompanies.map((company) => company.value) }),
      ...(selectedCompanyId && { company_id: selectedCompanyId }),
      ...(filters.startDate && filters.endDate && { date_from: filters.startDate, date_to: filters.endDate })
    };
    try {
      setIsLoading(true);
      const { data } = await StatisticsApi.getExpensesMonthly(queryData);
      setChartData(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [filters, selectedCompanyId]);

  const topChartConfig = useMemo(() => {
    const data = chartData ? chartData[topChartSelectedDataType.value] : [];
    let dataLabel = topChartSelectedDataType.label;
    let formatter;

    switch (topChartSelectedDataType.value) {
      case 'total_sum':
        formatter = (value) => getFormattedAmount(value);
        break;
      default:
        formatter = undefined;
        break;
    }

    return { data, dataLabel, formatter };
  }, [chartData, topChartSelectedDataType, selectedCompanyId]);

  const bottomChartConfig = useMemo(() => {
    const data = chartData ? chartData[bottomChartSelectedDataType.value] : [];
    let dataLabel = bottomChartSelectedDataType.label;
    let formatter;

    switch (bottomChartSelectedDataType.value) {
      case 'avg_ord_total_per_orders':
      case 'avg_ord_total_per_baskets': {
        formatter = (value) => getFormattedAmount(value);
        break;
      }
      case 'perc_shipings_per_ord_total': {
        formatter = (value) => +value.toFixed(2) + '%';
        break;
      }
      default: {
        formatter = (value) => Math.floor(value);
        break;
      }
    }

    return { data, dataLabel, formatter };
  }, [chartData, bottomChartSelectedDataType, selectedCompanyId]);

  console.log('chartData', chartData);

  return (
    <Card className={style.container}>
      <header className={style.header}>
        <h2 className={style.title}>Zamówienia</h2>
        <div className={style.options}>
          <ExportData
            apiCallback={StatisticsApi.exportExpensesMonthly}
            company_id={selectedCompanyId}
            filters={filters}
          />
        </div>
      </header>
      <div className={style.chartsWrapper}>
        <Chart
          dataLabelsFormatter={topChartConfig.formatter}
          yAxisFormatter={topChartConfig.formatter}
          title={'Dane'}
          configRefreshVariable={topChartConfig}
          categoriesFormatter={(value) => translateMonthsWithInfo(value, filters)}
          haveData={chartData?.have_data}
          categories={chartData?.months}
          chartTypes={['area', 'bar']}
          categoriesLabel={topChartSelectedDataType.label}
          data={topChartConfig.data}
          isLoading={isLoading}
          height={300}
          fullWidth
        >
          <Select
            onChange={setTopChartSelectedDataType}
            value={topChartSelectedDataType}
            wrapperStyle={style.select}
            options={topChartOptions}
            className={style.select}
            isSearchable={false}
            width={200}
          />
        </Chart>
        <Chart
          title={'Wskaźniki'}
          dataLabelsFormatter={bottomChartConfig.formatter}
          yAxisFormatter={bottomChartConfig.formatter}
          configRefreshVariable={bottomChartConfig}
          categoriesLabel={bottomChartSelectedDataType.label}
          categoriesFormatter={(value) => translateMonthsWithInfo(value, filters)}
          haveData={chartData?.have_data}
          categories={chartData?.months}
          data={bottomChartConfig.data}
          chartTypes={['area', 'bar']}
          isLoading={isLoading}
          height={300}
          fullWidth
        >
          <Select
            onChange={setBottomChartSelectedDataType}
            value={bottomChartSelectedDataType}
            options={bottomChartOptions}
            wrapperStyle={style.select}
            className={style.select}
            isSearchable={false}
            width={200}
          />
        </Chart>
      </div>
      <p>* - Dane na dzień {toDateNumber(filters.endDate)}r. Dane agregują się po odebraniu zamówienia.</p>
    </Card>
  );
};

export default ExpensesMonthly;
