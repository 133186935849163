import React from 'react';
import classnames from 'classnames';

import image from 'assets/img/product-thumbnail.png';
import { ProductStatus } from 'components';
import Radio from 'components/layout/Radio';

import shared from 'src/styles/custom/Shared.module.scss';
import { getFormattedAmount, imageErrorHandler } from 'src/utils/helpers';

import style from './SubstituteItem.module.scss';

const SubstituteItem = ({ product, selectedProduct, setSelectedProduct, quantity }) => {
  const { minPrice, maxPrice, availability } = product || {};

  return (
    <div
      className={classnames(style.container, {
        [style.selected]: product.product_id === selectedProduct?.product_id
      })}
      onClick={() => setSelectedProduct(product)}
    >
      <div className={style.wrapper}>
        <Radio value={product.product_id === selectedProduct?.product_id} />
      </div>
      <div className={style.wrapper}>
        <img
          src={product.thumbnail || image}
          alt='Miniatura przedmiotu'
          className={shared.defaultThumbnail}
          onError={imageErrorHandler}
        />
        <p className={style.text}>{product.name || 'produkt'}</p>
      </div>
      <div className={style.wrapper}>
        <p className={style.text}>
          <ProductStatus availability={availability} />
        </p>
      </div>
      <div className={style.wrapper}>
        <p className={style.text}>{`${getFormattedAmount(minPrice)} - ${getFormattedAmount(maxPrice)}`}</p>
      </div>
      <div className={style.wrapper}>
        <p className={style.text}>{quantity}</p>
      </div>
      <div className={style.wrapper}>
        <p className={style.text}>{`${getFormattedAmount(minPrice * quantity)} - ${getFormattedAmount(maxPrice * quantity)}`}</p>
      </div>
    </div>
  );
};

export default SubstituteItem;
