import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button, PageHeader, PaginationNav, SeoHelmet } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import minusIcon from 'src/assets/icons/minus.svg';
import pages from 'src/dictionaries/pages.json';
import WarehouseTable from 'src/features/Warehouse/components/WarehouseTable';
import query from 'src/utils/query';

import Filtering from './components/Filtering';
import ProductModal from './components/ProductModal';

import style from './Warehouse.module.scss';

export const modalTypes = {
  addNew: 'add_new',
  increase: 'increase',
  decrease: 'decrease'
};

const Warehouse = (props) => {
  const params = query(props);
  const [products, setProducts] = useState([]);
  const [states, setStates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [category, setCategory] = useState(params.get('category'));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [sortBy, setSortBy] = useState(params.get('sort_by'));
  const [sortOrder, setSortOrder] = useState(params.get('sort_order'));

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState();

  const reloadData = async () => {
    const queryParams = {
      page: currentPage || 1,
      perPage: perPage,
      search: search,
      currentPage: currentPage,
      ...(category && { category_id: [category] }),
      ...(sortBy && { sort_by: sortBy }),
      ...(sortOrder && { sort_order: sortOrder })
    };
    try {
      const { data } = await WarehouseApi.getWarehouseData(queryParams);
      setProducts(data.data.products);
      setPageQty(data?.last_page);
      setStates(
        data.data.states.map((el) => ({
          label: el.name,
          value: el.id,
          color: el.color
        }))
      );
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    reloadData().finally(() => {
      setIsLoading(false);
    });
  }, [search, currentPage, perPage, sortOrder, sortBy, category]);

  useEffect(() => {
    setCurrentPage(params.get('page'));
    setSearch(params.get('search'));
    setCategory(params.get('category'));
    setSortBy(params.get('sort_by'));
    setSortOrder(params.get('sort_order'));
  }, [params]);

  const handleOpenModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const handleReloadData = () => {
    setIsLoading(true);
    reloadData().finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      <SeoHelmet title={pages.warehouse.title} />
      <PageHeader
        name={pages.warehouse.title}
        className={style.header}
      >
        <Link to={'/warehouse/history'}>Historia magazynu</Link>
        <Button
          label='Dodaj produkt'
          onClick={() => handleOpenModal(modalTypes.addNew)}
          gray
        />
        <Button
          label='Zużycie'
          icon={minusIcon}
          onClick={() => handleOpenModal(modalTypes.decrease)}
        />
        <Button
          label='Dostawa'
          iconName={'add'}
          onClick={() => handleOpenModal(modalTypes.increase)}
        />
      </PageHeader>
      <div className={style.container}>
        <Filtering params={params} />
        <ProductModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          modalType={modalType}
          reloadData={handleReloadData}
        />
        <WarehouseTable
          params={params}
          list={products}
          isLoading={isLoading}
          states={states}
          reloadData={reloadData}
        />
        <PaginationNav
          params={params}
          onClick={(number) => setCurrentPage(number)}
          pagesQty={pageQty}
          setPerPage={setPerPage}
          defaultQty={perPage}
        />
      </div>
    </>
  );
};

export default Warehouse;
