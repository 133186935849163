import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { AsyncSelect, Button, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';

import { changeCompaniesVersion } from '../../actions';

import style from './MoveCompany.module.scss';

const MoveCompany = ({ closeModal, companyName, companyId }) => {
  const dispatch = useDispatch();
  const validator = useValidator();

  const [newCompany, setNewCompany] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const data = {
      moved_company_id: companyId,
      parent_company_id: newCompany?.value
    };

    try {
      setIsLoading(true);
      await AdminApi.moveCompany(data);
      dispatch(changeCompaniesVersion());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const producerLabel = (
    <p className={style.label}>
      Przenieś oddział
      <span className={style.producerName}>{companyName}</span>
      do:
    </p>
  );

  return (
    <form
      className={style.container}
      onSubmit={(e) => handleSubmit(e)}
    >
      <AsyncSelect
        id={'companies-select'}
        name={'companies-select'}
        value={newCompany}
        onChange={setNewCompany}
        apiCallback={AdminApi.getCompanies}
        valueKey={'id'}
        labelKey={'name'}
        label={producerLabel}
        placeholder={'np. 3M'}
        isClearable
        validator={validator}
        rule={'required'}
      />
      <Button
        label={'Przenieś'}
        type={'submit'}
        isLoading={isLoading}
        className={style.button}
      />
    </form>
  );
};

export default MoveCompany;
