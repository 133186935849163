import React, { useState } from 'react';
import { useParams } from 'react-router';

import { Button, FileDropzone, Input, QtySelector, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { orderStatuses } from 'src/constants/enums';
import { getDefaultInvoice } from 'src/features/Invoices/components/InvoiceForm';

import style from './SendOrder.module.scss';

const SendOrder = ({ getOrderData, status, orderValue }) => {
  const { uid } = useParams();
  const [tracking, setTracking] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [trackingUrl, setTrackingUrl] = useState('');
  const [invoice, setInvoice] = useState(getDefaultInvoice(orderValue));
  const validator = useValidator();

  const invoiceDataRule = invoice.invoice ? 'required' : '';

  const onChange = (e) =>
    setInvoice((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));

  const onChangeHandler = (name, data) => {
    setInvoice((prev) => ({
      ...prev,
      [name]: data
    }));
  };

  const completeOrder = async (e) => {
    e.preventDefault();

    if (!validator.allValid() && invoice.invoice) {
      return validator.showMessages();
    }

    const formData = new FormData();
    const invoiceData = {
      invoice: invoice.invoice,
      number: invoice.number,
      payment_date: invoice.payment_date,
      sum: invoice.sum,
      uid: uid,
      tracking_number: tracking,
      tracking_url: trackingUrl
    };
    for (const [key, value] of Object.entries(invoiceData)) {
      if (value) formData.append(key, value);
    }

    try {
      setIsLoading(true);
      await SupplierApi.finalizeOrder(formData);
      await getOrderData();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (status !== orderStatuses.processing) {
    return null;
  }

  return (
    <form
      className={style.container}
      onSubmit={completeOrder}
    >
      <h2>Realizacjia zamówienia</h2>
      <div className={style.column}>
        <div className={style.row}>
          <Input
            name={'tracking-number'}
            value={tracking}
            onChange={(e) => setTracking(e.target.value)}
            placeholder={'6231918009432'}
            label={'Numer listu przewozowego'}
            id={'tracking-number'}
          />
          <Input
            name={'tracing-url'}
            value={trackingUrl}
            onChange={(e) => setTrackingUrl(e.target.value)}
            placeholder={'https://www.ups.com/track?loc=pl_PL'}
            label={'Link do śledzenia przesyłki'}
            id={'tracing-url'}
          />
        </div>
        <FileDropzone
          label={'Plik z fakturą'}
          onChange={(file) => onChangeHandler('invoice', file)}
          value={invoice.invoice}
          validator={validator}
          rule={invoiceDataRule}
        />
        {invoice.invoice && (
          <div className={style.row}>
            <Input
              id='number'
              name='number'
              label='Numer faktury'
              value={invoice.number}
              onChange={onChange}
              validator={validator}
              rule={invoiceDataRule}
            />
            <div className={style.row}>
              <Input
                type='date'
                id='payment_date'
                name='payment_date'
                label='Termin płatności'
                value={invoice.payment_date}
                onChange={onChange}
                validator={validator}
                rule={invoiceDataRule}
              />
              <QtySelector
                id='sum'
                name='sum'
                label='Wartość faktury'
                state={invoice.sum}
                setState={(sum) => onChangeHandler('sum', sum)}
                wrapperStyle={style.sum}
                suffix={'zł'}
                priceInput
                hideArrows
                validator={validator}
                rule={invoiceDataRule ? `${invoiceDataRule}|only_positive` : ''}
              />
            </div>
          </div>
        )}
        <div className={style.buttonWrapper}>
          <Button
            label={'Potwierdź wysłanie zamówienia'}
            isLoading={isLoading}
            type={'submit'}
          />
        </div>
      </div>
    </form>
  );
};

export default SendOrder;
