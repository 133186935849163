import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Input, QtySelector, SwitchButton } from 'components';

import { addSupplierData } from 'src/features/ProductsBase/actions';

import style from './SupplierRow.module.scss';

const SupplierRow = ({ supplier }) => {
  const { name, link: oldLink, price: oldPrice, id, variant_ids, price_locked, availability_locked } = supplier || {};
  const [price, setPrice] = useState(Number(oldPrice) ? oldPrice : null);
  const [link, setLink] = useState(oldLink);
  const [variantID, setVariantID] = useState(variant_ids);
  const [priceLocked, setPriceLocked] = useState(price_locked);
  const [availabilityLocked, setAvailabilityLocked] = useState(availability_locked);
  const dispatch = useDispatch();

  useEffect(() => {
    if (oldLink !== link || oldPrice !== price) {
      dispatch(
        addSupplierData({
          supplier_id: id,
          link,
          price,
          variant_ids: variantID,
          price_locked: priceLocked,
          availability_locked: availabilityLocked
        })
      );
    }
  }, [link, oldLink, price, oldPrice, priceLocked, availabilityLocked, variantID]);

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <p className={style.text}>{name}</p>
      </div>
      <div className={style.wrapper}>
        <Input
          value={link}
          onChange={(e) => setLink(e.target.value)}
          placeholder={'Link do produktu'}
        />
      </div>
      <div className={style.wrapper}>
        <QtySelector
          wrapperStyle={style.qtySelector}
          setState={setPrice}
          state={price}
          suffix={'zł'}
          decimals={2}
          width={100}
          priceInput
          hideArrows
        />
      </div>
      <div className={style.wrapper}>
        <Input
          value={variantID}
          onChange={(e) => setVariantID(e.target.value)}
        />
      </div>
      <div className={style.wrapper}>
        <SwitchButton
          setValue={setAvailabilityLocked}
          value={availabilityLocked}
        />
      </div>
      <div className={style.wrapper}>
        <SwitchButton
          setValue={setPriceLocked}
          value={priceLocked}
        />
      </div>
      {/*<div className={style.wrapper}>*/}
      {/*  {updated_at && (*/}
      {/*    <p className={style.text}>*/}
      {/*      {`${toDate(updated_at)}, ${toTime(updated_at)}`}*/}
      {/*    </p>*/}
      {/*  )}*/}
      {/*</div>*/}
    </div>
  );
};

export default SupplierRow;
