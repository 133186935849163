import React from 'react';
import classNames from 'classnames';

import { useIsMobile } from 'components';

import { getFormattedAmount, getGiftsValue } from 'src/utils/helpers';

import GiftThumbnail from './components/GiftThumbnail';

import style from './Gifts.module.scss';
import shared from 'styles/custom/Shared.module.scss';

const Gifts = ({ gifts = [], className }) => {
  const isMobile = useIsMobile(768);
  return (
    <section className={classNames(shared.tableWrapper, style.wrapper, className)}>
      <div className={classNames(shared.tableHeader, style.tableHeader)}>
        <p>Nazwa</p>
        <p>Producent</p>
        <p>Ilość</p>
        <p>Wartość</p>
      </div>
      {gifts.map((gift, index) => (
        <GiftThumbnail
          key={gift.name + index}
          gift={gift}
        />
      ))}
      <div className={style.giftsSummary}>
        <p className={style.sum}>
          {isMobile && 'Suma wartości: '}
          {getFormattedAmount(getGiftsValue(gifts))}
        </p>
      </div>
    </section>
  );
};

export default Gifts;
