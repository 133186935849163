import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { PhoneInput, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { Button, Input } from 'src/components';
import { registerNewMember } from 'src/features/onboarding/actions';

import style from '../../../../onboarding.module.scss';

const MemberRegisterForm = ({ email, token: invitation_token }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const validator = useValidator();

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [secondPassword, setSecondPassword] = useState('');
  const [phone, setPhone] = useState('');

  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const userData = {
      invitation_token,
      password,
      phone,
      name
    };

    try {
      setLoading(true);
      await dispatch(registerNewMember(userData));
      history.push('/dashboard');
    } catch (err) {
      notifyApiError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      className={style.card}
      onSubmit={(e) => handleSubmit(e)}
    >
      <h2 className={style.formLabel}>Dołącz do zespołu</h2>
      <Input
        id='email'
        name='email'
        placeholder='Adres e-mail'
        type='email'
        value={email}
        disabled
      />
      <Input
        id='name'
        name='name'
        placeholder='Imię i nazwisko'
        value={name}
        onChange={(e) => setName(e.target.value)}
        validator={validator}
        rule={'required'}
      />
      <PhoneInput
        id='phone'
        name='phone'
        placeholder='Numer telefonu'
        value={phone}
        onChange={setPhone}
        validator={validator}
        rule={'required|phone'}
      />
      <Input
        id='password'
        name='password'
        placeholder='Hasło'
        type='password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        validator={validator}
        rule={'required|min_password'}
      />
      <Input
        id='repeatPassword'
        name='repeatPassword'
        placeholder='Powtórz hasło'
        type='password'
        value={secondPassword}
        onChange={(e) => setSecondPassword(e.target.value)}
        validator={validator}
        rule={`required|min_password|in:${password}`}
      />
      <div className={cn(style.buttons, style.center)}>
        <Button
          type='submit'
          label={'Utwórz konto'}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default MemberRegisterForm;
