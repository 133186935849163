import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import style from './Navigation.module.scss';

export default function Navigation({ supplierForm, items, isMobile }) {
  return (
    <nav
      className={cn(style.container, {
        [style.containerMobile]: isMobile
      })}
    >
      {!supplierForm &&
        items.map(({ label, pathname, external }) =>
          external ? (
            <a
              href={pathname}
              key={label}
              className={cn(style.link, {
                [style.linkMobile]: isMobile
              })}
            >
              {label}
            </a>
          ) : (
            <NavLink
              to={pathname}
              key={label}
              className={cn(style.link, {
                [style.linkMobile]: isMobile
              })}
            >
              {label}
            </NavLink>
          )
        )}
    </nav>
  );
}
