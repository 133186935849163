import React from 'react';
import classNames from 'classnames';

import { OrderOrValuationId, Tag, Thumbnail, useIsMobile } from 'components';

import shared from 'src/styles/custom/Shared.module.scss';
import { getApprovalStatusTag, getApprovalUpdatedAt } from 'src/utils/helpers';

import style from './ApprovalThumbnail.module.scss';

const ApprovalThumbnail = ({ item: approval, small = false }) => {
  const isMobile = useIsMobile();

  return (
    <Thumbnail
      item={approval}
      namePrefix={`Akceptacja zamówienia #${approval?.public_id}`}
      link={`/orders-approvals/${approval.token}`}
      small={small || isMobile}
      extraInfo={
        <>
          {approval.updated_at ? (
            <p>{getApprovalUpdatedAt(approval)}</p>
          ) : (
            <OrderOrValuationId
              id={approval?.public_id}
              withPrefix={'Numer:'}
            />
          )}
        </>
      }
      showTime
      hideOwner
      status={<div className={shared.thumbnailContentWrapper}>{getApprovalStatusTag({ status: approval.status })}</div>}
    >
      <div className={classNames(style.container, { [style.small]: small })}>
        <div className={shared.thumbnailContentWrapper}>
          <p>Osoba akceptująca:</p>
          <Tag value={approval.approval_email} />
        </div>
      </div>
    </Thumbnail>
  );
};

export default ApprovalThumbnail;
