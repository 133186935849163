import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import {
  OrderOrValuationId,
  SuppliersList,
  Tag,
  Thumbnail,
  useIsMobile,
  useModalConfirm,
  useUnreadNotifications
} from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OrdersApi } from 'src/api';
import { orderStatuses } from 'src/constants/enums';
import { ordersNtfTypes } from 'src/constants/notifications/types';
import { changeOrdersVersion } from 'src/features/Orders/actions';
import shared from 'src/styles/custom/Shared.module.scss';
import { getFormattedAmount, getOrderStatusTag } from 'src/utils/helpers';

import OrderApproval from '../OrderApproval';

import style from './OrderThumbnail.module.scss';

const statuses = ['DRAFT', 'WAITING_FOR_APPROVAL', 'ORDERED', 'PENDING', 'PROCESSING', 'SENT', 'COMPLETED', 'CANCELLED'];

const OrderThumbnail = ({ item: order, small = false }) => {
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const unreadOrdersNtfs = useUnreadNotifications(ordersNtfTypes);

  const cancelOrder = async () => {
    try {
      await OrdersApi.cancelOrder({ uid: order.uid });
      dispatch(changeOrdersVersion());
      notifyCommon([`Zamówienie ${order.name} została anulowane.`]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleCancelOrder = () => {
    handleOpenModalConfirm({
      message: `Czy na pewno chcesz anulować zamówienie ${order.name}?`,
      handleConfirm: cancelOrder,
      cancelButtonName: 'Nie',
      confirmButtonName: 'Tak'
    });
  };

  const actions = [
    {
      title: 'Anuluj zamówienie',
      icon: 'close',
      color: 'red',
      action: handleCancelOrder,
      disabled: order.status === orderStatuses.cancelled
    }
  ];

  return (
    <Thumbnail
      item={order}
      menu={actions}
      notificationCount={unreadOrdersNtfs?.filter((ntf) => ntf?.data?.uid === order?.uid).length}
      link={`/orders/${order.uid}`}
      small={small || isMobile}
      extraInfo={
        !small && (
          <OrderOrValuationId
            id={order?.public_id}
            withPrefix={'Numer:'}
            noPadding
          />
        )
      }
      showTime
      status={
        <div className={shared.thumbnailContentWrapper}>
          {getOrderStatusTag({ status: order.status, wholeOrderStatus: true })}
          <OrderApproval
            orderApprovalData={order.order_approval}
            orderStatus={order.status}
          />
          <Tag
            value={getFormattedAmount(order.amount)}
            title={'Wartość zamówienia'}
            className={shared.thumbnailTag}
          />
          {renderedModalConfirm}
        </div>
      }
    >
      {!small && (
        <div className={classNames(style.container, { [style.small]: small })}>
          <SuppliersList
            uid={order.uid}
            suppliers={order.suppliers}
            statusesList={statuses}
          />
        </div>
      )}
    </Thumbnail>
  );
};

export default OrderThumbnail;
