import throttle from 'lodash/throttle';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import rootReducer from '../reducers';
import { setLocalStorageItem, setSessionStorageItem } from '../utils/storage';
import { loadState } from './localStorage';
import { checkIfTokensRemembered } from './localStorage';

const persistedState = loadState();

const store = createStore(rootReducer, persistedState, composeWithDevTools(applyMiddleware(thunk)));

store.subscribe(
  throttle(() => {
    const state = store.getState();

    const { access, selectedCompanyId } = state.auth;

    const rememberMe = checkIfTokensRemembered();

    if (rememberMe) {
      setLocalStorageItem('access', access);
      setLocalStorageItem('selectedCompanyId', selectedCompanyId);
    } else {
      setSessionStorageItem('access', access);
      setSessionStorageItem('selectedCompanyId', selectedCompanyId);
    }
  })
);

export default store;
