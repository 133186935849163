import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import DropzoneArea from './components/DropzoneArea';
import List from './components/List';
import ProductDetails from './components/ProductDetails';

import style from './SuppliersList.module.scss';

const SuppliersList = ({ product }) => {
  const [isDragged, setIsDragged] = useState(false);

  if (!product) {
    return null;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={style.container}>
        <ProductDetails product={product} />
        <p className={style.description}>
          Aby ustawić ofertę jako <strong>preferowaną</strong> lub <strong>alternatywną</strong>, przeciągnij element z listy na
          odpowiednie pole. Przypisane oferty będą brane pod uwagę podczas składania zamówienia.
        </p>
        <DropzoneArea
          product={product}
          isDragged={isDragged}
          setIsDragged={setIsDragged}
        />
        <List
          product={product}
          setIsDragged={setIsDragged}
        />
      </div>
    </DndProvider>
  );
};

export default SuppliersList;
