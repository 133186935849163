import React, { useState } from 'react';

import { AsyncSelect, useAuthUser, useValidator } from 'components';
import { Button } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import { userRoles } from 'src/constants/enums';

import style from './MoveElementForm.module.scss';

const MoveElementForm = ({ closeModal, uid, apiCallback, buttonLabel, refreshCallback }) => {
  const user = useAuthUser();

  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const validator = useValidator();

  if (user?.role !== userRoles.admin) {
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsLoading(true);
      await apiCallback({ uid, company_id: selectedUser.id });
      refreshCallback();
      notifyCommon([`Element został przeniesiony do firmy ${selectedUser.label}`]);
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={handleSubmit}
    >
      <AsyncSelect
        value={selectedUser}
        onChange={setSelectedUser}
        apiCallback={AdminApi.getCompanies}
        className={style.select}
        valueKey={'id'}
        labelKey={'name'}
        placeholder={'Firma'}
        label={'Nazwa firmy'}
        isClearable
        validator={validator}
        rule={'required'}
      />
      <Button
        label={buttonLabel}
        title={buttonLabel}
        type={'submit'}
        isLoading={isLoading}
      />
    </form>
  );
};

export default MoveElementForm;
