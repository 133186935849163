import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { Button, Icon, Menu, OutsideClickHandler, useRequestAbortController } from 'components';
import List from 'components/layout/Header/Notification/components/List';
import { notifyApiError } from 'components/layout/Toasts';

import { UserApi } from 'src/api';
import {
  resetNotifications,
  setAllNotificationsAsRead,
  setOldNotifications as setOldNotificationsAction
} from 'src/features/NotificationsStore/actions';

import styles from './Notification.module.sass';

const QUERY_LIMIT = 30;

const Notification = ({ className }) => {
  const containerRef = useRef();
  const dispatch = useDispatch();
  const [total, setTotal] = useState();
  const [visible, setVisible] = useState(false);
  const [oldNotifications, setOldNotifications] = useState([]);
  const notifications = useSelector((state) => state.notifications.notifications);
  const version = useSelector((state) => state.notifications.version);
  const [abortController, setNewController] = useRequestAbortController();

  const containerStyles = cn(styles.notification, className, {
    [styles.active]: visible
  });

  const getNotifications = async () => {
    const queryParams = {
      page: 1,
      perPage: QUERY_LIMIT
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      const { data } = await UserApi.getNotifications(queryParams, signal);
      setOldNotifications(data.data.map((ntf) => ({ ...ntf, ...ntf.data })));
      dispatch(setOldNotificationsAction(data.data.map((ntf) => ({ ...ntf, ...ntf.data }))));
      dispatch(resetNotifications());
      setTotal(data.total);
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    getNotifications();
  }, [version]);

  useEffect(() => {
    if (visible && containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [visible, containerRef]);

  const hasUnread = useMemo(
    () => [...notifications.filter((ntf) => !ntf.read_at), ...oldNotifications.filter((ntf) => !ntf.read_at)].length > 0,
    [notifications, oldNotifications]
  );

  const handleRead = () => {
    getNotifications();
  };

  const handleReadAllNotifications = async () => {
    try {
      await UserApi.setAllNotificationsAsRead();
      dispatch(setAllNotificationsAsRead());
      getNotifications();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const actions = [
    {
      title: 'Oznacz jako przeczytane',
      icon: 'check',
      action: handleReadAllNotifications
    }
  ];

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
        <div className={containerStyles}>
          <button
            className={cn(styles.head, { [styles.active]: hasUnread })}
            onClick={() => setVisible(!visible)}
          >
            <Icon
              name='notification'
              size='24'
            />
          </button>
          <div
            className={styles.body}
            ref={containerRef}
          >
            <div className={styles.titleWrapper}>
              <h2 className={styles.title}>Powiadomienia</h2>
              <Menu
                actions={actions}
                className={styles.menu}
              />
            </div>
            <div className={styles.listWrapper}>
              <List
                title={'Nowe'}
                notifications={notifications}
                closeMenu={() => setVisible(false)}
                hideList={notifications.length === 0}
              />
              <List
                title={'Wcześniejsze'}
                notifications={oldNotifications}
                handleRead={handleRead}
                closeMenu={() => setVisible(false)}
              />
            </div>
            {total - QUERY_LIMIT > 0 && <p className={styles.info}>...oraz {total - QUERY_LIMIT} innych.</p>}
            <Button
              label={'Otwórz centrum powiadomień'}
              to={'/profile/notifications'}
              onClick={() => setVisible(false)}
              className={styles.bottomButton}
            />
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default Notification;
