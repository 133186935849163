import React from 'react';
import classNames from 'classnames';
import cn from 'classnames';

import { EmptyState, useRecentlyOrderedProducts } from 'components';

import ProductWithAllSuppliers from '../Product/containers/ProductWithAllSuppliers';
import ProductWithSelectedSuppliers from '../Product/containers/ProductWithSelectedSuppliers';

import style from '../../OfferCatalog.module.scss';
import shared from 'styles/custom/Shared.module.scss';

const ProductsList = ({
  pageData,
  productClasses,
  changeValuationSelections,
  status,
  showRefPrice,
  selectedCategories = [],
  selectedCompany,
  isCatalogForCorporations,
  isQuietLoading,
  type,
  listUID
}) => {
  if (pageData?.categories.length === 0) {
    return (
      <section className={classNames(shared.tableWrapper, style.tableWrapper, { [style.elastic]: isCatalogForCorporations })}>
        <EmptyState type={'noPrices'} />
      </section>
    );
  }

  const recentlyOrdered = useRecentlyOrderedProducts(listUID);
  const ProductComponent = isCatalogForCorporations ? ProductWithSelectedSuppliers : ProductWithAllSuppliers;

  return (
    <div className={style.leftColumn}>
      <section
        className={classNames(shared.tableWrapper, style.tableWrapper, { [style.elastic]: !isCatalogForCorporations })}
        id={'valuation-table'}
      >
        {isCatalogForCorporations && (
          <div className={cn(style.tableHeader, { [style.withRefPrices]: showRefPrice })}>
            <p className={style.supplierLabel}>Produkt</p>
            {showRefPrice && <p className={style.supplierLabel}>Cena referencyjna</p>}
            <p className={style.supplierLabel}>Oferta preferowana</p>
            <p className={style.supplierLabel}>Oferta alternatywna</p>
            <p className={style.supplierLabel}>Wszystkie oferty</p>
          </div>
        )}
        <div className={cn(style.box, { [style.loadingAnimation]: isQuietLoading })}>
          {pageData?.categories.map((category) => {
            if (
              selectedCategories.length === 0 ||
              (selectedCategories.length > 0 && selectedCategories.find((el) => el.value === category.id))
            ) {
              return category.products?.map((product) => (
                <ProductComponent
                  recentlyOrdered={recentlyOrdered.find((prod) => prod.product_id === product.product_id)}
                  product={product}
                  key={product.id}
                  className={productClasses}
                  changeValuationSelections={changeValuationSelections}
                  status={status}
                  showRefPrice={showRefPrice}
                  selectedCompany={selectedCompany}
                  type={type}
                />
              ));
            } else {
              return null;
            }
          })}
        </div>
      </section>
    </div>
  );
};

export default ProductsList;
