import React from 'react';
import classNames from 'classnames';

import { EmptyState } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import DistributorItem from 'src/features/Distributors/components/DistributorItem';
import shared from 'src/styles/custom/Shared.module.scss';

import style from './DistributorsTable.module.scss';

const DistributorsTable = ({ list, isLoading }) => {
  if (isLoading) {
    return (
      <section className={shared.tableWrapper}>
        <div className={classNames(shared.tableHeader, style.header)}>
          <p>Nazwa</p>
          <p>E-mail</p>
          <p>Telefon</p>
        </div>
        <div className={shared.tableCategory}>
          <ProductPlaceholder quantity={6} />
        </div>
      </section>
    );
  }

  return (
    <section className={shared.tableWrapper}>
      <div className={classNames(shared.tableHeader, style.header)}>
        <p>Nazwa</p>
        <p>E-mail</p>
        <p>Telefon</p>
      </div>
      <div className={shared.tableCategory}>
        {list.length ? (
          list.map((sup) => (
            <DistributorItem
              key={sup.id}
              supplier={sup}
            />
          ))
        ) : (
          <div className={shared.tableCategory}>
            <h3 className={shared.tableCategoryTitle}>
              <EmptyState type={'distrubutors'} />
            </h3>
          </div>
        )}
      </div>
    </section>
  );
};

export default DistributorsTable;
