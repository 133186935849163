import React from 'react';
import { Placeholder } from 'semantic-ui-react';

import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import shared from 'src/styles/custom/Shared.module.scss';

import style from './ProductsSectionsPlaceholder.module.scss';

const ProductsSectionsPlaceholder = () => {
  return (
    <>
      <div className={shared.tableCategory}>
        <h3 className={shared.tableCategoryTitle}>
          <Placeholder>
            <Placeholder.Line length={'long'} />
          </Placeholder>
        </h3>
        <ProductPlaceholder quantity={5} />
      </div>
      <div className={shared.tableCategory}>
        <h3 className={shared.tableCategoryTitle}>
          <Placeholder>
            <Placeholder.Line length={'long'} />
          </Placeholder>
        </h3>
        <ProductPlaceholder quantity={8} />
      </div>
    </>
  );
};

export default ProductsSectionsPlaceholder;
