import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

import ChartWrapper from 'components/layout/ChartWrapper';

import { getFormattedAmount } from 'src/utils/helpers';

import { mockMonths, mockValues } from './mock';

const months = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień'
];

const LineChartComponent = ({ data = [], seriesKey = 'price', categoriesFn }) => {
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    setIsEmpty(!data.length);
  }, [data]);

  const series = [
    {
      name: 'Kwota',
      data: isEmpty ? mockValues : data?.map((item) => item[seriesKey])
    }
  ];

  const options = {
    chart: {
      height: 300,
      type: 'line',
      fontFamily: 'Lato, sans-serif',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      colors: ['#4849FF'],
      width: 2
    },
    markers: {
      colors: '#A9C1FD',
      size: 5,
      strokeWidth: 3,
      sizeOffset: 10,
      shape: 'circle',
      hover: {
        size: 6,
        sizeOffset: 3
      }
    },
    title: {
      text: 'Wydatki w ujęciu miesięcznym',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return getFormattedAmount(value);
        }
      }
    },
    xaxis: {
      categories: categoriesFn ? categoriesFn(data) : isEmpty ? mockMonths : data?.map((item) => months[parseInt(item.month) - 1])
    }
  };

  if (series && options && data) {
    return (
      <ChartWrapper isEmpty={isEmpty}>
        <ReactApexChart
          options={options}
          series={series}
          type='line'
          height={330}
        />
      </ChartWrapper>
    );
  }
  return null;
};

export default LineChartComponent;
