import React, { useEffect, useState } from 'react';

import { Chart } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { StatisticsApi } from 'src/api';
import { getShortTime } from 'src/utils/dateTime';

import ExportData from '../ExportData';

const defaultData = [
  {
    name: 'Średni czas do otrzymania zamówienia',
    data: []
  },
  {
    name: 'Średni czas obsługi zamówienia',
    data: []
  }
];

const TimeOfResponseBySupplier = ({ selectedCompanyId, filters }) => {
  const [chartData, setChartData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    const queryData = {
      ...(!!filters.selectedCompanies && { company_ids: filters.selectedCompanies.map((company) => company.value) }),
      ...(selectedCompanyId && { company_id: selectedCompanyId }),
      ...(filters.startDate && filters.endDate && { date_from: filters.startDate, date_to: filters.endDate })
    };
    try {
      setIsLoading(true);
      const { data } = await StatisticsApi.getAvgActionsTimesByOrder(queryData);
      setChartData(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [filters, selectedCompanyId]);

  const chartHeight = Math.max((chartData?.categories?.length || 0) * 50, 350);
  const colors = ['#F9D370', '#6AF1C0'];

  return (
    <Chart
      customSeries={chartData?.series || defaultData}
      categories={chartData?.categories}
      dataLabelsFormatter={getShortTime}
      title={'Średni czas reakcji dostawców'}
      haveData={chartData?.have_data}
      emptyStateType={'avgSupplierTimeChart'}
      tooltipFormatter={getShortTime}
      xAxisFormatter={getShortTime}
      isLoading={isLoading}
      chartTypes={['bar']}
      height={chartHeight}
      colors={colors}
      tooltipShared
      horizontal
      fullWidth
      stacked
    >
      <ExportData
        company_id={selectedCompanyId}
        filters={filters}
        apiCallback={StatisticsApi.exportAvgActionsTimesByOrder}
      >
        <ExportData
          company_id={selectedCompanyId}
          filters={filters}
          apiCallback={StatisticsApi.exportActionsTimesByOrder}
          labels={{ XLSX: 'Pobierz z podziałem na zamówiania (.xlsx)' }}
          formats={['XLSX']}
          asButton
        />
      </ExportData>
    </Chart>
  );
};

export default TimeOfResponseBySupplier;
