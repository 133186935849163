import * as Sentry from '@sentry/react';
import axios from 'axios';
import qs from 'qs';

import store from 'src/store/configureStore';

import { apiErrorHandler, isNetworkError, throttledNotifyNetworkError } from './utils';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en',
    app_version: process.env.REACT_APP_ENV_VERSION
  },
  __tokenRequired: true
});

const publicClient = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en'
  },
  __tokenRequired: true
});

client.interceptors.request.use((config) => {
  const { selectedCompanyId, adminId, access } = store.getState().auth;
  if (config.__tokenRequired) config.headers.Authorization = `Bearer ${access}`;
  if (adminId) config.headers.isAdmin = adminId;
  config.headers.CurrentCompany = selectedCompanyId;

  return config;
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.message !== 'canceled') {
      const errorMessage = apiErrorHandler(error);
      Sentry.captureMessage(errorMessage[0]);
    }
    if (isNetworkError(error)) {
      throttledNotifyNetworkError();
    }
    return Promise.reject(error);
  }
);

class ApiClient {
  static get(url, params, customConfigs) {
    const configs = {
      params,
      paramsSerializer: qs.stringify,
      ...customConfigs
    };
    return client.get(url, configs);
  }
  static post(url, data, customConfigs) {
    return client.post(url, data, customConfigs);
  }
  static put(url, data, customConfigs) {
    return client.put(url, data, customConfigs);
  }
  static patch(url, data, customConfigs) {
    return client.patch(url, data, customConfigs);
  }
  static delete(url, params, customConfigs) {
    const configs = {
      params,
      paramsSerializer: qs.stringify,
      ...customConfigs
    };
    return client.delete(url, configs);
  }
}

export class PublicApiClient {
  static get(url, params, customConfigs) {
    const configs = { params, ...customConfigs };
    return publicClient.get(url, configs);
  }
  static post(url, data, customConfigs) {
    return publicClient.post(url, data, customConfigs);
  }
  static put(url, data, customConfigs) {
    return publicClient.put(url, data, customConfigs);
  }
  static patch(url, data, customConfigs) {
    return publicClient.patch(url, data, customConfigs);
  }
  static delete(url, customConfigs) {
    return publicClient.delete(url, customConfigs);
  }
}

export default ApiClient;
