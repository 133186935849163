import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';

import { Accordion, Checkbox, useIsMobile } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { orderProductTypes, orderStatuses } from 'src/constants/enums';
import { getProductStatusTag } from 'src/utils/helpers';

import OrderListProduct from '../../../OrderListProduct';

import style from '../../OrderCart.module.scss';
import shared from 'styles/custom/Shared.module.scss';

const ProductTable = (props) => {
  const { token, refreshDataCallback, setSelectedProducts, selectedProducts, status, products, hideProductActions } = props;
  const isLgScreen = useIsMobile(1500);
  const productsSection = useRef();

  const handleSaveQty = async (product, finally_quantity) => {
    const query = { token, products: [{ id: product.id, quantity: finally_quantity }] };
    try {
      await SupplierApi.acceptProductQuantity(query);
      await refreshDataCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const getIsSelected = (item) => selectedProducts.filter((product) => product.id === item.id)?.length > 0;
  const changeItemSelection = (item) => {
    setSelectedProducts((prev) => (getIsSelected(item) ? prev.filter((product) => product.id !== item.id) : [...prev, item]));
  };
  const selectAll = () => {
    if (selectedProducts?.length === products?.length) setSelectedProducts([]);
    else setSelectedProducts(products);
  };

  const showCheckbox = useCallback(
    (type) =>
      (type === orderProductTypes.user && status === orderStatuses.sent) ||
      (type === orderProductTypes.supplier && status === orderStatuses.pending),
    [status]
  );

  const productContent = (product, type) => (
    <div
      className={classNames(style.productCheckboxWrapper, {
        [style.flex]: !showCheckbox(type)
      })}
    >
      {getProductStatusTag(product.status, false, style.tag)}
      {showCheckbox(type) && (
        <Checkbox
          value={getIsSelected(product)}
          onChange={() => changeItemSelection(product)}
        />
      )}
    </div>
  );

  const checkbox = (
    <div className={style.checkboxWrapper}>
      {!isLgScreen && <p className={style.checkboxText}>Status</p>}
      <Checkbox
        content={isLgScreen ? 'Zaznacz wszystko' : null}
        value={selectedProducts?.length === products?.length}
        onChange={selectAll}
        reverse
      />
    </div>
  );

  const title = (
    <h3
      className={classNames(shared.tableCategoryTitle, shared.large, shared.noPadding, {
        [style.sectionTitle]: !hideProductActions
      })}
    >
      Lista produktów
    </h3>
  );
  const description = (
    <p className={style.description}>
      Zaznacz produkty, które jesteś w stanie zrealizować. Pozostałe produkty zostaną anulowane.
    </p>
  );

  return (
    <Accordion
      section
      title={title}
      description={!hideProductActions && description}
      withoutGap
    >
      <section
        ref={productsSection}
        className={style.container}
      >
        <div className={classNames(shared.tableHeader, style.header, { [style.withMenu]: !hideProductActions })}>
          <p>Lp.</p>
          <p>Nazwa</p>
          <p>Producent</p>
          <p>Ilość</p>
          <p>Cena za szt.</p>
          <p>Wartość</p>
          {showCheckbox(orderProductTypes.supplier) ? checkbox : <p>Status</p>}
        </div>
        {products?.length ? (
          products?.map((product, index) => (
            <OrderListProduct
              displayOver
              index={index + 1}
              status={status}
              key={product.id}
              product={product}
              type={orderProductTypes.supplier}
              isSelected={getIsSelected(product)}
              hideProductActions={hideProductActions}
              refreshDataCallback={refreshDataCallback}
              onQtyChange={(qty) => handleSaveQty(product, qty)}
              onSelectChange={() => changeItemSelection(product)}
            >
              {productContent(product, orderProductTypes.supplier)}
            </OrderListProduct>
          ))
        ) : (
          <div className={shared.tableCategory}>
            <h3 className={shared.tableCategoryTitle}>Brak produktów na liście</h3>
          </div>
        )}
      </section>
    </Accordion>
  );
};

export default ProductTable;
