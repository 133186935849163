import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

//icons
import { ReactComponent as AnalyticsIcon } from 'assets/icons/newSidebar/analytics.svg';
import { ReactComponent as ApprovalIcon } from 'assets/icons/newSidebar/approval.svg';
import { ReactComponent as CategoryIcon } from 'assets/icons/newSidebar/categories.svg';
import { ReactComponent as CompaniesIcon } from 'assets/icons/newSidebar/companies.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/newSidebar/dashboard.svg';
import { ReactComponent as InvoiceIcon } from 'assets/icons/newSidebar/file-invoice.svg';
import { ReactComponent as FormIcon } from 'assets/icons/newSidebar/form.svg';
import { ReactComponent as ListsIcon } from 'assets/icons/newSidebar/lists.svg';
import { ReactComponent as OffersIcon } from 'assets/icons/newSidebar/offers.svg';
import { ReactComponent as OrdersIcon } from 'assets/icons/newSidebar/orders.svg';
import { ReactComponent as ProducersIcon } from 'assets/icons/newSidebar/producers.svg';
import { ReactComponent as ProductsIcon } from 'assets/icons/newSidebar/products.svg';
import { ReactComponent as SuppliersIcon } from 'assets/icons/newSidebar/suppliers.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/newSidebar/users.svg';
import { ReactComponent as ValuationIcon } from 'assets/icons/newSidebar/valuation.svg';
import { ReactComponent as WarehouseIcon } from 'assets/icons/newSidebar/warehouse.svg';

import { userPermissions, userRoles } from 'src/constants/enums';
import { ordersNtfTypes, supplierOrdersNtfTypes, valuationsNtfTypes } from 'src/constants/notifications/types';
import pages from 'src/dictionaries/pages.json';
import { offerCatalogEnabled, suppliersManagementEnabled } from 'src/utils/companyAccessFunctions';

const { lists, valuations, orders, analytics, warehouse, offer_catalog, invoices, suppliers, products } = userPermissions;
const { user, admin, distributor, supplier } = userRoles;

const UseNavigation = () => {
  const notificationsStore = useSelector((state) => state.notifications);

  return useMemo(() => {
    const { notifications, oldNotifications } = notificationsStore;
    const ntfs = [...notifications, ...oldNotifications];
    const filtered = ntfs.filter((ntf) => !ntf.read_at);

    const ordersNtfs = filtered.filter((ntf) => ordersNtfTypes.includes(ntf.type));
    const supplierOrdersNtfs = filtered.filter((ntf) => supplierOrdersNtfTypes.includes(ntf.type));
    const valuationsNtfs = filtered.filter((ntf) => valuationsNtfTypes.includes(ntf.type));

    return [
      {
        title: 'Dentametr',
        dropdown: [
          {
            title: pages.dashboard.title,
            url: '/dashboard',
            icon: <DashboardIcon />,
            roles: [user, admin, supplier, distributor]
          },
          {
            title: pages.suppliersManagement.title,
            url: '/suppliers-management',
            icon: <UsersIcon />,
            roles: [user],
            companyAccessFn: suppliersManagementEnabled
          }
        ]
      },
      {
        title: 'Obsługa zakupów',
        dropdown: [
          {
            title: pages.valuations.title,
            url: '/supplier/valuations',
            icon: <ListsIcon />,
            roles: [supplier]
          },
          {
            title: pages.orders.title,
            url: '/supplier/orders',
            icon: <OrdersIcon />,
            roles: [supplier],
            count: supplierOrdersNtfs.length
          },
          {
            title: pages.lists.title,
            url: '/lists',
            icon: <ListsIcon />,
            roles: [user, admin],
            requiredPermission: lists.read
          },
          {
            title: pages.orders.title,
            url: '/orders',
            icon: <OrdersIcon />,
            roles: [user, admin],
            requiredPermission: orders.read,
            count: ordersNtfs.length
          },
          {
            title: pages.warehouse.title,
            url: '/warehouse',
            icon: <WarehouseIcon />,
            roles: [user, admin],
            requiredPermission: warehouse.read
          }
        ]
      },
      {
        title: 'Wyceny',
        dropdown: [
          {
            title: pages.offerCatalog.CATALOG.title,
            url: '/offer-catalog',
            icon: <OffersIcon />,
            roles: [user, admin],
            companyAccessFn: offerCatalogEnabled,
            requiredPermission: offer_catalog.read
          },
          {
            title: pages.valuations.title,
            url: '/valuations',
            icon: <ValuationIcon />,
            roles: [user, admin],
            requiredPermission: valuations.read,
            count: valuationsNtfs.length
          },
          {
            title: pages.invoices.title,
            url: '/invoices',
            icon: <InvoiceIcon />,
            roles: [user, admin],
            requiredPermission: invoices.read
          }
        ]
      },
      {
        title: 'Obsługa Dostawców',
        dropdown: [
          {
            title: pages.ordersApprovals.title,
            url: '/orders-approvals',
            icon: <ApprovalIcon />,
            roles: [admin]
          },
          {
            title: pages.supplierValuations.title,
            url: '/supplier/valuations',
            icon: <FormIcon />,
            roles: [admin]
          },
          {
            title: pages.supplierOrders.title,
            url: '/supplier/orders',
            icon: <OrdersIcon />,
            roles: [admin]
          }
        ]
      },
      {
        title: 'Raporty',
        dropdown: [
          {
            title: pages.analytics.title,
            url: '/analytics',
            icon: <AnalyticsIcon />,
            roles: [user, admin],
            requiredPermission: analytics.read
          },
          {
            title: pages.marketPrices.title,
            url: '/market-prices',
            icon: <ProductsIcon />,
            roles: [admin]
          }
        ]
      },
      {
        title: 'Kartoteki',
        dropdown: [
          {
            title: pages.productsBase.title,
            url: '/products-base',
            icon: <ProductsIcon />,
            roles: [user],
            requiredPermission: products.read
          },
          {
            title: pages.suppliers.title,
            url: '/suppliers',
            icon: <SuppliersIcon />,
            roles: [user, admin],
            requiredPermission: suppliers.read
          },
          {
            title: pages.users.title,
            url: '/users',
            icon: <UsersIcon />,
            roles: [admin]
          },
          {
            title: pages.supplierProducts.title,
            url: '/supplier/products',
            icon: <ProductsIcon />,
            roles: [supplier]
          },
          {
            title: pages.collections.title,
            url: '/supplier/collections',
            icon: <FormIcon />,
            roles: [supplier]
          },
          {
            title: pages.companies.title,
            url: '/companies',
            icon: <CompaniesIcon />,
            roles: [admin]
          }
        ]
      },
      {
        title: 'Kartoteki',
        dropdown: [
          {
            title: pages.productsBase.title,
            url: '/products-base',
            icon: <ProductsIcon />,
            roles: [admin]
          },
          {
            title: pages.categories.title,
            url: '/categories',
            icon: <CategoryIcon />,
            roles: [admin]
          },
          {
            title: pages.producers.title,
            url: '/producers',
            icon: <ProducersIcon />,
            roles: [admin]
          },
          {
            title: pages.supplierClients.title,
            url: '/supplier/clients',
            icon: <UsersIcon />,
            roles: [supplier]
          }
        ]
      }
    ];
  }, [notificationsStore.notifications, notificationsStore.oldNotifications]);
};

export default UseNavigation;
