import { Link } from 'react-router-dom';

import { CONTACT_PHONE_NUMBER, CONTACT_PHONE_NUMBER_FORMATTED } from './misc';

const faq = [
  {
    title: 'Czym jest aplikacja Dentametr?',
    answer: {
      text: [
        'Dentametr to narzędzie usprawniające proces tworzenia i realizacji zamówień do gabinetów stomatologicznych. Jest to oprogramowanie dzięki któremu możemy porównać aktualne ceny u wskazanych przez nas dostawców, w oparciu o wysłane im listy zakupowe.',
        'Dzięki Dentametr, nie jesteśmy zaskoczeni brakiem zamówionego produktu w paczce, zmianą ceny danego produktu czy otrzymaniem niechcianego zamiennika. Ponadto mamy kontrolę nad wydatkami. Możemy porównać ceny od kilku dostawców jednocześnie, wybrać najbardziej optymalną wersję koszyka zakupowego i zrealizować zamówienie od kilku dystrybutorów w jednym momencie. Zyskujemy czas oraz generujemy niemałe oszczędności.'
      ],
      list: []
    }
  },
  {
    title: 'Jakie są korzyści z korzystania z aplikacji?',
    answer: {
      text: [],
      list: [
        'Obniżenie kosztów materiałów średnio o 20%',
        'Oszczędność czasu do 50%',
        'Pewność, że zamówienie do nas dotrze',
        'Pewność, że przy zachowaniu odpowiednich procedur nigdy nie zabraknie nam materiałów',
        'Uproszczenie i uporządkowanie procesu zamówień',
        'Możliwość złożenia zamówienia o dowolnej porze (nie tylko w czasie pracy obsługi klienta u dostawców materiałów)',
        'Możliwość oddelegowania pracownika do procesu zamówień, który zostanie przeszkolony przez Dentametr ze sprawnego procesu zamawiania materiałów',
        'Zwiększenie efektywności finansowej dzięki możliwościom analizowania danych w module statystycznym',
        'Skuteczne zarządzanie materiałami poprzez moduł magazynowy'
      ]
    }
  },
  {
    title: 'Jakie funkcjonalności posiada aplikacja? ',
    answer: {
      text: [],
      list: [
        'Ogromna baza produktów dentystycznych – ponad 28 tys. produktów w bazie z możliwością dodania własnych',
        'Zakładka dostawcy – wszystkie niezbędne kontakty w jednym miejscu z możliwością dodania nowych kontaktów w dowolnym momencie',
        'Listy – możliwość tworzenia list zakupowych, które mogą być wielokrotnie wykorzystywane i dowolnie edytowane',
        'Wyceny – wygodny widok do porównywania ofert od dostawców z informacjami na temat dostępności produktów, aktualnych promocji oraz zamienników',
        'Zamówienia – przejrzysty widok historii zamówień – analiza wydatków',
        'Moduł magazynowy ułatwiający zarządzanie materiałami i stanem produktów w swoim gabinecie',
        'Statystki – uporządkowane dane wydatków i oszczędności, możliwe do pobrania w wygodnym formacie. '
      ]
    }
  },
  {
    title: 'Jak założyć konto w aplikacji? ',
    answer: {
      text: [
        <>
          Aby dokonać rejestracji w aplikacji, wystarczy wypełnić <Link to='/contact'>formularz</Link> lub skonsultować się z
          naszym przedstawicielem, którzy pomoże w konfiguracji konta tel.{' '}
          <a href={`tel:${CONTACT_PHONE_NUMBER}`}>{CONTACT_PHONE_NUMBER_FORMATTED}</a>
        </>
      ],
      list: []
    }
  },
  {
    title: 'Jacy dostawcy korzystają z Dentametr? ',
    answer: {
      text: [
        <>
          Aktualnie z aplikacji korzysta ponad 40 dostawców z całej Polski. Są to duże, ogólnokrajowe hurtownie ale również
          mniejsi, wyspecjalizowani dystrybutorzy materiałów oraz narzędzi stomatologicznych. Każdego tygodnia dołączają do nas
          nowi dostawcy. Jeżeli jesteś zainteresowanym dostawcą, skontaktuj się z nami poprzez wypełnienie tego&nbsp;
          <Link to='/contact'>formularza</Link>.
        </>
      ],
      list: []
    }
  },
  {
    title: 'W jaki sposób dostawca dokonuje wyceny? ',
    answer: {
      text: [
        'Dostawca zarówno otrzymuje jak i wysyła wyceny za pomocą specjalnego formularza, który jest integralną częścią aplikacji, a dane na temat cen i dostępności trafiają wyłącznie na nasze konto i nie są udostępniane osobom trzecim. Dostawcy mogą również wgrać ceny dla swoich stałych klientów na swoje konto. Dzięki temu proces potwierdzenia zamówienia jest jeszcze szybszy.'
      ],
      list: []
    }
  },
  {
    title: 'Jaki jest czas oczekiwania na oferty ze strony dostawców? ',
    answer: {
      text: [
        'Czas wyceny oraz potwierdzenia dostępności produktów ściśle zależy od dostawców, z którymi Państwo współpracujecie. Z naszego doświadczenia jest to kilka godzin. W przypadku ponad 75% wycen, czas oczekiwania nie przekracza 1 dnia roboczego.'
      ],
      list: []
    }
  },
  {
    title: 'W jaki sposób Dentametr chroni tajemnice handlowe dostawców?',
    answer: {
      text: [
        'Dane są przechowywane na zabezpieczonych serwerach w podobny sposób jak komunikacja wysyłana przy korzystaniu z usług różnych dostawców poczty elektronicznej. Nie ma tutaj żadnej różnicy od tego jak dostawca wysyła swoją ofertę w wiadomości email. Dentametr ułatwia komunikację pomiędzy klientem a dostawcą.'
      ],
      list: []
    }
  },
  {
    title: 'Czy Dentametr pobiera opłaty za zamówienia po stronie użytkownika albo dostawcy?',
    answer: {
      text: [
        'Całkowity koszt dla użytkownika Aplikacji to miesięczna opłata abonamentowa w wysokości 49 zł netto. Jest to opłata pokrywająca koszty serwera oraz wsparcia klienta. Dentametr nie pobiera dodatkowych opłat od dostawców.'
      ],
      list: []
    }
  },
  {
    title: 'Chciałbym/Chciałabym zmienić swój proces zamówień w gabinecie, nie wiem od czego zacząć? Aplikacja mi w tym pomoże?',
    answer: {
      text: [
        'Aplikacja Dentametr z pewnością będzie świetnym narzędziem wspomagającym zmiany. Skontaktuj się z nami– pomożemy Ci usprawnić procesy zakupowe w gabinecie.'
      ],
      list: []
    }
  },
  {
    title: 'Czy mogę pobrać aplikację na telefon? ',
    answer: {
      text: [
        'Aplikacja jest zoptymalizowana pod kątem urządzeń mobilnych oraz dostępna z poziomu każdej przeglądarki internetowej, zarówno na telefonie jak i komputerze. Nie ma jeszcze dedykowanej aplikacji do pobrania na telefon, ale pracujemy nad tym. '
      ],
      list: []
    }
  },
  {
    title: 'Ile kosztuje aplikacja?',
    answer: {
      text: ['Pierwszy miesiąc użytkowania aplikacji jest bezpłatny. Każdy kolejny kosztuje 49 zł miesięcznie + VAT.'],
      list: []
    }
  },
  {
    title: 'Jak wygląda płatność za aplikację? ',
    answer: {
      text: ['Płatność za aplikację odbywa się na podstawie wystawionej faktury VAT.'],
      list: []
    }
  },
  {
    title: 'Czy na jakiś czas mogę zawiesić opłatę abonamentową?',
    answer: {
      text: [
        'Jest możliwe zawieszenie opłaty abonamentowej – skontaktuj się z nami – razem znajdziemy wygodne dla Państwa rozwiązanie. '
      ],
      list: []
    }
  },
  {
    title: 'Jak wygląda rezygnacja z aplikacji? ',
    answer: {
      text: [
        'Przed rozpoczęciem kolejnego miesiąca należy nas o tym fakcie poinformować mailowo bądź telefonicznie. Okres wypowiedzenia wynosi jeden miesiąc.'
      ],
      list: []
    }
  },
  {
    title: 'Co się stanie z moimi danymi w przypadku rezygnacji z aplikacji? ',
    answer: {
      text: [
        <>
          Traktujemy kwestie ochrony danych osobowych oraz tajemnic przedsiębiorstwa bardzo poważnie. Wszystkie informacje na
          temat danych oraz ich przetwarzania znajdują się <Link to='/privacy-policy'>tutaj</Link>.
        </>
      ],
      list: []
    }
  }
];

export default faq;
