import React, { forwardRef } from 'react';
import cn from 'classnames';

import { ReplacementDetails, Tag } from 'components';
import InfoWidget from 'components/layout/InfoWidget';

import { getFormattedAmount, getSupplierBackgroundColor } from 'src/utils/helpers';

import { productTypes } from '../../../../constants/enums';

import style from './SupplierThumbnail.module.scss';

const SupplierThumbnail = forwardRef((props, ref) => {
  const { item, children, isDragging } = props;

  const containerClasses = cn(style.container, {
    [style.isDragging]: isDragging,
    [style.replacement]: item.type === 'replacement'
  });

  return (
    <div
      className={containerClasses}
      ref={ref}
    >
      <div className={style.nameWrapper}>
        <Tag
          value={item.supplier_name || '-'}
          style={getSupplierBackgroundColor(item)}
          className={style.tag}
        />
        {item?.type === 'replacement' && (
          <ReplacementDetails replacement={item}>
            <InfoWidget place={'top'}>
              <p>
                Cena dotyczy zamiennika, który może się różnić się od oryginalnego produktu.{' '}
                <span className={style.underline}>Kliknij aby sprawdzić jego parametry</span>
              </p>
            </InfoWidget>
          </ReplacementDetails>
        )}
      </div>
      <p className={style.price}>{getFormattedAmount(item.price)}</p>
      {children}
    </div>
  );
});

export default SupplierThumbnail;
