import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { Icon, Loader } from 'components';

import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';

import styles from './Search.module.sass';

const SearchInput = (props) => {
  const {
    className,
    inputClasses,
    children,
    state = '',
    setState,
    searchSize = 'small',
    isLoading,
    alwaysExpand,
    fullWidth,
    onClear,
    clearButtonVisible,
    disabled,
    ...rest
  } = props;

  const [isClearButtonVisible, setIsClearButtonVisible] = useState(false);

  const onInputChange = (e) => {
    if (setState) {
      setState(e.target.value);
    }
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    }
    if (setState) {
      setState('');
    }
  };

  const visibleClassNames = cn({
    [styles.small]: searchSize === 'small',
    [styles.medium]: searchSize === 'medium',
    [styles.large]: searchSize === 'large',
    [styles.expand]: alwaysExpand || state !== '',
    [styles.fullWidth]: fullWidth,
    [styles.disabled]: disabled
  });

  const clearSearchParams = () => {
    if (setState) {
      setState('');
    }
    if (onClear) {
      onClear();
    }
  };

  useEffect(() => {
    setIsClearButtonVisible(state !== '');
  }, [state]);

  return (
    <>
      <div className={cn(styles.search, visibleClassNames, className)}>
        <div
          className={cn(styles.head, {
            [styles.fullWidth]: fullWidth
          })}
        >
          <button className={styles.direction}>
            <Icon
              name='search'
              size='24'
            />
          </button>
          <input
            data-hj-allow
            className={cn(styles.input, visibleClassNames, inputClasses)}
            value={state}
            onChange={onInputChange}
            type='text'
            placeholder='Wyszukaj...'
            disabled={disabled}
            {...rest}
          />
          <button
            className={styles.close}
            onClick={handleClear}
          >
            <Icon
              name='close-circle'
              size='24'
            />
          </button>
        </div>
        <div className={styles.body}>
          <div className={styles.box}>
            {isLoading ? (
              <div className={styles.loader}>
                <Loader />
              </div>
            ) : (
              children
            )}
          </div>
        </div>
        {(isClearButtonVisible || clearButtonVisible) && (
          <button
            className={styles.clear}
            title={'Wyczyść'}
            onClick={clearSearchParams}
          >
            <CloseIcon />
          </button>
        )}
      </div>
    </>
  );
};

export default SearchInput;
