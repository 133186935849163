import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { LoadingIndicator, QtySelector, useAutoSave, useIsMobile, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';

import { refreshListView } from '../../actions';

import style from './SetQuantity.module.scss';

const SetQuantity = ({ uid, id, qty, unitName }) => {
  const validator = useValidator();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(qty);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveError, setIsSaveError] = useState(false);

  const saveQuantityHandler = async () => {
    const data = {
      product_id: id,
      quantity,
      uid
    };

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsSaveError(false);
      setIsLoading(true);
      await ListsApi.storeToList(data);
      dispatch(refreshListView());
    } catch (err) {
      notifyApiError(err);
      setIsSaveError(true);
      setQuantity(qty);
    } finally {
      setIsLoading(false);
    }
  };

  useAutoSave({
    first: +qty,
    second: +quantity,
    dependency: [quantity],
    saveHandler: saveQuantityHandler,
    timeoutValue: 800
  });

  return (
    <div className={style.wrapper}>
      <QtySelector
        state={quantity}
        setState={setQuantity}
        suffix={unitName}
        width={110}
        id={`offerQty-${parent.id}`}
        rule={'required|only_positive'}
        validator={validator}
      />
      <LoadingIndicator
        isLoading={isLoading}
        hasError={isSaveError}
        aboveInput
      />
    </div>
  );
};

export default SetQuantity;
