import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { EmptyState, FilterByCompany, PageHeader, SeoHelmet, StickyPageHeader, useIsAdmin } from 'components';
import TopProducts from 'components/layout/TopProducts';

import pages from 'src/dictionaries/pages.json';
import { getStartDate } from 'src/utils/helpers';
import query from 'src/utils/query';

import CompanySelect from './newComponents/CompanySelect';
import DatePickerWrapper, { dateOptions } from './newComponents/DatePickerWrapper';
import ExpensesByCategory from './newComponents/ExpensesByCategory';
import ExpensesMonthly from './newComponents/ExpensesMonthly';
import ProductsRealisationBySupplier from './newComponents/ProductsRealisationBySupplier';
import SavingsMonthly from './newComponents/SavingsMonthly';
import TimeOfResponseOfSingleOrder from './newComponents/TimeOfResponseOfSingleOrder';

import style from './Analytics.module.scss';

const Analytics = (props) => {
  const params = query(props);
  const isAdmin = useIsAdmin();

  const formattedCompanies = useSelector((state) => state.auth.formattedCompanies);
  const [selectedValue, setSelectedValue] = useState(dateOptions[3]);
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'year'));
  const [endDate, setEndDate] = useState(dayjs());
  const [selectedCompanies, setSelectedCompanies] = useState(formattedCompanies || []);

  const selectedCompanyId = useMemo(() => params?.get('company'), [params]);
  const filters = useMemo(
    () => ({ startDate: dayjs(startDate).toISOString(), endDate: dayjs(endDate).toISOString(), selectedCompanies }),
    [startDate, endDate, selectedCompanies]
  );

  useEffect(() => {
    const { startDate } = getStartDate(selectedValue.value);
    if (startDate) {
      setStartDate(startDate);
      setEndDate(dayjs());
    }
  }, [selectedValue]);

  if (isAdmin && !selectedCompanyId) {
    return (
      <>
        <PageHeader
          name={pages.analytics.title}
          text={'Wybierz firmę'}
        />
        <div className={style.centerContent}>
          <EmptyState type={'selectCompanyForAnalytics'} />
          <FilterByCompany params={params} />
        </div>
      </>
    );
  }

  return (
    <>
      <SeoHelmet title={pages.analytics.title} />
      <PageHeader name={pages.analytics.title} />
      <StickyPageHeader
        className={style.justifyLeft}
        buttonsWrapperClasses={style.buttonWrapper}
        containerClasses={style.headerContainer}
      >
        <DatePickerWrapper
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
        />
        <FilterByCompany
          label={'Firma'}
          params={params}
        />
        <CompanySelect
          selectedCompanies={selectedCompanies}
          setSelectedCompanies={setSelectedCompanies}
        />
      </StickyPageHeader>
      <div className={style.container}>
        <ExpensesMonthly
          selectedCompanyId={selectedCompanyId}
          filters={filters}
        />
        <SavingsMonthly
          selectedCompanyId={selectedCompanyId}
          filters={filters}
        />
        <ExpensesByCategory
          selectedCompanyId={selectedCompanyId}
          filters={filters}
        />
        <ProductsRealisationBySupplier
          selectedCompanyId={selectedCompanyId}
          filters={filters}
        />
        <TimeOfResponseOfSingleOrder
          selectedCompanyId={selectedCompanyId}
          filters={filters}
        />
        <TopProducts
          selectedCompanyId={selectedCompanyId}
          filters={filters}
          params={params}
        />
      </div>
    </>
  );
};

export default Analytics;
