import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { Card, Chart, Loader, usePermissions } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { StatisticsApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import Section from 'src/features/Dashboard/components/Section';
import { getFormattedAmount, translateMonths } from 'src/utils/helpers';

import style from './SavingsChart.module.scss';

const DASHBOARD_ELEMENT_ID = 3;

const SavingsChart = ({ config }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [canViewAnalytics] = usePermissions([userPermissions.analytics.read]);
  const [chartData, setChartData] = useState({});
  const formattedCompanies = useSelector((state) => state.auth.formattedCompanies);
  const canBeDisplayed = useMemo(
    () => canViewAnalytics && config?.elements?.includes(DASHBOARD_ELEMENT_ID),
    [canViewAnalytics, config]
  );

  const getData = async () => {
    const queryData = {
      date_from: dayjs().subtract(5, 'month').toISOString(),
      date_to: dayjs().toISOString(),
      ...(!!formattedCompanies && { company_ids: formattedCompanies.map((company) => company.value) })
    };

    try {
      setIsLoading(true);
      const { data } = await StatisticsApi.getSavingsMonthly(queryData);
      setChartData(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (canBeDisplayed) getData();
  }, [canBeDisplayed]);

  if (!canBeDisplayed) {
    return null;
  }

  if (isLoading) {
    return (
      <Section
        title={'Statystyki'}
        link={'/analytics'}
        className={style.container}
      >
        <Card className={style.emptyCard}>
          <Loader />
        </Card>
      </Section>
    );
  }

  return (
    <Section
      title={'Statystyki'}
      link={'/analytics'}
      className={style.container}
    >
      <Card className={style.card}>
        <Chart
          title={'Oszczędności w ujęciu miesięcznym'}
          data={chartData.saved_money}
          categories={chartData?.months}
          categoriesLabel={'Oszczędności'}
          chartTypes={['area', 'bar']}
          dataLabelsFormatter={(val) => getFormattedAmount(val)}
          yAxisFormatter={(val) => getFormattedAmount(val)}
          categoriesFormatter={(value) => translateMonths(value)}
          haveData={chartData?.have_data}
          isLoading={isLoading}
          colors={['#04e396']}
          height={350}
          fullWidth
        />
      </Card>
    </Section>
  );
};

export default SavingsChart;
