import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { AsyncSelect, Select } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ProductsApi } from 'src/api';
import { compareArrays, getAllCategories } from 'src/utils/helpers';

import style from '../Filtering.module.scss';

const FilterByCategory = ({
  params,
  fullWidth,
  valueKey = 'value',
  labelKey = 'label',
  queryParams,
  label,
  showAsTree,
  isMulti,
  onlyTop
}) => {
  const [selectedCategory, setSelectedCategory] = useState(isMulti ? [] : null);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const paramValue = params.get('category');

  const getAllCategoriesWithTree = async () => {
    try {
      setIsLoading(true);
      const { data } = await ProductsApi.getCategories({ tree: 1 });
      setCategories(onlyTop ? data : getAllCategories(data));
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const setSingleValue = (category) => {
    const areValueTheSame = category?.value === paramValue;

    if (!areValueTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: 'category', value: category?.value },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set('category', category?.value);
      }
    }
  };

  const setMultiValue = (category) => {
    const idsArray = category?.map((cat) => cat.value);
    const areArrayTheSame = compareArrays(idsArray, paramValue);

    if (!areArrayTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: 'category', value: idsArray },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set('category', idsArray);
      }
    }
  };

  const onChangeHandler = (e) => {
    setSelectedCategory(e);
    if (isMulti) {
      setMultiValue(e);
    } else {
      setSingleValue(e);
    }
  };

  useEffect(() => {
    const getSelectedElement = async () => {
      const queryParams = {
        category_id: paramValue,
        perPage: 1,
        page: 1
      };

      try {
        setIsLoading(true);
        const { data } = await ProductsApi.getCategories(queryParams);
        setSelectedCategory(data[0]);
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (paramValue) getSelectedElement();
  }, []);

  useEffect(() => {
    if (showAsTree) {
      getAllCategoriesWithTree();
    }
  }, [showAsTree]);

  if (showAsTree) {
    return (
      <Select
        options={categories}
        value={selectedCategory}
        onChange={onChangeHandler}
        placeholder={'Kategoria'}
        label={label}
        isLoading={isLoading}
        wrapperStyle={classNames(style.select, {
          [style.selected]: !!selectedCategory,
          [style.fullWidth]: fullWidth
        })}
        isClearable
        isMulti={isMulti}
      />
    );
  }

  return (
    <AsyncSelect
      value={selectedCategory}
      onChange={onChangeHandler}
      apiCallback={ProductsApi.getCategories}
      valueKey={valueKey}
      label={label}
      labelKey={labelKey}
      placeholder={'Kategoria'}
      isClearable
      isMulti={isMulti}
      showAsTree={showAsTree}
      queryParams={{ pagination: 1, ...queryParams }}
      wrapperStyle={classNames(style.select, {
        [style.selected]: !!selectedCategory,
        [style.fullWidth]: fullWidth
      })}
    />
  );
};

export default FilterByCategory;
