import React, { useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { QtySelector, SaveButton } from 'components';
import { notifyApiError, notifyDanger } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { imageErrorHandler } from 'src/utils/helpers';

import style from './ProductThumbnail.module.scss';
import shared from 'styles/custom/Shared.module.scss';

const operationTypes = {
  increase: 'increase',
  decrease: 'decrease'
};

const ProductThumbnail = ({ product, setProducts, type, date, validator }) => {
  const { id, name, quantity, thumbnail, unit_name } = product || {};

  const [stateDifference, setStateDifference] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getFinalState = () => {
    if (type === operationTypes.increase) {
      return +quantity + +stateDifference;
    } else if (type === operationTypes.decrease) {
      return +quantity - +stateDifference;
    } else {
      return '-';
    }
  };

  const changeProduct = (newQuantity) => {
    if (setProducts) {
      setProducts((prev) =>
        prev.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              quantity: newQuantity
            };
          } else {
            return item;
          }
        })
      );
    }
  };

  const handleSave = async () => {
    if (!date) notifyDanger(['Proszę wybrać datę']);

    const data = [
      {
        id,
        type,
        change_value: +stateDifference,
        date: dayjs(date).toISOString()
      }
    ];

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    try {
      setIsLoading(true);
      await WarehouseApi.changeProductState(data);
      changeProduct(getFinalState());
      setStateDifference(undefined);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <img
          src={thumbnail}
          alt='Miniatura przedmiotu'
          className={shared.defaultThumbnail}
          onError={imageErrorHandler}
        />
        <p className={style.text}>{name}</p>
      </div>
      <div className={style.wrapper}>
        <p className={style.quantity}>
          <span>
            {quantity}&nbsp;{unit_name}
          </span>
          {!!+stateDifference && (
            <>
              <span>=&gt;</span>
              <span
                className={classNames({
                  [style.increase]: type === operationTypes.increase,
                  [style.decrease]: type === operationTypes.decrease
                })}
              >
                {getFinalState()} {unit_name}
              </span>
            </>
          )}
        </p>
      </div>
      <div className={style.wrapper}>
        <QtySelector
          state={stateDifference}
          setState={setStateDifference}
          suffix={unit_name}
          width={130}
          disabled={isLoading}
          hideArrows
        />
        {!!+stateDifference && (
          <SaveButton
            onClick={handleSave}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default ProductThumbnail;
