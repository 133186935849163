import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { AsyncSelect, Select, useRequestAbortController } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ProductsApi } from 'src/api';

import style from './ProductsFiltering.module.scss';

const ProductsFiltering = (props) => {
  const { setSelectedProducer, selectedProducer, setSelectedCategory, selectedCategory, isFilteringVisible } = props;

  const [categoriesList, setCategoriesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setNewController] = useRequestAbortController();

  const getCategoriesList = async () => {
    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      const { data } = await ProductsApi.getProductsProperties(signal);
      setCategoriesList(data.categories);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategoriesList();
  }, []);

  const containerClasses = cn(style.container, {
    [style.isHidden]: isFilteringVisible
  });

  return (
    <div className={containerClasses}>
      <div className={style.wrapper}>
        <Select
          options={categoriesList}
          value={selectedCategory}
          onChange={setSelectedCategory}
          placeholder={'Kategoria'}
          label={'Kategoria'}
          isLoading={isLoading}
          isClearable
        />
        <AsyncSelect
          value={selectedProducer}
          onChange={setSelectedProducer}
          apiCallback={ProductsApi.getProducers}
          valueKey={'id'}
          labelKey={'producer_name'}
          label={'Producent'}
          placeholder={'Producent'}
          queryParams={{ category_id: selectedCategory?.value }}
          isClearable
        />
      </div>
    </div>
  );
};

export default ProductsFiltering;
