import React from 'react';
import classNames from 'classnames';

import { Gifts } from 'components';

import style from '../../SingleCart.module.scss';
import shared from 'styles/custom/Shared.module.scss';

const OrderGifts = ({ gifts = [] }) => {
  if (!gifts.length) {
    return null;
  }

  return (
    <div className={style.orderComment}>
      <h3 className={classNames(shared.tableCategoryTitle, shared.large)}>Gratisy do zamówienia</h3>
      <Gifts
        className={style.gifts}
        gifts={gifts}
      />
    </div>
  );
};

export default OrderGifts;
