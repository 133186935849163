import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Button, EmptyState } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ValuationsApi } from 'src/api';
import { refreshValuations } from 'src/features/ValuationView/actions';
import SubstituteItem from 'src/features/ValuationView/components/Column/components/SubstituteItem';
import shared from 'src/styles/custom/Shared.module.scss';

import style from './SubstituteSelect.module.scss';

const SubstituteSelect = ({ item, substitutes, closeModal }) => {
  const [selectedProduct, setSelectedProduct] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const selectSubstitute = async () => {
    setIsLoading(true);
    try {
      await ValuationsApi.selectSubstitute({
        products_pricings_id: item.pricing_prod_id,
        product_id: selectedProduct?.product_id
      });
      closeModal();
      dispatch(refreshValuations());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSelectedProduct(substitutes.find((substitute) => substitute?.product_id === item?.product_id));
  }, []);

  return (
    <div className={style.container}>
      {substitutes.length === 0 ? (
        <EmptyState type={'substitute'} />
      ) : (
        <section className={shared.tableWrapper}>
          <div className={classNames(shared.tableHeader, style.header)}>
            <p></p>
            <p>Nazwa</p>
            <p>Dostepność</p>
            <p>Zakres cen (szt.)</p>
            <p>ilość</p>
            <p>Zakres cen</p>
          </div>
          <div className={shared.tableCategory}>
            {substitutes?.map((sub) => (
              <SubstituteItem
                key={sub.product_id}
                quantity={item.quantity}
                product={sub}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
              />
            ))}
            <div className={style.buttonWrapper}>
              <Button
                label={'Wybierz zamiennik'}
                onClick={selectSubstitute}
                disabled={!selectedProduct}
                isLoading={isLoading}
              />
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default SubstituteSelect;
