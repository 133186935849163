import React from 'react';
import classNames from 'classnames';

import { EmptyState } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import ProducersItem from 'src/features/Producers/components/ProducersItem';
import shared from 'src/styles/custom/Shared.module.scss';

import style from './ProducersTable.module.scss';

const ProducersTable = ({ list = [], isLoading }) => {
  if (isLoading) {
    return (
      <section className={shared.tableWrapper}>
        <div className={classNames(shared.tableHeader, style.header)}>
          <p>Nazwa</p>
          <p>Liczba produktów</p>
          <p>Właściciel</p>
        </div>
        <div className={shared.tableCategory}>
          <ProductPlaceholder quantity={6} />
        </div>
      </section>
    );
  }

  if (!list.length) {
    return (
      <section className={shared.tableWrapper}>
        <div className={shared.tableCategory}>
          <EmptyState type={'suppliers'} />
        </div>
      </section>
    );
  }

  return (
    <section className={shared.tableWrapper}>
      <div className={classNames(shared.tableHeader, style.header)}>
        <p>Nazwa</p>
        <p>Liczba produktów</p>
        <p>Właściciel</p>
      </div>
      <div className={shared.tableCategory}>
        {list.map((producer) => (
          <ProducersItem
            key={producer.id}
            producer={producer}
          />
        ))}
      </div>
    </section>
  );
};

export default ProducersTable;
