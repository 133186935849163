import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import classNames from 'classnames';

import InfoWidget from 'components/layout/InfoWidget';
import OrderProductThumbnail from 'components/layout/OrderProductThumbnail';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi, OfferCatalogApi } from 'src/api';
import shared from 'src/styles/custom/Shared.module.scss';

import { refreshValuations } from '../../../ValuationView/actions';

import style from './ProductsList.module.scss';

const ProductsList = ({ products = [] }) => {
  const productsSection = useRef();
  const dispatch = useDispatch();
  const { listUID } = useParams();

  const [areProductsOpen, setAreProductsOpen] = useState(false);

  const changeQuantityOnListHandler = async (quantity, product_id) => {
    const data = { product_id, quantity, uid: listUID };
    await ListsApi.storeToList(data);
  };

  const changeOrderQtyHandler = async (quantity, product_id) => {
    const data = { product_id, quantity };
    await OfferCatalogApi.changeQuantity(data);
  };

  const handleChangeQty = async (quantity, productId) => {
    const apiCallback = listUID ? changeQuantityOnListHandler : changeOrderQtyHandler;

    try {
      await apiCallback(quantity, productId);
      dispatch(refreshValuations());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const info = (
    <InfoWidget className={style.info}>
      <p>Możesz zmienić zamawianą liczbę produktu</p>
      <p>klikając aktualną wartość.</p>
      <p>Dostawca nie gwarantuje, że będzie w stanie</p>
      <p>dostarczyć więcej sztuk produktu.</p>
    </InfoWidget>
  );

  return (
    <section
      style={areProductsOpen ? { height: productsSection.current?.scrollHeight } : {}}
      ref={productsSection}
      className={classNames(shared.tableWrapper, style.suppliers, {
        [style.largeList]: products.length > 6,
        [style.withOverflow]: products.length > 6 && !areProductsOpen
      })}
    >
      <div className={classNames(shared.tableHeader, style.header)}>
        <p>Lp.</p>
        <p>Nazwa</p>
        <p>Producent</p>
        <p className={style.qtyTitle}>Ilość {info}</p>
        <p>Cena za szt.</p>
        <p>Suma</p>
        <p>Komentarz</p>
      </div>
      {products?.map((product, index) => (
        <OrderProductThumbnail
          index={index + 1}
          key={product.id}
          product={product}
          hideStatusColumn
          onQtyChange={(qty) => handleChangeQty(qty, product.product_id)}
          qtyWithoutMargin
        />
      ))}
      {products.length > 6 && !areProductsOpen && (
        <button
          className={style.showMore}
          onClick={() => setAreProductsOpen(true)}
        >
          Pokaż wszystkie produkty
        </button>
      )}
    </section>
  );
};

export default ProductsList;
