import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Button, Input, PhoneInput, Select, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { CompanyApi } from 'src/api';
import { legalForms } from 'src/constants/misc';
import { refreshUserData } from 'src/features/onboarding/actions';

import style from '../../../details.module.scss';

const defaultAddress = {
  street: '',
  building_number: '',
  apartment_number: '',
  zip_code: '',
  city: ''
};

const CompanyForm = ({ invoiceData, closeModal, refreshCb }) => {
  const dispatch = useDispatch();
  const validator = useValidator();
  const [isLoading, setIsLoading] = useState(false);

  const [address, setAddress] = useState(invoiceData?.address || defaultAddress);
  const [name, setName] = useState(invoiceData?.name || '');
  const [legalForm, setLegalForm] = useState(null);
  const [phone, setPhone] = useState(invoiceData?.phone || '');
  const [nip, setNip] = useState(invoiceData?.nip || '');
  const [notificationEmail, setNotificationEmail] = useState(invoiceData?.notification_email || '');

  const [parentCompany, setParentCompany] = useState(null);

  const formattedCompanies = useSelector((state) => state.auth.formattedCompanies);

  const handleAddressChange = (e) => {
    setAddress((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const saveFormData = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return null;
    }

    const data = {
      ...(!invoiceData && {
        parent_company_id: parentCompany?.value.toString()
      }),
      ...(invoiceData && { company_id: invoiceData.id }),
      ...address,
      legal_form: legalForm?.value,
      notification_email: notificationEmail || undefined,
      name,
      phone,
      nip
    };

    const apiCallback = invoiceData ? CompanyApi.updateCompanyData : CompanyApi.createNewCompany;

    try {
      setIsLoading(true);
      await apiCallback(data);
      notifyCommon(['Dane zostały zapisane.']);
      await dispatch(refreshUserData());
      if (refreshCb) refreshCb();
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const legalFormOptions = useMemo(
    () =>
      legalForms.map((form) => ({
        value: form.name,
        label: `${form.name} (${form.title})`
      })),
    [legalForms]
  );

  useEffect(() => {
    if (!legalForm) {
      setLegalForm(legalFormOptions.find((option) => option.value === invoiceData?.legal_form));
    }
  }, [invoiceData?.legal_form]);

  return (
    <div className={style.container}>
      {!invoiceData && (
        <div className={classNames(style.wrapper, style.parentWrapper)}>
          <div className={classNames(style.wrapper, style.selectWrapper)}>
            <p>Aby utworzyć nowy oddział, musisz wybrać oddział nadrzędny.</p>
            <Select
              id={'company-parent'}
              name={'company-parent'}
              className={style.select}
              options={formattedCompanies}
              value={parentCompany}
              onChange={setParentCompany}
              label={'Firma nadrzędna'}
              placeholder={'Nazwa firmy'}
              validator={validator}
              rule={'required'}
            />
          </div>
        </div>
      )}
      <Input
        id='name'
        name='name'
        label='Nazwa firmy'
        value={name}
        onChange={(e) => setName(e.target.value)}
        validator={validator}
        rule={'required'}
      />
      <div className={style.wrapper}>
        <Input
          id='nip'
          name='nip'
          label='NIP'
          value={nip}
          onChange={(e) => setNip(e.target.value)}
          validator={validator}
          rule={'required'}
        />
        <Input
          id='notificationEmail'
          name='notificationEmail'
          label='E-mail (do powiadomień)'
          value={notificationEmail}
          onChange={(e) => setNotificationEmail(e.target.value)}
          validator={validator}
          rule={'email'}
        />
      </div>
      <div className={style.wrapper}>
        <Select
          options={legalFormOptions}
          id='legal_form'
          name='legal_form'
          label='Forma prawna'
          placeholder={'Wybierz...'}
          value={legalForm}
          onChange={setLegalForm}
          validator={validator}
          rule={'required'}
          isSearchable={false}
        />
        <PhoneInput
          id='phone'
          name='phone'
          label='Telefon'
          value={phone}
          onChange={setPhone}
          validator={validator}
          rule={'required|phone'}
        />
      </div>
      <div className={classNames(style.wrapper, style.street)}>
        <Input
          id='street'
          name='street'
          label='Ulica'
          value={address.street}
          onChange={handleAddressChange}
          validator={validator}
          rule={'required'}
        />
        <Input
          id='building_number'
          name='building_number'
          label='Numer'
          value={address.building_number}
          onChange={handleAddressChange}
          validator={validator}
          rule={'required'}
        />
        <Input
          id='apartment_number'
          name='apartment_number'
          label='Lokal'
          value={address.apartment_number}
          onChange={handleAddressChange}
        />
      </div>
      <div className={classNames(style.wrapper, style.zipCode)}>
        <Input
          id='zip_code'
          name='zip_code'
          label='Kod pocztowy'
          value={address['zip_code']}
          onChange={handleAddressChange}
          validator={validator}
          rule={'required'}
        />
        <Input
          id='city'
          name='city'
          label='Miejscowość'
          value={address.city}
          onChange={handleAddressChange}
          validator={validator}
          rule={'required'}
        />
      </div>
      <Button
        label={'Zapisz'}
        className={style.button}
        onClick={saveFormData}
        isLoading={isLoading}
      />
    </div>
  );
};

export default CompanyForm;
