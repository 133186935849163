import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { Button, FilterByCompany, Menu, useIsAdmin, useModalConfirm, usePermissions } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi, OfferCatalogApi } from 'src/api';
import { OfferCatalogTypes, userPermissions } from 'src/constants/enums';

import { refreshValuations } from '../../actions';

import style from '../../OfferCatalog.module.scss';

const HeaderContent = (props) => {
  const {
    isDisabled,
    isLoading,
    handleProceedOrder,
    setShowRefPrice,
    showRefPrice,
    selectedCompany,
    params,
    type,
    isOfferCatalogForCorp,
    setIsOfferCatalogForCorp
  } = props;

  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();
  const { listUID } = useParams();

  const [isLocalLoading, setIsLocalLoading] = useState(false);
  const [isOptimizeLoading, setIsOptimizeLoading] = useState(false);
  const [canCreateOrder] = usePermissions([userPermissions.orders.create]);
  const [canMenageCatalog] = usePermissions([userPermissions.offer_catalog.menage]);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const asCart = type === OfferCatalogTypes.cart;
  const Api = type === OfferCatalogTypes.cart ? ListsApi : OfferCatalogApi;

  const changeViewModeHandler = async () => {
    const callback = async () => {
      await deleteSelectsHandler();
      setIsOfferCatalogForCorp((prev) => !prev);
    };

    if (!isOfferCatalogForCorp) {
      handleOpenModalConfirm({
        message: `Przełączenie widoku na zdefiniowany spowoduje wyczyszczenie zaznaczenia w ${
          type === OfferCatalogTypes.cart ? 'koszyku' : 'ofertach'
        }. Czy chcesz kontynuować?`,
        handleConfirm: callback,
        cancelButtonName: 'Nie',
        confirmButtonName: 'Tak'
      });
    } else {
      await callback();
    }
  };

  const optimizeHandler = async () => {
    const queryData = {
      use_preferred: +isOfferCatalogForCorp,
      ...(isAdmin && { company_id: selectedCompany }),
      ...(listUID && { list_uid: listUID })
    };

    try {
      setIsOptimizeLoading(true);
      await Api.optimizeOfferCatalog(queryData);
      dispatch(refreshValuations());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsOptimizeLoading(false);
    }
  };

  const deleteSelectsHandler = async () => {
    try {
      setIsLocalLoading(true);
      await Api.removeAllSelects({ ...(isAdmin && { company_id: selectedCompany }) });
      dispatch(refreshValuations());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLocalLoading(false);
    }
  };

  const selectSuppliers = async () => {
    const data = {
      list_uid: listUID,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      await Api.selectPreferred(data);
      dispatch(refreshValuations());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const setFirstSecondSuppliers = async () => {
    const data = {
      list_uid: listUID,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      await Api.setPreferredSuppliers(data);
      dispatch(refreshValuations());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const actions = [
    {
      title: 'Zaznacz preferowane',
      action: selectSuppliers,
      icon: 'tick',
      roles: isOfferCatalogForCorp ? ['user', 'admin'] : ['admin']
    },
    {
      title: 'Przypisz oferty preferowane i alternatywne',
      action: setFirstSecondSuppliers,
      icon: 'edit',
      roles: isOfferCatalogForCorp ? ['user', 'admin'] : ['admin']
    },
    {
      title: isOfferCatalogForCorp ? 'Włącz widok pełny' : 'Włącz widok zdefiniowany',
      action: changeViewModeHandler,
      icon: isOfferCatalogForCorp ? 'toggleOff' : 'toggleOn',
      color: 'blue'
    },
    {
      title: showRefPrice ? 'Ukryj ceny ref.' : 'Pokaż ceny ref.',
      action: () => setShowRefPrice((prev) => !prev),
      icon: isOfferCatalogForCorp ? 'toggleOff' : 'toggleOn',
      color: 'blue',
      roles: ['admin']
    }
  ];

  return (
    <>
      {isAdmin && (
        <FilterByCompany
          params={params}
          className={style.topSelect}
        />
      )}
      <Button
        label={'Wyczyść'}
        onClick={deleteSelectsHandler}
        isLoading={isLocalLoading}
        gray
      />
      {asCart && (
        <Button
          label='Optymalizuj'
          onClick={optimizeHandler}
          isLoading={isOptimizeLoading || isLoading}
          disabled={isDisabled}
          gray
        />
      )}
      {canCreateOrder && (
        <Button
          label='Podsumowanie zamówienia'
          onClick={handleProceedOrder}
          isLoading={isLoading}
          disabled={isDisabled}
        />
      )}
      {canMenageCatalog && (
        <Menu
          actions={actions}
          className={style.menu}
        />
      )}
      {renderedModalConfirm}
    </>
  );
};

export default HeaderContent;
