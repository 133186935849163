import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { AsyncSelect, useAuthUser } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import { userRoles } from 'src/constants/enums';
import { compareArrays, formatCompanyValue } from 'src/utils/helpers';

import style from '../Filtering.module.scss';

const FilterByCompany = ({ params, fullWidth, valueKey = 'id', labelKey = 'name', isMulti, className, label }) => {
  const [selectedCompany, setSelectedCompany] = useState(isMulti ? [] : null);
  const [isLoading, setIsLoading] = useState(false);
  const user = useAuthUser();

  const paramValue = params.get('company');

  const setSingleValue = (company) => {
    const areValueTheSame = company?.value === paramValue;

    if (!areValueTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: 'company', value: company?.value },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set('company', company?.value);
      }
    }
  };

  const setMultiValue = (companies) => {
    const idsArray = companies?.map((comp) => comp.value);
    const areArrayTheSame = compareArrays(idsArray, paramValue);

    if (!areArrayTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: 'company', value: idsArray },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set('company', idsArray);
      }
    }
  };

  const onChangeHandler = (e) => {
    setSelectedCompany(e);
    if (isMulti) {
      setMultiValue(e);
    } else {
      setSingleValue(e);
    }
  };

  useEffect(() => {
    const setDefaultState = async () => {
      if (Array.isArray(paramValue)) return;
      //  TODO fill company data when array

      try {
        setIsLoading(true);
        const { data } = await AdminApi.getCompanyDetails(paramValue);
        setSelectedCompany(formatCompanyValue(data));
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (paramValue) setDefaultState();
  }, []);

  if (user?.role !== userRoles.admin) {
    return null;
  }

  return (
    <AsyncSelect
      value={selectedCompany}
      onChange={onChangeHandler}
      apiCallback={AdminApi.getCompanies}
      valueKey={valueKey}
      labelKey={labelKey}
      label={label}
      placeholder={'Firma'}
      wrapperStyle={classNames(
        style.select,
        {
          [style.selected]: !!selectedCompany,
          [style.fullWidth]: fullWidth
        },
        className
      )}
      isClearable
      isMulti={isMulti}
    />
  );
};

export default FilterByCompany;
