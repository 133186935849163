import React from 'react';
import classNames from 'classnames';

import thumbnail from 'assets/img/offer-thumbnail.png';

import { imageErrorHandler } from 'src/utils/helpers';

import style from '../ProductComponents.module.scss';
import shared from 'styles/custom/Shared.module.scss';

const OfferDetails = () => {
  return (
    <div className={classNames(style.wrapper, style.productDetails)}>
      <div className={style.wrapper}>
        <img
          src={thumbnail}
          alt='Miniatura przedmiotu'
          className={classNames(shared.defaultThumbnail, style.offerThumbnail)}
          onError={imageErrorHandler}
        />
        <p className={style.text}>Specjalna oferta cenowa przy zakupie innej ilości sztuk:</p>
      </div>
    </div>
  );
};

export default OfferDetails;
