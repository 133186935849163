import React, { useState } from 'react';

import { Button, Modal, OptionSwitch } from 'components';

import { ReactComponent as PlusIcon } from 'src/assets/icons/plus-blue.svg';

import ProductDetails from '../SuppliersList/components/ProductDetails';
import Product from './components/Product';
import Replacement from './components/Replacement';

import style from './AddNewPrice.module.scss';

const AddNewPrice = ({ productId, selectedCompany, asButton, gray, product }) => {
  const [visible, setVisible] = useState(false);
  const [productForm, setProductForm] = useState(true);

  const id = productId || product?.product_id;

  return (
    <>
      {asButton ? (
        <Button
          label={id ? 'Dodaj ofertę' : 'Dodaj nowy produkt'}
          onClick={() => setVisible(true)}
          gray={gray}
        />
      ) : (
        <button
          className={style.container}
          onClick={() => setVisible(true)}
        >
          {/*{!id && <span>Dodaj nowy produkt</span>}*/}
          <PlusIcon className={style.icon} />
          <p className={style.label}>{id ? 'Dodaj ofertę' : 'Dodaj nową ofertę'}</p>
        </button>
      )}
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        title={'Dodaj ofertę do produktu:'}
      >
        {!!product && (
          <div className={style.productWrapper}>
            <ProductDetails
              product={product}
              tiny
            />
          </div>
        )}
        <div className={style.switchWrapper}>
          <OptionSwitch
            defaultOption={productForm}
            onChange={setProductForm}
            falseLabel={'Oferta na zamiennik'}
            trueLabel={'Oferta na produkt'}
          />
        </div>
        {productForm ? (
          <Product
            productId={id}
            selectedCompany={selectedCompany}
            closeModal={() => setVisible(false)}
          />
        ) : (
          <Replacement
            productId={id}
            selectedCompany={selectedCompany}
            closeModal={() => setVisible(false)}
          />
        )}
      </Modal>
    </>
  );
};

export default AddNewPrice;
