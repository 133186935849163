import React from 'react';
import cn from 'classnames';

import InputWrapper, { getWrapperProps } from 'components/layout/InputWrapper';

import styles from './Checkbox.module.sass';

const Checkbox = (props) => {
  const wrapperProps = getWrapperProps(props);

  const { className, classCheckboxTick, content, value, onChange, reverse, disabled = false, showError, name, isLoading } = props;

  return (
    <InputWrapper {...wrapperProps}>
      <label
        className={cn(styles.checkbox, className, {
          [styles.reverse]: reverse,
          [styles.disabled]: disabled,
          [styles.isLoading]: isLoading
        })}
      >
        <input
          data-hj-allow
          className={styles.input}
          type='checkbox'
          onChange={onChange}
          checked={value}
          disabled={disabled}
          name={name}
        />
        <span className={styles.inner}>
          <span
            className={cn(styles.tick, classCheckboxTick, {
              [styles.error]: !!wrapperProps.errorMessage || showError
            })}
          ></span>
          {content && (
            <>
              <span
                className={cn(styles.text, { [styles.reverse]: reverse })}
                dangerouslySetInnerHTML={{ __html: content }}
              ></span>
            </>
          )}
        </span>
      </label>
    </InputWrapper>
  );
};

export default Checkbox;
