import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { DateRangePicker } from 'components';

import style from '../Filtering.module.scss';

const toLocalFormat = (date) => (date ? date.format('MM/DD/YYYY') : null);

const FilterByDateRange = ({ params, label }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const startParamValue = params.get('startDate');
  const endParamValue = params.get('endDate');

  const setDateInParams = (date, key) => {
    if (date !== startParamValue && params.get('page')) {
      params.setFew([
        { key: key, value: date },
        { key: 'page', value: '1' }
      ]);
    } else if (date !== startParamValue) {
      params.set(key, date);
    }
  };

  useEffect(() => {
    setDateInParams(toLocalFormat(startDate), 'startDate');
  }, [startDate]);

  useEffect(() => {
    setDateInParams(toLocalFormat(endDate), 'endDate');
  }, [endDate]);

  useEffect(() => {
    if (startParamValue) setStartDate(moment(startParamValue));
    if (endParamValue) setEndDate(moment(endParamValue));
  }, []);

  return (
    <DateRangePicker
      dateF
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      wrapperStyle={style.wrapper}
      setEndDate={setEndDate}
      label={label}
    />
  );
};

export default FilterByDateRange;
