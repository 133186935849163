import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import cn from 'classnames';

import {
  Accordion,
  ChangeInvoiceStatus,
  Menu,
  Modal,
  OrderOrValuationId,
  SaveButton,
  Tag,
  useIsMobile,
  useModalConfirm
} from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { InvoicesApi, SupplierApi } from 'src/api';
import { invoiceStatuses } from 'src/constants/enums';
import { toDateNumber } from 'src/utils/dateTime';
import { getFormattedAmount, getInvoiceType } from 'src/utils/helpers';

import InvoiceForm from '../InvoiceForm';

import style from './InvoiceThumbnail.module.scss';

const InvoiceThumbnail = (props) => {
  const {
    number,
    payment_date,
    order_public_id,
    sum,
    supplier_name,
    supplier_id,
    type,
    status,
    file_name,
    order_uid,
    item,
    refreshCallback,
    company_id,
    suborder_uid,
    forSupplier,
    inOrderView,
    company_name,
    created_at,
    comment,
    id,
    small
  } = props;
  const [visible, setVisible] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const isDateError = useMemo(() => new Date(payment_date) <= new Date() && status !== invoiceStatuses.COMPLETED, [payment_date]);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [isActive, setIsActive] = useState(false);
  const isMobile = useIsMobile();

  const saveStatus = async (status) => {
    const editedInvoice = {
      suborder_uid,
      supplier_id,
      status,
      id
    };

    const formData = new FormData();
    for (const [key, value] of Object.entries(editedInvoice)) formData.append(key, value);
    const apiCallback = suborder_uid ? InvoicesApi.editUserInvoice : InvoicesApi.editUserInvoiceOther;

    try {
      await apiCallback(formData);
      await refreshCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const downloadFile = (url, onlyFileView) => {
    const link = document.createElement('a');

    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    if (!onlyFileView) {
      link.setAttribute('download', file_name);
    }

    document.body.appendChild(link);
    link.click();
  };

  const downloadInvoice = async (onlyFileView = false) => {
    const params = { suborder_uid, file_name, supplier_id, company_id };

    const apiCallback = forSupplier
      ? SupplierApi.downloadInvoice
      : order_public_id
      ? InvoicesApi.downloadInvoice
      : InvoicesApi.downloadInvoiceOther;

    if (fileUrl) {
      downloadFile(fileUrl, onlyFileView);
      return;
    }

    try {
      const { data, headers } = await apiCallback(params);
      const url = window.URL.createObjectURL(new Blob([data], { type: headers['content-type'] }));
      setFileUrl(url);
      downloadFile(url, onlyFileView);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteInvoice = async () => {
    const params = { suborder_uid, file_name, supplier_id, company_id };
    const apiCallback = order_public_id ? InvoicesApi.deleteUserInvoice : InvoicesApi.deleteUserInvoiceOther;
    try {
      await apiCallback(params);
      refreshCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteInvoiceHandler = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz usunąć fakturę?',
      handleConfirm: deleteInvoice,
      confirmButtonName: 'Tak',
      cancelButtonName: 'Nie'
    });
  };

  const actions = [
    {
      title: 'Edytuj dane',
      icon: 'edit',
      action: () => setVisible(true)
    },
    {
      title: 'Zmień status',
      icon: 'edit',
      action: () => setTimeout(() => setIsActive(true), 10)
    },
    {
      title: 'Otwórz plik w nowej karcie',
      icon: 'search',
      action: () => downloadInvoice(true)
    },
    {
      title: 'Pobierz plik',
      icon: 'download',
      action: downloadInvoice,
      color: 'blue'
    },
    {
      title: 'Usuń fakturę',
      icon: 'trash',
      action: deleteInvoiceHandler,
      color: 'red'
    }
  ];

  const thumbnail = (
    <div className={classNames(style.container, { [style.inOrder]: inOrderView, [style.small]: small })}>
      <div className={cn(style.wrapper, style.mobileRow)}>
        {getInvoiceType({ type, className: style.tag })}
        {(isMobile || small) && (
          <div className={style.wrapper}>
            <ChangeInvoiceStatus
              currentStatus={status}
              suborderUid={suborder_uid}
              onSave={saveStatus}
              isActive={isActive}
              setIsActive={setIsActive}
              displayOver
            />
          </div>
        )}
      </div>
      {!inOrderView && (
        <>
          <div className={style.wrapper}>
            <p className={style.label}>Dostawca:</p>
            <p className={style.text}>{supplier_name}</p>
          </div>
          <div className={style.wrapper}>
            <p className={style.label}>Oddział:</p>
            <p
              title={company_name}
              className={cn(style.text, style.company)}
            >
              {company_name}
            </p>
          </div>
        </>
      )}
      <div className={style.wrapper}>
        <p className={style.label}>Numer faktury:</p>
        {inOrderView ? (
          <Link to={`/invoices?search=${number}`}>
            <p
              className={cn(style.text, style.invoiceNumber)}
              title={number}
            >
              {number}
            </p>
          </Link>
        ) : (
          <p
            className={cn(style.text, style.invoiceNumber)}
            title={number}
          >
            {number}
          </p>
        )}
      </div>
      <div className={style.wrapper}>
        <p className={style.label}>Suma:</p>
        <p className={style.text}>{getFormattedAmount(sum)}</p>
      </div>
      <div className={style.wrapper}>
        <p className={style.label}>Data płatności:</p>
        <p className={classNames(style.text, { [style.error]: isDateError })}>{payment_date}</p>
      </div>
      {!(isMobile || small) && (
        <div className={style.wrapper}>
          <ChangeInvoiceStatus
            currentStatus={status}
            suborderUid={suborder_uid}
            onSave={saveStatus}
            isActive={isActive}
            setIsActive={setIsActive}
            displayOver
          />
        </div>
      )}
      {inOrderView ? (
        <div className={classNames(style.wrapper, style.row)}>
          <SaveButton
            onClick={downloadInvoice}
            type={'download'}
          />
          <SaveButton
            onClick={() => downloadInvoice(true)}
            type={'search'}
          />
        </div>
      ) : (
        <Menu
          actions={actions}
          className={style.menu}
          displayOver
        />
      )}
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        title={'Edytuj fakturę'}
      >
        <InvoiceForm
          closeModal={refreshCallback}
          invoiceData={item}
        />
      </Modal>
      {renderedModalConfirm}
    </div>
  );

  if (inOrderView || small) {
    return thumbnail;
  }

  return (
    <Accordion
      title={thumbnail}
      defaultWrapped
      noPadding
      openOnArrowClick
    >
      <div className={style.contentWrapper}>
        <span />
        <div className={style.wrapper}>
          <p className={style.label}>Numer zamówienia:</p>
          {order_public_id ? (
            <Link to={`/orders/${order_uid}`}>
              <OrderOrValuationId id={order_public_id} />
            </Link>
          ) : (
            <Tag value={'Brak'} />
          )}
        </div>
        <div className={style.wrapper}>
          <p className={style.label}>Komentarz:</p>
          <p
            title={comment}
            className={cn(style.text, style.comment)}
          >
            {comment || '-'}
          </p>
        </div>
        <div className={style.wrapper}>
          <p className={style.label}>Nazwa pliku:</p>
          <p
            title={file_name}
            className={cn(style.text, style.filename)}
          >
            {file_name}
          </p>
        </div>
        <div className={style.wrapper}>
          <p className={style.label}>Data dodania:</p>
          {created_at && <p className={style.text}>{toDateNumber(created_at)}</p>}
        </div>
      </div>
    </Accordion>
  );
};

export default InvoiceThumbnail;
