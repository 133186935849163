import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, PageHeader, PaginationNav, SwitchButton, useRequestAbortController } from 'components';
import { headerTextPlaceholder } from 'components/layout/PageHeader/placeholders';
import { notifyApiError } from 'components/layout/Toasts';

import { CompanyApi } from 'src/api';
import pages from 'src/dictionaries/pages.json';
import query from 'src/utils/query';

import { getCorrectFormOfResultsLabel } from '../../utils/helpers';
import Filtering from './components/Filtering';
import ProductsList from './components/ProductsList';

import style from './SuppliersManagement.module.scss';

const SuppliersManagement = (props) => {
  const params = query(props);

  const [isOpen, setIsOpen] = useState(false);
  const [abortController, setNewController] = useRequestAbortController();
  const [isLoading, setIsLoading] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [itemsQty, setItemsQty] = useState();
  const [onlyAdded, setOnlyAdded] = useState(false);

  const [currentPage, setCurrentPage] = useState(params.get('page'));
  const [querySearch, setQuerySearch] = useState(params.get('search'));
  const [queryCategory, setQueryCategory] = useState(params.get('category'));
  const [queryProducer, setQueryProducer] = useState(params.get('producer'));
  const [queryCompany, setQueryCompany] = useState(params.get('company'));
  const [sortBy, setSortBy] = useState(params.get('sort_by'));
  const [sortOrder, setSortOrder] = useState(params.get('sort_order'));

  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(100);

  const version = useSelector((state) => state.suppliersManagement.version);

  const getData = async () => {
    let error;
    const queryProps = {
      only_added: +onlyAdded,
      page: currentPage || 1,
      perPage: perPage,
      search: querySearch,
      categoryId: queryCategory,
      producerId: queryProducer,
      company_id: queryCompany,
      ...(sortBy && { sort_by: sortBy }),
      ...(sortOrder && { sort_order: sortOrder })
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      const { data } = await CompanyApi.getProductsWithSuppliers(queryProps, signal);
      setPageData(data.data);
      setPageQty(data?.last_page);
      setItemsQty(data?.total);
      setIsLoading(false);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(error?.message === 'canceled');
    }
  };

  useEffect(() => {
    if (version > 0) {
      getData();
    }
  }, [version]);

  useEffect(() => {
    const getDataWithLoadingHandler = async () => {
      setIsLoading(true);
      await getData();
      setIsLoading(false);
    };

    getDataWithLoadingHandler();
  }, [currentPage, perPage, querySearch, queryCategory, queryProducer, queryCompany, sortBy, sortOrder, onlyAdded]);

  useEffect(() => {
    setQueryCompany(params.get('company'));
    setCurrentPage(params.get('page'));
    setQuerySearch(params.get('search'));
    setQueryCategory(params.get('category'));
    setQueryProducer(params.get('producer'));
    setSortBy(params.get('sort_by'));
    setSortOrder(params.get('sort_order'));
  }, [params]);

  return (
    <>
      <PageHeader
        name={pages.suppliersManagement.title}
        text={isLoading ? headerTextPlaceholder() : getCorrectFormOfResultsLabel(itemsQty)}
      >
        <SwitchButton
          value={onlyAdded}
          setValue={setOnlyAdded}
          label={'Ukryj nieprzypisane produkty'}
        />
        <Button
          label={'Zarządzaj wieloma'}
          onClick={() => setIsOpen((prev) => !prev)}
          gray={isOpen}
        />
      </PageHeader>
      <div className={style.container}>
        <Filtering params={params} />
        <ProductsList
          isOpen={isOpen}
          list={pageData}
          isLoading={isLoading}
          params={params}
        />
      </div>
      <PaginationNav
        onClick={(number) => setCurrentPage(number)}
        params={params}
        pagesQty={pageQty}
        setPerPage={setPerPage}
        defaultQty={perPage}
      />
    </>
  );
};

export default SuppliersManagement;
