import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Button, Checkbox, EmptyState, SuppliersListItem, useIsMobile } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { UserApi, ValuationsApi } from 'src/api';
import { setAllSuppliersCheckedValue } from 'src/utils/helpers';

import { refreshValuations } from '../../actions';

import style from './DistributorsModal.module.scss';
import shared from 'styles/custom/Shared.module.scss';

const DistributorsModal = ({ closeModal, uid, excludedSuppliers }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const [suppliers, setSuppliers] = useState([]);

  const changeSelectedSupplier = (id) => {
    setSuppliers((prev) => prev.map((dist) => (dist.id === id ? { ...dist, checked: !dist.checked } : dist)));
  };

  const sendValuations = async () => {
    let params = {
      uid,
      distributors: suppliers.filter((dist) => dist.checked).map((dist) => dist.id)
    };

    try {
      await ValuationsApi.createSupplierValuation(params);
      notifyCommon(['Wycena została wysłana do wybranych dystrybutorów.']);
      dispatch(refreshValuations());
    } catch (err) {
      notifyApiError(err);
    } finally {
      closeModal();
    }
  };

  const selectAll = () => {
    const isAllSelected = suppliers.every((supplier) => !!supplier.checked);
    setSuppliers((prev) => prev.map((sup) => ({ ...sup, checked: !isAllSelected })));
  };

  useEffect(() => {
    const getData = async () => {
      const getAvailableSuppliers = (suppliers) => {
        const excludedSuppliersIds = excludedSuppliers.map((sup) => sup.id);
        const filteredSuppliers = suppliers.filter((sup) => !excludedSuppliersIds.includes(sup.id));
        return setAllSuppliersCheckedValue(filteredSuppliers);
      };

      const params = { pagination: 0 };

      try {
        const { data } = await UserApi.getDistributors(params);
        setSuppliers(getAvailableSuppliers(data));
      } catch (err) {
        notifyApiError(err);
      }
    };

    getData();
  }, []);

  // TODO refactor for clean look of component

  return (
    <>
      <section className={classNames(shared.tableWrapper, style.wrapper)}>
        {!!suppliers.length && (
          <div className={classNames(shared.tableHeader, style.header)}>
            <Checkbox
              wrapperStyle={style.checkbox}
              value={suppliers.every((dist) => dist.checked === true)}
              onChange={selectAll}
              content={isMobile ? 'Wybierz wszystkich' : null}
              reverse={isMobile}
            />
            <p>Dostawcy</p>
            <p>E-mail</p>
          </div>
        )}
        {suppliers.length ? (
          suppliers.map((sup) => (
            <SuppliersListItem
              key={sup.id}
              supplier={sup}
              onChange={changeSelectedSupplier}
            />
          ))
        ) : (
          <div className={shared.tableCategory}>
            <EmptyState type={'distrubutorsList'} />
          </div>
        )}
      </section>
      {!!suppliers.length && (
        <Button
          className={style.button}
          label='Wyślij wycenę do wybranych dostawców'
          onClick={sendValuations}
          disabled={suppliers.filter((dist) => dist.checked).length === 0}
        />
      )}
    </>
  );
};

export default DistributorsModal;
