import React from 'react';

import { ReactComponent as AnalyticsIcon } from 'assets/icons/newSidebar/analytics.svg';
import { ReactComponent as InvoiceIcon } from 'assets/icons/newSidebar/file-invoice.svg';
import { ReactComponent as ListsIcon } from 'assets/icons/newSidebar/lists.svg';
import { ReactComponent as OffersIcon } from 'assets/icons/newSidebar/offers.svg';
import { ReactComponent as OrdersIcon } from 'assets/icons/newSidebar/orders.svg';
import { ReactComponent as ProductsIcon } from 'assets/icons/newSidebar/products.svg';
import { ReactComponent as ValuationsIcon } from 'assets/icons/newSidebar/valuation.svg';
import { ReactComponent as WarehouseIcon } from 'assets/icons/newSidebar/warehouse.svg';

import { userPermissions } from './enums';

export const groupsNames = {
  quickAccess: 'quickAccess',
  orders: 'orders',
  analytics: 'analytics',
  notifications: 'notifications',
  invoices: 'invoices'
};

export const groupsTitles = {
  quickAccess: 'Szybki dostęp',
  orders: 'Zamówienia',
  analytics: 'Statystyki',
  notifications: 'Powiadomienia',
  invoices: 'Faktury'
};

export const OrdersElements = [
  {
    id: 1,
    title: 'Ostatnie zamówienia',
    link: '/orders',
    requiredPermissions: [userPermissions.orders.read]
  }
];

export const AnalyticsElements = [
  {
    id: 1,
    title: 'Wydatki w ujęciu miesięcznym',
    link: '/analytics',
    requiredPermissions: [userPermissions.analytics.read]
  },
  {
    id: 2,
    title: 'Wydatki z podziałem na kategorie',
    link: '/analytics',
    requiredPermissions: [userPermissions.analytics.read]
  },
  {
    id: 3,
    title: 'Oszczędności w ujęciu miesięcznym',
    link: '/analytics',
    requiredPermissions: [userPermissions.analytics.read]
  }
];

export const NotificationsElements = [
  {
    id: 1,
    title: 'Nieodczytane powiadomienia',
    link: '/profile/notifications'
  }
];

export const InvoicesElements = [
  {
    id: 1,
    title: 'Ostatnie faktury',
    link: '/invoices'
  }
];

export const userAndAdminTabs = [
  {
    id: 1,
    title: 'Listy',
    icon: <ListsIcon />,
    link: '/lists',
    requiredPermissions: [userPermissions.lists.read]
  },
  {
    id: 2,
    title: 'Nowa lista',
    icon: <ListsIcon />,
    link: '/lists?new_element=true',
    buttonText: 'Dodaj',
    requiredPermissions: [userPermissions.lists.read]
  },
  {
    id: 3,
    title: 'Zamówienia',
    icon: <OrdersIcon />,
    link: '/orders',
    requiredPermissions: [userPermissions.orders.read]
  },
  {
    id: 8,
    title: 'Oferty',
    icon: <OffersIcon />,
    link: '/offer-catalog',
    requiredPermissions: [userPermissions.offer_catalog.read]
  },
  {
    id: 9,
    title: 'Statystyki',
    icon: <AnalyticsIcon />,
    link: '/analytics',
    requiredPermissions: [userPermissions.analytics.read]
  },
  {
    id: 4,
    title: 'Faktury',
    icon: <InvoiceIcon />,
    link: '/invoices',
    requiredPermissions: [userPermissions.invoices.read]
  },
  {
    id: 5,
    title: 'Nowa faktura',
    icon: <InvoiceIcon />,
    link: '/invoices?new_element=true',
    buttonText: 'Dodaj',
    requiredPermissions: [userPermissions.invoices.read]
  },
  {
    id: 7,
    title: 'Wyceny',
    icon: <ValuationsIcon />,
    link: '/valuations',
    requiredPermissions: [userPermissions.valuations.read]
  },
  {
    id: 6,
    title: 'Magazyn',
    icon: <WarehouseIcon />,
    link: '/warehouse',
    requiredPermissions: [userPermissions.warehouse.read]
  }
  // {
  //   id: 10,
  //   title: 'Nowy produkt',
  //   icon: <ProductsIcon />,
  //   link: '/products-base?new_element=true',
  //   buttonText: 'Dodaj',
  //   requiredPermissions: [userPermissions.products.read]
  // }
];

export const supplierTabs = [
  {
    id: 1000,
    title: 'Wyceny',
    icon: <ValuationsIcon />,
    link: '/supplier/valuations',
    linkType: 'link',
    linkTitle: 'Pokaż więcej'
  },
  {
    id: 1001,
    title: 'Zamówienia',
    icon: <OrdersIcon />,
    link: '/supplier/orders',
    linkType: 'link',
    linkTitle: 'Pokaż więcej'
  }
];

export const defaultDashboardConfig = {
  quickAccess: {
    enabled: true,
    elements: [2, 3, 8]
  },
  orders: {
    enabled: true,
    elements: [1]
  },
  analytics: {
    enabled: true,
    elements: []
  },
  notifications: {
    enabled: true,
    elements: [1]
  },
  invoices: {
    enabled: true,
    elements: []
  }
};

export const AdminDashboardConfig = {
  quickAccess: {
    enabled: true,
    elements: [2, 3, 8]
  },
  orders: {
    enabled: true,
    elements: [1]
  },
  analytics: {
    enabled: true,
    elements: []
  },
  notifications: {
    enabled: true,
    elements: [1]
  },
  invoices: {
    enabled: true,
    elements: []
  }
};
