import React from 'react';

import ThumbnailPlaceholder from 'components/layout/ThumbnailPlaceholder';

import shared from 'src/styles/custom/Shared.module.scss';

const ColumnList = ({ list, component: Component, emptyState, isLoading, type, extraProps }) => {
  if (isLoading) {
    return <ThumbnailPlaceholder />;
  }

  return (
    <section className={shared.listContent}>
      {list?.length ? (
        list.map((item) => (
          <Component
            type={type}
            key={item?.id || item.uid}
            item={item}
            {...item}
            {...extraProps}
          />
        ))
      ) : (
        <div className={shared.tableCategory}>
          <h3 className={shared.tableCategoryTitle}>{emptyState}</h3>
        </div>
      )}
    </section>
  );
};

export default ColumnList;
