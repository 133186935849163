import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { MemberThumbnail, ProfileSection } from 'components';
import { MemberSkeleton } from 'components/company/memberThumbnails/MemberThumbnail';
import { notifyApiError } from 'components/layout/Toasts';

import { CompanyApi } from 'src/api';

import style from '../../MembersManagement.module.scss';
import shared from 'styles/custom/Shared.module.scss';

const MembersList = ({ addMemberButton, version }) => {
  const [members, setMembers] = useState([]);
  const [owner, setOwner] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getMembersList = async () => {
    try {
      const { data } = await CompanyApi.getMembers();
      setMembers(data.members);
      setOwner(data.owner);
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    const handleGetData = async () => {
      try {
        setIsLoading(true);
        await getMembersList();
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    };

    handleGetData();
  }, [version]);

  return (
    <ProfileSection
      title={'Personel oddziału'}
      button={addMemberButton}
      isLoading={isLoading}
      skeleton={MemberSkeleton}
    >
      <div className={classNames(shared.tableHeader, style.header)}>
        <p>Imię i nazwisko</p>
        <p>E-mail</p>
        <p>Stanowisko</p>
        <p>Data dodania</p>
      </div>
      <div className={style.container}>
        {!!owner && (
          <MemberThumbnail
            member={owner}
            isOwner
          />
        )}
        {members?.map((member) => (
          <MemberThumbnail
            member={member}
            key={member.name}
            refreshCallback={getMembersList}
          />
        ))}
      </div>
    </ProfileSection>
  );
};

export default MembersList;
