import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { Button, Card, LoaderGlobal, PublicFooter, PublicHeader } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AccountsApi } from 'src/api';

import { getUserData } from '../onboarding/actions';

import style from './JoinCompany.module.scss';

const JoinCompany = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  const handleJoinCompany = async () => {
    const queryParams = {
      invitation_token: params?.token
    };

    try {
      setIsRequestLoading(true);
      await AccountsApi.joinCompany(queryParams);
      notifyCommon([`Gratulacje! Posiadasz dostęp do ${userDetails?.company_name}!`]);
      dispatch(getUserData());
      history.push('/dashboard');
    } catch (err) {
      if (err.response.status === 404) {
        history.push({
          pathname: '/404',
          state: { message: err.response.data.message }
        });
      } else {
        notifyApiError(err);
        history.push('/dashboard');
      }
    } finally {
      setIsRequestLoading(false);
    }
  };

  useEffect(() => {
    const getMemberData = async () => {
      const queryParams = {
        invitation_token: params?.token
      };

      try {
        setIsLoading(true);
        const { data } = await AccountsApi.validateInvitation(queryParams);
        setUserDetails(data);
      } catch (err) {
        notifyApiError(err);
        history.push('/dashboard');
      } finally {
        setIsLoading(false);
      }
    };

    getMemberData();
  }, []);

  if (isLoading) {
    return <LoaderGlobal />;
  }

  return (
    <>
      <PublicHeader />
      <div className={style.container}>
        <Card className={style.wrapper}>
          <h1>
            <span className={style.blue}>{userDetails?.company_name}</span> wita w aplikacji Dentametr!
          </h1>
          <p className={style.text}>Potwierdź zaproszenie aby uzyskać dostęp.</p>
          <Button
            label={'Dołącz do ' + userDetails?.company_name}
            isLoading={isRequestLoading}
            onClick={handleJoinCompany}
          />
        </Card>
      </div>
      <PublicFooter />
    </>
  );
};

export default JoinCompany;
