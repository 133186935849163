import React from 'react';

import Navigation from './components/Navigation';
import Router from './components/Router';

import style from './Profile.module.scss';

const Profile = () => {
  return (
    <>
      <div className={style.container}>
        <Navigation />
        <Router />
      </div>
    </>
  );
};

export default Profile;
