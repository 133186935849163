import React, { useEffect, useState } from 'react';

import { AsyncSelect, Button, useCompany, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { CompanyApi } from 'src/api';

import style from '../../MemberForm.module.scss';

const ExistedMemberForm = ({ closeModal, changeVersion }) => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [membersIds, setMembersIds] = useState([]);
  const validator = useValidator();
  const company = useCompany();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const queryData = { user_id: selectedEmployee.value, company_id: company.id };

    try {
      setIsLoading(true);
      await CompanyApi.addEmployee(queryData);
      notifyCommon(['Wybrany pracownik został dodany do oddziału.']);
      changeVersion();
      if (closeModal) closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getMembersList = async () => {
    try {
      const { data } = await CompanyApi.getMembers();
      setMembersIds(data.members.map((member) => member.id));
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    getMembersList();
  }, []);

  const isOptionDisabled = (option) => membersIds.includes(option.value);

  return (
    <div className={style.container}>
      <AsyncSelect
        value={selectedEmployee}
        onChange={setSelectedEmployee}
        apiCallback={CompanyApi.getEmployees}
        isOptionDisabled={isOptionDisabled}
        valueKey={'user_id'}
        labelKey={'user_name'}
        emailKey={'user_email'}
        label={'Pracownik'}
        placeholder={'Jan Kowalski'}
        validator={validator}
        rule={'required'}
        isClearable
      />
      <Button
        className={style.button}
        label={'Dodaj do oddziału'}
        onClick={handleSubmit}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ExistedMemberForm;
