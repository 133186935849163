import React from 'react';
import classNames from 'classnames';

import { ColumnSortButton, EmptyState } from 'components';
import InfoWidget from 'components/layout/InfoWidget';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import WarehouseRow from 'src/features/Warehouse/components/ProductRow';
import shared from 'src/styles/custom/Shared.module.scss';

import style from './WarehouseTable.module.scss';

const WarehouseTable = ({ list, isLoading, states, reloadData, params }) => {
  const tableHeader = (
    <div className={classNames(shared.tableHeader, style.header)}>
      <ColumnSortButton
        parameterName={'name'}
        params={params}
        content={'Nazwa'}
      />
      <ColumnSortButton
        parameterName={'expire_date'}
        params={params}
        content={'Data ważności'}
      />
      <p>
        Opakowanie
        <InfoWidget>Aktualny stan otwartego opakowania</InfoWidget>
      </p>
      <p>
        Komplet
        <InfoWidget>Liczba sztuk w opakowaniu zbiorczym</InfoWidget>
      </p>
      <ColumnSortButton
        parameterName={'quantity'}
        params={params}
        content={'Ilość'}
      />
      <ColumnSortButton
        parameterName={'price'}
        params={params}
        content={'Cena'}
      />
      <p>Wartość</p>
    </div>
  );

  if (isLoading) {
    return (
      <section className={shared.tableWrapper}>
        {tableHeader}
        <ProductPlaceholder quantity={12} />
      </section>
    );
  }

  return (
    <section className={shared.tableWrapper}>
      {list?.length ? (
        <>
          {tableHeader}
          {list.map((item) => (
            <WarehouseRow
              item={item}
              key={item.id}
              states={states}
              reloadData={reloadData}
            />
          ))}
        </>
      ) : (
        <div className={shared.tableCategory}>
          <h3 className={shared.tableCategoryTitle}>
            <EmptyState type={'warehouse'} />
          </h3>
        </div>
      )}
    </section>
  );
};

export default WarehouseTable;
