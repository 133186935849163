import React, { useMemo } from 'react';

import { Card, EmptyState, NotificationCard, useUnreadNotifications } from 'components';

import { ordersNtfTypes, supplierOrdersNtfTypes, valuationsNtfTypes } from 'src/constants/notifications/types';
import Section from 'src/features/Dashboard/components/Section';

import style from './Notifications.module.scss';

const DASHBOARD_ELEMENT_ID = 1;

const Notifications = ({ config }) => {
  const unreadNotifications = useUnreadNotifications([...ordersNtfTypes, ...supplierOrdersNtfTypes, ...valuationsNtfTypes]);
  const canBeDisplayed = useMemo(() => config?.elements?.includes(DASHBOARD_ELEMENT_ID), [config]);

  if (!canBeDisplayed) {
    return null;
  }

  if (unreadNotifications.length === 0) {
    return (
      <Section
        title={'Nieodczytane powiadomienia'}
        link={'/profile/notifications'}
        className={style.container}
      >
        <Card className={style.emptyCard}>
          <EmptyState type={'unreadNotifications'} />
        </Card>
      </Section>
    );
  }

  return (
    <Section
      title={`Nieodczytane powiadomienia${unreadNotifications.length ? ` (${unreadNotifications.length})` : ''}`}
      className={style.container}
    >
      <Card className={style.card}>
        <div className={style.wrapper}>
          {unreadNotifications?.map((el) => (
            <NotificationCard
              key={el.id}
              notification={el}
            />
          ))}
        </div>
      </Card>
    </Section>
  );
};

export default Notifications;
