import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';

import {
  Button,
  Modal,
  PageHeader,
  ProductSearch,
  SeoHelmet,
  useCompany,
  useIsAdmin,
  useIsMobile,
  useModalConfirm,
  usePermissions
} from 'components';
import CreateValuationForm from 'components/layout/CreateValuationForm';
import TimelineHistory from 'components/layout/TimelineHistory';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import ListViewTable from 'src/features/ListView/components/ListViewTable';
import shared from 'src/styles/custom/Shared.module.scss';
import { handleNumeralWords } from 'src/utils/helpers';

import ListComment from './components/ListComment';
import { deleteAll, refreshListView } from './actions';

import style from './ListView.module.scss';

const ListView = () => {
  const { uid } = useParams();
  const isMobile = useIsMobile();
  const history = useHistory();
  const dispatch = useDispatch();
  const company = useCompany();
  const isAdmin = useIsAdmin();

  const [pageData, setPageData] = useState([]);
  const [listData, setListData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [hiddenElements, setHiddenElements] = useState([]);
  const [isValuationLoading, setIsValuationLoading] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [isValuationModalOpen, setIsValuationModalOpen] = useState();

  const [canCreateHorizontal] = usePermissions([userPermissions.valuations.horizontalCreate]);
  const [canCreateVertical] = usePermissions([userPermissions.valuations.verticalCreate]);
  const [canCreateOrder] = usePermissions([userPermissions.orders.create]);

  const products = useSelector((state) => state.listsView.products);
  const version = useSelector((state) => state.listsView.version);
  const isOfferCatalogEnabled = company?.offer_catalog_enabled;

  const { listProducts, productsLength } = useMemo(() => {
    const products = [];
    pageData.forEach((cat) => products.push(...cat.items.map((item) => ({ ...item, category_name: cat.name }))));
    return { listProducts: products, productsLength: products.length };
  }, [pageData]);

  const getData = async () => {
    try {
      const { data } = await ListsApi.getListProducts(uid);
      setPageData(data.categories);
      setListData(data.list);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const getHistory = async () => {
    try {
      const { data } = await ListsApi.getListActions(uid);
      setHistoryData(data);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const createValuation = async () => {
    const queryParams = {
      products: products ?? [],
      uid
    };

    try {
      setIsValuationLoading(true);
      const { data } = await ListsApi.createHorizontalPricing(queryParams);
      history.push(`/valuations/${data.UID}`);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsValuationLoading(false);
    }
  };

  const addToList = async (item, quantity) => {
    const data = {
      product_id: item.id,
      quantity,
      uid
    };

    try {
      await ListsApi.storeToList(data);
      dispatch(refreshListView());
      setHiddenElements((prev) => [...prev, item.id]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const createValuationHandler = () => {
    handleOpenModalConfirm({
      message: 'Lista zostanie wysłana do wyceny. Czy chcesz kontynuować?',
      handleConfirm: createValuation,
      cancelButtonName: 'Nie',
      confirmButtonName: 'Tak'
    });
  };

  const getProductName = (id) => listProducts.find((prod) => prod.id === id)?.name || '';

  useEffect(() => {
    const getDataWithLoading = async () => {
      dispatch(deleteAll());
      setIsLoading(true);
      await getData();

      setIsLoading(false);
    };

    getDataWithLoading();
  }, []);

  useEffect(() => {
    if (version) getData();
    getHistory();
  }, [version]);

  const hasHorizontalValuations = (!!company?.suppliers_management_enabled && canCreateHorizontal) || isAdmin;

  return (
    <>
      <SeoHelmet title={listData?.title || 'Lista'} />
      <PageHeader
        name={`Lista: ${listData?.title}`}
        text={`${productsLength} ${handleNumeralWords(['produkt', 'produkty', 'produktów'], listData?.count || 0)}`}
        isLoading={isLoading}
        className={style.title}
      >
        <div
          className={classNames(shared.buttonWrapper, {
            [shared.reverse]: isMobile
          })}
        >
          <TimelineHistory
            history={historyData}
            getProductName={getProductName}
            title={`Historia listy (${listData?.title})`}
            buttonLabel={'Historia listy'}
          />
          {hasHorizontalValuations && !isAdmin && (
            <Button
              label='Wyślij do wyceny (wybór automatyczny)'
              onClick={createValuationHandler}
              disabled={!pageData?.length || products?.length === 0}
              isLoading={isValuationLoading}
              gray
            />
          )}
          {canCreateVertical && (
            <Button
              label='Zapytaj o wycenę'
              onClick={() => setIsValuationModalOpen(true)}
              disabled={!pageData?.length || products?.length === 0}
              gray={isOfferCatalogEnabled}
            />
          )}
          {isOfferCatalogEnabled && canCreateOrder && (
            <Button
              label={'Zamów'}
              to={`/lists/${uid}/cart`}
              state={{ clearSelection: true }}
              disabled={!pageData?.length || products?.length === 0}
            />
          )}
        </div>
      </PageHeader>
      <div className={style.container}>
        <ProductSearch
          list={pageData}
          hiddenElements={hiddenElements}
          setHiddenElements={setHiddenElements}
          placeholder={'Wyszukaj produkt, aby dodać go do listy...'}
          onProductClick={addToList}
          scrollIntoViewOnActive
          fullWidth
        />
        <ListComment
          uid={uid}
          listComment={listData?.list_comment}
          parentIsLoading={isLoading}
        />
        <ListViewTable
          list={pageData}
          isLoading={isLoading}
          uid={uid}
        />
      </div>
      {renderedModalConfirm}
      <Modal
        visible={isValuationModalOpen}
        onClose={() => setIsValuationModalOpen(false)}
        title={'Zapytaj o wycenę'}
      >
        <CreateValuationForm
          products={listProducts}
          listUID={uid}
          onFinish={() => setIsValuationModalOpen(false)}
        />
      </Modal>
    </>
  );
};

export default ListView;
