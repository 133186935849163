import cn from 'classnames';

import { CONTACT_PHONE_NUMBER, CONTACT_PHONE_NUMBER_FORMATTED } from 'src/constants/misc';

import ActionButtons from '../ActionButtons';
import Navigation from '../Navigation';

import style from './DesktopMenu.module.scss';

export default function DesktopMenu({ supplierForm, items, isAuth }) {
  return (
    <>
      <Navigation
        supplierForm={supplierForm}
        items={items}
      />
      <div className={cn(style.phone, style.phoneInside)}>
        <a href={`tel:${CONTACT_PHONE_NUMBER}`}>{CONTACT_PHONE_NUMBER_FORMATTED}</a>
      </div>
      <div className={style.buttonWrapper}>
        <ActionButtons isAuth={isAuth} />
        <div className={cn(style.phone, style.phoneOutside)}>
          <a href={`tel:${CONTACT_PHONE_NUMBER}`}>{CONTACT_PHONE_NUMBER_FORMATTED}</a>
        </div>
      </div>
    </>
  );
}
