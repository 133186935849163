import React, { useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import cn from 'classnames';

import { EmptyState, Loader } from 'components';

import { ReactComponent as BarChart } from 'src/assets/icons/bar-chart.svg';
import { ReactComponent as LineChart } from 'src/assets/icons/line-chart.svg';
import { ReactComponent as PieChart } from 'src/assets/icons/pie-chart.svg';
import { ReactComponent as TreemapChart } from 'src/assets/icons/treemap.svg';

import style from './Chart.module.scss';

const icons = {
  line: LineChart,
  bar: BarChart,
  pie: PieChart,
  treemap: TreemapChart,
  area: LineChart
};

const defaultColors = [
  '#008FFB',
  '#546E7A',
  '#D4526E',
  '#F86624',
  '#00E396',
  '#8D5B4C',
  '#D7263D',
  '#3F51B5',
  '#FEB019',
  '#1B998B',
  '#FF4560',
  '#2E294E',
  '#F46036',
  '#775DD0',
  '#E2C044'
];

const Chart = (props) => {
  const {
    chartTypes,
    categories = [],
    labels = [],
    categoriesLabel,
    data = [],
    fullWidth,
    dataLabelsFormatter,
    categoriesFormatter,
    yAxisFormatter,
    xAxisFormatter,
    tooltipFormatter,
    chartClassName,
    title,
    isLoading,
    height = 340,
    customSeries,
    customOptions,
    renderCustomSeries,
    horizontal = false,
    stacked = false,
    stackedType = 'normal',
    colors = defaultColors,
    haveData,
    children,
    headerMargin,
    tooltipShared = false,
    configRefreshVariable = null,
    distributed = false,
    customTooltipRender,
    emptyStateType = 'chart'
  } = props;

  const [selectedType, setSelectedType] = useState(chartTypes[0]);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setCounter((prev) => prev + 1);
  }, [configRefreshVariable]);

  const options = customOptions || {
    plotOptions: {
      bar: {
        horizontal: horizontal,
        columnWidth: '80%',
        borderRadius: '6px 6px 0 0',
        startingShape: 'rounded',
        distributed: distributed,
        enableShades: !distributed,
        dataLabels: {
          position: 'bottom',
          enabled: false
        }
      },
      treemap: {
        distributed: distributed,
        enableShades: !distributed
      }
    },
    tooltip: {
      shared: tooltipShared,
      intersect: !tooltipShared,
      y: { ...(tooltipFormatter && { formatter: tooltipFormatter }) },
      ...(!!customTooltipRender && { custom: customTooltipRender })
    },
    colors: colors || [],
    forceUpdate: counter,
    labels,
    chart: {
      height,
      width: '100%',
      type: selectedType,
      fontFamily: 'Lato, sans-serif',
      foreColor: '#0A1B2D',
      stacked: stacked,
      stackType: stackedType,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    ...(selectedType === 'area' && {
      stroke: {
        width: 5,
        curve: 'smooth'
      }
    }),
    ...(['line', 'area'].includes(selectedType) && {
      markers: {
        colors: colors?.length ? colors[0] : '#0d8ffb',
        size: 5,
        strokeWidth: 3,
        sizeOffset: 10,
        shape: 'circle',
        hover: {
          size: 6,
          sizeOffset: 3
        }
      }
    }),
    ...(selectedType === 'pie' && {
      legend: {
        position: 'left'
      }
    }),
    dataLabels: {
      enabled: ['pie', 'treemap'].includes(selectedType),
      textAnchor: horizontal ? 'start' : 'middle',
      offsetX: 0,
      formatter: (value, opts) => (dataLabelsFormatter ? dataLabelsFormatter(value, opts) : value),
      style: {
        colors: ['white']
      },
      background: {
        enabled: false,
        foreColor: '#777777',
        padding: 4,
        borderRadius: 2,
        borderWidth: 0,
        dropShadow: {
          enabled: false
        },
        opacity: 1
      }
    },
    title: {
      text: '',
      align: 'left',
      style: {
        fontSize: '17px',
        marginBottom: '6px',
        display: 'none !important'
      }
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    xaxis: {
      categories: categoriesFormatter ? categories.map(categoriesFormatter) : categories,
      labels: {
        formatter: (value, opts) => (xAxisFormatter ? xAxisFormatter(value, opts) : value)
      }
    },
    yaxis: {
      labels: {
        formatter: (value, opts) => (yAxisFormatter ? yAxisFormatter(value, opts) : value)
      }
    }
  };

  const series = renderCustomSeries
    ? renderCustomSeries(selectedType)
    : customSeries || [
        {
          name: categoriesLabel,
          data: data
        }
      ];

  const chart = () =>
    chartTypes.map(
      (type, index) =>
        type === selectedType && (
          <ReactApexChart
            className={cn(style.chart, chartClassName)}
            key={type + index + 'chart'}
            options={options}
            height={height}
            series={series}
            type={type}
          />
        )
    );

  const typesButtons = useMemo(
    () =>
      chartTypes.length > 1 &&
      chartTypes.map((type) => {
        const IconComponent = icons[type] || <span>{type}</span>;
        return (
          <button
            onClick={() => setSelectedType(type)}
            className={cn(style.typeButton, { [style.active]: selectedType === type, [style.disabled]: !haveData })}
            disabled={!haveData}
            key={type}
          >
            <IconComponent />
          </button>
        );
      }),
    [chartTypes, selectedType]
  );

  return (
    <div className={cn(style.container, { [style.fullWidth]: fullWidth })}>
      <header className={cn(style.header, { [style.headerMargin]: headerMargin })}>
        <h2 className={style.title}>{title}</h2>
        <div className={style.options}>
          {(children || typesButtons) && isLoading && <Loader className={style.loader} />}
          {children}
          {typesButtons}
        </div>
      </header>
      <div className={cn(style.wrapper, { [style.isLoading]: isLoading })}>
        {chart()}
        {!haveData && !isLoading && (
          <EmptyState
            tiny
            type={emptyStateType}
            className={style.empty}
          />
        )}
      </div>
    </div>
  );
};

export default Chart;
