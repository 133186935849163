import React, { useMemo } from 'react';
import Barcode from 'react-barcode';

import { CustomTooltip } from 'components';

import { ReactComponent as ScannerIcon } from 'src/assets/icons/scanner-gun.svg';

import style from './EanIndicator.module.scss';

const EanIndicator = ({ ean }) => {
  if (!ean) {
    return null;
  }

  const text = useMemo(
    () => (
      <div className={style.wrapper}>
        <p>Ten produkt posiada dodany kod EAN.</p>
        <Barcode
          value={ean}
          height={40}
        />
      </div>
    ),
    [ean]
  );

  return (
    <CustomTooltip title={text}>
      <ScannerIcon classNames={style.icon} />
    </CustomTooltip>
  );
};

export default EanIndicator;
