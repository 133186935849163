import React from 'react';
import classNames from 'classnames';

import { ColumnSortButton } from 'components';

import shared from 'styles/custom/Shared.module.scss';

const Table = ({ columns, params, headerClasses, className, children }) => {
  return (
    <section className={classNames(shared.tableWrapper, className)}>
      <div className={classNames(shared.tableHeader, headerClasses)}>
        {columns.map((column) => {
          if (column.parameterName && params) {
            return (
              <ColumnSortButton
                key={column.name}
                parameterName={column.parameterName}
                params={params}
                content={column.name}
              />
            );
          } else {
            return <p key={column.name}>{column.name}</p>;
          }
        })}
      </div>
      {children}
    </section>
  );
};

export default Table;
