import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  Button,
  ColumnList,
  EmptyState,
  FilterByCompany,
  Modal,
  PaginationNav,
  SeoHelmet,
  StickyPageHeader,
  SwitchButton,
  useAuthUser,
  usePermissions,
  useRequestAbortController
} from 'components';
import FilterBySupplier from 'components/layout/filtering/FilterBySupplier';
import { notifyApiError } from 'components/layout/Toasts';

import { DistributorApi, OrdersApi } from 'src/api';
import { SupplierApi } from 'src/api';
import { orderTypes, userPermissions, userRoles } from 'src/constants/enums';
import pages from 'src/dictionaries/pages.json';
import query from 'src/utils/query';

import Searchbar from '../../components/layout/Searchbar';
import ExportOrders from './components/ExportOrders';
import OrderThumbnail from './components/OrderThumbnail';
import ProviderOrderThumbnail from './components/ProviderOrderThumbnail';

import style from './Orders.module.scss';

const Orders = (props) => {
  const params = query(props);
  const [isLoading, setIsLoading] = useState(false);
  const [pageData, setPageData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [hideCancelled, setHideCancelled] = useState(true);
  const [querySearch, setQuerySearch] = useState();
  const [querySupplier, setQuerySupplier] = useState();
  const [queryCompany, setQueryCompany] = useState();
  const ordersVersion = useSelector((state) => state.orders.version);
  const user = useAuthUser();
  const location = useLocation();
  const [abortController, setNewController] = useRequestAbortController();
  const [isExportVisible, setIsExportVisible] = useState(false);
  const [isCompanyAdmin] = usePermissions([userPermissions.company.admin]);

  const getCorrectRequest = useCallback(
    async (params, signal) => {
      if (props.type === orderTypes.supplier) {
        return await SupplierApi.getOrders(params, signal);
      } else if (props.type === orderTypes.distributor) {
        return await DistributorApi.getOrder(params, signal);
      } else {
        return await OrdersApi.getOrders(params, signal);
      }
    },
    [props.type]
  );

  const getData = async () => {
    let error;
    const params = {
      page: currentPage || 1,
      perPage: perPage,
      search: querySearch,
      hide: props.type === orderTypes.supplier ? false : hideCancelled,
      supplier_user_id: querySupplier,
      company_id: queryCompany
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      const { data } = await getCorrectRequest(params, signal);
      setPageData(data);
      setPageQty(data?.last_page);
    } catch (err) {
      notifyApiError(err);
      error = err;
    } finally {
      setIsLoading(error?.message === 'canceled');
    }
  };

  useEffect(() => {
    getData();
  }, [currentPage, perPage, querySearch, hideCancelled, ordersVersion, queryCompany, querySupplier, location.pathname]);

  useEffect(() => {
    setCurrentPage(params.get('page'));
    setQuerySearch(params.get('search'));
    setQuerySupplier(params.get('supplier'));
    setQueryCompany(params.get('company'));
  }, [params]);

  const thumbnailComponent = useMemo(() => {
    switch (props.type) {
      case orderTypes.user:
        return OrderThumbnail;
      case orderTypes.supplier:
      case orderTypes.distributor:
        return ProviderOrderThumbnail;
      default:
        return null;
    }
  }, [props.type]);

  const pageTitle = useMemo(
    () => (user.role === userRoles.admin && props.type !== orderTypes.user ? pages.supplierOrders.title : pages.orders.title),
    [user, props.type]
  );

  return (
    <div className={style.container}>
      <SeoHelmet title={pageTitle} />
      <StickyPageHeader name={pageTitle}>
        {props.type === orderTypes.user && (
          <SwitchButton
            value={hideCancelled}
            setValue={setHideCancelled}
            label={'Ukryj anulowane'}
          />
        )}
        <Searchbar params={params} />
        <FilterByCompany params={params} />
        {props.type !== orderTypes.user && <FilterBySupplier params={params} />}
        {isCompanyAdmin && (
          <Button
            onClick={() => setIsExportVisible(true)}
            label={'Eksport'}
          />
        )}
      </StickyPageHeader>
      <ColumnList
        type={props.type}
        isLoading={isLoading}
        list={pageData?.data}
        component={thumbnailComponent}
        emptyState={<EmptyState type={params.get('search') ? 'search' : 'orders'} />}
      />
      <PaginationNav
        onClick={(number) => setCurrentPage(number)}
        params={params}
        pagesQty={pageQty}
        setPerPage={setPerPage}
        defaultQty={perPage}
      />
      <Modal
        visible={isExportVisible}
        onClose={() => setIsExportVisible(false)}
        title={'Eksport zamówień'}
      >
        <ExportOrders onClose={() => setIsExportVisible(false)} />
      </Modal>
    </div>
  );
};

export default Orders;
