import React from 'react';
import cn from 'classnames';

import Details from '../../../Product/components/Details';

import style from '../../SuppliersList.module.scss';

const ProductDetails = ({ product, tiny }) => {
  return (
    <div className={cn(style.product, { [style.tiny]: tiny })}>
      <Details
        product={product}
        autoSize
        hideQty
        tiny={tiny}
      />
    </div>
  );
};

export default ProductDetails;
