import React from 'react';

import { ReactComponent as LogoDentametr } from 'assets/img/Logo-Dentametr.svg';
import { ReactComponent as LogoMedicover } from 'assets/img/Logo-medicover.svg';

import LoginForm from '../../Login/components/LoginForm';

import style from '../MedicoverPublic.module.scss';

const MedicoverLogin = () => {
  return (
    <div className={style.container}>
      <header className={style.header}>
        <LogoDentametr className={style.logo} />
        <LogoMedicover className={style.logo} />
      </header>
      <div className={style.titleWrapper}>
        <h1 className={style.title}>Witaj w Dentametr!</h1>
        <p className={style.text}>Zaloguj się do swojego konta.</p>
      </div>
      <LoginForm />
    </div>
  );
};

export default MedicoverLogin;
