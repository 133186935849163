import React, { useMemo } from 'react';
import classNames from 'classnames';

import { EmptyState, useIsAdmin, useRecentlyOrderedProducts } from 'components';
import ProductsSectionsPlaceholder from 'components/layout/ProductsSectionPlaceholder';

import ListViewItem from 'src/features/ListView/components/ListViewItem';
import shared from 'src/styles/custom/Shared.module.scss';

import style from './ListViewTable.module.scss';

const ListViewTable = ({ list, isLoading, uid }) => {
  const products = useMemo(() => {
    const arr = [];
    list.forEach((cat) => arr.push(...cat.items));
    return arr;
  }, [list]);

  const isAdmin = useIsAdmin();

  const recentlyOrdered = useRecentlyOrderedProducts(uid);

  const headerClasses = classNames(shared.tableHeader, style.header, { [style.admin]: isAdmin });

  if (isLoading) {
    return (
      <section className={shared.tableWrapper}>
        <div className={headerClasses}>
          <p>Lp.</p>
          <p>Nazwa</p>
          <p>Producent</p>
          {!!isAdmin && <p>Komentarz</p>}
          <p>Ilość</p>
          {!!isAdmin && <p className={style.referencePrice}>Cena referencyjna</p>}
        </div>
        <ProductsSectionsPlaceholder />
      </section>
    );
  }

  if (!list.length > 0) {
    return (
      <section className={shared.tableWrapper}>
        <EmptyState type={'emptyList'} />
      </section>
    );
  }

  return (
    <section className={shared.tableWrapper}>
      <div className={headerClasses}>
        <p>Lp.</p>
        <p>Nazwa</p>
        <p>Producent</p>
        {!!isAdmin && <p>Komentarz</p>}
        <p>Ilość</p>
        {!!isAdmin && <p className={style.referencePrice}>Cena referencyjna</p>}
      </div>
      {list.map((cat) => (
        <div
          className={shared.tableCategory}
          key={cat.name}
        >
          <h3 className={shared.tableCategoryTitle}>
            {cat.name} <span className={shared.counter}>({cat.items?.length})</span>
          </h3>
          {cat.items.map((item) => (
            <ListViewItem
              key={String(item.id + item.name)}
              item={item}
              uid={uid}
              index={products.map((prod) => prod.id).indexOf(item.id)}
              recentlyOrdered={recentlyOrdered.find((prod) => prod.product_id === item.id)}
            />
          ))}
        </div>
      ))}
    </section>
  );
};

export default ListViewTable;
