import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { OrderOrValuationId } from 'components';

import { valuationStatuses } from 'src/constants/enums';
import { toDate, toTime } from 'src/utils/dateTime';

import style from '../../SupplierForm.module.scss';

const ValuationDetails = () => {
  const store = useSelector((state) => state.supplierForm);
  const { status, created_at, public_id, sent_date, expire_date } = store;

  const createdDate = useMemo(() => (created_at ? `${toDate(created_at)}, ${toTime(created_at)}` : ''), [created_at]);
  const sentDate = useMemo(() => (sent_date ? `${toDate(sent_date)}, ${toTime(sent_date)}` : ''), [sent_date]);
  const expireDate = useMemo(() => (expire_date ? `${toDate(expire_date)}, ${toTime(expire_date)}` : ''), [expire_date]);
  const formDate = useMemo(
    () =>
      status === valuationStatuses.pending ? (
        <p>Wygasa: {expireDate}</p>
      ) : status === valuationStatuses.sent ? (
        <p>Wysłano: {sentDate}</p>
      ) : (
        ''
      ),
    [status]
  );

  return (
    <div
      className={style.info}
      id={'valuation-info'}
    >
      <div className={style.valuationInfo}>
        {!!public_id && (
          <OrderOrValuationId
            id={public_id}
            withPrefix={'Wycena numer:'}
          />
        )}
        {!!created_at && <p>Otrzymano: {createdDate}</p>}
        {formDate}
      </div>
    </div>
  );
};

export default ValuationDetails;
