import React from 'react';
import classnames from 'classnames';

import { CustomTooltip, Radio, Tag, useIsMobile } from 'components';

import { getFormattedAmount } from 'src/utils/helpers';

import style from './ModalProduct.module.scss';

const ModalProduct = ({ product, setValuation, valuation }) => {
  const { id, replacement_name, replacement_producer, name, price, producer, quantity, type } = product;
  const isMobile = useIsMobile(1240);

  const itemName = type === 'replacement' ? replacement_name : name;

  return (
    <div
      onClick={() => setValuation({ ...product, type: 'Dentametr' })}
      className={classnames(style.container, {
        [style.selected]: valuation?.id === id
      })}
    >
      <Radio
        value={valuation?.id === id}
        onChange={() => {}}
        className={style.radio}
      />
      <div className={classnames(style.wrapper, style.productName)}>
        <CustomTooltip title={itemName}>
          <p
            className={classnames(style.text, style.name)}
            title={itemName}
          >
            {itemName}
          </p>
        </CustomTooltip>
      </div>
      <div className={classnames(style.wrapper, style.producer)}>
        {isMobile && <p>Producent: </p>}
        <p className={style.text}>{type === 'replacement' ? replacement_producer : producer}</p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p>Ilość: </p>}
        <p className={style.text}>{quantity}</p>
      </div>
      <div className={classnames(style.wrapper, style.price)}>
        {isMobile && <p>Cena (za szt.): </p>}
        <p className={style.text}>{getFormattedAmount(price)}</p>
      </div>
      <div className={classnames(style.wrapper, style.value)}>
        {isMobile && <p>Wartość: </p>}
        <p className={style.text}>{getFormattedAmount(price * quantity)}</p>
      </div>
      <div className={classnames(style.wrapper, style.status)}>
        <Tag
          className={style.tag}
          color={type === 'offer' ? 'yellow' : type === 'replacement' ? 'blue' : ''}
          value={type === 'offer' ? 'Oferta dostawcy' : type === 'replacement' ? 'Zamiennik' : 'Produkt z listy'}
        />
      </div>
    </div>
  );
};

export default ModalProduct;
