import { useEffect, useState } from 'react';

import { useIsAdmin } from 'components';
import useIsMedicover from 'components/hooks/useIsMedicover';
import { notifyApiError } from 'components/layout/Toasts';

import { ArticlesApi } from 'src/api';
import { informationPagesTypes } from 'src/constants/enums';

import InfoCard from '../InfoCard';
import Section from '../Section';

import style from './InformationSection.module.scss';
const InformationSection = ({ type }) => {
  const [articles, setArticles] = useState([]);
  const isAdmin = useIsAdmin();
  const isMedicover = useIsMedicover();

  const fetchContent = async () => {
    try {
      const response = await ArticlesApi.getPrivateArticles();
      setArticles(
        response.data?.filter((article) => {
          if (isAdmin) {
            return true;
          } else if (isMedicover) {
            return article.tags.includes(type) && article.tags.includes(informationPagesTypes.medicover);
          } else {
            return article.tags.includes(type) && !article.tags.includes(informationPagesTypes.medicover);
          }
        })
      );
    } catch (err) {
      notifyApiError(err);
    }
  };

  // useEffect(() => {
  //   fetchContent();
  // }, []);

  if (articles.length === 0) {
    return null;
  }

  return (
    <Section title='Informacje'>
      <div className={style.content}>
        {articles.map((article) => (
          <InfoCard
            tags={article.tags}
            title={article.title.rendered}
            key={article.id}
            id={article.id}
          />
        ))}
      </div>
    </Section>
  );
};

export default InformationSection;
