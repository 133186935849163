import React from 'react';

import { Loader } from 'components';

import style from './LoaderGlobal.module.scss';

const LoaderGlobal = () => {
  return (
    <div className={style.container}>
      <Loader />
    </div>
  );
};

export default LoaderGlobal;
