import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { AsyncSelect, Button, QtySelector, useRequestAbortController, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';
import { refreshOrder } from 'src/features/OrdersView/actions';

import style from '../../AddProductToList.module.scss';
import shared from 'styles/custom/Shared.module.scss';

const AddToListForm = ({ products, onClose, selectedProducts, productId, orderID }) => {
  const dispatch = useDispatch();
  const validator = useValidator();
  const [isLoading, setIsLoading] = useState();
  const [selectedList, setSelectedList] = useState();
  const [productQuantity, setProductQuantity] = useState(1);
  const [abortController, setNewController] = useRequestAbortController();

  useEffect(() => {
    setSelectedList(null);
    validator.hideMessages();
  }, [onClose]);

  const selectedProductsData = useMemo(
    () =>
      selectedProducts?.map((id) => {
        const product = products?.find(({ product_id }) => product_id === id);
        return {
          id,
          name: product?.name,
          quantity: product?.qty
        };
      }),
    [selectedProducts]
  );

  const storeProductToList = async () => {
    const data = {
      order_id: orderID,
      uid: selectedList?.uid,
      products: selectedProductsData?.map((product) => ({ id: product.id, quantity: product.quantity })),
      product_id: productId,
      quantity: productQuantity
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      await ListsApi.storeToList(data, signal);
      notifyCommon(['Dodano produkt do listy.']);
      dispatch(refreshOrder());
      onClose();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return null;
    }

    storeProductToList();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={shared.defaultWrapper}
    >
      <div className={classnames(style.wrapper, { [style.cancelledWrapper]: products })}>
        <AsyncSelect
          label={'Lista'}
          placeholder={'Wybierz listę...'}
          value={selectedList}
          onChange={setSelectedList}
          apiCallback={ListsApi.getLists}
          valueKey={'name'}
          labelFormat={(list) => `${list.name} (${list.company_name ?? list.username})`}
          validator={validator}
          rule={'required'}
          id={'list-select'}
          name={'list-select'}
        />
        {!products && (
          <QtySelector
            label={'Ilość'}
            id={'product-quantity'}
            name={'product-quantity'}
            state={productQuantity}
            setState={setProductQuantity}
            validator={validator}
            rule={'required|only_positive'}
          />
        )}
      </div>
      <Button
        label='Dodaj do listy'
        type={'submit'}
        isLoading={isLoading}
        className={style.button}
      />
    </form>
  );
};

export default AddToListForm;
