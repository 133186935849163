import React from 'react';
import cn from 'classnames';
import classNames from 'classnames';

import { Menu } from 'components';
import BlurDataInfo from 'components/layout/BlurDataInfo';
import { titlePlaceholder } from 'components/layout/PageHeader/placeholders';

import shared from 'src/styles/custom/Shared.module.scss';

const PageHeader = ({
  name,
  text,
  actions,
  children,
  className,
  isLoading,
  hideChildrenWhenLoading,
  textCenter,
  valuations,
  wrapOnMobile,
  titleClasses,
  blurTitle,
  blurredTitlePrefix,
  textMaxWidth
}) => {
  return (
    <header className={cn(shared.pageHeader, className)}>
      <div
        className={classNames(shared.titleWrapper, titleClasses, {
          [shared.center]: textCenter,
          [shared.wrapOnMobile]: wrapOnMobile
        })}
      >
        {isLoading ? (
          titlePlaceholder()
        ) : (
          <>
            {name && (
              <>
                {!!blurredTitlePrefix && blurTitle && <h2 className={classNames(shared.pageTitle)}>{blurredTitlePrefix}</h2>}
                <h2 className={classNames(shared.pageTitle, { [shared.blur]: blurTitle })}>{name}</h2>
              </>
            )}
            {blurTitle && <BlurDataInfo />}
            {text && (
              <h3
                className={cn(shared.pageSubtitle, { [shared.maxWidth]: textMaxWidth })}
                title={text}
              >
                {text}
              </h3>
            )}
          </>
        )}
      </div>
      {!!children && (
        <div
          className={classNames(shared.headerWrapper, {
            [shared.valuationsHeader]: valuations
          })}
        >
          {hideChildrenWhenLoading && isLoading ? titlePlaceholder('400px') : children}
        </div>
      )}
      {!!actions && (
        <Menu
          actions={actions}
          className={shared.pageHeaderMenu}
        />
      )}
    </header>
  );
};

export default PageHeader;
