import React, { useState } from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';

import { AsyncSelect, Button, DateRangePicker, Select, useIsAdmin, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi, StatisticsApi } from 'src/api';
import { months } from 'src/constants/misc';
import { EXCEL_FILE_TYPE } from 'src/constants/misc';
import { downloadFile, getDecodeBase64File, getStartDate } from 'src/utils/helpers';

import style from './ExportOrders.module.scss';

export const dateOptions = [
  { label: `bieżący miesiąc (${months[dayjs().month()]})`, value: 'CURRENT_MONTH' },
  { label: `poprzedni miesiąc (${months[dayjs().subtract(1, 'month').month()]})`, value: 'PREVIOUS_MONTH' },
  { label: 'bieżący kwartał', value: 'CURRENT_QUARTER' },
  { label: 'poprzedni kwartał', value: 'PREVIOUS_QUARTER' },
  { label: 'bieżący rok', value: 'CURRENT_YEAR' },
  { label: 'wybierz własny', value: 'CUSTOM_RANGE' }
];

const ExportOrders = () => {
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [isXlsxLoading, setIsXlsxLoading] = useState(false);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(dateOptions[0]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const isAdmin = useIsAdmin();
  const validator = useValidator();

  const downloadHandler = async (type) => {
    console.log('validator.allValid()', validator.allValid());
    console.log('validator', validator);

    if (!validator.allValid()) {
      validator.showMessages();
      return null;
    }

    let dates;

    if (selectedValue.value !== 'CUSTOM_RANGE') {
      const { startDate, endDate } = getStartDate(selectedValue?.value);
      dates = {
        date_from: dayjs(startDate).toISOString(),
        date_to: dayjs(endDate).toISOString()
      };
    } else {
      dates = {
        date_from: dayjs(startDate).toISOString(),
        date_to: dayjs(endDate).toISOString()
      };
    }

    const queryData = {
      file_format: type,
      company_id: selectedCompany?.value,
      ...dates
    };

    const date = dayjs(queryData.date_from).format('DD.MM.YYYY') + '-' + dayjs(queryData.date_to).format('DD.MM.YYYY');

    const downloadCSV = (data) => {
      const blob = new Blob([data], { type: 'text/csv' });
      downloadFile(blob, 'eksport_zamowien' + '_' + date + '.csv');
    };
    const downloadXLSX = (data) => {
      const blob = getDecodeBase64File(data, EXCEL_FILE_TYPE);
      downloadFile(blob, 'eksport_zamowien' + '_' + date + '.xlsx');
    };

    try {
      type === 'CSV' ? setIsCsvLoading(true) : setIsXlsxLoading(true);
      const data = await StatisticsApi.exportOrdersData(queryData);
      type === 'CSV' ? downloadCSV(data.data) : downloadXLSX(data.data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      type === 'CSV' ? setIsCsvLoading(false) : setIsXlsxLoading(false);
    }
  };

  return (
    <div className={style.container}>
      {isAdmin && (
        <AsyncSelect
          value={selectedCompany}
          onChange={setSelectedCompany}
          apiCallback={AdminApi.getCompanies}
          className={style.select}
          valueKey={'id'}
          labelKey={'name'}
          placeholder={'Firma'}
          label={'Nazwa firmy'}
          isClearable
          validator={validator}
          rule={'required'}
          id={'company'}
        />
      )}
      <div className={style.inputsWrapper}>
        <Select
          options={dateOptions}
          onChange={setSelectedValue}
          value={selectedValue}
          wrapperStyle={cn({ [style.select]: selectedValue.value === 'CUSTOM_RANGE' })}
          label={'Okres badania'}
          id={'range'}
          validator={validator}
          rule={'required'}
        />
        {selectedValue.value === 'CUSTOM_RANGE' && (
          <DateRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            id={'rangeSelect'}
            label={'Wybierz zakres'}
          />
        )}
      </div>
      <div className={style.buttonWrapper}>
        <Button
          label={'Pobierz .csv'}
          onClick={() => downloadHandler('CSV')}
          isLoading={isCsvLoading}
          gray
        />
        <Button
          label={'Pobierz .xlsx'}
          onClick={() => downloadHandler('XLSX')}
          isLoading={isXlsxLoading}
        />
      </div>
    </div>
  );
};

export default ExportOrders;
