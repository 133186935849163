import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Badge } from 'antd';

import { Accordion, Loader, NotificationCard } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { UserApi } from 'src/api';
import { NotificationTypes } from 'src/constants/enums';
import { scrollToElementAndReturnPromise, stringToBase64 } from 'src/utils/helpers';

import { setNotificationAsRead } from '../../../NotificationsStore/actions';

import style from './Notifications.module.scss';

const Notifications = ({ notifications = [] }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const setAsRead = async (notification) => {
    try {
      setIsLoading(true);
      await UserApi.setNotificationAsRead(notification.id);
      dispatch(setNotificationAsRead(notification.id));
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const readAll = async (items) => {
    const promises = items.map(setAsRead);
    await Promise.all(promises);
  };

  const title = (
    <div className={style.headerWrapper}>
      <h2 className={style.tableTitle}>
        Powiadomienia <Badge count={notifications.length} />
      </h2>
      {notifications.length > 1 && (
        <a
          className={style.readAll}
          onClick={() => readAll(notifications)}
        >
          {isLoading && <Loader />}
          Oznacz jako przeczytane
        </a>
      )}
    </div>
  );

  if (notifications.length === 0) {
    return null;
  }

  const getFocusedElementId = (notification) => {
    switch (notification.type) {
      case NotificationTypes.OrderCancelledBySupplier:
      case NotificationTypes.OrderAccepted: {
        return `suborder-list-${stringToBase64(notification.data?.supplier_id)}`;
      }
      case NotificationTypes.PriceChanged: {
        return `suborder-product-${notification.data?.product_id}`;
      }
      case NotificationTypes.MessageSent: {
        return `message-${notification?.data?.message_id}`;
      }
      default:
        return '';
    }
  };

  const scrollToProductHandler = async (notification, onClick) => {
    const elementID = getFocusedElementId(notification);
    const element = document.getElementById(elementID);

    if (element) {
      await scrollToElementAndReturnPromise(element);
      element.focus({ preventScroll: true });
      if (onClick) onClick();
    } else {
      console.warn(`Nie znaleziono elementu o ID: ${elementID}`);
    }
  };

  return (
    <Accordion
      title={title}
      extraDeps={[notifications]}
      openOnArrowClick
    >
      <div className={style.wrapper}>
        {notifications.map((ntf) => (
          <NotificationCard
            notification={ntf}
            key={ntf.id}
            onBeforeRead={() => scrollToProductHandler(ntf)}
            tiny
          />
        ))}
      </div>
    </Accordion>
  );
};

export default Notifications;
