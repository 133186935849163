import React from 'react';

import { Tag } from 'components';

import { ReactComponent as SuppliersIcon } from 'src/assets/icons/users-list.svg';

import style from './OtherSuppliers.module.scss';

const OtherSuppliers = ({ onClick, count }) => {
  return (
    <button onClick={onClick}>
      <div className={style.container}>
        <div className={style.box}>
          <SuppliersIcon className={style.icon} />
          <span className={style.counter}>{count}</span>
        </div>
        <p className={style.label}>Wszystkie oferty</p>
      </div>
    </button>
  );
};

export default OtherSuppliers;
