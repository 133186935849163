import React from 'react';

import { ReactComponent as LogoDentametr } from 'assets/img/Logo-Dentametr.svg';
import { ReactComponent as LogoMedicover } from 'assets/img/Logo-medicover.svg';

import ForgotPasswordForm from '../../ForgotPassword/components/ForgotPasswordForm';

import style from '../MedicoverPublic.module.scss';

const MedicoverForgotPassword = () => {
  return (
    <div className={style.container}>
      <header className={style.header}>
        <LogoDentametr className={style.logo} />
        <LogoMedicover className={style.logo} />
      </header>
      <div className={style.titleWrapper}>
        <h1 className={style.title}>Resetowanie hasła</h1>
        <p className={style.text}>Na podany adres e-mail zostanie wysłana wiadomość z linkiem do resetu hasła.</p>
      </div>
      <ForgotPasswordForm />
    </div>
  );
};

export default MedicoverForgotPassword;
