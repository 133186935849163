import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Placeholder } from 'semantic-ui-react';

import { Button, Menu, Modal, useIsMobile, useModalConfirm } from 'components';
import OrderHeader from 'components/layout/OrderHeader';
import ProductsSectionsPlaceholder from 'components/layout/ProductsSectionPlaceholder';
import SupplierContactData from 'components/layout/SupplierContactData';
import TimelineHistory from 'components/layout/TimelineHistory';
import { notifyApiError } from 'components/layout/Toasts';

import { InvoicesApi, OrdersApi } from 'src/api';
import { orderStatuses, productStatuses } from 'src/constants/enums';
import { getOrderProductModalContent } from 'src/constants/misc';
import InvoiceForm from 'src/features/Invoices/components/InvoiceForm';
import Attachments from 'src/features/orderConfirmations/components/Attachments';
import Communication from 'src/features/orderConfirmations/components/Communication';
import UploadAttachment from 'src/features/orderConfirmations/components/UploadAttachment';
import Summary from 'src/features/OrdersView/components/Summary';
import shared from 'src/styles/custom/Shared.module.scss';
import { clientOrderStatus } from 'src/utils/helpers';

import { refreshOrder } from '../../actions';
import OrderTracing from '../OrderTracking';
import Invoices from './components/Invoices';
import OrderGifts from './components/OrderGifts';
import ProductsList from './components/ProductsList';
import ProductsSelect, { ADD_TO_WAREHOUSE } from './components/ProductsSelect';

import style from './SingleCart.module.scss';

const SingleCart = ({ supplier, isLoading, createdAt, uid, companyId, notifications = [] }) => {
  const { products, id, name, suborder_uid } = supplier;
  const isMobile = useIsMobile();

  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [isOpen, setIsOpen] = useState(false);
  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);
  const [newStatus, setNewStatus] = useState(null);

  const dispatch = useDispatch();

  const modalContent = useMemo(() => getOrderProductModalContent(newStatus), [newStatus]);
  const getModalAllowedProducts = useCallback((status) => getAllowedProducts(status), [products, modalContent]);
  const getAllowedProducts = (status) =>
    products.filter((product) => getOrderProductModalContent(status).allowedStatuses.includes(product.status)).length === 0;

  const refreshDataCallback = () => dispatch(refreshOrder());

  const cancelOrder = async () => {
    try {
      await OrdersApi.cancelOrderLink({ uid: uid, supplier_id: id });
      refreshDataCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const cancelOrderHandler = () => {
    handleOpenModalConfirm({
      message: 'Czy na pewno chcesz anulować zamówienie? ',
      handleConfirm: cancelOrder,
      cancelButtonName: 'Nie',
      confirmButtonName: 'Tak'
    });
  };

  const actions = [
    {
      title: 'Dodaj do magazynu',
      icon: 'add',
      action: () => openProductsModal(ADD_TO_WAREHOUSE),
      disabled: getModalAllowedProducts(ADD_TO_WAREHOUSE)
    },
    {
      title: 'Zwrot produktów',
      icon: 'add',
      action: () => openProductsModal(productStatuses.refunded),
      disabled: getModalAllowedProducts(productStatuses.refunded)
    },
    {
      title: 'Reklamacja',
      icon: 'add',
      action: () => openProductsModal(productStatuses.complaint),
      disabled: getModalAllowedProducts(productStatuses.complaint)
    },
    {
      title: 'Anuluj produkty',
      icon: 'close',
      color: 'red',
      action: () => openProductsModal(productStatuses.cancelled),
      disabled: getModalAllowedProducts(productStatuses.cancelled)
    },
    {
      title: 'Anuluj zamówienie',
      icon: 'close',
      color: 'red',
      action: () => cancelOrderHandler(productStatuses.cancelled),
      disabled: ![orderStatuses.pending, orderStatuses.processing, orderStatuses.sent].includes(supplier?.status)
    }
  ];

  const closeModal = () => setIsOpen(false);
  const openProductsModal = (status) => {
    setIsOpen(!!status);
    setNewStatus(status);
  };

  const closeAttachmentModal = () => setIsAttachmentModalOpen(false);
  const closeInvoiceModal = () => {
    refreshDataCallback();
    setIsInvoiceModalOpen(false);
  };

  const getProductName = (id) => products.find((prod) => prod.id === id || prod.product_id === id)?.name || '';

  if (isLoading) {
    return (
      <section className={classNames(shared.tableWrapper, style.suppliers)}>
        <h2 className={style.supplierName}>
          <Placeholder style={{ width: '200px', mixBlendMode: 'darken' }}>
            <Placeholder.Paragraph>
              <Placeholder.Line length={'full'} />
            </Placeholder.Paragraph>
          </Placeholder>
        </h2>
        <ProductsSectionsPlaceholder />
      </section>
    );
  }

  return (
    <section
      className={classNames(shared.tableWrapper, style.suppliers)}
      key={name}
    >
      <OrderHeader
        supplier={supplier}
        createdAt={createdAt}
      >
        <a
          onClick={() => setIsModalOpen(true)}
          className={style.link}
        >
          Kontakt z dostawcą
        </a>
        <TimelineHistory
          history={supplier?.actions}
          getProductName={getProductName}
          title={`Historia zamówienia (${name})`}
          buttonLabel={'Historia zamówienia'}
        />
        {!isMobile && clientOrderStatus({ sup: supplier, className: style.tag })}
      </OrderHeader>
      <SupplierContactData
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        supplier={supplier}
      />
      <div className={style.topSection}>
        <div className={style.buttons}>
          <Button
            label={'Dodaj załącznik'}
            onClick={() => setIsAttachmentModalOpen(true)}
            gray
          />
          <Button
            label={'Dodaj fakturę'}
            onClick={() => setIsInvoiceModalOpen(true)}
            gray
          />
        </div>
        <div className={style.buttons}>
          <Button
            label={'Odbierz produkty'}
            onClick={() => openProductsModal(productStatuses.completed)}
            disabled={getModalAllowedProducts(productStatuses.completed)}
          />
          <Menu
            actions={actions}
            className={style.menu}
          />
        </div>
        <Modal
          visible={isAttachmentModalOpen}
          onClose={closeAttachmentModal}
          title='Dodaj załączniki'
        >
          <UploadAttachment
            uid={uid}
            supplierId={id}
            refreshDataCallback={refreshDataCallback}
            closeModal={closeAttachmentModal}
            userAttachment
          />
        </Modal>
        <Modal
          visible={isInvoiceModalOpen}
          onClose={() => setIsInvoiceModalOpen(false)}
          title='Dodaj fakturę'
        >
          <InvoiceForm
            orderValue={supplier.shipping_price + (supplier?.additional_shipping_price || 0) + supplier.amount}
            apiHandler={InvoicesApi.addUserInvoice}
            closeModal={closeInvoiceModal}
            suborderUid={suborder_uid}
            companyId={companyId}
            supplierId={id}
          />
        </Modal>
      </div>
      <Invoices
        invoices={supplier?.invoices}
        supplierId={supplier.id}
        suborderUid={supplier.suborder_uid}
        refreshCallback={refreshDataCallback}
        uid={uid}
      />
      <ProductsList
        products={products}
        history={supplier?.actions}
        suborderUid={supplier.suborder_uid}
        supplierId={supplier.id}
        refreshDataCallback={refreshDataCallback}
        notifications={notifications}
      />
      <OrderGifts gifts={supplier?.gifts} />
      <OrderTracing
        className={style.orderTracing}
        order={supplier}
        setIsInvoiceLoading={setIsInvoiceLoading}
        isLoading={isInvoiceLoading}
        supplierId={supplier.id}
      />
      <Communication
        orderCreatedAt={createdAt}
        pricingComment={supplier?.supplier_pricing_comment}
        userComment={supplier?.user_comment}
        supplierId={supplier.id}
        messages={supplier?.messages}
        refreshHandler={refreshDataCallback}
        notifications={notifications}
      />
      <Attachments
        attachments={supplier.attachments}
        uid={uid}
        supplierId={supplier.id}
        refreshDataCallback={refreshDataCallback}
        userAttachment
      />
      <Summary sup={supplier} />
      <Modal
        title={modalContent.title}
        visible={isOpen}
        onClose={closeModal}
      >
        <ProductsSelect
          products={products}
          newStatus={newStatus}
          closeModal={closeModal}
          supplierId={supplier.id}
        />
      </Modal>
      {renderedModalConfirm}
    </section>
  );
};

export default SingleCart;
