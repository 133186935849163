import React, { useEffect, useRef } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';

function Scanner({ onScan }) {
  const scannerRef = useRef(null);

  useEffect(() => {
    let qrboxFunction = function (viewfinderWidth, viewfinderHeight) {
      let minEdgePercentage = 0.3;
      let minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
      let qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);

      return {
        width: Math.max(50, qrboxSize * 1.3),
        height: Math.max(50, qrboxSize)
      };
    };

    const scanner = new Html5QrcodeScanner(
      'qr-reader',
      {
        qrbox: qrboxFunction,
        aspectRatio: 1.7,
        fps: 30
      },
      false
    );

    scanner.codeParseError = () => {};

    scanner.render((decodedText) => {
      onScan(decodedText);
      scanner.pause(true);
    });

    return () => {
      scanner.clear();
    };
  }, []);

  return (
    <div>
      <div
        id='qr-reader'
        ref={scannerRef}
        style={{ width: '100%', aspectRatio: 1.7 }}
      />
    </div>
  );
}

export default Scanner;
