import cn from 'classnames';

import ActionButtons from '../ActionButtons';
import Navigation from '../Navigation';

import style from './MobileMenu.module.scss';

export default function MobileMenu({ items, isActive, isAuth, supplierForm }) {
  return (
    <div
      className={cn(style.container, {
        [style.containerActive]: isActive
      })}
    >
      <div className={style.buttonsWrapper}>
        <ActionButtons
          isAuth={isAuth}
          className={style.buttons}
        />
      </div>
      <Navigation
        supplierForm={supplierForm}
        items={items}
        isMobile
      />
    </div>
  );
}
