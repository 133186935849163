import React from 'react';
import cn from 'classnames';

import styles from './Loader.module.sass';

const Loader = ({ className, white, center, prefix, style }) => {
  if (prefix) {
    return (
      <div
        className={styles.prefixWrapper}
        style={style}
      >
        {prefix}
        <div
          className={cn(styles.loader, className, {
            [styles.loaderWhite]: white,
            [styles.center]: center
          })}
        />
      </div>
    );
  }

  return (
    <div
      style={style}
      className={cn(styles.loader, className, {
        [styles.loaderWhite]: white,
        [styles.center]: center
      })}
    />
  );
};

export default Loader;
