import React, { useState } from 'react';
import cn from 'classnames';

import { Icon, Loader, useAuthUser } from 'components';
import styles from 'components/layout/Actions/Actions.module.sass';

const Button = ({ item, classActionsOption, toggle, index }) => {
  const { roles, disabled, color, action, icon, title } = item;
  const user = useAuthUser();
  const [isLoading, setIsLoading] = useState(false);

  const buttonClasses = cn(styles.option, classActionsOption, {
    [styles.disabled]: disabled,
    [styles[color]]: color
  });

  const onClick = async () => {
    setIsLoading(true);
    await action();
    toggle();
    setIsLoading(false);
  };

  if (roles && !roles?.includes(user?.role)) {
    return null;
  }

  return (
    <button
      key={index}
      tabIndex={2}
      onClick={onClick}
      disabled={disabled}
      className={buttonClasses}
    >
      {icon &&
        (isLoading ? (
          <div style={{ width: '24px', marginLeft: '12px' }}>
            <Loader />
          </div>
        ) : (
          <Icon
            name={icon}
            size='24'
          />
        ))}
      <span>{title}</span>
    </button>
  );
};

export default Button;
