import React from 'react';
import classNames from 'classnames';

import { Accordion } from 'components';

import InvoiceThumbnail from 'src/features/Invoices/components/InvoiceThumbnail';

import shared from 'styles/custom/Shared.module.scss';

const Invoices = ({ invoices = [], supplierId, uid, suborderUid, refreshCallback }) => {
  const title = <h3 className={classNames(shared.tableCategoryTitle, shared.large, shared.noPadding)}>Faktury</h3>;

  if (invoices.length === 0) {
    return null;
  }

  return (
    <Accordion
      section
      title={title}
      extraDeps={[invoices]}
    >
      {invoices.map((invoice) => (
        <InvoiceThumbnail
          refreshCallback={refreshCallback}
          supplier_id={supplierId}
          key={invoice.file_name}
          order_public_id
          order_uid={uid}
          suborder_uid={suborderUid}
          item={invoice}
          inOrderView
          {...invoice}
        />
      ))}
    </Accordion>
  );
};

export default Invoices;
