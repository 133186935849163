import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Loader } from 'semantic-ui-react';

import { useAuthUser } from 'components';
import { SeoHelmet, useIsAdmin } from 'components/index';
import { notifyApiError } from 'components/layout/Toasts';

import { ArticlesApi } from 'src/api';
import { informationPagesTypes } from 'src/constants/enums';

import style from './InformationPage.module.scss';

const InformationPage = () => {
  const { id } = useParams();
  const user = useAuthUser();
  const isAdmin = useIsAdmin();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState('');
  const [article, setArticle] = useState([]);

  const fetchInformation = async () => {
    try {
      setIsLoading(true);
      const response = await ArticlesApi.getPrivateArticlePage(id);
      if (!response.data.tags.includes(informationPagesTypes[user.role]) && !isAdmin) {
        history.push('/dashboard');
      } else {
        setArticle(response.data);
        setContent(response.data.content.rendered);
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchInformation();
  // }, []);

  return (
    <div className={style.content}>
      {isLoading && <Loader />}
      <SeoHelmet title={article?.title?.rendered || 'Informacje'} />
      <h2 className={style.title}>{article?.title?.rendered}</h2>
      <div
        className={style.container}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

export default InformationPage;
